import React, { ReactElement } from 'react';
import { Icon, Typography } from '@cencosud-ds/bigbang';
import './style.css';

interface IPropsHeaderDrawer {
  title: string;
  onClose: () => void;
  extra?: ReactElement;
  isGoBack: string;
  onGoBack?: () => void;
}

export const HeaderDrawer: React.FC<IPropsHeaderDrawer> = props => {
  const { title, onClose, onGoBack, isGoBack } = props;

  return (
    <div className={isGoBack ? 'drawer-header-goback' : 'drawer-header'}>
      {isGoBack && (
        <div style={{ color: '#0471cb' }}>
          <div className="drawer-header-close" onClick={onGoBack}>
            <Icon name="ArrowIosLeft" size="l" />
          </div>
        </div>
      )}

      <Typography weight="bold" color="#323A44" typeElement="subtitle1">
        {title}
      </Typography>
      <div style={{ color: '#0471cb' }}>
        <div className="drawer-header-close" onClick={onClose}>
          <Icon name="Close" size="l" />
        </div>
      </div>
    </div>
  );
};
