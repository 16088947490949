import styled from '@emotion/styled';
import { colors } from '@cencosud-ds/bigbang';
import { TooltipInterface } from './interface';

export const WrapperBigTooltipTextOnly = styled.div`
  width: fit-content;
  position: relative;

  max-width: 20rem;
  min-width: 20rem;
  border-radius: 0.75rem;
  padding: 0.75rem;
  box-shadow: 0px 8px 24px rgba(50, 58, 68, 0.06);
  flex-shrink: 0;

  ${({ darkMode, content }: TooltipInterface) => {
    return `
    background:${darkMode ? colors.primary[500] : colors.neutral[0]}`;
  }};

  div:first-of-type {
    display: flex;

    div:nth-of-type(1) {
      margin-right: 0.5rem;
    }

    div:nth-of-type(2) {
      display: flex;
      height: fit-content;
      position: static;
      transform: none;

      ${({ showCloseButton }: TooltipInterface) => {
        return `
  width: ${showCloseButton ? '14.75rem' : '16.5rem'}`;
      }};
      flex-direction: column;
      margin-right: 0.25rem;

      p:first-of-type {
        padding-bottom: 0.25rem;
      }
    }

    div:nth-of-type(3) {
      ${({ showCloseButton }: TooltipInterface) => {
        return `
  display: ${showCloseButton ? 'flex' : 'none'}`;
      }};
      position: static;
      background-color: transparent;
      width: 1.5rem;
      height: 1.5rem;
      transform: none;
    }
  }

  .Tooltip-Arrow--top {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: -5px;
    left: calc(50% - 0.75rem / 2);
    ${(realProps: TooltipInterface) => {
      const props = { ...realProps };
      return `
    background:${props.darkMode ? colors.primary[500] : colors.neutral[0]};
    display: ${props.position === 'top' && props['with-triangle'] ? 'flex' : 'none'};`;
    }};
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    border-radius: 0.063rem;
  }

  .Tooltip-Arrow--right {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: calc(50% - 0.75rem / 2);
    right: -5px;
    ${(realProps: TooltipInterface) => {
      const props = { ...realProps };
      return `
    background:${props.darkMode ? colors.primary[500] : colors.neutral[0]};
    display: ${props.position === 'right' && props['with-triangle'] ? 'flex' : 'none'};`;
    }};
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    border-radius: 0.063rem;
  }

  .Tooltip-Arrow--bottom {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    left: calc(50% - 0.75rem / 2);
    bottom: -5px;
    ${(realProps: TooltipInterface) => {
      const props = { ...realProps };
      return `
    background:${props.darkMode ? colors.primary[500] : colors.neutral[0]};
    display: ${props.position === 'bottom' && props['with-triangle'] ? 'flex' : 'none'};`;
    }};
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    border-radius: 0.063rem;
  }

  .Tooltip-Arrow--left {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    top: calc(50% - 0.75rem / 2);
    left: -5px;
    ${(realProps: TooltipInterface) => {
      const props = { ...realProps };
      return `
    background:${props.darkMode ? colors.primary[500] : colors.neutral[0]};
    display: ${props.position === 'left' && props['with-triangle'] ? 'flex' : 'none'};`;
    }};
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    border-radius: 0.063rem;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
