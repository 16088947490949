import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import styled from '@emotion/styled';
import { IconSquareButton, Icons } from '@cencosud-ds/bigbang/antiguo';

import NotificationList from './components/NotificationList';

import SessionContext from '../../api/session/context';

import objectHasProperty from '../../utils/objectHasProperty';
import { headerRoutes, homeRoute } from '../../utils/routes';
import { trackGTMEventAction } from '../../utils/analytics';

import t from '../../utils/translate';
import { Modal, Typography, Button, colors, Icon } from '@cencosud-ds/bigbang';
import './index.css';
import getIcon from './../../utils/getIcon';

const HeaderStyled = styled.div`
  background: 'white';
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  height: 98px;
  justify-content: space-between;
  padding-left: 2em;
`;

const MarketPlaceLogoStyled = styled(Icons.LogoParis)`
  &:hover {
    cursor: pointer;
  }
`;

function Header(props) {
  const {
    history,
    location: { pathname },
  } = props;
  const { notifications, openNotification } = useContext(SessionContext);
  const [notificationCount, setNotificationCount] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(notifications) && notifications.length) {
      setNotificationCount(notifications.filter(n => !n.opened).length);
    }
  }, [notifications]);

  const [showNotificationList, setShowNotificationList] = useState(false);
  const notificationIconText = notificationCount ? `Notificaciones (${notificationCount})` : 'Notificaciones';

  const handlerShowNotificationList = () => {
    trackGTMEventAction({ action: 'Clic botón', category: 'Notificaciones', tag: pathname });
    setShowNotificationList(!showNotificationList);
  };

  return (
    <>
      <HeaderStyled>
        <span onClick={() => history.push(homeRoute.path)}>
          <MarketPlaceLogoStyled />
        </span>
        <div>
          <IconSquareButton
            id={'Notifications'}
            onClick={handlerShowNotificationList}
            text={notificationIconText}
            hidden={false}
            Icon={Icons.Notificaciones}
            variant={notificationCount > 0 ? 'alert' : 'primary'}
          />
          {headerRoutes.map((route, index) => (
            <IconSquareButton
              key={`header-IconSquareButton-${index}`}
              onClick={() => {
                if (route.path === '/logout') {
                  const isAlertAccount = localStorage.getItem('alertAccount');
                  if (isAlertAccount && isAlertAccount === 'on') {
                    localStorage.setItem('route', route.path);
                    setOpen(true);
                  } else {
                    history.push(route.path);
                  }
                } else {
                  history.push(route.path);
                }
              }}
              text={route.text}
              {...route}
              hidden={false}
              Icon={getIcon(route.icon)}
              variant={objectHasProperty(route, 'variant') ? route.variant : 'primary'}
            />
          ))}
        </div>
      </HeaderStyled>
      <NotificationList
        notifications={notifications}
        handlerShowNotificationList={handlerShowNotificationList}
        showNotificationList={showNotificationList}
        openNotification={openNotification}
      />
      <Modal
        open={open}
        persistent={false}
        onClose={function() {
          setOpen(false);
        }}
        with-blur={false}
        id="modal-example"
      >
        <section
          className={localStorage.getItem('dataFormikFinancial') ? 'exit-alert-data-financial' : 'exit-alert-data'}
        >
          <div className="close-alert">
            <div
              style={{ color: '#0471cb' }}
              onClick={function() {
                setOpen(false);
              }}
            >
              <Icon name={'Close'} />
            </div>
          </div>
          <div className="title-alert">
            <Typography
              typeElement="subtitle2"
              weight="bold"
              color={colors.primary[500]}
              children={localStorage.getItem('dataFormikFinancial') ? t('titleAlertFinancial') : t('titleAlert')}
            />
          </div>
          <div className="content-alert">
            {localStorage.getItem('dataFormikFinancial') ? t('descriptionAlertFinancial') : t('descriptionAlert')}
          </div>
          <div className="container-buttons-alert">
            <div style={{ width: '50%' }}>
              <Button
                darkMode={true}
                type-button="primary"
                variant-button="m"
                width="100%"
                className="button-yes"
                disabled={false}
                loading={false}
                onClick={function(e) {
                  const exitModal = new Promise((resolve, reject) => {
                    setOpen(false);
                    const route = localStorage.getItem('route') ? localStorage.getItem('route') : '';
                    resolve(route);
                  });
                  exitModal
                    .then(data => {
                      if (data) {
                        history.push(data);
                      }
                    })
                    .catch(err => {
                      console.error('seller', err);
                    });
                }}
                children={localStorage.getItem('dataFormikFinancial') ? t('noAlertFinancial') : t('yesAlert')}
              />
            </div>

            <div style={{ width: '50%' }}>
              <Button
                darkMode={false}
                type-button="primary"
                className="button-no"
                variant-button="m"
                width="100%"
                disabled={false}
                loading={false}
                onClick={function(e) {
                  setOpen(false);
                }}
                children={localStorage.getItem('dataFormikFinancial') ? t('yesAlertFinancial') : t('noAlert')}
              />
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}

export default withRouter(Header);
