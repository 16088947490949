import { Column } from '@cencosud-ds/bigbang/antiguo';
import styled from '@emotion/styled';

export const StyledColumn = styled(Column)`
  width: 100%;
  button {
    margin: 1em;
  }
`;

export const WrapperButtons = styled(Column)`
  width: 100%;
  margin-top: 1em;
  button {
    width: 45%;
    font-weight: 500;
    font-size: 1.1em;
  }
`;
