import * as React from 'react';

function SvgNivelHalf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="227" height="228" viewBox="0 0 227 228" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8" filter="url(#filter0_f_1049_20397)">
        <ellipse cx="113.719" cy="114.165" rx="63.148" ry="63.3962" fill="#B8DCFF" />
      </g>
      <path
        d="M132.806 28.3165L124.638 44.8765L122.853 48.4765L112.677 24.8065C113.302 23.8165 114.418 23.1865 115.623 23.1865H129.682C132.27 23.1865 133.966 25.9765 132.806 28.3165Z"
        fill="#0041D6"
      />
      <path
        d="M133.119 61.0316V53.4716C133.119 46.5416 127.495 40.8716 120.622 40.8716H105.179C98.3056 40.8716 92.682 46.5416 92.682 53.4716V61.0316C78.3552 68.4566 68.5361 83.5316 68.5361 100.902C68.5361 125.607 88.3973 145.632 112.9 145.632C137.403 145.632 157.264 125.607 157.264 100.902C157.264 83.4866 147.445 68.4566 133.119 61.0316ZM125.085 57.8366C121.202 56.7116 117.14 56.1266 112.9 56.1266C108.66 56.1266 104.599 56.7116 100.716 57.8366V53.4716C100.716 50.9966 102.724 48.9716 105.179 48.9716H120.622C122.318 48.9716 123.791 49.9166 124.549 51.3116L124.594 51.3566C124.906 51.9866 125.085 52.7066 125.085 53.4266V57.8366V57.8366Z"
        fill="url(#paint0_linear_1049_20397)"
      />
      <path
        d="M120.621 40.872C127.495 40.872 133.118 46.542 133.118 53.472V61.032C147.445 68.457 157.264 83.487 157.264 100.857C157.264 125.562 137.403 145.587 112.9 145.587C88.3972 145.587 68.536 125.562 68.536 100.857C68.536 83.487 78.355 68.412 92.6818 61.032V53.472C92.6818 46.542 98.3055 40.872 105.179 40.872H120.621ZM100.716 57.837C104.599 56.712 108.66 56.127 112.9 56.127C117.14 56.127 121.202 56.712 125.085 57.837V53.472C125.085 50.997 123.076 48.972 120.621 48.972H105.179C102.724 48.972 100.716 50.997 100.716 53.472V57.837M120.621 39.522H105.179C97.5467 39.522 91.3429 45.777 91.3429 53.472V60.177C84.291 64.002 78.3103 69.582 74.0703 76.467C69.5625 83.757 67.1523 92.172 67.1523 100.857C67.1523 126.282 87.6831 146.937 112.855 146.937C138.073 146.937 158.559 126.237 158.559 100.857C158.559 92.217 156.148 83.802 151.641 76.467C147.401 69.582 141.42 64.002 134.368 60.177V53.472C134.457 45.777 128.253 39.522 120.621 39.522ZM102.055 56.082V53.472C102.055 51.717 103.438 50.322 105.179 50.322H120.621C122.362 50.322 123.746 51.717 123.746 53.472V56.082C120.22 55.227 116.56 54.777 112.9 54.777C109.24 54.777 105.58 55.227 102.055 56.082Z"
        fill="url(#paint1_linear_1049_20397)"
      />
      <path
        d="M113.436 141.086C135.497 141.086 153.381 122.833 153.381 100.316C153.381 77.7997 135.497 59.5464 113.436 59.5464C91.3745 59.5464 73.4902 77.7997 73.4902 100.316C73.4902 122.833 91.3745 141.086 113.436 141.086Z"
        fill="url(#paint2_linear_1049_20397)"
      />
      <path
        d="M103.554 108.577L103.554 94.7205C103.554 93.4252 104.593 92.3778 105.875 92.3813L119.618 92.3813C120.903 92.3813 121.942 93.4286 121.938 94.7205L121.938 108.577C121.938 109.873 120.9 110.92 119.618 110.916H105.875C104.59 110.916 103.551 109.869 103.554 108.577Z"
        fill="#515151"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M100.655 108.569L100.655 94.7205C100.655 91.8165 102.987 89.4527 105.877 89.4579C105.879 89.4579 105.881 89.4579 105.882 89.4579L119.618 89.4579C122.498 89.4579 124.843 91.809 124.838 94.7238C124.838 94.7253 124.838 94.7268 124.838 94.7283L124.838 108.577C124.838 111.481 122.506 113.845 119.615 113.84C119.613 113.84 119.612 113.84 119.61 113.84L105.875 113.84C102.994 113.84 100.65 111.488 100.655 108.574C100.655 108.572 100.655 108.571 100.655 108.569ZM103.554 108.577L103.554 94.7205C103.554 93.4251 104.593 92.3778 105.875 92.3812L119.618 92.3812C120.903 92.3812 121.942 93.4286 121.938 94.7205L121.938 108.577C121.938 109.872 120.9 110.92 119.618 110.916H105.875C104.59 110.916 103.551 109.869 103.554 108.577Z"
        fill="url(#paint3_linear_1049_20397)"
      />
      <path
        opacity="0.5"
        d="M112.231 141.671C90.406 141.671 72.6425 123.807 72.2854 101.667C72.2854 101.892 72.2854 102.162 72.2854 102.387C72.2854 124.887 90.1828 143.157 112.231 143.157C134.279 143.157 152.177 124.932 152.177 102.387C152.177 102.162 152.177 101.892 152.177 101.667C151.82 123.807 134.101 141.671 112.231 141.671Z"
        fill="white"
      />
      <path
        d="M112.9 141.266C90.8075 141.266 72.8208 123.131 72.8208 100.856C72.8208 78.5808 90.8075 60.4458 112.9 60.4458C134.993 60.4458 152.98 78.5808 152.98 100.856C152.98 123.176 134.993 141.266 112.9 141.266ZM112.9 63.6408C92.5481 63.6408 75.945 80.3358 75.945 100.901C75.945 121.466 92.5035 138.161 112.9 138.161C133.252 138.161 149.856 121.466 149.856 100.901C149.856 80.3358 133.252 63.6408 112.9 63.6408Z"
        fill="url(#paint4_linear_1049_20397)"
      />
      <path
        d="M124.55 51.357C123.791 49.962 122.318 49.017 120.622 49.017H115.802L111.74 40.917H119.863L124.55 51.357Z"
        fill="#501717"
      />
      <path
        d="M117.988 53.4261H104.822C103.483 53.4261 102.278 52.6611 101.698 51.4911L100.537 49.1061L90.4951 28.8111C89.3347 26.4711 91.0307 23.7261 93.6193 23.7261H107.678C109.062 23.7261 110.312 24.5361 110.847 25.7961L121.157 48.5211C121.247 48.7461 121.336 48.9711 121.38 49.1961C121.916 51.2211 120.309 53.4261 117.988 53.4261Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M76.7036 100.722C76.7036 80.8765 92.6372 64.8115 112.32 64.8115C132.003 64.8115 147.936 80.8765 147.936 100.722C147.936 120.567 133.208 95.3215 113.57 95.3215C93.8869 95.2765 76.7036 120.522 76.7036 100.722Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M129.503 46.0914C131.11 48.2514 131.467 50.7264 130.307 51.6264C129.146 52.4814 129.012 49.8714 127.406 47.6664C125.799 45.4614 123.344 44.6064 124.505 43.7064C125.665 42.8064 127.897 43.9314 129.503 46.0914Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M136.066 80.207C137.303 77.8302 134.501 73.891 129.809 71.4085C125.116 68.9259 120.309 68.8402 119.072 71.217C117.835 73.5938 120.637 77.533 125.33 80.0155C130.023 82.498 134.829 82.5838 136.066 80.207Z"
        fill="white"
      />
      <path
        d="M169.668 21.7017C170.191 20.2832 172.197 20.2832 172.72 21.7017L176.171 31.0655C176.335 31.5109 176.686 31.8624 177.131 32.0277L186.476 35.4992C187.889 36.0243 187.889 38.0234 186.476 38.5484L177.131 42.02C176.686 42.1853 176.335 42.5367 176.171 42.9821L172.72 52.3459C172.197 53.7645 170.191 53.7645 169.668 52.3459L166.216 42.9821C166.052 42.5367 165.702 42.1853 165.257 42.02L155.912 38.5484C154.498 38.0234 154.498 36.0243 155.912 35.4992L165.257 32.0277C165.702 31.8624 166.052 31.5109 166.216 31.0655L169.668 21.7017Z"
        fill="#1C8AF5"
      />
      <path
        d="M32.7058 107.236C33.2287 105.818 35.235 105.818 35.7578 107.236L37.682 112.457C37.8462 112.902 38.1967 113.254 38.6417 113.419L43.8597 115.357C45.273 115.882 45.273 117.882 43.8597 118.407L38.6417 120.345C38.1967 120.51 37.8462 120.862 37.682 121.307L35.7578 126.528C35.235 127.946 33.2287 127.946 32.7058 126.528L30.7816 121.307C30.6174 120.862 30.2669 120.51 29.822 120.345L24.604 118.407C23.1906 117.882 23.1906 115.882 24.604 115.357L29.822 113.419C30.2669 113.254 30.6174 112.902 30.7816 112.457L32.7058 107.236Z"
        fill="#B8DCFF"
      />
      <path
        d="M193.665 70.0035C194.188 68.5849 196.195 68.5849 196.717 70.0035L197.987 73.4483C198.151 73.8936 198.502 74.2451 198.947 74.4104L202.396 75.6917C203.809 76.2168 203.809 78.2158 202.396 78.7409L198.947 80.0222C198.502 80.1875 198.151 80.5389 197.987 80.9843L196.717 84.4291C196.195 85.8476 194.188 85.8476 193.665 84.4291L192.396 80.9843C192.232 80.5389 191.881 80.1875 191.436 80.0222L187.987 78.7409C186.573 78.2158 186.573 76.2168 187.987 75.6917L191.436 74.4104C191.881 74.2451 192.232 73.8936 192.396 73.4483L193.665 70.0035Z"
        fill="#B8DCFF"
      />
      <path
        d="M62.3577 158.557C62.8806 157.139 64.8869 157.139 65.4098 158.557L66.6795 162.002C66.8436 162.447 67.1942 162.799 67.6391 162.964L71.0883 164.245C72.5017 164.77 72.5017 166.77 71.0883 167.295L67.6391 168.576C67.1942 168.741 66.8436 169.093 66.6795 169.538L65.4098 172.983C64.8869 174.401 62.8806 174.401 62.3577 172.983L61.088 169.538C60.9239 169.093 60.5733 168.741 60.1284 168.576L56.6792 167.295C55.2658 166.77 55.2658 164.77 56.6792 164.245L60.1284 162.964C60.5733 162.799 60.9239 162.447 61.088 162.002L62.3577 158.557Z"
        fill="#1C8AF5"
      />
      <defs>
        <filter
          id="filter0_f_1049_20397"
          x="0.570557"
          y="0.769043"
          width="226.296"
          height="226.792"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_1049_20397" />
        </filter>
        <linearGradient
          id="paint0_linear_1049_20397"
          x1="80.2881"
          y1="54.842"
          x2="149.692"
          y2="113.691"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.0111016" stop-color="#5B5B5B" />
          <stop offset="0.0992964" stop-color="#AAAAAA" />
          <stop offset="0.1398" stop-color="#C9C9C9" />
          <stop offset="0.2581" stop-color="#F9F9F9" />
          <stop offset="0.3602" stop-color="#C9C9C9" />
          <stop offset="0.4892" stop-color="#505050" />
          <stop offset="0.4991" stop-color="#5B5B5B" />
          <stop offset="0.5771" stop-color="#AAAAAA" />
          <stop offset="0.6129" stop-color="#C9C9C9" />
          <stop offset="0.7366" stop-color="#F9F9F9" />
          <stop offset="1" stop-color="#C9C9C9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1049_20397"
          x1="107.673"
          y1="143.532"
          x2="137.532"
          y2="121.644"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.5" stop-color="#F9F9F9" />
          <stop offset="1" stop-color="#C9C9C9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1049_20397"
          x1="73.4883"
          y1="100.302"
          x2="153.405"
          y2="100.302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#696969" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1049_20397"
          x1="112.727"
          y1="86.0093"
          x2="112.711"
          y2="114.635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.0111016" stop-color="#5B5B5B" />
          <stop offset="0.0992964" stop-color="#AAAAAA" />
          <stop offset="0.1398" stop-color="#C9C9C9" />
          <stop offset="0.2581" stop-color="#F9F9F9" />
          <stop offset="0.3602" stop-color="#C9C9C9" />
          <stop offset="0.4892" stop-color="#505050" />
          <stop offset="0.4991" stop-color="#5B5B5B" />
          <stop offset="0.5771" stop-color="#AAAAAA" />
          <stop offset="0.6129" stop-color="#C9C9C9" />
          <stop offset="0.7366" stop-color="#F9F9F9" />
          <stop offset="0.8387" stop-color="#C9C9C9" />
          <stop offset="1" stop-color="#505050" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1049_20397"
          x1="83.4378"
          y1="71.2237"
          x2="137.71"
          y2="125.113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.0111016" stop-color="#5B5B5B" />
          <stop offset="0.0992964" stop-color="#AAAAAA" />
          <stop offset="0.1398" stop-color="#C9C9C9" />
          <stop offset="0.2581" stop-color="#F9F9F9" />
          <stop offset="0.3602" stop-color="#C9C9C9" />
          <stop offset="0.4892" stop-color="#505050" />
          <stop offset="0.4991" stop-color="#5B5B5B" />
          <stop offset="0.5771" stop-color="#AAAAAA" />
          <stop offset="0.6129" stop-color="#C9C9C9" />
          <stop offset="0.7366" stop-color="#F9F9F9" />
          <stop offset="0.8387" stop-color="#C9C9C9" />
          <stop offset="1" stop-color="#505050" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNivelHalf;
