const isCompanyExtensionValid = (extension: string) => {
  switch (extension) {
    case 'SA':
    case 'LTDA':
    case 'SPA':
    case 'EIRL':
      return true;
    default:
      return false;
  }
};

const isFundationCompany = (firstWord: string): boolean => {
  return firstWord === 'FUNDACIÓN' || firstWord === 'FUNDACION';
};

const normalizeCompanyName = (name: string): string | false => {
  const expRegular = /(\S{1,})/g;
  const empty = '';
  const whiteSpace = ' ';
  if (name) {
    const wordListDirty = name.split(expRegular);

    const wordList: string[] = [];
    wordListDirty.forEach(item => {
      const word = item
        .trim()
        .replaceAll('.', '')
        .toUpperCase();
      if (word !== empty) {
        wordList.push(word);
      }
    });

    const firstWord = wordList[0];
    const lastWord = wordList[wordList.length - 1];

    if (isFundationCompany(firstWord) || isCompanyExtensionValid(lastWord)) {
      return wordList.join(whiteSpace);
    }
  }

  return false;
};

const extractWordsCompanyName = (name: string): string[] => {
  const expRegular = /(\S{1,})/g;
  const empty = '';
  if (name) {
    const wordListDirty = name.split(expRegular);

    const wordList: string[] = [];
    wordListDirty.forEach(item => {
      const word = item
        .trim()
        .replaceAll('.', '')
        .toUpperCase();
      if (word !== empty) {
        wordList.push(word);
      }
    });
    return wordList;
  }
  return [];
};

const normalizeBusinessName = (name: string): string | false => {
  const whiteSpace = ' ';
  const wordList: string[] = extractWordsCompanyName(name);

  if (wordList.length > 0) {
    const lastWord = wordList[wordList.length - 1];

    if (!isCompanyExtensionValid(lastWord)) {
      return wordList.join(whiteSpace);
    }
  }
  return false;
};

const extractCompanyExtension = (name: string): string | boolean => {
  if (name) {
    const wordList = name.split(' ');
    if (wordList.length > 0) {
      return wordList[wordList.length - 1];
    }
  }
  return false;
};

const checkCompanyIsFundation = (name: string): string | boolean => {
  if (name) {
    const wordList = name.split(' ');
    if (wordList.length > 0) {
      return isFundationCompany(wordList[0]);
    }
  }
  return false;
};

export { normalizeCompanyName, extractCompanyExtension, checkCompanyIsFundation, normalizeBusinessName };
