import React, { useState } from 'react';
import { Icon, ListBox } from '@cencosud-ds/bigbang';
import GlobalSearch from '../../../../../components/BigBangGlobalSearch';
import { ActivityCodes } from 'utils/signup/activityNumbers';
import { allownumber } from 'utils/patterns';
interface GlobalSearchTemplateI {
  formik: any;
  feedbackText: string;
  inputState: string;
}

interface ActivityCodeI {
  code: number;
  label: string;
}

const GlobalSearchTemplate: React.FC<GlobalSearchTemplateI> = props => {
  const { formik, feedbackText, inputState } = props;
  const [showResults, setShowResults] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(
    formik.values.codeUploadFile ? `${formik.values.codeUploadFile} - ${formik.values.codeUploadFileLabel}` : '',
  );
  const [data, setData] = useState<ActivityCodeI[]>([]);
  const [showCategories, setShowCategories] = useState<boolean>(false);
  const [valueCategory, setValueCategory] = useState<string>('Buscar por');

  const getData = async (text: string) => {
    setLoading(true);
    setShowResults(false);
    if (valueCategory === 'Actividad') {
      setData(
        ActivityCodes.filter(
          ({ label }) =>
            label
              .toString()
              .toLowerCase()
              .search(text.toLowerCase()) !== -1,
        ).slice(0, 20),
      );
    } else if (valueCategory === 'Código') {
      setData(
        ActivityCodes.filter(
          ({ code }) =>
            code
              .toString()
              .toLowerCase()
              .search(text.toLowerCase()) !== -1,
        ).slice(0, 20),
      );
    } else {
      if (isNaN(Number(text))) {
        setData(
          ActivityCodes.filter(
            ({ label }) =>
              label
                .toString()
                .toLowerCase()
                .search(text.toLowerCase()) !== -1,
          ).slice(0, 20),
        );
      } else {
        setData(
          ActivityCodes.filter(
            ({ code }) =>
              code
                .toString()
                .toLowerCase()
                .search(text.toLowerCase()) !== -1,
          ).slice(0, 20),
        );
      }
    }

    setLoading(false);
    setShowResults(true);
  };

  const slotResultsCreator: any = (newData: ActivityCodeI[]) => {
    const handleOnClick = (result: string) => {
      setShowResults(false);
      setInputValue(result);
      formik.setFieldValue('codeUploadFile', result.split(' - ')[0]);
      formik.setFieldValue('codeUploadFileLabel', result.split(' - ')[1]);
    };

    const results =
      newData.length >= 1 &&
      newData.map(({ code, label }) => ({
        onClick: handleOnClick,
        slot3: <Icon name="DiagonalArrowLeftUp" />,
        'title-text': code,
        value: `${code} - ${label}`,
        'subtitle-text': label,
      }));

    return results ? <ListBox variant="default" maxHeight="160px" data={results} /> : undefined;
  };

  const handleOnChange = (text: string) => {
    getData(text);
    if (text.length === 0) {
      formik.setFieldValue('codeUploadFile', undefined);
      formik.setFieldValue('codeUploadFileLabel', undefined);
    }
    setInputValue(text);
  };

  const slotCategoriesCreator = () => {
    const categories = [
      { value: '1', text: 'Actividad' },
      { value: '2', text: 'Código' },
    ];

    const handleOnClickCategory = (selectValue: string) => {
      const categoryText = categories.find(({ value }) => value === selectValue).text || '';
      setInputValue('');
      setValueCategory(categoryText);
      formik.setFieldValue('codeUploadFile', undefined);
      formik.setFieldValue('codeUploadFileLabel', undefined);
      setShowCategories(false);
    };

    const results =
      categories.length >= 1 &&
      categories.map(({ text, value }: any) => ({
        onClick: handleOnClickCategory,
        'title-text': text,
        value,
        slot1: <Icon isFill={false} name="Archive" size="s" />,
      }));

    return results ? <ListBox variant="default" maxHeight="160px" data={results} /> : undefined;
  };

  return (
    <GlobalSearch
      label-category={valueCategory}
      onChangeInput={handleOnChange}
      isLoading={loading}
      input-value={inputValue}
      slot-categories={slotCategoriesCreator()}
      slot-results={slotResultsCreator(data)}
      icon-end={<></>}
      maxLength={valueCategory === 'Código' ? 6 : 255}
      without-results-texts={{
        title: 'No hay sugerencias para',
        description:
          'Si tu búsqueda no arrojó resultados, revisa tu ortografía navega para encontrar un producto similar.',
      }}
      disabled={false}
      width="48rem"
      placeholder="Busca por actividad o código"
      feedback-text={feedbackText}
      input-state={inputState}
      onKeyPress={event => {
        if (valueCategory === 'Código' && !allownumber.test(event.key)) {
          event.preventDefault();
        }
      }}
      onPaste={event => {
        if (valueCategory === 'Código' && !allownumber.test(event.clipboardData.getData('Text'))) {
          event.preventDefault();
        }
      }}
      {...{ showCategories, setShowCategories, showResults, setShowResults }}
    />
  );
};

export default GlobalSearchTemplate;
