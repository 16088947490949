import React from 'react';
import { Input } from '@cencosud-ds/bigbang';
import { FormSection } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { allowEmail } from 'utils/patterns';

export const NotificationInfo: React.FC<{ formik: any }> = props => {
  const { formik } = props;
  return (
    <>
      <FormSection
        label={t('sign_up_contact_notification_info_label')}
        description={<span>{t('sign_up_contact_notification_info_description')}</span>}
      >
        <div className="row mb-8">
          <div className="col-6">
            <Input
              name="notificationInfoEmail"
              input-state={
                formik.touched.notificationInfoEmail && formik.errors.notificationInfoEmail ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              maxLength={100}
              label-text={t('sign_up_contact_notification_info_email')}
              feedback-text={
                formik.touched.notificationInfoEmail && formik.errors.notificationInfoEmail
                  ? formik.errors.notificationInfoEmail
                  : ''
              }
              value={formik.values.notificationInfoEmail ? formik.values.notificationInfoEmail : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <Input
              name="notificationInfoConfirmEmail"
              value={formik.values.notificationInfoConfirmEmail ? formik.values.notificationInfoConfirmEmail : ''}
              input-state={
                formik.touched.notificationInfoConfirmEmail && formik.errors.notificationInfoConfirmEmail
                  ? 'error'
                  : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              maxLength={100}
              label-text={t('sign_up_contact_notification_info_confirm_email')}
              feedback-text={
                formik.touched.notificationInfoConfirmEmail && formik.errors.notificationInfoConfirmEmail
                  ? formik.errors.notificationInfoConfirmEmail
                  : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </FormSection>
    </>
  );
};
