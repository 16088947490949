import { Modal } from '@cencosud-ds/bigbang/antiguo';
import React from 'react';
import { NotificationModalProps } from './interfaces';
import SelectComponent from '../Notifications/selectComponent';

import { trackGTMEventAction } from '../../../../utils/analytics';

const getModalSize = (size: string, content: string) => {
  if (size === 'custom') {
    return { width: 'auto', height: 'auto' };
  }

  if (size === 'l' || content.length >= 3000) {
    return { width: '800px', height: '600px' };
  }

  if (size === 'm' || (content.length < 3000 && content.length >= 1500)) {
    return { width: '600px', height: '400px' };
  }

  return { width: '400px', height: '300px' };
};

export const NotificationModal = (props: NotificationModalProps) => {
  const { openModal, setOpenModal, notification, setActiveNotification, handlerShowNotificationList } = props;
  const content = notification ? notification.notification.config.content : '';
  const notificationSize = notification ? notification.notification.size : '';
  const [contentIsObject] = React.useState<boolean>(() => content.startsWith('{') && content.endsWith('}'));
  const toggleModalState = () => {
    if (notification) {
      trackGTMEventAction({
        category: 'Notificaciones',
        action: 'Modal notificaciones',
        tag: `Clic: ${notification ? notification.notification.label : ''}`,
      });
    }

    setActiveNotification(null);
    setOpenModal(false);
    handlerShowNotificationList();
  };
  const setHtml = () => ({ __html: content });
  const size = getModalSize(notificationSize, content);

  return (
    <Modal
      modalIsOpen={openModal}
      modalTitle={notification ? notification.notification.label : ''}
      toggleModalIsOpenState={toggleModalState}
      closable="all"
      width={size.width}
      height={size.height}
    >
      {contentIsObject ? (
        <SelectComponent toggleModalState={toggleModalState} info={content} />
      ) : (
        <div dangerouslySetInnerHTML={setHtml()} />
      )}
    </Modal>
  );
};
