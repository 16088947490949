import styled from '@emotion/styled';

export const Container = styled.div`
   {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 8px;
    width: 302px;
    height: 347px;
    background: #ffffff;
    border-radius: 8px;
  }
`;

export const Description = styled.div`
   {
    width: 278px;
    height: 128px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #323a44;
  }
`;

export const Title = styled.div`
   {
    width: 278px;
    height: 21px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: #323a44;
  }
`;

export const Content = styled.div`
   {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 278px;
    height: 148px;
    background: #e1f0ff;
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }
`;

export const ContentRow = styled.div`
   {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TitleTable = styled.p`
   {
    width: auto;
    height: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.25px;
    color: #166dc2;
  }
`;

export const ItemTableLink = styled.a`
   {
    width: auto;
    height: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #166dc2;
  }
`;

export const ItemTable = styled.p`
   {
    width: auto;
    height: 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.25px;
    color: #323a44;
  }
`;

export const TooltipPopover = styled.div`
   {
    padding: 12px;
    isolation: isolate;
    width: 273px;
    height: 72px;
    color: white;
    background: #166dc2;
    box-shadow: 0px 8px 24px rgba(50, 58, 68, 0.06);
    border-radius: 12px;
  }
`;
export const TextTooltip = styled.p`
   {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
  }
`;

export const TextTooltipLink = styled.a`
   {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  &:hover {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  &:focus {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  &:active {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
`;
