import React from 'react';
import { typeType } from 'components/Reputation/Satelite/interface';
import { Typography, Chip, colors } from '@cencosud-ds/bigbang';
import { Steps } from 'components/Reputation';
import { LOCK } from 'variables/nivele';
import t from 'utils/translate';
import './style.css';

export default function MyReputation({ nivele }: { nivele: typeType | string }) {
  return (
    <div className="reputation">
      <div className="reputation__container--text">
        <Typography color={colors.primary[500]} weight="bold" typeElement="body2">
          {t('reputation_components_drawer_my_reputation')}
        </Typography>
        <Chip
          form="rounded"
          size="s"
          text={t('reputation_components_drawer_my_reputation_chip')}
          variant="primary"
          width-close={false}
          width-dot={false}
          className="chip"
          onClick={() =>
            window.open('https://ayuda.marketplace.paris.cl/category/reputacion-de-paris-marketplace/', '_blank')
          }
        />
      </div>
      {[LOCK].includes(nivele) && (
        <Typography color={colors.primary[500]} weight="semiBold" typeElement="body3">
          {t('reputation_components_drawer_my_reputation_message_locked')}
        </Typography>
      )}
      <div className="reputation__container--steps">
        <Steps nivel={nivele} />
      </div>
    </div>
  );
}
