import React, { useState, ReactElement, useCallback } from 'react';
import { IWizardControl } from '../interfaces/wizard-control.interface';
import { IWizardForm } from '../interfaces/wizard-form.interface';

const WizardContext = React.createContext<IWizardControl>({
  finished: false,
  forms: [],
  step: 0,
  nextStep: () => '',
  backStep: () => '',
  setForms: () => '',
  finish: () => undefined,
});

const useWizardControl = () => {
  return React.useContext(WizardContext);
};

const useWizardFormControl = (position: number) => {
  const { forms } = React.useContext(WizardContext);
  return forms[position];
};

interface IWizardProviderProps {
  children?: ReactElement[] | ReactElement;
}

const WizardProvider = ({ children }: IWizardProviderProps) => {
  const [step, setStep] = useState(0);
  const defaultWizardFormList: IWizardForm[] = [];
  const [wizardFormList, setWizardFormList] = useState(defaultWizardFormList);
  const [finished, setFinished] = useState(false);

  const finish = useCallback(() => {
    setFinished(true);
  }, [step]);

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const backStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const setForms = useCallback(
    (update: IWizardForm[]) => {
      setWizardFormList(update);
    },
    [wizardFormList],
  );

  return (
    <WizardContext.Provider value={{ step, nextStep, backStep, forms: wizardFormList, finished, setForms, finish }}>
      {children}
    </WizardContext.Provider>
  );
};

export { WizardProvider, useWizardControl, useWizardFormControl };
