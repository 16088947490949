import { add, setHours, setMinutes, setSeconds } from 'date-fns';

const forceMidnight = (date: string | Date) => {
  let toDate = new Date(date);
  toDate = setHours(toDate, 0);
  toDate = setMinutes(toDate, 0);
  toDate = setSeconds(toDate, 0);

  return toDate;
};

const fixTimeZone = (date: string) => {
  const toDate = new Date(date);

  if (toDate.getHours() === 23) {
    return add(toDate, { hours: 1 });
  }
  return toDate;
};

export { fixTimeZone, forceMidnight };
