import React from 'react';
import * as AllNotificationsComponents from './index';
type NameComponentOptions = 'BulkLoadNotification';

export default function BulkLoadNotification(props: { info: string; toggleModalState: () => void }) {
  const [state] = React.useState(() => {
    return JSON.parse(props.info);
  });
  const Name: NameComponentOptions = state.typeChildrenComponent;
  const ComponentSelected = AllNotificationsComponents[Name];
  return <ComponentSelected toggleModalState={props.toggleModalState} info={state} />;
}
