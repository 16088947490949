import React from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import { ToggleButton } from '@cencosud-ds/bigbang';
import { WrapperBigTooltipTextOnly } from './styles';
import { colors } from '@cencosud-ds/bigbang';
import { TooltipInterface } from './interface';
import { Icon } from '@cencosud-ds/bigbang';

function BigTooltipTextOnly(props: TooltipInterface) {
  return (
    <WrapperBigTooltipTextOnly {...props}>
      <div>
        <div className="Tooltip-BigTooltipStyled-ArrowIcon">
          <Icon name="ChevronRight" iconColor="#FF8A00" />
        </div>
        <div className="contentWrapper">
          {props.title && (
            <Typography
              typeElement="body3"
              weight="bold"
              children={props.title}
              color={props.darkMode ? colors.neutral[0] : colors.neutral[400]}
            />
          )}

          <Typography
            typeElement="body3"
            weight="regular"
            children={props.content.slice(0, props.maxLetters)}
            color={props.darkMode ? colors.neutral[0] : colors.neutral[400]}
          />
        </div>

        <div>
          {props.showCloseButton ? (
            <></>
          ) : (
            <ToggleButton
              icon={<Icon name="Close" />}
              size="xs"
              onClick={props.handleCloseButtonClick}
              darkMode={props.darkMode ? true : false}
            />
          )}
        </div>
      </div>
      <div className="Tooltip-Arrow--top" />
      <div className="Tooltip-Arrow--right" />
      <div className="Tooltip-Arrow--bottom" />
      <div className="Tooltip-Arrow--left" />
    </WrapperBigTooltipTextOnly>
  );
}

BigTooltipTextOnly.defaultProps = {
  darkMode: true,
  showCloseButton: true,
  content: 'Texto informativo',
  handleCloseButtonClick: (e: any) => alert('handleCloseButtonClick'),
  'with-triangle': false,
  position: 'bottom',
  maxLetters: 250,
};

export default BigTooltipTextOnly;
