import Yup from '../utils/yup';
import t from '../utils/translate';

const requiredField = t('Required field');
const minCharacterField = t('Must contain at least 8 characters');
const differentPasswords = t('The passwords are not the same');

const ChangePasswordSchema = Yup.object().shape({
  pass: Yup.string()
    .required(requiredField)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Debe contener una mayuscula, una minuscula y un numero')
    .min(8, minCharacterField),
  password: Yup.string()
    .required(requiredField)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Debe contener una mayuscula, una minuscula y un numero')
    .min(8, minCharacterField)
    .test('passwords-match', differentPasswords, function(value) {
      return this.parent.pass === value;
    }),
});

export default ChangePasswordSchema;
