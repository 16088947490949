import React, { useEffect } from 'react';
import { Typography, colors } from '@cencosud-ds/bigbang';
import { BottomBar } from '../../components';
import { IWizardFormProps } from '../../interfaces/wizard.interface';
import { useWizardFormControl } from '../../context';
import './index.css';
import t from '../../../../../utils/translate';
import { useFormik } from 'formik';
import validationSchema from './contact.schema';
import { NotificationInfo, ComertialArea, FinanceArea, PostSell } from './components';
import { trackGTMPageViewCuston } from 'utils/analytics';

const ContactStep: React.FC<IWizardFormProps> = props => {
  const { position } = props;

  useEffect(() => {
    trackGTMPageViewCuston({
      page: '/registrate-ahora',
      title: 'Información de contacto',
    });
  }, []);

  const formWizard = useWizardFormControl(position);

  const formik = useFormik({
    initialValues: {
      notificationInfoEmail: formWizard.data.notificationInfoEmail ? formWizard.data.notificationInfoEmail : '',
      notificationInfoConfirmEmail: formWizard.data.notificationInfoConfirmEmail
        ? formWizard.data.notificationInfoConfirmEmail
        : '',
      comertialAreaName: formWizard.data.comertialAreaName ? formWizard.data.comertialAreaName : '',
      comertialAreaSecondName: formWizard.data.comertialAreaSecondName ? formWizard.data.comertialAreaSecondName : '',
      comertialAreaEmail: formWizard.data.comertialAreaEmail ? formWizard.data.comertialAreaEmail : '',
      comertialAreaConfirmEmail: formWizard.data.comertialAreaConfirmEmail
        ? formWizard.data.comertialAreaConfirmEmail
        : '',
      comertialAreaCharge: formWizard.data.comertialAreaCharge ? formWizard.data.comertialAreaCharge : '',
      comertialAreaPhone: formWizard.data.comertialAreaPhone ? formWizard.data.comertialAreaPhone : '',
      financeAreaName: formWizard.data.financeAreaName ? formWizard.data.financeAreaName : '',
      financeAreaSecondName: formWizard.data.financeAreaSecondName ? formWizard.data.financeAreaSecondName : '',
      financeAreaEmail: formWizard.data.financeAreaEmail ? formWizard.data.financeAreaEmail : '',
      financeAreaConfirmEmail: formWizard.data.financeAreaConfirmEmail ? formWizard.data.financeAreaConfirmEmail : '',
      financeAreaCharge: formWizard.data.financeAreaCharge ? formWizard.data.financeAreaCharge : '',
      financeAreaPhone: formWizard.data.financeAreaPhone ? formWizard.data.financeAreaPhone : '',
      postSellName: formWizard.data.postSellName ? formWizard.data.postSellName : '',
      postSellSecondName: formWizard.data.postSellSecondName ? formWizard.data.postSellSecondName : '',
      postSellEmail: formWizard.data.postSellEmail ? formWizard.data.postSellEmail : '',
      postSellConfirmEmail: formWizard.data.postSellConfirmEmail ? formWizard.data.postSellConfirmEmail : '',
      postSellCharge: formWizard.data.postSellCharge ? formWizard.data.postSellCharge : '',
      postSellPhone: formWizard.data.postSellPhone ? formWizard.data.postSellPhone : '',
    },
    onSubmit: values => {
      console.log(values);
    },
    validationSchema,
    enableReinitialize: true,
  });

  /**
   * Utilizado para generar acciones o eventos al regresar el Formulario
   * No esta previsto ser utilizado aún
   */
  const handleOnBack = () => {
    formWizard.data = formik.values;
  };

  /**
   * Utilizado para ejecutar los Eventos del Formulario
   * Aqui se hacen las validaciones y si funciona se devuelve TRUE o FALSE
   * tambien se asigna la data al Context
   * @returns
   */
  const handleOnNext = async (): Promise<boolean> => {
    await formik.submitForm();
    const isFormValid = formik.isValid && Object.keys(formik.touched).length > 0;

    if (isFormValid) {
      formWizard.data = formik.values;
      return true;
    }
    return false;
  };

  /**
   * Estos controles son de Ejemplo.  Lo más importantes sojn
   * -  FormSection
   * -  BottomBar
   */
  return (
    <>
      <div className="wizard-form-section pb-3">
        <div className="mb-5">
          <Typography
            typeElement="subtitle1"
            weight="semiBold"
            color={colors.primary[500]}
            children={t('sign_up_contact_howDoWeContactYou')}
          />
        </div>
        <NotificationInfo formik={formik} />
        <ComertialArea formik={formik} />
        <FinanceArea formik={formik} />
        <PostSell formik={formik} />
      </div>
      <BottomBar onBack={() => handleOnBack()} onNext={() => handleOnNext()} />
    </>
  );
};

export default ContactStep;
