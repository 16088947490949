import React from 'react';
import { HelpCenterIcon, TourGuideIcon, FulfillmentIcon, IconRendimiento, IconReputacion, IconMetrics } from './icons';
import { Container } from './container';
import './style.css';

interface IProps {
  icon?: 'TourGuide' | 'HelpCenter' | 'IconRendimiento' | 'IconReputacion';
  label?: string;
  onClick: () => void;
}

const getIconSvg = (icon?: string) => {
  switch (icon) {
    case 'TourGuide':
      return <TourGuideIcon className="ButtonNavigation-center-icon" />;
    case 'HelpCenter':
      return <HelpCenterIcon className="ButtonNavigation-center-icon" />;
    case 'FulfillmentIcon':
      return <FulfillmentIcon className="ButtonNavigation-center-icon" />;
    case 'IconRendimiento':
      return <IconRendimiento className="ButtonNavigation-center-icon" />;
    case 'IconReputacion':
      return <IconReputacion className="ButtonNavigation-center-icon" />;
    case 'IconMetrics':
      return <IconMetrics className="ButtonNavigation-center-icon" />;
    default:
      return null;
  }
};

const NavigationItem = (props: IProps) => {
  const { icon, label, onClick } = props;
  return (
    <Container id="tourGuideRoute" className="itemsMenu" label={label ? label : ''} onClick={onClick}>
      {getIconSvg(icon)}
    </Container>
  );
};

export default NavigationItem;
