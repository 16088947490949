import React from 'react';
import styled from '@emotion/styled';
// import { CONTENT_URL } from '../../config';

const FooterContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 10px 40px;
  grid-auto-flow: column;
  position: absolute;
  bottom: 0;
  width: 100% !important;
  height: 48px !important ;
  background: #ffffff;
  z-index: 4;
  margin-top: 20px;
`;

// const Dot = styled.span`
//   height: 8px;
//   width: 8px;
//   background-color: #166dc2;
//   border-radius: 50%;
//   display: inline-block;
//   margin-top: 20px;
// `;

const Brand = styled.div`
  font-family: 'Open Sans';
  color: #166dc2;
  font-weight: 600;
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
`;

// const TC = styled.div`
//   font-family: 'Open Sans';
//   color: #166dc2;
//   text-decoration: underline;
//   font-weight: 900;
//   font-size: 12px;
//   line-height: 16px;
//   margin-top: 16px;
//   cursor: pointer;
// `;
const today = new Date();
const Footer = () => {
  // const downloadTyC = async () => {
  //   const linkSource = `${CONTENT_URL}/document/downloadtermsfiles`;
  //   const downloadLink = document.createElement('a');
  //   const fileName = 'Terminos y Condiciones.pdf';

  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // };

  return (
    <FooterContainer>
      <Brand> {`© Cencosud Marketplace ${today.getFullYear()}`}</Brand>
      {/* <Dot className="dot" />
      <TC onClick={downloadTyC}>Términos y Condiciones</TC> */}
    </FooterContainer>
  );
};

export default Footer;
