import React, { useEffect, useState } from 'react';
import { Icons, Container, Column, Row, SimpleTable, Typography } from '@cencosud-ds/bigbang/antiguo';
import { NotificationListProps } from './interfaces';
import { NotificationModal } from '../NotificationModal';

import { UserNotificationInterface } from '../../../../api/session/interfaces';

import t from '../../../../utils/translate';
import { trackGTMEventAction } from '../../../../utils/analytics';

import { NotificationListStyled } from './styles';
import getIcon from 'utils/getIcon';

const NotificationList = (props: NotificationListProps) => {
  const { notifications, showNotificationList, openNotification, handlerShowNotificationList } = props;
  const [openModal, setOpenModal] = useState(false);
  const [activeNotification, setActiveNotification] = useState<UserNotificationInterface | null>(null);

  useEffect(() => {
    if (activeNotification) {
      setOpenModal(true);
    }
  }, [activeNotification]);

  const onClick = (row: any, index: number) => {
    const [, notificationName] = row;

    if (notificationName) {
      trackGTMEventAction({ action: 'Clic notificaciones', category: 'Notificaciones', tag: notificationName });
    }

    openNotification(notifications[index].notification.id);
    const selectedNotification = notifications[index];
    if (selectedNotification) {
      setActiveNotification(selectedNotification);
    }
  };

  return (
    <>
      {
        // @ts-ignore
        <NotificationListStyled showNotificationList={showNotificationList}>
          <Container>
            <Row>
              <Column>
                {Array.isArray(notifications) && notifications.length ? (
                  <SimpleTable
                    header={[]}
                    items={notifications.map((n, index: number) => {
                      const Icon = getIcon(n.notification.icon);

                      if (!n.opened) {
                        const IsNew = Icons.Warning;
                        return [
                          <Icon key={`notification-icon-${index}`} />,
                          n.notification.label,
                          <IsNew key={`notification-is-new-${index}`} />,
                        ];
                      }

                      return [<Icon key={`notification-icon-${index}`} />, n.notification.label, ''];
                    })}
                    noHeader={true}
                    variant="hover"
                    columnWidth={['50px', '200px', '50px']}
                    rowClick={onClick}
                  />
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Typography variant="h3">{t('emptyNotifications')}</Typography>
                  </div>
                )}
              </Column>
            </Row>
          </Container>
        </NotificationListStyled>
      }
      {openModal && (
        <NotificationModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          notification={activeNotification}
          setActiveNotification={setActiveNotification}
          handlerShowNotificationList={handlerShowNotificationList}
        />
      )}
    </>
  );
};

export default NotificationList;
