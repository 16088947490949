import React from 'react';
import rehypeHighlight from 'rehype-highlight';
import Drawer from 'components/Drawer';
import ReactMarkdown from 'react-markdown';
import { Typography, Loader } from '@cencosud-ds/bigbang';
import { HeaderDrawer } from './Header';
import { FooterDrawer } from './Footer';
import { Questions } from '../Questions';
import { Toast } from '../Toast';
import { Ratings, RatingsNo, RatingsYes } from '../CardRatings';
import { IDataQuestions, IPropsDrawerLearningGuide } from '../interfaces';

export const LearningGuide: React.FC<IPropsDrawerLearningGuide> = props => {
  const viewQuestions: IDataQuestions = props.dataQuestions.find((data: any) => data.id === props.keyQuestions) || {};
  return (
    <>
      <Drawer onOutSideClick={props.onClose} open={props.isOpen} width={490}>
        <>
          <HeaderDrawer
            onClose={props.onClose}
            title={props.titleHeader}
            extra={props.extra}
            onGoBack={props.onGoBack}
            isGoBack={props.isGoBack}
          />
          <div className="drawer-body">
            {props.isGoBack == null || props.isGoBack === '' ? (
              <>
                {props.isLoader && <Loader size="large" />}
                {props.errorMessageInfo && <Toast message={props.errorMessageInfo} />}
                {!props.isLoader && !props.errorMessageInfo && (
                  <>
                    <Typography color="#166DC2" weight="semiBold" typeElement="subtitle2">
                      {props.titleBody}
                    </Typography>
                    <ReactMarkdown className="drawer-body-markdown" remarkPlugins={[rehypeHighlight]}>
                      {props.contentBody}
                    </ReactMarkdown>
                    <Questions data={props.dataQuestions} onClickNext={e => props.onGoQuestions(e)} />
                  </>
                )}
              </>
            ) : (
              <>
                <ReactMarkdown className="drawer-body-markdown" remarkPlugins={[rehypeHighlight]}>
                  {viewQuestions.content}
                </ReactMarkdown>
                <div className="drawer__body-line" />
                {!props.isClickRatings && !viewQuestions.answer && (
                  <Ratings onClick={value => props.onClickRatings(value)} />
                )}
                {props.isClickRatings === 'yes' && <RatingsYes />}
                {!['yes', 'no'].includes(props.isClickRatings) && viewQuestions.answer && <RatingsYes />}
                {props.isClickRatings === 'no' && !viewQuestions.answer && (
                  <RatingsNo onClick={value => props.onClickRatings(value)} />
                )}
              </>
            )}
          </div>
          <FooterDrawer />
        </>
      </Drawer>
    </>
  );
};
