import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'formik';
import CustomInput from '../CustomInput/CustomInput.jsx';
import Danger from '../Typography/Danger';

const styles = {
  errorMessage: {
    textAlign: 'left',
  },
};

const InputWithErrors = props => {
  const { errors, name, touched, classes, ...rest } = props;

  return (
    <React.Fragment>
      <Field name={name} render={({ field }) => <CustomInput name={name} {...field} {...rest} />} />
      {errors && touched ? <Danger className={classes.errorMessage}>{errors}</Danger> : null}
    </React.Fragment>
  );
};

export default withStyles(styles)(InputWithErrors);
