import React from 'react';

export const ShoppingSvg = () => {
  return (
    <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1307_15668)">
        <ellipse rx="23.0622" ry="4.03588" transform="matrix(1 0 0 -1 66.3852 113.5)" fill="#ECF6FF" />
        <path
          d="M35.0168 38.8678C35.0168 36.2036 32.8126 34.0906 30.0574 34.0906C29.7819 34.0906 29.5063 34.0906 29.2308 34.1825C28.4043 31.6102 25.9246 29.7728 22.9857 29.7728C22.6183 29.7728 22.2509 29.7728 21.8836 29.8647C20.6896 28.1192 18.6691 27.0168 16.3731 27.0168C12.7913 27.0168 9.85245 29.8647 9.85245 33.3557C9.85245 33.7232 9.85245 33.9988 9.94429 34.3662C9.57692 34.2744 9.20956 34.1825 8.8422 34.1825C6.08698 34.1825 3.88281 36.2955 3.88281 38.9597C3.88281 41.6239 6.08698 43.7368 8.8422 43.7368C9.02588 43.7368 9.20956 43.7369 9.3014 43.645H29.5063C29.69 43.645 29.8737 43.7368 29.9655 43.7368C30.1492 43.7368 30.3329 43.7369 30.4247 43.645H30.6084C33.18 43.2775 35.0168 41.2564 35.0168 38.8678Z"
          fill="#B8DCFF"
        />
        <path
          d="M121.5 24.7454C121.5 20.9787 118.439 17.9914 114.612 17.9914C114.229 17.9914 113.847 17.9914 113.464 18.1213C112.316 14.4846 108.872 11.8869 104.79 11.8869C104.28 11.8869 103.77 11.8869 103.259 12.0168C101.601 9.54902 98.7949 7.99042 95.606 7.99042C90.6313 7.99042 86.5495 12.0168 86.5495 16.9524C86.5495 17.4719 86.5495 17.8615 86.6771 18.3811C86.1668 18.2512 85.6566 18.1213 85.1464 18.1213C81.3197 18.1213 78.2584 21.1086 78.2584 24.8752C78.2584 28.6419 81.3197 31.6292 85.1464 31.6292C85.4015 31.6292 85.6566 31.6292 85.7842 31.4993H113.847C114.102 31.4993 114.357 31.6292 114.484 31.6292C114.739 31.6292 114.995 31.6292 115.122 31.4993H115.377C118.949 30.9798 121.5 28.1223 121.5 24.7454Z"
          fill="#B8DCFF"
        />
        <path
          d="M94.9784 71.3671C94.9784 70.081 96.0397 69.0609 97.3663 69.0609C97.499 69.0609 97.6316 69.0609 97.7643 69.1053C98.1623 67.8635 99.3562 66.9764 100.771 66.9764C100.948 66.9764 101.125 66.9764 101.302 67.0208C101.877 66.1781 102.85 65.6459 103.955 65.6459C105.68 65.6459 107.095 67.0208 107.095 68.7061C107.095 68.8835 107.095 69.0166 107.05 69.194C107.227 69.1496 107.404 69.1053 107.581 69.1053C108.908 69.1053 109.969 70.1253 109.969 71.4115C109.969 72.6976 108.908 73.7177 107.581 73.7177C107.493 73.7177 107.404 73.7177 107.36 73.6734H97.6316C97.5432 73.6734 97.4547 73.7177 97.4105 73.7177C97.3221 73.7177 97.2336 73.7177 97.1894 73.6734H97.101C95.8628 73.496 94.9784 72.5202 94.9784 71.3671Z"
          fill="#B8DCFF"
        />
        <path
          d="M32.5001 54L47.9999 39.5L45.0001 35L26.0001 52.5L30.0001 58.5L35.5001 57.5L32.5001 54Z"
          fill="#FFDCB2"
        />
        <path
          d="M75.2119 51.8232C74.7523 51.9134 74.2595 51.7671 73.9253 51.393L60.9898 36.9356L54.8434 42.9732C54.6155 43.1969 54.3289 43.3239 54.0328 43.3578L62.13 52.2619C62.6401 52.8224 62.5979 53.6881 62.0374 54.1982C61.4768 54.7082 60.6111 54.6661 60.1011 54.1055L44.49 36.9385L27.7718 52.9895L32.1505 57.8905C32.6546 58.4545 32.6061 59.3214 32.0421 59.8255C31.4782 60.3295 30.6112 60.281 30.1072 59.7171L24.8503 53.8328C24.3577 53.2821 24.3916 52.4423 24.9233 51.9313L43.6102 33.9888C43.8782 33.7329 44.2376 33.5938 44.605 33.6079C44.974 33.6193 45.3243 33.7826 45.5726 34.0565L52.5399 41.7196C52.5931 41.4616 52.7199 41.218 52.9217 41.0193L60.0926 33.9762C60.3593 33.7139 60.7226 33.5718 61.0963 33.5846C61.4701 33.5974 61.8229 33.7623 62.0741 34.0401L70.0009 42.8981C70.0682 42.7567 70.1639 42.6252 70.2829 42.5112L77.8319 35.2326C78.1033 34.9716 78.4695 34.8334 78.8441 34.8504C79.2208 34.867 79.5723 35.0367 79.8184 35.322L89.6286 46.6254C90.1255 47.1974 90.0633 48.0626 89.4913 48.5594C88.9193 49.0563 88.0542 48.9941 87.5573 48.4221L78.6946 38.2077L72.1863 44.4842C72.0412 44.6232 71.8733 44.7247 71.6936 44.7887L75.9661 49.5647C76.4702 50.1287 76.4216 50.9956 75.8577 51.4997C75.67 51.6691 75.4459 51.7772 75.2119 51.8232Z"
          fill="#FF8A00"
        />
        <path
          d="M41.0001 103L25.0001 69.5L58.0001 64L67.5001 100.5L53.0001 103C45.4001 105 41.8335 103.833 41.0001 103Z"
          fill="#ECF6FF"
        />
        <path d="M23.5 64.5L97.5 50L96 46.5L21 61L19.5 64.5L21 69.5H25L23.5 64.5Z" fill="#ECF6FF" />
        <path
          d="M48.8214 97.3117C48.2129 97.4312 47.5803 97.1246 47.3119 96.5342L37.1732 74.3083C36.8588 73.6188 37.1636 72.8076 37.851 72.4936C38.5381 72.1775 39.3518 72.4839 39.6658 73.1714L49.8045 95.3973C50.1189 96.0869 49.8141 96.898 49.1266 97.212C49.0257 97.2606 48.9235 97.2917 48.8214 97.3117ZM75.8516 91.9794C75.7984 91.9899 75.7427 91.9986 75.6882 92.0027C74.9324 92.0583 74.2756 91.4934 74.22 90.7375L72.7437 70.9318C72.6885 70.178 73.2534 69.5213 74.0089 69.4635C74.7647 69.4079 75.4215 69.9728 75.4771 70.7287L76.9534 90.5344C77.0041 91.2316 76.5218 91.8478 75.8516 91.9794ZM66.6535 93.7857C66.6513 93.7861 66.6492 93.7865 66.6471 93.7869C65.9037 93.9285 65.185 93.4421 65.0434 92.6987L61.8356 75.9134C61.6945 75.1721 62.1771 74.4564 62.9175 74.311C62.9197 74.3106 62.9218 74.3102 62.9239 74.3097C63.6673 74.1682 64.386 74.6546 64.5276 75.398L67.7354 92.1833C67.8765 92.9246 67.3918 93.6407 66.6535 93.7857ZM84.5629 90.2599C84.4608 90.28 84.3545 90.2898 84.2452 90.2847C83.4883 90.2566 82.899 89.6188 82.9276 88.8641L83.8547 64.474C83.8829 63.7171 84.5207 63.1278 85.2754 63.1564C86.0323 63.1846 86.6216 63.8224 86.593 64.5771L85.6659 88.9672C85.6428 89.6147 85.1715 90.1404 84.5629 90.2599ZM57.5176 95.414C56.8495 95.5452 56.173 95.161 55.9542 94.4968L49.9014 76.1422C49.6654 75.424 50.0546 74.6493 50.7745 74.4107C51.4949 74.1742 52.2675 74.5638 52.5061 75.2838L58.5589 93.6384C58.7949 94.3566 58.4057 95.1313 57.6858 95.3699C57.63 95.3897 57.5751 95.4027 57.5176 95.414Z"
          fill="#FF8A00"
        />
        <path
          d="M58.5731 65.5013L58.571 65.5013C57.9618 65.5151 57.4377 65.1319 57.2455 64.5882C56.637 64.461 56.1741 63.9308 56.1571 63.2827C56.1407 62.6563 56.5452 62.1186 57.1153 61.9388L56.4203 32.9561C56.4026 32.1996 57 31.5722 57.7543 31.5524L57.7565 31.5524C58.513 31.5347 59.1404 32.1321 59.1603 32.8908L59.8606 62.1574C60.149 62.4015 60.3363 62.761 60.347 63.1685C60.3578 63.5803 60.1854 63.9536 59.9059 64.2126C59.8658 64.9164 59.2906 65.4824 58.5731 65.5013Z"
          fill="#166DC2"
        />
        <path
          d="M97.0272 57.2047L23.8336 71.578C21.5783 72.0209 19.384 70.547 18.9411 68.2916L18.2224 64.6319C17.7795 62.3765 19.2535 60.1823 21.5088 59.7394L94.7024 45.3661C96.9578 44.9232 99.152 46.3971 99.5949 48.6525L100.314 52.3122C100.756 54.5676 99.2826 56.7619 97.0272 57.2047ZM22.037 62.4288C21.2646 62.5805 20.7602 63.3314 20.9119 64.1038L21.6305 67.7635C21.7822 68.5358 22.5331 69.0402 23.3055 68.8886L96.4991 54.5153C97.2714 54.3636 97.7758 53.6127 97.6242 52.8403L96.9055 49.1807C96.7538 48.4083 96.0029 47.9039 95.2305 48.0556L22.037 62.4288Z"
          fill="#166DC2"
        />
        <path
          d="M88.5477 97.9033L47.4104 105.982C44.2826 106.596 40.6727 104.867 39.1886 102.047L22.858 71.0094C22.5052 70.3384 22.7624 69.5101 23.4334 69.1574C24.1044 68.8046 24.9328 69.0618 25.2855 69.7328L41.6158 100.769C42.5355 102.515 44.9477 103.67 46.8839 103.29L88.0191 95.2117C89.9511 94.8323 91.7262 92.8551 91.8959 90.8949L94.9406 55.829C95.007 55.0757 95.6707 54.5167 96.424 54.5831C97.1773 54.6495 97.7363 55.3131 97.6699 56.0665L94.6252 91.1323C94.3483 94.3135 91.6797 97.2882 88.5477 97.9033Z"
          fill="#166DC2"
        />
        <path
          d="M58.4135 69.1712C60.5288 69.1157 62.2018 67.4743 62.1502 65.505C62.0985 63.5357 60.3418 61.9843 58.2265 62.0398C56.1111 62.0953 54.4382 63.7367 54.4898 65.7059C54.5415 67.6752 56.2982 69.2267 58.4135 69.1712Z"
          fill="#166DC2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1307_15668">
          <rect width="120" height="120" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
