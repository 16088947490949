import React from 'react';
import { Typography, colors, Button } from '@cencosud-ds/bigbang';
import t from '../../../../../utils/translate';
import SignUpFailIcon from '../vectors/signup-fail';
import '../../index.css';

const Fail: React.FC<{ handleRetry: () => void }> = ({ handleRetry }) => {
  return (
    <div className="sending-error">
      <div className="container-horizontal">
        <SignUpFailIcon />
        <Typography
          typeElement="h3"
          color={colors.feedback.information.dark}
          children={
            <div className="success-title">
              <span>{t('sign_up_main_fail_fail_title')}</span>
            </div>
          }
        />
        <span className="summary">
          <Typography
            typeElement="body1"
            weight="bold"
            color={'#334661'}
            children={t('sign_up_main_fail_fail_description')}
          />
        </span>
        <Button
          darkMode={false}
          type-button="primary"
          variant-button="m"
          disabled={false}
          loading={false}
          width="fit-content"
          onClick={() => handleRetry()}
          children={t('sign_up_main_fail_retry')}
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default Fail;
