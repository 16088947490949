import React from 'react';
import { Input, Typography } from '@cencosud-ds/bigbang';
import { FormSection, ToolTip } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { allowAlphanumeric, allowAplphabetic, allownumber, allowEmail } from 'utils/patterns';

export const PostSell: React.FC<{ formik: any }> = props => {
  const { formik } = props;

  const getMessageTooltip = (message: string) => {
    return (
      <>
        <span className="font-message">{t(message)}</span>
      </>
    );
  };

  return (
    <>
      <FormSection
        label={t('sign_up_contact_post_sell_label')}
        description={<span>{t('sign_up_contact_post_sell_description')}</span>}
      >
        <div className="row mb-8">
          <div className="col-6">
            <Input
              name="postSellName"
              input-state={formik.touched.postSellName && formik.errors.postSellName ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_post_sell_name')}
              feedback-text={
                formik.touched.postSellName && formik.errors.postSellName ? formik.errors.postSellName : ''
              }
              value={formik.values.postSellName ? formik.values.postSellName : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <Input
              name="postSellSecondName"
              input-state={formik.touched.postSellSecondName && formik.errors.postSellSecondName ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_post_sell_second_name')}
              feedback-text={
                formik.touched.postSellSecondName && formik.errors.postSellSecondName
                  ? formik.errors.postSellSecondName
                  : ''
              }
              value={formik.values.postSellSecondName ? formik.values.postSellSecondName : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <Input
              name="postSellEmail"
              input-state={formik.touched.postSellEmail && formik.errors.postSellEmail ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_post_sell_email')}
              feedback-text={
                formik.touched.postSellEmail && formik.errors.postSellEmail ? formik.errors.postSellEmail : ''
              }
              value={formik.values.postSellEmail ? formik.values.postSellEmail : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={100}
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <Input
              name="postSellConfirmEmail"
              input-state={
                formik.touched.postSellConfirmEmail && formik.errors.postSellConfirmEmail ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_post_sell_confirm_email')}
              feedback-text={
                formik.touched.postSellConfirmEmail && formik.errors.postSellConfirmEmail
                  ? formik.errors.postSellConfirmEmail
                  : ''
              }
              value={formik.values.postSellConfirmEmail ? formik.values.postSellConfirmEmail : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={100}
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <Input
              name="postSellCharge"
              input-state={formik.touched.postSellCharge && formik.errors.postSellCharge ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_post_sell_charge')}
              feedback-text={
                formik.touched.postSellCharge && formik.errors.postSellCharge ? formik.errors.postSellCharge : ''
              }
              value={formik.values.postSellCharge ? formik.values.postSellCharge : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <div className="label-rut">
              <div className="phone-area">
                <Typography typeElement="body1" weight="regular" color="#212C39" children={t('phone_area')} />
              </div>
              <Input
                name="postSellPhone"
                input-state={formik.touched.postSellPhone && formik.errors.postSellPhone ? 'error' : 'default'}
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_contact_post_sell_phone')}
                feedback-text={
                  formik.touched.postSellPhone && formik.errors.postSellPhone ? formik.errors.postSellPhone : ''
                }
                value={formik.values.postSellPhone ? formik.values.postSellPhone : ''}
                onBlur={formik.handleBlur}
                width="323px"
                placeholder=""
                onKeyPress={event => {
                  if (!allownumber.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={9}
                onPaste={event => {
                  if (!allownumber.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              <div className="tooltip-space">
                <ToolTip msg={getMessageTooltip('phone_information')} position="top-left" />
              </div>
            </div>
          </div>
        </div>
      </FormSection>
    </>
  );
};
