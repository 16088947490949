import React, { useContext } from 'react';
import URI from 'URIjs';
import { useLocation, useHistory } from 'react-router-dom';
import { EIFFEL_PREFIX } from '../../../../config';
import SessionContext from '../../../../api/session/context';
import { importStart } from '../../../../api/import';
export default function CentryAuth() {
  const location = useLocation();
  const history = useHistory();
  const { auth } = useContext(SessionContext);
  React.useEffect(() => {
    let uri = new URI(location.pathname + location.search);
    const segments = uri.search(true);
    importStart(auth.jwt, {
      eiffelSellerId: auth.sellerId,
      code: segments.code,
    }).finally(() => {
      history.push(EIFFEL_PREFIX);
    });
  }, []);
  return <></>;
}
