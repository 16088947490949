import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Input, Typography } from '@cencosud-ds/bigbang';
import { FormSection, ToolTip } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { PartherTwo } from '../parther-two';
import { getRegions, getCommunesByRegion, SocietyExtended, Country } from 'utils/signup';
import { Representative } from '../representative';
import { IWizardForm } from 'views/pages/signup/interfaces/wizard-form.interface';
import { SocietyType } from 'utils/signup/constants/society';
import { PERSON_NATURAL } from 'variables/society';
import { allowAlphanumeric, allowAplphabetic, allownumberAndKV20 } from 'utils/patterns';
import InformativeAlert from '../InformativeAlert';
import * as RUTJS from 'rut.js';

interface IDataDrop {
  value: string;
  label: string;
  children: string;
}

export const PartherOne: React.FC<{ formik: any; stepOne: IWizardForm }> = props => {
  const { formik, stepOne } = props;

  const [optionsCommune, setOptionsCommune] = useState<IDataDrop[]>([]);

  useEffect(() => {
    setOptionsCommune(getCommunesByRegion(formik.values.regionPartherOne));
  }, [formik.values.regionPartherOne]);

  const getMessageTooltip = () => {
    return (
      <>
        <span className="font-message">{t('sign_up_legal_label_first_message_rut')}</span>
        <br />
        <br />
        <span className="bold-message">{t('sign_up_legal_label_important_message')}</span>
        <span className="font-message">{t('sign_up_legal_label_second_rut')}</span>
      </>
    );
  };

  const setRegion = (region: string) => {
    if (region !== formik.values.regionPartherOne) {
      setOptionsCommune([]);
      formik.setFieldValue('regionPartherOne', region);
    }
  };

  return (
    <div>
      <FormSection
        label={t('sign_up_legal_subtitle')}
        description={
          <div className="content-description">
            <div className="text-description">
              <span>{t('sign_up_legal_text')}</span>
            </div>
            <div className="list-description">
              <ul>
                <li itemType="circle">{t('sign_up_legal_option1')}</li>
                <li itemType="circle">{t('sign_up_legal_option2')}</li>
                <li itemType="circle">{t('sign_up_legal_option3')}</li>
                <li itemType="circle">{t('sign_up_legal_option4')}</li>
                <li itemType="circle">{t('sign_up_legal_option5')}</li>
              </ul>
            </div>
            <InformativeAlert
              title={t('sign_up_legal_alert_title')}
              description={
                <>
                  {t('sign_up_legal_alert_desc1')} <b>{t('sign_up_legal_alert_desc2')}</b>
                </>
              }
            />
          </div>
        }
      >
        <div className="content-row-parther-first">
          <div>
            <Icon name="Person" isFill={false} />
          </div>
          <div className="content-label-parther">
            <Typography
              typeElement="subtitle2"
              weight="semiBold"
              color="#212C39"
              children={t('sign_up_legal_label_first_partner')}
            />
          </div>
        </div>

        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <Dropdown
              dropdown-options={SocietyExtended}
              variant={
                formik.touched.societyTypePartherOne && formik.errors.societyTypePartherOne ? 'error' : 'default'
              }
              text-feedback={
                formik.touched.societyTypePartherOne && formik.errors.societyTypePartherOne
                  ? formik.errors.societyTypePartherOne
                  : ''
              }
              disabled={false}
              width="100%"
              placeholder={t('sign_up_legal_label_society')}
              default-value={formik.values.societyTypePartherOne !== '' ? formik.values.societyTypePartherOne : ''}
              onChange={value => formik.setFieldValue('societyTypePartherOne', value)}
              label-text={t('sign_up_choose_company_type')}
            />
          </div>
          <div className="col-6">
            {[PERSON_NATURAL].includes(formik.values.societyTypePartherOne) ? (
              <div className="label-rut">
                <Input
                  name="rutNumberPartherOne"
                  value={formik.values.rutNumberPartherOne ? formik.values.rutNumberPartherOne : ''}
                  input-state={
                    formik.touched.rutNumberPartherOne && formik.errors.rutNumberPartherOne ? 'error' : 'default'
                  }
                  disabled={false}
                  label-text={t('sign_up_legal_label_rut_pn')}
                  feedback-text={
                    formik.touched.rutNumberPartherOne && formik.errors.rutNumberPartherOne
                      ? formik.errors.rutNumberPartherOne
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={12}
                  onChange={event => {
                    let value = event.target.value;
                    if (value.length > 7) {
                      value = RUTJS.format(value);
                    }
                    return formik.setFieldValue('rutNumberPartherOne', value);
                  }}
                  onKeyUp={() => {
                    if (
                      formik.values.rutNumberPartherOne &&
                      !allownumberAndKV20.test(formik.values.rutNumberPartherOne)
                    ) {
                      formik.setFieldValue(
                        'rutNumberPartherOne',
                        formik.values.rutNumberPartherOne.slice(0, -1).toUpperCase(),
                      );
                    }
                  }}
                />
                <ToolTip msg={getMessageTooltip()} position="right" />
              </div>
            ) : (
              <Input
                name="namePartherOne"
                value={formik.values.namePartherOne ? formik.values.namePartherOne : ''}
                input-state={formik.touched.namePartherOne && formik.errors.namePartherOne ? 'error' : 'default'}
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_legal_label_name')}
                feedback-text={
                  formik.touched.namePartherOne && formik.errors.namePartherOne ? formik.errors.namePartherOne : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={150}
                onKeyPress={event => {
                  if (!allowAplphabetic.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
            )}
          </div>
        </div>
        <div className="row  mb-8 rows-margin-top">
          <div className="col-6">
            {[PERSON_NATURAL].includes(formik.values.societyTypePartherOne) ? (
              <>
                <Input
                  name="firstPartherOne"
                  value={formik.values.firstPartherOne ? formik.values.firstPartherOne : ''}
                  input-state={formik.touched.firstPartherOne && formik.errors.firstPartherOne ? 'error' : 'default'}
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_legal_last_name_first_partner')}
                  feedback-text={
                    formik.touched.firstPartherOne && formik.errors.firstPartherOne ? formik.errors.firstPartherOne : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={150}
                  onKeyPress={event => {
                    if (!allowAplphabetic.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </>
            ) : (
              <div className="label-rut">
                <Input
                  name="rutNumberPartherOne"
                  value={formik.values.rutNumberPartherOne ? formik.values.rutNumberPartherOne : ''}
                  input-state={
                    formik.touched.rutNumberPartherOne && formik.errors.rutNumberPartherOne ? 'error' : 'default'
                  }
                  disabled={false}
                  label-text={t('sign_up_legal_label_rut')}
                  feedback-text={
                    formik.touched.rutNumberPartherOne && formik.errors.rutNumberPartherOne
                      ? formik.errors.rutNumberPartherOne
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={12}
                  onChange={event => {
                    let value = event.target.value;
                    if (value.length > 7) {
                      value = RUTJS.format(value);
                    }
                    return formik.setFieldValue('rutNumberPartherOne', value);
                  }}
                  onKeyUp={() => {
                    if (
                      formik.values.rutNumberPartherOne &&
                      !allownumberAndKV20.test(formik.values.rutNumberPartherOne)
                    ) {
                      formik.setFieldValue(
                        'rutNumberPartherOne',
                        formik.values.rutNumberPartherOne.slice(0, -1).toUpperCase(),
                      );
                    }
                  }}
                />

                <ToolTip msg={getMessageTooltip()} position="right" />
              </div>
            )}
          </div>
          <div className="col-6">
            {[PERSON_NATURAL].includes(formik.values.societyTypePartherOne) ? (
              <>
                <Input
                  name="secondNamePartherOne"
                  value={formik.values.secondNamePartherOne ? formik.values.secondNamePartherOne : ''}
                  input-state={
                    formik.touched.secondNamePartherOne && formik.errors.secondNamePartherOne ? 'error' : 'default'
                  }
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_legal_last_name_second_partner')}
                  feedback-text={
                    formik.touched.secondNamePartherOne && formik.errors.secondNamePartherOne
                      ? formik.errors.secondNamePartherOne
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={150}
                  onKeyPress={event => {
                    if (!allowAplphabetic.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </>
            ) : (
              <Dropdown
                dropdown-options={Country}
                variant={
                  formik.touched.nationalityPartherOne && formik.errors.nationalityPartherOne ? 'error' : 'default'
                }
                text-feedback={
                  formik.touched.nationalityPartherOne && formik.errors.nationalityPartherOne
                    ? formik.errors.nationalityPartherOne
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_nationality')}
                default-value={formik.values.nationalityPartherOne ? formik.values.nationalityPartherOne : ''}
                onChange={value => formik.setFieldValue('nationalityPartherOne', value)}
              />
            )}
          </div>
        </div>
        <div className="row  mb-8 rows-margin-top">
          <div className="col-6">
            {[PERSON_NATURAL].includes(formik.values.societyTypePartherOne) && formik.values.societyTypePartherOne ? (
              <>
                <Dropdown
                  dropdown-options={Country}
                  variant={
                    formik.touched.nationalityPartherOne && formik.errors.nationalityPartherOne ? 'error' : 'default'
                  }
                  text-feedback={
                    formik.touched.nationalityPartherOne && formik.errors.nationalityPartherOne
                      ? formik.errors.nationalityPartherOne
                      : ''
                  }
                  disabled={false}
                  width="100%"
                  placeholder={t('sign_up_legal_label_nationality')}
                  default-value={formik.values.nationalityPartherOne ? formik.values.nationalityPartherOne : ''}
                  onChange={value => formik.setFieldValue('nationalityPartherOne', value)}
                />
              </>
            ) : (
              <>
                <Input
                  name="addressPartherOne"
                  value={formik.values.addressPartherOne ? formik.values.addressPartherOne : ''}
                  input-state={
                    formik.touched.addressPartherOne && formik.errors.addressPartherOne ? 'error' : 'default'
                  }
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_legal_label_address')}
                  feedback-text={
                    formik.touched.addressPartherOne && formik.errors.addressPartherOne
                      ? formik.errors.addressPartherOne
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={255}
                  onKeyPress={event => {
                    if (!allowAlphanumeric.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </>
            )}
          </div>
          <div className="col-6">
            {[PERSON_NATURAL].includes(formik.values.societyTypePartherOne) ? (
              <>
                <Input
                  name="addressPartherOne"
                  value={formik.values.addressPartherOne ? formik.values.addressPartherOne : ''}
                  input-state={
                    formik.touched.addressPartherOne && formik.errors.addressPartherOne ? 'error' : 'default'
                  }
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_legal_label_address')}
                  feedback-text={
                    formik.touched.addressPartherOne && formik.errors.addressPartherOne
                      ? formik.errors.addressPartherOne
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={255}
                  onKeyPress={event => {
                    if (!allowAlphanumeric.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </>
            ) : (
              <Dropdown
                dropdown-options={getRegions()}
                variant={formik.touched.regionPartherOne && formik.errors.regionPartherOne ? 'error' : 'default'}
                text-feedback={
                  formik.touched.regionPartherOne && formik.errors.regionPartherOne
                    ? formik.errors.regionPartherOne
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_region')}
                default-value={formik.values.regionPartherOne ? formik.values.regionPartherOne : ''}
                onChange={value => setRegion(value)}
              />
            )}
          </div>
        </div>

        <div className="row  mb-8 rows-margin-top">
          {[PERSON_NATURAL].includes(formik.values.societyTypePartherOne) ? (
            <>
              <div className="col-6">
                <Dropdown
                  dropdown-options={getRegions()}
                  variant={formik.touched.regionPartherOne && formik.errors.regionPartherOne ? 'error' : 'default'}
                  text-feedback={
                    formik.touched.regionPartherOne && formik.errors.regionPartherOne
                      ? formik.errors.regionPartherOne
                      : ''
                  }
                  disabled={false}
                  width="100%"
                  placeholder={t('sign_up_legal_label_region')}
                  default-value={formik.values.regionPartherOne ? formik.values.regionPartherOne : ''}
                  onChange={value => setRegion(value)}
                />
              </div>
              <div className="col-6">
                {optionsCommune && optionsCommune.length > 0 && (
                  <Dropdown
                    dropdown-options={optionsCommune}
                    variant={formik.touched.communePartherOne && formik.errors.communePartherOne ? 'error' : 'default'}
                    text-feedback={
                      formik.touched.communePartherOne && formik.errors.communePartherOne
                        ? formik.errors.communePartherOne
                        : ''
                    }
                    disabled={false}
                    width="100%"
                    placeholder={t('sign_up_legal_label_commune')}
                    default-value={formik.values.communePartherOne ? formik.values.communePartherOne : ''}
                    onChange={value => formik.setFieldValue('communePartherOne', value)}
                  />
                )}
              </div>
            </>
          ) : (
            <div className="col-6">
              {optionsCommune && optionsCommune.length > 0 && (
                <Dropdown
                  dropdown-options={optionsCommune}
                  variant={formik.touched.communePartherOne && formik.errors.communePartherOne ? 'error' : 'default'}
                  text-feedback={
                    formik.touched.communePartherOne && formik.errors.communePartherOne
                      ? formik.errors.communePartherOne
                      : ''
                  }
                  disabled={false}
                  width="100%"
                  placeholder={t('sign_up_legal_label_commune')}
                  default-value={formik.values.communePartherOne ? formik.values.communePartherOne : ''}
                  onChange={value => formik.setFieldValue('communePartherOne', value)}
                />
              )}
            </div>
          )}
        </div>
        <div>
          {(stepOne.data.societyType === SocietyType.SA || stepOne.data.societyType === SocietyType.LTDA) && (
            <PartherTwo formik={formik} />
          )}
        </div>
        <div>
          <Representative formik={formik} />
        </div>
      </FormSection>
    </div>
  );
};
