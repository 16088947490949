import React from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import t from 'utils/translate';
import './style.css';

export const FooterDrawer: React.FC = () => {
  return (
    <div className="drawer-footer">
      <Typography weight="regular" typeElement="body3">
        {t('learningguide_footer')}
        <span>{'onboardingmarketplace@paris.cl'}</span>
      </Typography>
    </div>
  );
};
