import styled from '@emotion/styled';
import zIndex from './zIndex';
import { StyleInterface } from './interfaces';

const positionTooltip = {
  top: {
    top: (heightTooltip: number, yChildren: number, heightChildren: number) => `${yChildren - heightTooltip - 10}px`,
    left: (widthChildren: number, xChildren: number) => `${xChildren + widthChildren / 2}px`,
  },
  bottom: {
    top: (heightTooltip: number, yChildren: number, heightChildren: number) => `${yChildren + heightChildren + 10}px`,
    left: (widthChildren: number, xChildren: number) => `${xChildren + widthChildren / 2}px`,
  },
  left: {
    top: (heightTooltip: number, yChildren: number, heightChildren: number) =>
      `${
        heightTooltip > heightChildren
          ? yChildren - (heightTooltip - heightChildren) / 2
          : yChildren + (heightChildren - heightTooltip) / 2
      }px`,
    left: (widthChildren: number, xChildren: number) => `${xChildren - 16}px`,
  },
  right: {
    top: (heightTooltip: number, yChildren: number, heightChildren: number) =>
      `${
        heightTooltip > heightChildren
          ? yChildren - (heightTooltip - heightChildren) / 2
          : yChildren + (heightChildren - heightTooltip) / 2
      }px`,
    left: (widthChildren: number, xChildren: number) => `${xChildren + widthChildren + 16}px`,
  },
};

export const WrapperChildren = styled.div`
  display: flex;

  .wrapper-children {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const WrapperTooltip = styled.div<StyleInterface>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  visibility: ${({ visibilityTooltip }) => (visibilityTooltip ? 'visible' : 'hidden')};
  position: fixed;
  z-index: ${zIndex.tooltip};
  ${({ position }) => (['top', 'bottom'].includes(position) ? 'transform: translateX(-50%);' : '')};
  ${({ position }) => (['left'].includes(position) ? 'transform: translateX(-100%);' : '')};
  ${({ position, heightTooltip, heightChildren, widthChildren, xChildren, yChildren }) => {
    return `
      top: ${positionTooltip[position].top(heightTooltip, yChildren, heightChildren)};
      left: ${positionTooltip[position].left(widthChildren, xChildren)};
    `;
  }}

  .Tooltip--top {
    .Tooltip-Arrow--top {
      display: flex;
    }
    .Tooltip-Arrow--right {
      display: none;
    }
    .Tooltip-Arrow--bottom {
      display: none;
    }
    .Tooltip-Arrow--left {
      display: none;
    }
  }

  .Tooltip--bottom {
    .Tooltip-Arrow--top {
      display: none;
    }
    .Tooltip-Arrow--right {
      display: none;
    }
    .Tooltip-Arrow--bottom {
      display: flex;
    }
    .Tooltip-Arrow--left {
      display: none;
    }
  }

  .Tooltip--left {
    .Tooltip-Arrow--top {
      display: none;
    }
    .Tooltip-Arrow--right {
      display: none;
    }
    .Tooltip-Arrow--bottom {
      display: none;
    }
    .Tooltip-Arrow--left {
      display: flex;
    }
  }

  .Tooltip--right {
    .Tooltip-Arrow--top {
      display: none;
    }
    .Tooltip-Arrow--right {
      display: flex;
    }
    .Tooltip-Arrow--bottom {
      display: none;
    }
    .Tooltip-Arrow--left {
      display: none;
    }
  }
`;
