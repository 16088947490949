import * as Yup from 'yup';
import t from '../../../../../utils/translate';

export default () =>
  Yup.object({
    notificationInfoEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .email(t('invalid_email')),
    notificationInfoConfirmEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .oneOf([Yup.ref('notificationInfoEmail'), null], t('email_doesnt_match')),
    comertialAreaName: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    comertialAreaSecondName: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    comertialAreaEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .email(t('invalid_email')),
    comertialAreaConfirmEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .oneOf([Yup.ref('comertialAreaEmail'), null], t('email_doesnt_match')),
    comertialAreaCharge: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    comertialAreaPhone: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .matches(/^((2|9)[0-9]{8})/, t('invalid_phone')),
    financeAreaName: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    financeAreaSecondName: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    financeAreaEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .email(t('invalid_email')),
    financeAreaConfirmEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .oneOf([Yup.ref('financeAreaEmail'), null], t('email_doesnt_match')),
    financeAreaCharge: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    financeAreaPhone: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .matches(/^((2|9)[0-9]{8})/, t('invalid_phone')),
    postSellName: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    postSellSecondName: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    postSellEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .email(t('invalid_email')),
    postSellConfirmEmail: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .oneOf([Yup.ref('postSellEmail'), null], t('email_doesnt_match')),
    postSellCharge: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field')),
    postSellPhone: Yup.string()
      .trim()
      .required(t('sign_up_contact_required_field'))
      .matches(/^((2|9)[0-9]{8})/, t('invalid_phone')),
  });
