import React from 'react';

const SignupSuccessIcon = () => {
  return (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3302_30420)">
        <path
          d="M91.9095 109.627C91.3273 109.627 90.749 109.404 90.3067 108.962C89.4826 108.137 89.4259 106.833 90.1404 105.941C90.1857 105.2 89.4524 102.418 85.4681 96.7101C81.8505 91.5276 76.5961 85.4113 70.6764 79.4954C64.7567 73.5757 58.6443 68.3251 53.4617 64.7037C47.7537 60.7195 44.9753 59.9861 44.2306 60.0315C43.3423 60.7459 42.0344 60.6892 41.2103 59.8652C40.3257 58.9806 40.3257 57.5441 41.2103 56.6558C43.4973 54.3688 47.7953 55.4575 54.7394 60.0844C60.4247 63.8721 67.2252 69.6255 73.8858 76.2861C80.5464 82.9467 86.2998 89.7471 90.0874 95.4324C94.7143 102.377 95.803 106.675 93.516 108.962C93.07 109.408 92.4878 109.627 91.9095 109.627Z"
          fill="#166DC2"
        />
        <path
          d="M115.512 112.152C114.261 112.152 113.244 111.135 113.244 109.884C113.244 99.1367 106.909 94.0222 101.594 91.6218C90.9981 86.8399 77.2573 89.108 71.5493 94.1393C70.6081 94.9672 69.1754 94.8765 68.3476 93.939C67.5197 92.9977 67.6104 91.5651 68.5479 90.7372C76.2783 83.9216 92.2645 82.4323 103.457 87.4863C112.692 91.6558 117.78 99.6092 117.78 109.884C117.78 111.135 116.764 112.152 115.512 112.152Z"
          fill="#FF8A00"
        />
        <path
          d="M57.8148 84.8499C57.433 84.8499 57.0437 84.7516 56.6883 84.5475C55.6034 83.9238 55.2292 82.5364 55.8529 81.4515C61.2283 72.0957 60.5289 52.3974 50.9236 39.0876C46.051 32.3363 37.0921 24.931 22.0698 27.9891C20.8413 28.2386 19.643 27.4485 19.3935 26.22C19.144 24.9915 19.9378 23.7931 21.1626 23.5437C34.7333 20.7804 46.6105 25.3581 54.6017 36.4301C64.3658 49.9592 66.7397 71.6119 59.7843 83.7121C59.3647 84.4416 58.6011 84.8499 57.8148 84.8499Z"
          fill="#FF8A00"
        />
        <path
          d="M91.9121 109.632C91.33 109.632 90.7516 109.413 90.3094 108.967C89.4248 108.082 89.421 106.645 90.3094 105.761L90.3131 105.757C91.1977 104.873 92.6341 104.873 93.5225 105.757C94.407 106.642 94.407 108.078 93.5225 108.967C93.0764 109.409 92.4943 109.632 91.9121 109.632Z"
          fill="#166DC2"
        />
        <path
          d="M42.8106 60.5224C42.2284 60.5224 41.6501 60.3032 41.2078 59.8571C40.3232 58.9726 40.3195 57.5361 41.2078 56.6516L41.2116 56.6478C42.0961 55.7632 43.5326 55.7632 44.4209 56.6478C45.3055 57.5323 45.3055 58.9688 44.4209 59.8571C43.9711 60.3032 43.3889 60.5224 42.8106 60.5224Z"
          fill="#166DC2"
        />
        <path
          d="M31.25 129.688L50.7812 76.5625L42.1875 61.7188L14.0625 131.25L15.625 135.156L31.25 129.688Z"
          fill="#E1F0FF"
        />
        <path
          d="M93.5136 105.76C92.6895 104.936 91.3929 104.879 90.5046 105.586C90.4781 105.586 90.4517 105.59 90.4252 105.593C89.6049 105.597 86.819 104.803 81.2622 100.921C76.0796 97.3037 69.9633 92.0493 64.0474 86.1296C58.1315 80.2099 52.8771 74.0974 49.2558 68.9148C45.3736 63.3505 44.5797 60.5721 44.5835 59.748C44.5873 59.7215 44.5873 59.6989 44.5911 59.6724C45.298 58.7841 45.2413 57.4837 44.4172 56.6634C43.5326 55.7789 42.0962 55.7789 41.2079 56.6634C40.5766 57.2947 40.2137 58.0847 40.1003 59.0373L12.9967 130.841C12.3049 132.671 12.7359 134.67 14.1194 136.054C15.0682 137.003 16.3005 137.502 17.5745 137.502C18.1604 137.502 18.7539 137.396 19.3322 137.177L91.117 110.088C92.0809 109.978 92.8748 109.608 93.5136 108.969C94.3981 108.081 94.3981 106.644 93.5136 105.76ZM17.7294 132.931C17.6727 132.954 17.4989 133.018 17.3287 132.844C17.1549 132.671 17.2191 132.5 17.2418 132.444L42.2285 66.2309C42.8825 67.4444 43.6801 68.7599 44.6327 70.1925C48.4204 75.8778 54.1737 82.6783 60.8343 89.3389C67.4949 95.9995 74.2954 101.753 79.9807 105.541C81.4096 106.493 82.7251 107.295 83.9347 107.949L17.7294 132.931Z"
          fill="#166DC2"
        />
        <path
          d="M87.8694 71.1997C87.3326 71.1997 86.792 71.0107 86.3573 70.6213C85.4236 69.7859 85.3442 68.3532 86.1796 67.4195C88.8824 64.3954 92.6853 65.1439 95.4599 65.6882C98.208 66.2288 99.6029 66.3762 100.487 65.3858C101.372 64.3954 101.07 63.0308 100.227 60.3582C99.3761 57.6592 98.208 53.9623 100.911 50.9419C103.614 47.9216 107.416 48.6663 110.191 49.2144C112.939 49.755 114.334 49.9024 115.215 48.912C116.05 47.9783 117.483 47.8989 118.417 48.7343C119.35 49.5697 119.43 51.0024 118.594 51.9361C115.892 54.9564 112.089 54.2117 109.314 53.6636C106.566 53.1231 105.171 52.9756 104.29 53.966C103.406 54.9564 103.708 56.3211 104.551 58.9936C105.402 61.6926 106.57 65.3896 103.867 68.4099C101.164 71.4303 97.3613 70.6856 94.5867 70.1412C91.8385 69.6007 90.4436 69.4533 89.5629 70.4436C89.113 70.9426 88.4931 71.1997 87.8694 71.1997Z"
          fill="#FF8A00"
        />
        <path
          d="M77.928 55.8241C76.7107 55.8241 75.7015 54.8564 75.6636 53.6278C75.5313 49.5755 78.8012 47.4927 81.1864 45.9693C83.549 44.461 84.6566 43.6067 84.615 42.2799C84.5734 40.953 83.4091 40.1743 80.9558 38.821C78.4761 37.4564 75.0815 35.589 74.9492 31.5367C74.8169 27.4844 78.0867 25.4016 80.472 23.8782C82.8346 22.3699 83.9421 21.5156 83.9006 20.1925C83.859 18.9413 84.8418 17.8942 86.093 17.8526C87.3405 17.8148 88.3914 18.7939 88.4329 20.0451C88.5653 24.0974 85.2954 26.1803 82.9102 27.7037C80.5476 29.2119 79.44 30.0662 79.4816 31.3893C79.5232 32.7161 80.6874 33.4986 83.1408 34.8481C85.6205 36.2127 89.0151 38.0801 89.1474 42.1324C89.2797 46.1847 86.0099 48.2676 83.6246 49.791C81.262 51.2993 80.1545 52.1536 80.196 53.4804C80.2376 54.7316 79.2548 55.7787 78.0036 55.8203C77.9771 55.8203 77.9506 55.8241 77.928 55.8241Z"
          fill="#FF8A00"
        />
        <path
          d="M60.6208 23.2228C60.2277 23.2228 59.827 23.1207 59.4641 22.9053C58.3867 22.2664 58.0352 20.8716 58.674 19.798L61.426 15.1711C62.0648 14.0938 63.4597 13.7422 64.5332 14.3811C65.6106 15.0199 65.9621 16.4148 65.3233 17.4883L62.5714 22.1152C62.148 22.8259 61.3957 23.2228 60.6208 23.2228Z"
          fill="#166DC2"
        />
        <path
          d="M108.824 133.889C108.056 133.889 107.308 133.499 106.881 132.796C106.23 131.727 106.571 130.332 107.644 129.682L113.428 126.174C114.498 125.523 115.892 125.864 116.543 126.937C117.193 128.007 116.853 129.402 115.779 130.052L109.995 133.56C109.629 133.783 109.224 133.889 108.824 133.889Z"
          fill="#166DC2"
        />
        <path
          d="M131.558 107.434C131.165 107.434 130.764 107.332 130.402 107.116C129.324 106.477 128.973 105.082 129.612 104.009L132.363 99.382C133.002 98.3047 134.393 97.9532 135.471 98.592C136.548 99.2308 136.9 100.626 136.261 101.699L133.509 106.326C133.085 107.037 132.329 107.434 131.558 107.434Z"
          fill="#FF8A00"
        />
        <path
          d="M14.7662 84.1337C14.1841 84.1337 13.6057 83.9107 13.1634 83.4684C12.2789 82.5839 12.2789 81.1474 13.1634 80.2591L16.97 76.4525C17.8546 75.5679 19.291 75.5679 20.1793 76.4525C21.0639 77.337 21.0639 78.7735 20.1793 79.6618L16.3727 83.4684C15.9267 83.9107 15.3483 84.1337 14.7662 84.1337Z"
          fill="#FF8A00"
        />
        <path
          d="M121.073 28.3331C120.491 28.3331 119.912 28.11 119.47 27.6678L115.663 23.8649C114.779 22.9804 114.779 21.5439 115.663 20.6556C116.548 19.771 117.984 19.771 118.873 20.6556L122.679 24.4622C123.564 25.3467 123.564 26.7832 122.679 27.6715C122.233 28.1138 121.655 28.3331 121.073 28.3331Z"
          fill="#166DC2"
        />
        <path
          d="M74.1319 133.903C73.2209 133.903 72.3628 133.351 72.0151 132.451L70.0721 127.431C69.6184 126.263 70.2006 124.948 71.3686 124.498C72.5367 124.044 73.8522 124.626 74.302 125.794L76.245 130.814C76.6986 131.983 76.1165 133.298 74.9484 133.748C74.68 133.854 74.4041 133.903 74.1319 133.903Z"
          fill="#FF8A00"
        />
        <path
          d="M29.3574 51.3827C28.832 51.3827 28.3065 51.2012 27.8794 50.8346L23.8006 47.3228C22.8518 46.5063 22.7422 45.0736 23.5625 44.1248C24.379 43.176 25.8116 43.0664 26.7605 43.8867L30.8392 47.3984C31.788 48.2149 31.8977 49.6476 31.0774 50.5964C30.6313 51.1181 29.9962 51.3827 29.3574 51.3827Z"
          fill="#166DC2"
        />
        <path
          d="M125.468 76.9869C124.478 76.9869 123.567 76.3329 123.287 75.3312L121.828 70.1486C121.488 68.9428 122.191 67.6915 123.397 67.3513C124.603 67.0111 125.854 67.7142 126.194 68.9201L127.653 74.1027C127.993 75.3085 127.29 76.5597 126.084 76.9C125.88 76.9604 125.672 76.9869 125.468 76.9869Z"
          fill="#166DC2"
        />
      </g>
      <defs>
        <clipPath id="clip0_3302_30420">
          <rect width="150" height="150" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignupSuccessIcon;
