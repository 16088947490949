import React from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';
import { CurtainConfigurationData, Service } from '../../../single-spa/interfaces/serviceStatus.interface';
import BucketImage from '../../../assets/img/bucket.svg';
import { useHistory } from 'react-router-dom';
import t from '../../../utils/translate';
import { getServicesStatus } from '../../../single-spa/getServicesStatus';

const DEBOUNCE_TIMEOUT = 310;

const baseStyles = `
  #curtain-image-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    img {
      width: 16%;
      margin-bottom: 0.75em;
    }
  }

  #curtain-title {
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 60px;
    letter-spacing: 0.25px;
    color: #166DC2;
  }

  #curtain-subtitle {
    text-align: center;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 21px;
    padding: 0.75em 0;

    .second-line {
      font-weight: bold;
    }
  }

  #curtain-button-container {
    padding-top: 1em;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    button {
      cursor: pointer;
      font-weight: 700;
      font-size: 0.9rem;
      padding: 1em 2.5em;
      border: none;
      border-radius: 10px;
      color: white;
      background-color: #166DC2;
    }
  }

  color: #323A44;
  font-family: 'Open Sans';
  letter-spacing: 0.25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: white;
`;

const CurrentServiceUnavailableContainer = styled.div`
  ${baseStyles}
  overflow: hidden;
  position: absolute;
  left: 100px;
  right: 0;
  top: 0;
  bottom: 30px;
  max-height: inherit;
  z-index: 5;
`;

const AllServicesUnavailableContainer = styled.div`
  ${baseStyles}
  height: 80vh;
`;

interface CurtainProps {
  type: 'current' | 'all';
  isCurrentServiceUnavailable: boolean;
  currentService?: Service;
}

const getServiceTexts = (configData: CurtainConfigurationData) => {
  if (!configData) {
    return;
  }
  const transKey = configData.serviceTranslationKey;
  const serviceName = transKey ? t(transKey).toLowerCase() : '';

  return {
    titleFirstLine: t(configData.titleFirstLine),
    titleSecondLine: `${t(configData.titleSecondLine)} ${serviceName ? serviceName : ''}`,
    subtitleFirstLine: t(configData.subtitleFirstLine),
    subtitleSecondLine: t(configData.subtitleSecondLine),
    showButton: configData.showButton,
  };
};

const allServicesUnavailableConfig = {
  titleFirstLine: t('curtainAllServicesTitleFirstLine'),
  titleSecondLine: t('curtainAllServicesTitleSecondLine'),
  subtitleFirstLine: t('curtainAllServicesSubtitleFirstLine'),
  subtitleSecondLine: t('curtainAllServicesSubtitleSecondLine'),
  showButton: false,
};

function Curtain({
  service,
  onClick,
}: {
  service: Service | undefined;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const currentServiceConfig = service ? getServiceTexts(service.configuration_data) : null;
  const config = currentServiceConfig || allServicesUnavailableConfig;

  return (
    <div>
      <div id="curtain-image-container">
        <img src={BucketImage} alt="bucket" />
      </div>
      <div id="curtain-title">
        <div className="first-line">{config.titleFirstLine}</div>
        <div className="second-line">{config.titleSecondLine}</div>
      </div>
      <div id="curtain-subtitle">
        <div className="first-line">{config.subtitleFirstLine}</div>
        <div className="second-line">{config.subtitleSecondLine}</div>
      </div>
      <div id="curtain-button-container">
        <button onClick={debounce(onClick, DEBOUNCE_TIMEOUT)} style={{ display: config.showButton ? 'flex' : 'none' }}>
          Ir al inicio
        </button>
      </div>
    </div>
  );
}

export function ServiceUnavailableCurtain({ type, isCurrentServiceUnavailable, currentService }: CurtainProps) {
  const history = useHistory();

  const curtainContainers = new Map();
  curtainContainers.set('current', CurrentServiceUnavailableContainer);
  curtainContainers.set('all', AllServicesUnavailableContainer);
  const Container = curtainContainers.get(type);

  if (!isCurrentServiceUnavailable) {
    return null;
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    getServicesStatus({ dispatchEvent: true }).then(() => {
      history.push('/eiffel');
    });
  };

  return (
    <Container>
      <Curtain service={currentService} onClick={handleClick} />
    </Container>
  );
}
