import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import { loginRoute } from '../../utils/routes';
import SessionContext from '../../api/session/context';

const Logout = () => {
  const { logout } = useContext(SessionContext);
  useEffect(() => {
    logout();
  });

  return <Redirect to={loginRoute.path} />;
};

export default withRouter(Logout);
