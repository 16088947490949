import getAxios from '../../clients';
import { IMPORTER_URL } from '../../config';
import { flashMessage } from '../../utils/flashMessage';
import t from '../../utils/translate';

export const importStart = async (
  token: string,
  { eiffelSellerId, code }: { eiffelSellerId: string; code: string },
) => {
  try {
    await getAxios.instance(token).get(`${IMPORTER_URL}/import-start?seller_id=${eiffelSellerId}&code=${code}`);
    flashMessage(t('importStarted'), 'success');
  } catch (error) {
    flashMessage(t('importFailed'), 'danger');
  }
};
