import styled from '@emotion/styled';

export const NotificationListStyled = styled.div`
  position: fixed;
  ${(props: any) => {
    if (props.showNotificationList) {
      return 'display: block;';
    }
    return 'display: none;';
  }}
  right: 10px;
  width: 390px;
  padding: 10px;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  background: #ffffff;
`;
