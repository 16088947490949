import * as React from 'react';

function SvgNivelHalf(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={72} height={72} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_607_8762)">
        <circle cx="36" cy="36" r="35.5" stroke="#B8DCFF" />
        <circle cx="36" cy="36" r="24" fill="white" />
        <path
          d="M41.8676 19.2027L39.3745 24.1617L38.8295 25.2397L35.7234 18.1516C35.9141 17.8552 36.2547 17.6665 36.6225 17.6665H40.9139C41.7041 17.6665 42.2218 18.502 41.8676 19.2027Z"
          fill="#0041D6"
        />
        <path
          d="M41.9631 28.9994V26.7355C41.9631 24.6603 40.2465 22.9624 38.1485 22.9624H33.4348C31.3368 22.9624 29.6203 24.6603 29.6203 26.7355V28.9994C25.2471 31.2228 22.25 35.7371 22.25 40.9386C22.25 48.3367 28.3124 54.3332 35.7917 54.3332C43.2709 54.3332 49.3333 48.3367 49.3333 40.9386C49.3333 35.7236 46.3362 31.2228 41.9631 28.9994ZM39.5109 28.0426C38.3256 27.7058 37.0859 27.5306 35.7917 27.5306C34.4974 27.5306 33.2577 27.7058 32.0725 28.0426V26.7355C32.0725 25.9944 32.6855 25.388 33.4348 25.388H38.1485C38.6662 25.388 39.1158 25.671 39.3474 26.0887L39.361 26.1022C39.4564 26.2908 39.5109 26.5064 39.5109 26.722V28.0426Z"
          fill="#BABABA"
        />
        <path
          d="M36.1543 52.6884C42.7103 52.6884 48.0249 47.3862 48.0249 40.8456C48.0249 34.3051 42.7103 29.0029 36.1543 29.0029C29.5983 29.0029 24.2836 34.3051 24.2836 40.8456C24.2836 47.3862 29.5983 52.6884 36.1543 52.6884Z"
          fill="url(#paint0_linear_607_8762)"
        />
        <path
          d="M32.2346 43.6584L32.2778 38.0664C32.2818 37.5436 32.7124 37.1178 33.2395 37.1152L38.893 37.0725C39.4215 37.0685 39.8455 37.4879 39.8401 38.0093L39.7969 43.6013C39.7929 44.124 39.3623 44.5499 38.8352 44.5525L33.1817 44.5952C32.6532 44.5992 32.2292 44.1798 32.2346 43.6584Z"
          fill="url(#paint1_linear_607_8762)"
        />
        <path
          d="M33.1461 42.9543L33.1461 38.7002C33.1461 38.3025 33.4712 37.9809 33.8722 37.982L38.1732 37.982C38.5753 37.982 38.9004 38.3035 38.8993 38.7002L38.8993 42.9543C38.8993 43.352 38.5742 43.6736 38.1732 43.6725H33.8722C33.4701 43.6725 33.1451 43.351 33.1461 42.9543Z"
          fill="#515151"
        />
        <path
          d="M35.7917 53.0261C29.0481 53.0261 23.5579 47.5955 23.5579 40.9252C23.5579 34.2548 29.0481 28.8242 35.7917 28.8242C42.5353 28.8242 48.0255 34.2548 48.0255 40.9252C48.0255 47.609 42.5353 53.0261 35.7917 53.0261ZM35.7917 29.781C29.5794 29.781 24.5115 34.7804 24.5115 40.9386C24.5115 47.0969 29.5658 52.0963 35.7917 52.0963C42.004 52.0963 47.0719 47.0969 47.0719 40.9386C47.0719 34.7804 42.004 29.781 35.7917 29.781Z"
          fill="white"
        />
        <path
          d="M37.3447 26.7219H33.3258C32.9171 26.7219 32.5493 26.4928 32.3722 26.1425L32.0179 25.4283L28.9527 19.3508C28.5985 18.6501 29.1162 17.8281 29.9063 17.8281H34.1977C34.62 17.8281 35.0015 18.0707 35.165 18.448L38.312 25.2531C38.3392 25.3205 38.3664 25.3878 38.3801 25.4552C38.5436 26.0616 38.0531 26.7219 37.3447 26.7219Z"
          fill="#1C8AF5"
        />
        <mask id="path-10-inside-1_607_8762" fill="white">
          <path d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z" />
        </mask>
        <path
          d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-10-inside-1_607_8762)"
        />
        <mask id="path-11-inside-2_607_8762" fill="white">
          <path d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z" />
        </mask>
        <path
          d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-11-inside-2_607_8762)"
        />
        <mask id="path-12-inside-3_607_8762" fill="white">
          <path d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z" />
        </mask>
        <path
          d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z"
          stroke="#166DC2"
          stroke-width="6"
          mask="url(#path-12-inside-3_607_8762)"
        />
        <mask id="path-13-inside-4_607_8762" fill="white">
          <path d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z" />
        </mask>
        <path
          d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z"
          stroke="#166DC2"
          stroke-width="8"
          mask="url(#path-13-inside-4_607_8762)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_607_8762"
          x1="24.283"
          y1="40.8415"
          x2="48.032"
          y2="40.8415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#696969" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_607_8762"
          x1="32.563"
          y1="37.3949"
          x2="39.44"
          y2="44.3475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#696969" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <clipPath id="clip0_607_8762">
          <rect width={72} height={72} fill="white" />
        </clipPath>
      </defs>
      "72"
    </svg>
  );
}

export default SvgNivelHalf;
