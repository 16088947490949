import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Home as Eiffel } from './eiffel';
import CentryAuth from './centryAuth';

import { EiffelStyled } from './styles';

export default function Home(props) {
  const history = useHistory();
  useEffect(() => {
    document.querySelector('#root').style.display = history.location.pathname === '/eiffel' ? 'none' : 'block';
  }, [history.location.pathname]);
  return (
    <EiffelStyled display={`${history.location.pathname.startsWith('/eiffel')}`} {...props}>
      <Switch>
        <Route exact={true} key={'eiffel'} component={Eiffel} path={'/eiffel'} />
        <Route exact={true} key={'centryAuth'} component={CentryAuth} path={'/eiffel/centry-auth'} />
      </Switch>
    </EiffelStyled>
  );
}
