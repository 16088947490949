/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

interface IProperties {
  className?: string;
}

const FulfillmentIcon = ({ className }: IProperties) => {
  return (
    <svg width="48" height="48" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...{ className }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0332 4.11562C23.3253 3.96146 23.6747 3.96146 23.9668 4.11562L41.9668 13.6156C42.2948 13.7887 42.5 14.1291 42.5 14.5V43C42.5 43.5523 42.0523 44 41.5 44H5.5C4.94772 44 4.5 43.5523 4.5 43V14.5C4.5 14.1291 4.70525 13.7887 5.03324 13.6156L23.0332 4.11562ZM6.5 15.103V42H9.5V17C9.5 16.4477 9.94772 16 10.5 16H36.5C37.0523 16 37.5 16.4477 37.5 17V42H40.5V15.103L23.5 6.13073L6.5 15.103ZM17.5 36V42H11.5V36H13.5H15.5H17.5ZM15.5 34H21.5V30H15.5V34ZM23.5 34H26.5C27.0523 34 27.5 34.4477 27.5 35V42H35.5V26H11.5V34H13.5V28.9412C13.5 28.4214 13.9477 28 14.5 28H22.5C23.0523 28 23.5 28.4214 23.5 28.9412V34ZM23.5 36H21.5H19.5V42H25.5V36H23.5ZM35.5 24V22H11.5V24H35.5ZM35.5 20V18H11.5V20H35.5Z"
        fill="black"
      />
    </svg>
  );
};

export default FulfillmentIcon;
