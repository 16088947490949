import Axios, { AxiosError } from 'axios';

const getAxios = {
  instance: (token: string = '') => {
    let instance = Axios.create();

    if (token) {
      instance = Axios.create({
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      });
    }

    instance.interceptors.request.use(request => {
      return request;
    });

    instance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.message.includes('Network Error')) {
          return Promise.reject({
            message: 'Network not connected. Verify your internet connexion.',
            error: 'Network Error',
            statusCode: 0,
          });
        }
        return Promise.reject(JSON.parse(error.request.responseText));
      },
    );

    return instance;
  },
  error: (error: AxiosError) => {
    if (error.response) {
      console.log('Status', error.response.status, error.response.data);
      throw new Error(`${error.config.url}: ${error.response.status} ${error.response.data}`);
    } else if (error.request) {
      console.log('Request', error.request);
    } else {
      console.log('Error', error.message);
    }
  },
};

export default getAxios;
