import styled from '@emotion/styled';
import { colors } from '@cencosud-ds/bigbang';

const colorsType = {
  Bajo: colors.feedback.error.dark,
  Nuevo: colors.neutral[400],
  Medio: colors.primary[500],
  Bueno: colors.primary[500],
  Destacado: colors.primary[500],
};
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 64px;
  width: 100%;
  height: 144px;
  background: #ffffff;
  border: 1px solid #bdbfc3;
  border-radius: 8px;
`;

export const ContentSeller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 16px;
  width: 596px;
  > svg {
    margin-bottom: 3px;
    margin-left: 10px;
  }
`;

export const ContentDataSeller = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 494px;
  margin-left: 16px;
`;
export const ContentNameNivelSeller = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 494px;
  > button {
    width: 116px;
  }
`;
export const ContentNivelSeller = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  margin-bottom: 0px;
  p {
    span {
      color: ${props => colorsType[props.type]};
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }
  }
  > svg {
    cursor: pointer;
  }
`;

export const MeasuringSeller = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

export const MeasuringPorcentajesReputacion = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 0px;
  width: 100%;
`;

export const MeasuringPorcentajes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
`;

export const MeasuringPorcentajesItems = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space;
  padding: 0px;
  gap: 5px;
  p {
    line-height: 35px;
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  > svg {
    cursor: pointer;
  }
`;
