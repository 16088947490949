import styled from '@emotion/styled';
import { Loader } from '@cencosud-ds/bigbang/antiguo';

export const ContainerApp = styled.div`
  overflow: auto;
  position: absolute;
  left: ${({ withMargin }) => {
    return withMargin ? '100px' : '0px';
  }};
  right: 0px;
  ${({ isLearningGuide, isInvoice, isMyAccount }) =>
    isMyAccount ? 'top: 32px' : isLearningGuide && !isInvoice ? 'top: 70px' : 'top: 20px'};
  bottom: 30px;
  max-height: inherit;
  overflow-x: hidden;
  ${({ isInvoice }) =>
    isInvoice &&
    `@media (min-width: 1440px) {
      display: flex !important;
      justify-content: center !important;
    }`};
  overflow-x: hidden;
`;

export const LoaderStyled = styled(Loader)`
  position: absolute;
  left: 50%;
  top: 40%;
`;

export const Container = styled.div`
  background: #e1f0ff;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 100px;
  bottom: 0px;
  overflow: auto;
  margin-bottom: 20px;
`;

export const ContainerBreadcrumbsAccesHall = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  height: 38px;
`;

export const ContainerBreandcrum = styled.div`
  background-color: ${({ pages }) => (['/campaigns', '/invoice'].includes(pages) ? colors.primary[100] : 'white')},
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 1275px;
  height: 38px;
`;
