import { SELLERS_URL } from '../../config';
import getAxios from '../../clients';
import { typeType } from 'components/Reputation/Satelite/interface';
import { INewReputationSeller, ISellerDataToSave } from './interfaces';
import { NEW, PLANGROWTH } from 'variables/nivele';

export interface ISatelite {
  categoriaReputacion: string | typeType;
  entregasRetraso: string;
  porcentajeReclamos: string;
  cancelQuiebre: string;
}

export const getSellerReputacionHome = async (jwt: string, sellerId: string): Promise<ISatelite> => {
  try {
    const response = await getAxios.instance(jwt).get(`${SELLERS_URL}/v1/sellers/status-reputation/${sellerId}`);
    return response.data;
  } catch (ex) {
    return {
      categoriaReputacion: 'Bloqueo',
      cancelQuiebre: '00',
      porcentajeReclamos: '00',
      entregasRetraso: '0,00',
    };
  }
};

export const getIsNewReputacion = async (jwt: string, sellerId: string): Promise<INewReputationSeller> => {
  const firstLoginDay = new Date();
  try {
    const response = await getAxios
      .instance(jwt)
      .get(`${SELLERS_URL}/v1/sellers/show-reputation-modal/${sellerId}/${firstLoginDay}`);

    return {
      reputationCategory: ![PLANGROWTH].includes(response.data.reputationCategory)
        ? response.data.reputationCategory
        : NEW,
      isFirstLogin: response.data.isFirstLogin,
      isNewReputation: response.data.isNewReputation,
    };
  } catch (ex) {
    return {
      reputationCategory: 'Bloqueo',
      isFirstLogin: false,
      isNewReputation: false,
    };
  }
};

export const saveSellerLog = async (jwt: string, data: ISellerDataToSave) => {
  try {
    const result = await getAxios.instance(jwt).post(`${SELLERS_URL}/v1/sellers/reputation-logs`, { ...data });
    return result.data;
  } catch (error) {
    return false;
  }
};
