import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header, Typography } from '@cencosud-ds/bigbang';
import HeaderLogo from './HeaderLogo';
import t from '../../utils/translate';
import { trackGTMEventAction } from '../../utils/analytics';

const HeaderMarquetPlace = () => {
  const history = useHistory();
  const onRegisterClick = () => {
    trackGTMEventAction({ action: 'Clic botón: Regístrate ahora', category: 'Login', tag: '/login' });
    history.push('/registrate-ahora');
  };

  return (
    <>
      <Header hideBreadcrumbs={true} iconHeader={<HeaderLogo />}>
        <Typography color="#166DC2" propsElement={{}} typeElement="body1" weight="semiBold">
          <span className="mr-5">{t('aunNoTienesCuenta')}</span>
        </Typography>
        <Button type="button" type-button="secondary" variant-button="m" onClick={() => onRegisterClick()}>
          {t('registrateHora')}
        </Button>
      </Header>
    </>
  );
};

export default HeaderMarquetPlace;
