import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import CardFooter from '../../components/Card/CardFooter.jsx';
import CardHeader from '../../components/Card/CardHeader.jsx';
import Button from '../../components/CustomButtons/Button.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import InputWithErrors from '../../components/InputWithErrors/InputWithErrors.jsx';
import SelectWithErrors from '../../components/SelectWithErrors/SelectWithErrors';
import Success from '../../components/Typography/Success.jsx';
import { trackGTMEventAction } from '../../utils/analytics';
import t from '../../utils/translate';

const styles = theme => ({
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontSize: '1.5em',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  linkCenter: {
    margin: '0 auto',
  },
  title: {
    padding: '0px 0px 0px 15px',
  },
});

class CustomFormChangePassword extends React.PureComponent {
  createTextInput(field, errors, touched) {
    return (
      <InputWithErrors
        id={field.id}
        name={field.name}
        labelText={field.label}
        disabled={field.disabled}
        type={field.type}
        formControlProps={{
          fullWidth: true,
        }}
        errors={errors[field.id]}
        touched={touched[field.id]}
      />
    );
  }

  createSelectInput(field, errors, touched) {
    return (
      <SelectWithErrors
        id={field.id}
        name={field.name}
        labelText={field.label}
        disabled={field.disabled}
        type={field.type}
        options={field.options}
        formControlProps={{
          fullWidth: true,
        }}
        errors={errors[field.id]}
        touched={touched[field.id]}
      />
    );
  }

  createField(field, errors, touched) {
    let f = null;
    switch (field.type) {
      case 'text':
      case 'email':
      case 'password':
        f = this.createTextInput(field, errors, touched);
        break;

      case 'select':
        f = this.createSelectInput(field, errors, touched);
        break;

      default:
        return null;
    }
    return (
      <GridItem xs={12} sm={12} md={field.width} key={field.id}>
        {f}
      </GridItem>
    );
  }

  handleBackButton() {
    trackGTMEventAction({ category: 'Login', action: 'Cambiar contraseña: botón volver', tag: '/forgot/password ' });
  }

  render() {
    const {
      classes,
      onSubmitFunction,
      validationSchema,
      title,
      includeCardHeader,
      headerColor,
      submitColor,
      submitText,
      submitSuccess,
      submitSuccessMsg,
      fields,
      state,
    } = this.props;

    let initialValues;
    if (state) {
      initialValues = state;
    } else {
      initialValues = fields.reduce((acc, field) => {
        acc[field.name] = field.initialValue ? field.initialValue : '';
        return acc;
      }, {});
    }

    return (
      <Card>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => onSubmitFunction(values, actions)}
          render={({ errors, status, touched, isSubmitting }) => (
            <Form>
              {includeCardHeader && (
                <CardHeader color={headerColor}>
                  <h4 className={classes.cardTitleWhite}>{title}</h4>
                </CardHeader>
              )}

              <CardBody>
                <GridContainer>
                  <h5 className={classes.title}>Ingresa el correo asociado a esta cuenta</h5>
                </GridContainer>
                <GridContainer>{fields.map(field => this.createField(field, errors, touched))}</GridContainer>
              </CardBody>

              {submitSuccess && <Success>{t(submitSuccessMsg)}</Success>}

              <CardFooter className={classes.wrapper}>
                <Button type="submit" color={submitColor}>
                  {submitText}
                </Button>
                <div onClick={this.handleBackButton}>
                  <Link to="/login" variant="outlined" color="info">
                    Volver
                  </Link>
                </div>
              </CardFooter>
            </Form>
          )}
        />
      </Card>
    );
  }
}

const colors = ['warning', 'success', 'danger', 'info', 'primary', 'rose'];

CustomFormChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  formErrors: PropTypes.string.isRequired,
  submitErrors: PropTypes.string,
  submitSuccess: PropTypes.bool,
  submitSuccessMsg: PropTypes.string,
  onSubmitFunction: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  validationSchema: PropTypes.object.isRequired,
  includeCardHeader: PropTypes.bool.isRequired,
  title: PropTypes.string,
  headerColor: PropTypes.oneOf(colors),
  submitColor: PropTypes.oneOf(colors).isRequired,
  submitText: PropTypes.string.isRequired,
};

export default withStyles(styles)(CustomFormChangePassword);
