import * as React from 'react';

function SvgNivelWell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="227" height="228" viewBox="0 0 227 228" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8" filter="url(#filter0_f_1049_20440)">
        <ellipse cx="113.719" cy="114.165" rx="63.148" ry="63.3962" fill="#B8DCFF" />
      </g>
      <path
        d="M133.925 29.2888L125.757 45.8427L123.972 49.4414L113.796 25.7801C114.421 24.7904 115.537 24.1606 116.742 24.1606H130.801C133.389 24.2056 135.085 26.9496 133.925 29.2888Z"
        fill="#0041D6"
      />
      <path
        d="M134.237 62.0369V54.4797C134.237 47.5522 128.614 41.8843 121.74 41.8843H106.298C99.4243 41.8843 93.8007 47.5522 93.8007 54.4797V62.0369C79.4738 69.4592 69.6548 84.5287 69.6548 101.892C69.6548 126.588 89.516 146.606 114.019 146.606C138.522 146.606 158.383 126.588 158.383 101.892C158.383 84.4837 148.564 69.4142 134.237 62.0369ZM126.203 58.8431C122.32 57.7185 118.259 57.1337 114.019 57.1337C109.779 57.1337 105.717 57.7185 101.834 58.8431V54.4797C101.834 52.0056 103.843 49.9813 106.298 49.9813H121.74C123.436 49.9813 124.909 50.926 125.668 52.3205L125.713 52.3654C126.025 52.9952 126.203 53.715 126.203 54.4347V58.8431Z"
        fill="url(#paint0_linear_1049_20440)"
      />
      <path
        d="M114.019 147.281C89.159 147.281 68.9854 126.903 68.9854 101.892C68.9854 84.8885 78.2242 69.5041 93.1312 61.632V54.4796C93.1312 47.1473 99.0226 41.2095 106.298 41.2095H121.74C129.015 41.2095 134.907 47.1473 134.907 54.4796V61.632C149.814 69.5041 159.053 84.8885 159.053 101.892C159.097 126.903 138.879 147.281 114.019 147.281ZM114.019 56.4589C117.902 56.4589 121.785 56.9537 125.534 57.9434V54.4796C125.534 52.3654 123.838 50.656 121.74 50.656H106.298C104.2 50.656 102.504 52.3654 102.504 54.4796V57.9434C106.253 56.9537 110.136 56.4589 114.019 56.4589Z"
        fill="url(#paint1_radial_1049_20440)"
      />
      <path
        d="M121.74 41.8844C128.614 41.8844 134.237 47.5524 134.237 54.4798V62.0371C148.564 69.4593 158.383 84.4839 158.383 101.848C158.383 126.543 138.522 146.561 114.019 146.561C89.516 146.561 69.6548 126.543 69.6548 101.848C69.6548 84.4839 79.4739 69.4144 93.8007 62.0371V54.4798C93.8007 47.5524 99.4243 41.8844 106.298 41.8844H121.74ZM101.834 58.8432C105.717 57.7186 109.779 57.1338 114.019 57.1338C118.259 57.1338 122.321 57.7186 126.204 58.8432V54.4798C126.204 52.0057 124.195 49.9815 121.74 49.9815H106.298C103.843 49.9815 101.834 52.0057 101.834 54.4798V58.8432M121.74 40.5349H106.298C98.6656 40.5349 92.4618 46.7876 92.4618 54.4798V61.1824C85.4099 65.006 79.4292 70.5839 75.1892 77.4664C70.6814 84.7538 68.2712 93.1657 68.2712 101.848C68.2712 127.263 88.8019 147.911 113.974 147.911C139.191 147.911 159.677 127.218 159.677 101.848C159.677 93.2107 157.267 84.7987 152.76 77.4664C148.52 70.5839 142.539 65.006 135.487 61.1824V54.4798C135.576 46.7876 129.372 40.5349 121.74 40.5349ZM103.173 57.0889V54.4798C103.173 52.7255 104.557 51.331 106.298 51.331H121.74C123.481 51.331 124.865 52.7255 124.865 54.4798V57.0889C121.339 56.2342 117.679 55.7843 114.019 55.7843C110.359 55.7843 106.699 56.1892 103.173 57.0889Z"
        fill="#A33311"
      />
      <path
        d="M113.528 142.018C135.023 142.018 152.447 124.456 152.447 102.792C152.447 81.1281 135.023 63.5662 113.528 63.5662C92.0338 63.5662 74.6091 81.1281 74.6091 102.792C74.6091 124.456 92.0338 142.018 113.528 142.018Z"
        fill="url(#paint2_radial_1049_20440)"
      />
      <path
        d="M115.472 84.6768L119.741 93.4171C119.962 93.8713 120.392 94.1806 120.88 94.253L130.421 95.6549C131.664 95.8391 132.158 97.3858 131.26 98.2678L124.355 105.073C123.997 105.429 123.835 105.935 123.919 106.429L125.546 116.038C125.761 117.289 124.459 118.243 123.347 117.657L114.815 113.122C114.372 112.885 113.845 112.885 113.402 113.122L104.871 117.657C103.758 118.249 102.456 117.295 102.671 116.038L104.298 106.429C104.382 105.929 104.22 105.422 103.862 105.073L96.9569 98.2678C96.0588 97.3792 96.5534 95.8326 97.7964 95.6549L107.337 94.253C107.832 94.1806 108.255 93.8647 108.476 93.4171L112.745 84.6768C113.311 83.5316 114.912 83.5316 115.472 84.6768Z"
        fill="url(#paint3_linear_1049_20440)"
      />
      <path
        d="M115.203 89.2171L118.412 95.7646C118.579 96.1048 118.901 96.3366 119.268 96.3908L126.44 97.4409C127.374 97.579 127.746 98.7376 127.071 99.3983L121.881 104.496C121.611 104.762 121.489 105.142 121.553 105.512L122.776 112.71C122.937 113.647 121.959 114.362 121.122 113.923L114.709 110.526C114.376 110.349 113.98 110.349 113.648 110.526L107.234 113.923C106.398 114.367 105.42 113.652 105.581 112.71L106.804 105.512C106.868 105.137 106.745 104.758 106.476 104.496L101.286 99.3983C100.611 98.7327 100.983 97.5741 101.917 97.4409L109.088 96.3908C109.46 96.3366 109.778 96.0999 109.945 95.7646L113.154 89.2171C113.579 88.3593 114.783 88.3593 115.203 89.2171Z"
        fill="#A65A00"
      />
      <path
        d="M113.841 141.703C92.0156 141.703 74.2521 123.844 73.895 101.712C73.895 101.937 73.895 102.207 73.895 102.432C73.895 124.924 91.7924 143.187 113.841 143.187C135.889 143.187 153.786 124.969 153.786 102.432C153.786 102.207 153.786 101.937 153.786 101.712C153.384 123.889 135.666 141.703 113.841 141.703Z"
        fill="white"
      />
      <path
        d="M137.629 69.7743C120.133 56.6391 95.318 60.2827 81.8838 77.8263C82.0177 77.6464 82.1516 77.4215 82.3301 77.2415C95.6751 59.2031 120.803 55.3345 138.522 68.6047C156.241 81.8748 159.767 107.291 146.422 125.329C146.288 125.509 146.109 125.734 145.975 125.914C158.785 107.92 155.125 82.9095 137.629 69.7743Z"
        fill="url(#paint4_radial_1049_20440)"
      />
      <path
        d="M114.019 142.242C91.9261 142.242 73.9395 124.114 73.9395 101.847C73.9395 79.5803 91.9261 61.4519 114.019 61.4519C136.112 61.4519 154.098 79.5803 154.098 101.847C154.098 124.114 136.112 142.242 114.019 142.242ZM114.019 64.6008C93.6668 64.6008 77.0637 81.2897 77.0637 101.847C77.0637 122.405 93.6221 139.094 114.019 139.094C134.371 139.094 150.974 122.405 150.974 101.847C150.974 81.2897 134.416 64.6008 114.019 64.6008Z"
        fill="url(#paint5_linear_1049_20440)"
      />
      <path
        d="M125.668 52.3205C124.909 50.926 123.436 49.9813 121.74 49.9813H116.92L112.859 41.8843H120.982L125.668 52.3205Z"
        fill="#501717"
      />
      <path
        d="M119.151 54.3896H105.985C104.646 54.3896 103.441 53.6249 102.861 52.4553L101.7 50.0712L91.6579 29.7836C90.4975 27.4444 92.1935 24.7004 94.7822 24.7004H108.841C110.225 24.7004 111.475 25.5101 112.01 26.7697L122.32 49.4864C122.409 49.7113 122.499 49.9362 122.543 50.1611C123.079 52.2304 121.427 54.3896 119.151 54.3896Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M78.2688 102.117C78.2688 82.2796 94.2024 66.2205 113.885 66.2205C133.568 66.2205 149.501 82.2796 149.501 102.117C149.501 121.955 134.773 96.7193 115.135 96.7193C95.4967 96.7193 78.2688 121.955 78.2688 102.117Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M130.622 47.1025C132.229 49.2617 132.586 51.7358 131.425 52.6354C130.265 53.4901 130.131 50.8811 128.524 48.6769C126.918 46.4727 124.463 45.618 125.623 44.7183C126.784 43.8636 129.015 44.8983 130.622 47.1025Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M135.857 80.5478C137.094 78.1719 134.292 74.2341 129.599 71.7525C124.906 69.2709 120.099 69.1852 118.863 71.5611C117.626 73.937 120.427 77.8748 125.12 80.3564C129.813 82.838 134.62 82.9237 135.857 80.5478Z"
        fill="white"
      />
      <path
        d="M169.668 21.7015C170.191 20.2829 172.197 20.2829 172.72 21.7015L176.171 31.0653C176.335 31.5107 176.686 31.8621 177.131 32.0274L186.476 35.499C187.889 36.024 187.889 38.0231 186.476 38.5482L177.131 42.0197C176.686 42.185 176.335 42.5365 176.171 42.9818L172.72 52.3457C172.197 53.7642 170.191 53.7642 169.668 52.3457L166.216 42.9818C166.052 42.5365 165.702 42.185 165.257 42.0197L155.912 38.5482C154.498 38.0231 154.498 36.024 155.912 35.499L165.257 32.0274C165.702 31.8621 166.052 31.5107 166.216 31.0653L169.668 21.7015Z"
        fill="#1C8AF5"
      />
      <path
        d="M32.7058 107.236C33.2287 105.818 35.235 105.818 35.7578 107.236L37.682 112.457C37.8462 112.902 38.1967 113.253 38.6417 113.419L43.8597 115.357C45.273 115.882 45.273 117.881 43.8597 118.406L38.6417 120.345C38.1967 120.51 37.8462 120.861 37.682 121.307L35.7578 126.527C35.235 127.946 33.2287 127.946 32.7058 126.527L30.7816 121.307C30.6174 120.861 30.2669 120.51 29.822 120.345L24.604 118.406C23.1906 117.881 23.1906 115.882 24.604 115.357L29.822 113.419C30.2669 113.253 30.6174 112.902 30.7816 112.457L32.7058 107.236Z"
        fill="#B8DCFF"
      />
      <path
        d="M193.665 70.0032C194.188 68.5847 196.195 68.5847 196.717 70.0032L197.987 73.448C198.151 73.8934 198.502 74.2448 198.947 74.4101L202.396 75.6915C203.809 76.2165 203.809 78.2156 202.396 78.7406L198.947 80.022C198.502 80.1873 198.151 80.5387 197.987 80.9841L196.717 84.4288C196.195 85.8474 194.188 85.8474 193.665 84.4288L192.396 80.9841C192.232 80.5387 191.881 80.1873 191.436 80.022L187.987 78.7406C186.573 78.2156 186.573 76.2165 187.987 75.6915L191.436 74.4101C191.881 74.2448 192.232 73.8934 192.396 73.448L193.665 70.0032Z"
        fill="#B8DCFF"
      />
      <path
        d="M62.3577 158.557C62.8806 157.138 64.8869 157.138 65.4098 158.557L66.6795 162.002C66.8436 162.447 67.1942 162.799 67.6391 162.964L71.0883 164.245C72.5017 164.77 72.5017 166.769 71.0883 167.294L67.6391 168.576C67.1942 168.741 66.8436 169.092 66.6795 169.538L65.4098 172.983C64.8869 174.401 62.8806 174.401 62.3577 172.983L61.088 169.538C60.9239 169.092 60.5733 168.741 60.1284 168.576L56.6792 167.294C55.2658 166.769 55.2658 164.77 56.6792 164.245L60.1284 162.964C60.5733 162.799 60.9239 162.447 61.088 162.002L62.3577 158.557Z"
        fill="#1C8AF5"
      />
      <defs>
        <filter
          id="filter0_f_1049_20440"
          x="0.570557"
          y="0.768799"
          width="226.296"
          height="226.792"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_1049_20440" />
        </filter>
        <linearGradient
          id="paint0_linear_1049_20440"
          x1="69.6405"
          y1="94.2339"
          x2="158.405"
          y2="94.2339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#558895" />
          <stop offset="0.2368" stop-color="#588A97" />
          <stop offset="0.392" stop-color="#60909C" />
          <stop offset="0.5238" stop-color="#6F9BA5" />
          <stop offset="0.6425" stop-color="#85A9B3" />
          <stop offset="0.7524" stop-color="#A0BCC4" />
          <stop offset="0.8559" stop-color="#C2D4D9" />
          <stop offset="0.9525" stop-color="#E9EFF1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1049_20440"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(114.024 94.2348) scale(48.9763 49.3622)"
        >
          <stop stop-color="#F2E520" />
          <stop offset="0.4563" stop-color="#F2E320" />
          <stop offset="0.6207" stop-color="#F2DC1F" />
          <stop offset="0.7379" stop-color="#F2D11E" />
          <stop offset="0.8327" stop-color="#F1C01C" />
          <stop offset="0.9137" stop-color="#F1AA1A" />
          <stop offset="0.9843" stop-color="#F08F17" />
          <stop offset="1" stop-color="#F08816" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1049_20440"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(122.229 78.4668) scale(38.9075 39.214)"
        >
          <stop offset="0.0237017" stop-color="#F2E520" />
          <stop offset="1" stop-color="#F08816" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1049_20440"
          x1="101.65"
          y1="120.203"
          x2="133.663"
          y2="78.7573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC700" />
          <stop offset="1" stop-color="#E54717" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_1049_20440"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(118.184 93.3178) scale(34.3813 34.6521)"
        >
          <stop offset="0.0237017" stop-color="#F2E520" />
          <stop offset="1" stop-color="#F08816" />
        </radialGradient>
        <linearGradient
          id="paint5_linear_1049_20440"
          x1="114.019"
          y1="61.4519"
          x2="114.019"
          y2="142.242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDEB31" />
          <stop offset="0.25" stop-color="white" />
          <stop offset="0.46875" stop-color="#FDEB31" />
          <stop offset="1" stop-color="#FD3131" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNivelWell;
