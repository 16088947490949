import React from 'react';

const HeaderLogo = () => {
  return (
    <svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.314 28.4785L29.1582 26.533L29.782 10.4786C29.8355 9.0533 28.5197 7.93563 27.0251 7.93563H23.0469C22.9046 3.54346 19.1131 0 14.8799 0C10.6433 0 6.85285 3.54974 6.71284 7.93563H2.75737C1.26511 7.93563 -0.0529961 9.05434 0.00164052 10.4786L0.785907 30.6824C0.837128 32.0146 2.04824 33.1469 3.53936 33.1469H21.1414L23.4669 39.3694C23.5591 39.6122 23.7651 39.8257 24.0474 39.9325C24.5699 40.124 25.1652 39.8948 25.3746 39.4144L28.2567 32.8801L35.347 30.2356C35.6224 30.1382 35.8455 29.9394 35.9457 29.6663C36.1255 29.1775 35.8444 28.6438 35.314 28.4785ZM14.8799 2.16103C17.5912 2.16103 20.5268 4.70612 20.6839 7.93459H9.07473C9.2227 4.71031 12.1583 2.16103 14.8799 2.16103ZM26.8316 25.7984L21.8836 24.2349V13.9666H20.0544L14.889 21.0316L9.72354 13.9666H7.87386V27.8663H9.72354V20.0343C9.72354 18.9009 9.70305 17.845 9.68256 17.0203L9.72354 17.0015C10.3667 17.9235 11.0098 18.8444 11.6734 19.7465L14.848 24.0853L18.0237 19.7465C18.6874 18.8444 19.3305 17.9235 19.9736 17.0015L20.0146 17.0203C19.9941 17.846 19.9736 18.9019 19.9736 20.0343V23.6321L19.2326 23.3977C19.0266 23.3286 18.7921 23.3286 18.5713 23.3977C18.0408 23.5662 17.7585 24.0968 17.9407 24.5855L20.3321 30.9849H3.53936C3.28439 30.9849 3.14666 30.8572 3.13527 30.6134L2.35329 10.4001C2.45915 10.1479 2.59688 10.1731 2.75737 10.0956H6.68666C6.67414 10.7078 6.65023 11.3169 6.65023 11.5262C6.81756 13.0635 8.97684 12.6847 9.00188 11.5262C9.00188 11.4142 9.02351 10.7413 9.03831 10.0956H20.7328C20.7431 10.7465 20.7544 11.4121 20.7556 11.5387C20.9662 13.0509 23.0845 12.6522 23.1061 11.5032C23.1038 11.3567 23.0936 10.7193 23.0822 10.0956H27.0251C27.2812 10.0956 27.436 10.2515 27.4292 10.4001L26.8316 25.7984Z"
        fill="#166DC2"
      />
    </svg>
  );
};

export default HeaderLogo;
