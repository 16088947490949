import { CONTENT_URL_2 } from '../../config';
import getAxios from '../../clients';

export const approveTerms = async (jwt: string, documentId: string) => {
  try {
    const response = await getAxios.instance(jwt).get(`${CONTENT_URL_2}/document/approve/${documentId}`);

    return response.data;
  } catch (e) {
    return false;
  }
};
