import React, { useEffect, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getQuestionsByModule, postAnswersByUser } from 'api/learningGuide';
import SessionContext from 'api/session/context';
import { LearningGuide as CLearningGuide, ButtonLearning } from 'components/LearningGuide';
import { IDataByModule } from 'utils/learningGuide';
import { IconBook, IconRatings } from 'components/icons';
import { SELLER } from 'variables/roles';
import t from 'utils/translate';

export const LearningGuide: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const { pathname } = useLocation();
  const location = pathname.split('/')[1];
  const subLocation = pathname.split('/')[2];
  const [dataByLearningGuide, setDataByLearningGuide] = React.useState<IDataByModule>({
    title: '',
    module: '',
    content: '',
    data: [],
  });
  const [isAnswer, setAnswer] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClickNext, setOnClickNext] = React.useState<string>('');
  const [isClickRatings, setOnClickRatings] = React.useState<string>('');
  const {
    auth: { jwt, role, userId },
  } = useContext(SessionContext);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setOnClickNext('');
    setOnClickRatings('');
    setAnswer(false);
  };

  const onGoQuestions = (value: string) => {
    setOnClickNext(value);
    setOnClickRatings('');
    setAnswer(false);
  };

  const onGoBack = () => {
    setOnClickNext('');
    setOnClickRatings('');
    setAnswer(false);
  };

  const onClickRatings = async (value: string) => {
    if (value === 'yes') {
      const answer = await postAnswersByUser(jwt, {
        userId,
        value: 1,
        valueDetailed: 'null',
        learningGuideQuestionId: isClickNext,
      });
      setAnswer(answer);
    } else if (isClickRatings === 'no') {
      const answer = await postAnswersByUser(jwt, {
        userId,
        value: 0,
        valueDetailed: value,
        learningGuideQuestionId: isClickNext,
      });
      setAnswer(answer);
      setOnClickRatings(value);
    }
    setOnClickRatings(value);
  };

  const onButtonLearningTitle = useCallback(() => {
    switch (location) {
      case 'reports':
        switch (subLocation) {
          case 'metrics':
            return t('learningguide_button_access_metrics');
          default:
            return t('learningguide_button_access');
        }
      default:
        return t('learningguide_button_access');
    }
  }, [location, subLocation]);

  const onButtonLearningIcons = useCallback(() => {
    switch (location) {
      case 'reports':
        switch (subLocation) {
          case 'metrics':
            return <IconRatings />;
          default:
            return <IconBook />;
        }
      default:
        return <IconBook />;
    }
  }, [location, subLocation]);

  useEffect(() => {
    const getDataByLearningGuide = async () => {
      try {
        setIsLoading(true);
        const dataLocation = ['metrics'].includes(subLocation) ? subLocation : location;
        const data: IDataByModule = await getQuestionsByModule(jwt, dataLocation, userId);
        setDataByLearningGuide(data);
        setError('');
      } catch (e) {
        setError(t('Internal server error'));
      } finally {
        setIsLoading(false);
      }
    };
    if (isOpen || isClickNext || isAnswer) {
      getDataByLearningGuide();
    }
  }, [location, isAnswer, isOpen, isClickNext]);

  return (
    <>
      {role === SELLER && ['reports', 'finance', 'pim', 'orders', 'stock'].includes(location) && (
        <>
          <ButtonLearning icon={onButtonLearningIcons()} title={onButtonLearningTitle()} onClick={toggleDrawer} />
          <CLearningGuide
            titleHeader={isClickNext ? t('return') : t('learning_guide_tilte')}
            titleBody={dataByLearningGuide.title}
            contentBody={dataByLearningGuide.content}
            onClose={toggleDrawer}
            dataQuestions={dataByLearningGuide.data || []}
            onGoQuestions={e => onGoQuestions(e)}
            onGoBack={() => onGoBack()}
            isGoBack={isClickNext}
            isOpen={isOpen}
            width={490}
            keyQuestions={isClickNext}
            isClickRatings={isClickRatings}
            onClickRatings={(value: string) => onClickRatings(value)}
            isLoader={isLoading}
            errorMessageInfo={error}
          />
        </>
      )}
    </>
  );
};
