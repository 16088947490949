import React, { useState, useEffect } from 'react';
import { BACKEND_URL, CONTENT_URL_2, SELLERS_URL, SINGLE_SPA_ROUTE_CHANGED_EVENT } from '../../config';
import { COOKIE_NAME_APPROVAL } from '../../config';
import {
  FlashMessage,
  AuthentificationData,
  NotificationResultInterface,
  UserNotificationInterface,
  Document,
  Seller,
  INewReputationSeller,
} from './interfaces';
import cookies from '../../utils/cookies';
import decodeJwt from '../../utils/decodeJwt';
import getAxios from '../../clients';
import SessionContext, { emptyAuth } from './context';
import t from '../../utils/translate';
import { trackGTMEventAction, trackGTMEventActionIdentifyUser } from '../../utils/analytics';
import { SELLER } from '../../variables/roles';
import { getIsNewReputacion, saveSellerLog } from 'api/reputation';
import { fetchIsInPublishHistory, getSellerConfiguration } from 'api/sellers';
import { LOCK, NEW, PLANGROWTH } from 'variables/nivele';
import { headers, stateConfigSeller } from 'utils/patterns';
import getCheckExpiration from 'utils/getCheckExpiration';
import { dataTextModal } from 'utils/getModalConference';

const SessionProvider = (props: any) => {
  const [auth, setAuth] = useState<AuthentificationData>(emptyAuth);
  const [checkSession, setCheckSession] = useState(true);
  const [terms, setTerms] = useState<Document[]>([]);
  const [checkNotifications, setCheckNotifications] = useState(true);
  const isModalConference = localStorage.getItem('_is_modal_conference')
    ? JSON.parse(localStorage.getItem('_is_modal_conference') || '0')
    : '0';
  const [isModalInfo, setIsModalInfo] = useState<boolean>(stateConfigSeller[isModalConference]);
  const [notifications, setNotifications] = useState<UserNotificationInterface[]>([]);
  const [reputation, setReputation] = useState<INewReputationSeller>({
    reputationCategory: 'Bloqueo',
    isNewReputation: false,
    isFirstLogin: false,
  });
  const [flashMessage, setFlashMessage] = useState<FlashMessage>({ message: null, type: '' });
  const jwt = localStorage.getItem('_ud') ? JSON.parse(localStorage.getItem('_ud') || '') : '';
  const firstTimeTour = localStorage.getItem('firstTimeTour') || '0';
  const isReputationSeller =
    localStorage.getItem('_reputation_seller') ||
    '{"reputationCategory":"Bloqueo", "isNewReputation":"false", "isFirstLogin": "false"}';
  const changeRequestIntegratio = localStorage.getItem('_change_request')
    ? JSON.parse(localStorage.getItem('_change_request') || '')
    : '';
  const [seller, setSeller] = useState<Seller>({
    id: '',
    publish: false,
    vendor_code_801_provider: '',
    vendor_code_801_client: '',
    terms_conditions_signed: 0,
  });

  useEffect(() => {
    if (checkSession) {
      setAuthFromCheckExpiration();
    }
  }, [checkSession]);

  useEffect(() => {
    if (checkNotifications) {
      handleNotificationsRequest();
    }
  }, [checkNotifications]);

  useEffect(() => {
    window.addEventListener(SINGLE_SPA_ROUTE_CHANGED_EVENT, () => {
      setCheckSession(true);
      setCheckNotifications(true);
    });
  }, []);

  useEffect(() => {
    if (changeRequestIntegratio.sellerId) {
      logout();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }, [changeRequestIntegratio]);

  const fetchIsNewReputacion = async (accessToken: string, sellerId: string) => {
    const data = await getIsNewReputacion(accessToken, sellerId);
    return data;
  };

  const fetchISellerConfiguration = async (accessToken: string, sellerId: string) => {
    const dataTourGuide = await getSellerConfiguration(accessToken, sellerId);
    localStorage.setItem('firstTimeTour', dataTourGuide.tourGuideStatus.toString());
    return stateConfigSeller[dataTourGuide.tourGuideStatus];
  };

  const fetchISellerConferencen = async (sellerId: string) => {
    if (dataTextModal.webinar.sellersID.includes(sellerId)) {
      const dateNowTextModal = new Date();
      const dataEndModalConference = dataTextModal.webinar.dataEnd;
      if (dataEndModalConference.getTime() >= dateNowTextModal.getTime()) {
        setIsModalInfo(!isModalInfo);
        return localStorage.setItem('_is_modal_conference', '1');
      }
    }
  };

  useEffect(() => {
    if (firstTimeTour && !terms.length) {
      const loadReputation = String(isReputationSeller);
      if (JSON.parse(loadReputation).isNewReputation) {
        setReputation(JSON.parse(loadReputation));
      }
    }
  }, [terms, firstTimeTour, isReputationSeller]);

  const checkTerms = async (accessToken: string, sellerId: string) => {
    const response = await getAxios.instance(accessToken).get(`${CONTENT_URL_2}/document-seller/check`, { headers });
    const data: Document[] = response.data;

    if (response.data !== true) {
      setTerms(data);
    }
    getDataSeller(accessToken, sellerId);
  };

  const getDataSeller = async (accessToken: string, sellerId: string) => {
    const response = await getAxios.instance(accessToken).get(`${SELLERS_URL}/sellers/${sellerId}`, { headers });
    const data: Seller = response.data;
    setSeller(data);
  };

  const setAuthFromCheckExpiration = async () => {
    if (await getCheckExpiration(jwt)) {
      const decodedJwt = decodeJwt(jwt);
      let responseIsInPublishHistory = null;

      let statusCompleteTourGuide = false;
      let newReputationSeller: INewReputationSeller = {
        reputationCategory: 'Bloqueo',
        isNewReputation: false,
        isFirstLogin: false,
      };

      if (decodedJwt.role === SELLER) {
        checkTerms(jwt, decodedJwt.sellerId);
        responseIsInPublishHistory = await fetchIsInPublishHistory(jwt, decodedJwt.sellerId);

        if (firstTimeTour === '0') {
          statusCompleteTourGuide = await fetchISellerConfiguration(jwt, decodedJwt.sellerId);
        }

        if (firstTimeTour === '1' && !terms.length && responseIsInPublishHistory) {
          newReputationSeller = await fetchIsNewReputacion(jwt, decodedJwt.sellerId);
        }
      }

      setAuth({
        ...auth,
        ...decodedJwt,
        isAuthenticated: true,
        loginError: '',
        isChecking: false,
        sellerIsInPublishHistory: responseIsInPublishHistory,
        statusCompleteTourGuide,
        newReputationSeller,
      });
    } else {
      setAuth({ ...emptyAuth, isChecking: false });
    }

    setCheckSession(false);
  };

  const handleNotificationsRequest = async () => {
    try {
      const result = await getNotifications();
      setNotifications(result);
      setCheckNotifications(false);
    } catch (error) {
      setNotifications([]);
      setCheckNotifications(false);
    }
  };

  const login = async (values: { email: string; password: string }) => {
    let sellerIsInPublishHistory: boolean = false;
    let statusCompleteTourGuide: boolean = false;
    let newReputationSeller: INewReputationSeller = {
      reputationCategory: 'Bloqueo',
      isNewReputation: false,
      isFirstLogin: false,
    };

    setAuth({
      ...auth,
      loading: true,
      loginError: <>&nbsp;</>,
    });
    try {
      const response = await getAxios.instance().post(`${BACKEND_URL}/auth/login`, values, { headers });
      const {
        data: { accessToken, jwtPayload },
      } = response;

      const actionData = {
        jwt: accessToken,
        userId: jwtPayload.id,
        email: jwtPayload.email,
        firstName: jwtPayload.first_name,
        lastName: jwtPayload.last_name,
        sellerId: jwtPayload.seller_id,
        sellerName: jwtPayload.seller_name,
        role: jwtPayload.role,
        permissions: jwtPayload.permissions,
        facilityId: jwtPayload.facility_id,
        sellerType: jwtPayload.seller_type,
        isCollector: jwtPayload.is_collector,
        apiKey: jwtPayload.api_key,
        sellerSapClient: jwtPayload.sellerSapClient,
        sellerSapProvider: jwtPayload.sellerSapProvider,
        sellerIsPublished: jwtPayload.sellerIsPublished,
      };

      localStorage.setItem('_ud', JSON.stringify(accessToken));

      if (cookies.get(COOKIE_NAME_APPROVAL)) {
        cookies.delete(COOKIE_NAME_APPROVAL);
      }
      cookies.set(COOKIE_NAME_APPROVAL, accessToken);

      trackGTMEventAction(
        { category: 'Login', action: 'Iniciar sesión exitoso ', tag: ' /login' },
        jwtPayload.seller_id,
      );
      trackGTMEventActionIdentifyUser('Inicio de Sesión', jwtPayload.role, jwtPayload.seller_id, jwtPayload.role);

      if (jwtPayload.role === SELLER) {
        fetchISellerConferencen(jwtPayload.seller_id);
        checkTerms(accessToken, jwtPayload.seller_id);
        sellerIsInPublishHistory = await fetchIsInPublishHistory(accessToken, jwtPayload.seller_id);
        newReputationSeller = await fetchIsNewReputacion(accessToken, jwtPayload.seller_id);
        if (newReputationSeller.isFirstLogin) {
          if ([LOCK].includes(newReputationSeller.reputationCategory)) {
            saveSellerLog(accessToken, {
              modalShow: newReputationSeller.isNewReputation,
              reputation: 'sin reputacion',
            });
          } else if (!newReputationSeller.isNewReputation) {
            let memoReputation = newReputationSeller.reputationCategory;
            if ([NEW].includes(newReputationSeller.reputationCategory)) {
              memoReputation = PLANGROWTH;
            }
            saveSellerLog(accessToken, { modalShow: newReputationSeller.isNewReputation, reputation: memoReputation });
          }
        }
        statusCompleteTourGuide = await fetchISellerConfiguration(accessToken, jwtPayload.seller_id);
      }

      setAuth({
        ...actionData,
        isAuthenticated: true,
        loginError: '',
        isChecking: false,
        sellerIsInPublishHistory,
        statusCompleteTourGuide,
        newReputationSeller,
      });
    } catch (error) {
      let loginError = error.message;
      if (error.statusCode === 404) {
        loginError = t('server connection error');
      }
      trackGTMEventAction({
        action: 'Iniciar sesión fallido',
        category: 'Login',
        tag: values.email,
      });
      setAuth({
        ...emptyAuth,
        loginError,
        isAuthenticated: false,
        isChecking: false,
      });
    }
  };

  const logout = () => {
    localStorage.removeItem('route');
    localStorage.removeItem('alertAccount');
    localStorage.removeItem('dataFormik');
    localStorage.removeItem('dataFormikFinancial');
    localStorage.removeItem('firstTimeTour');
    localStorage.removeItem('_ud');
    localStorage.removeItem('_change_request');
    localStorage.removeItem('_reputation_seller');
    localStorage.removeItem('_is_modal_conference');
    if (cookies.get(COOKIE_NAME_APPROVAL)) {
      cookies.delete(COOKIE_NAME_APPROVAL);
    }
    setTerms([]);
    setAuth({ ...emptyAuth, isChecking: false });
    window.location.reload();
  };

  const flashMessageTimeout = (msgAndType: FlashMessage) => {
    setFlashMessage(msgAndType);
    setTimeout(() => setFlashMessage({ message: null, type: '' }), 10000);
  };

  const getNotifications = async () => {
    const response = await getAxios.instance(jwt).get(`${CONTENT_URL_2}/user-notification`);
    const result: NotificationResultInterface = response.data;
    return result.data;
  };

  const openNotification = async (id: string) => {
    const result = await getAxios.instance(jwt).post(`${CONTENT_URL_2}/user-notification/open/${id}`);
    setCheckNotifications(true);
    return result.data;
  };

  return (
    <SessionContext.Provider
      value={{
        auth,
        setAuth,
        flashMessage,
        flashMessageTimeout,
        checkExpiration: getCheckExpiration(jwt),
        login,
        logout,
        notifications,
        openNotification,
        terms,
        setTerms,
        seller,
        setSeller,
        reputation,
        setReputation,
        isModalInfo,
        setIsModalInfo,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
