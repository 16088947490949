export const ActivityCodes = [
  {
    code: 11101,
    label: 'Cultivo de trigo',
  },
  {
    code: 11102,
    label: 'Cultivo de maíz',
  },
  {
    code: 11103,
    label: 'Cultivo de avena',
  },
  {
    code: 11200,
    label: 'Cultivo de arroz',
  },
  {
    code: 11104,
    label: 'Cultivo de cebada',
  },
  {
    code: 11105,
    label: 'Cultivo de otros cereales (excepto trigo, maíz, avena\ny cebada)',
  },
  {
    code: 11902,
    label: 'Cultivos forrajeros en praderas mejoradas o sembradas; cultivos suplementarios forrajeros',
  },
  {
    code: 11902,
    label: 'Cultivos forrajeros en praderas mejoradas o\nsembradas; cultivos suplementarios forrajeros',
  },
  {
    code: 11106,
    label: 'Cultivo de porotos',
  },
  {
    code: 11107,
    label: 'Cultivo de lupino',
  },
  {
    code: 11108,
    label: 'Cultivo de otras legumbres (excepto porotos y lupino)',
  },
  {
    code: 11301,
    label: 'Cultivo de papas',
  },
  {
    code: 11302,
    label: 'Cultivo de camotes',
  },
  {
    code: 11303,
    label: 'Cultivo de otros tubérculos (excepto papas y camotes)',
  },
  {
    code: 11109,
    label: 'Cultivo de semillas de raps',
  },
  {
    code: 11110,
    label: 'Cultivo de semillas de maravilla (girasol)',
  },
  {
    code: 12600,
    label: 'Cultivo de frutos oleaginosos (incluye el cultivo de aceitunas)',
  },
  {
    code: 11111,
    label: 'Cultivo de semillas de cereales, legumbres y oleaginosas (excepto semillas de raps y maravilla)',
  },
  {
    code: 11304,
    label: 'Cultivo de remolacha azucarera',
  },
  {
    code: 11500,
    label: 'Cultivo de tabaco',
  },
  {
    code: 16300,
    label: 'Actividades poscosecha',
  },
  {
    code: 11600,
    label: 'Cultivo de plantas de fibra',
  },
  {
    code: 12900,
    label: 'Cultivo de otras plantas perennes',
  },
  {
    code: 12802,
    label: 'Cultivo de plantas aromáticas, medicinales y farmacéuticas',
  },
  {
    code: 11400,
    label: 'Cultivo de caña de azúcar',
  },
  {
    code: 11306,
    label: 'Cultivo de hortalizas y melones',
  },
  {
    code: 11901,
    label: 'Cultivo de flores',
  },
  {
    code: 13000,
    label: 'Cultivo de plantas vivas incluida la producción en viveros (excepto viveros forestales)',
  },
  {
    code: 11305,
    label: 'Cultivo de semillas de hortalizas',
  },
  {
    code: 11903,
    label: 'Cultivos de semillas de flores; cultivo de semillas de plantas forrajeras',
  },
  {
    code: 12501,
    label: 'Cultivo de semillas de frutas',
  },
  {
    code: 23000,
    label: 'Recolección de productos forestales distintos de la madera',
  },
  {
    code: 12111,
    label: 'Cultivo de uva destinada a la producción de pisco y aguardiente',
  },
  {
    code: 12112,
    label: 'Cultivo de uva destinada a la producción de vino',
  },
  {
    code: 110200,
    label: 'Elaboración de vinos',
  },
  {
    code: 12120,
    label: 'Cultivo de uva para mesa',
  },
  {
    code: 12400,
    label: 'Cultivo de frutas de pepita y de hueso',
  },
  {
    code: 12200,
    label: 'Cultivo de frutas tropicales y subtropicales (incluye el cultivo de paltas)',
  },
  {
    code: 12300,
    label: 'Cultivo de cítricos',
  },
  {
    code: 12502,
    label: 'Cultivo de otros frutos y nueces de árboles y arbustos',
  },
  {
    code: 12700,
    label: 'Cultivo de plantas con las que se preparan bebidas (incluye el cultivo de café, té y mate)',
  },
  {
    code: 12801,
    label: 'Cultivo de especias',
  },
  {
    code: 14101,
    label: 'Cría de ganado bovino para la producción lechera',
  },
  {
    code: 14102,
    label: 'Cría de ganado bovino para la producción de carne o\ncomo ganado reproductor',
  },
  {
    code: 14410,
    label: 'Cría de ovejas (ovinos)',
  },
  {
    code: 14200,
    label: 'Cría de caballos y otros equinos',
  },
  {
    code: 14500,
    label: 'Cría de cerdos',
  },
  {
    code: 14601,
    label: 'Cría de aves de corral para la producción de carne',
  },
  {
    code: 14602,
    label: 'Cría de aves de corral para la producción de huevos',
  },
  {
    code: 14909,
    label: 'Cría de otros animales n.c.p.',
  },
  {
    code: 14901,
    label: 'Apicultura',
  },
  {
    code: 32200,
    label: 'Acuicultura de agua dulce',
  },
  {
    code: 14300,
    label: 'Cría de llamas, alpacas, vicuñas, guanacos y otros\ncamélidos',
  },
  {
    code: 14420,
    label: 'Cría de cabras (caprinos)',
  },
  {
    code: 32130,
    label: 'Reproducción y cría de moluscos, crustáceos y gusanos marinos',
  },
  {
    code: 15000,
    label: 'Cultivo de productos agrícolas en combinación con la cría de animales (explotación mixta)',
  },
  {
    code: 16400,
    label: 'Tratamiento de semillas para propagación',
  },
  {
    code: 16100,
    label: 'Actividades de apoyo a la agricultura',
  },
  {
    code: 813000,
    label: 'Actividades de paisajismo, servicios de jardinería y\nservicios conexos',
  },
  {
    code: 960901,
    label: 'Servicios de adiestramiento, guardería, peluquería, paseo de mascotas (excepto act. veterinarias)',
  },
  {
    code: 16200,
    label: 'Actividades de apoyo a la ganadería',
  },
  {
    code: 17000,
    label: 'Caza ordinaria y mediante trampas y actividades de servicios conexas',
  },
  {
    code: 949909,
    label: 'Actividades de otras asociaciones n.c.p.',
  },
  {
    code: 22000,
    label: 'Extracción de madera',
  },
  {
    code: 21002,
    label: 'Silvicultura y otras actividades forestales (excepto explotación de viveros forestales)',
  },
  {
    code: 21001,
    label: 'Explotación de viveros forestales',
  },
  {
    code: 24001,
    label: 'Servicios de forestación a cambio de una retribución\no por contrata',
  },
  {
    code: 24002,
    label: 'Servicios de corta de madera a cambio de una retribución o por contrata',
  },
  {
    code: 24003,
    label: 'Servicios de extinción y prevención de incendios\nforestales',
  },
  {
    code: 24009,
    label: 'Otros servicios de apoyo a la silvicultura n.c.p.',
  },
  {
    code: 32110,
    label: 'Cultivo y crianza de peces marinos',
  },
  {
    code: 32120,
    label: 'Cultivo, reproducción y manejo de algas marinas',
  },
  {
    code: 32140,
    label: 'Servicios relacionados con la acuicultura marina',
  },
  {
    code: 31110,
    label: 'Pesca marítima industrial, excepto de barcos factoría',
  },
  {
    code: 102050,
    label: 'Actividades de elaboración y conservación de\npescado, realizadas en barcos factoría',
  },
  {
    code: 31120,
    label: 'Pesca marítima artesanal',
  },
  {
    code: 31200,
    label: 'Pesca de agua dulce',
  },
  {
    code: 31130,
    label: 'Recolección y extracción de productos marinos',
  },
  {
    code: 31140,
    label: 'Servicios relacionados con la pesca marítima',
  },
  {
    code: 51000,
    label: 'Extracción de carbón de piedra',
  },
  {
    code: 52000,
    label: 'Extracción de lignito',
  },
  {
    code: 89200,
    label: 'Extracción de turba',
  },
  {
    code: 99001,
    label: 'Actividades de apoyo para la explotación de otras minas y canteras prestados por empresas',
  },
  {
    code: 192000,
    label: 'Fabricación de productos de la refinación del petróleo',
  },
  {
    code: 61000,
    label: 'Extracción de petróleo crudo',
  },
  {
    code: 62000,
    label: 'Extracción de gas natural',
  },
  {
    code: 91001,
    label: 'Actividades de apoyo para la extracción de petróleo y gas natural prestados por empresas',
  },
  {
    code: 91001,
    label: 'Actividades de apoyo para la extracción de petróleo y\ngas natural prestados por empresas',
  },
  {
    code: 72100,
    label: 'Extracción de minerales de uranio y torio',
  },
  {
    code: 71000,
    label: 'Extracción de minerales de hierro',
  },
  {
    code: 72910,
    label: 'Extracción de oro y plata',
  },
  {
    code: 72991,
    label: 'Extracción de zinc y plomo',
  },
  {
    code: 72992,
    label: 'Extracción de manganeso',
  },
  {
    code: 72999,
    label: 'Extracción de otros minerales metalíferos no ferrosos\nn.c.p. (excepto zinc, plomo y manganeso)',
  },
  {
    code: 40000,
    label: 'Extracción y procesamiento de cobre',
  },
  {
    code: 81000,
    label: 'Extracción de piedra, arena y arcilla',
  },
  {
    code: 89190,
    label: 'Extracción de minerales para la fabricación de abonos y productos químicos n.c.p.',
  },
  {
    code: 89300,
    label: 'Extracción de sal',
  },
  {
    code: 89110,
    label: 'Extracción y procesamiento de litio',
  },
  {
    code: 89900,
    label: 'Explotación de otras minas y canteras n.c.p.',
  },
  {
    code: 101011,
    label: 'Explotación de mataderos de bovinos, ovinos, equinos, caprinos, porcinos y camélidos',
  },
  {
    code: 521001,
    label: 'Explotación de frigoríficos para almacenamiento y depósito',
  },
  {
    code: 101019,
    label: 'Explotación de mataderos de aves y de otros tipos de animales n.c.p.',
  },
  {
    code: 101020,
    label: 'Elaboración y conservación de carne y productos cárnicos',
  },
  {
    code: 102010,
    label: 'Producción de harina de pescado',
  },
  {
    code: 102030,
    label: 'Elaboración y conservación de otros pescados, en plantas en tierra (excepto barcos factoría)',
  },
  {
    code: 102020,
    label: 'Elaboración y conservación de salmónidos',
  },
  {
    code: 102040,
    label: 'Elaboración y conservación de crustáceos, moluscos y otros productos acuáticos, en plantas en tierra',
  },
  {
    code: 107500,
    label: 'Elaboración de comidas y platos preparados envasados, rotulados y con información nutricional',
  },
  {
    code: 107500,
    label: 'Elaboración de comidas y platos preparados\nenvasados, rotulados y con información nutricional',
  },
  {
    code: 102030,
    label: 'Elaboración y conservación de otros pescados, en\nplantas en tierra (excepto barcos factoría)',
  },
  {
    code: 102060,
    label: 'Elaboración y procesamiento de algas',
  },
  {
    code: 103000,
    label: 'Elaboración y conservación de frutas, legumbres y hortalizas',
  },
  {
    code: 104000,
    label: 'Elaboración de aceites y grasas de origen vegetal y animal (excepto elaboración de mantequilla)',
  },
  {
    code: 104000,
    label: 'Elaboración de aceites y grasas de origen vegetal y\nanimal (excepto elaboración de mantequilla)',
  },
  {
    code: 105000,
    label: 'Elaboración de productos lácteos',
  },
  {
    code: 106101,
    label: 'Molienda de trigo: producción de harina, sémola y gránulos',
  },
  {
    code: 106102,
    label: 'Molienda de arroz; producción de harina de arroz',
  },
  {
    code: 106109,
    label: 'Elaboración de otros productos de molinería n.c.p.',
  },
  {
    code: 106200,
    label: 'Elaboración de almidones y productos derivados del almidón',
  },
  {
    code: 106200,
    label: 'Elaboración de almidones y productos derivados del\nalmidón',
  },
  {
    code: 108000,
    label: 'Elaboración de piensos preparados para animales',
  },
  {
    code: 107100,
    label: 'Elaboración de productos de panadería y pastelería',
  },
  {
    code: 107200,
    label: 'Elaboración de azúcar',
  },
  {
    code: 107300,
    label: 'Elaboración de cacao, chocolate y de productos de\nconfitería',
  },
  {
    code: 107300,
    label: 'Elaboración de cacao, chocolate y de productos de confitería',
  },
  {
    code: 107400,
    label: 'Elaboración de macarrones, fideos, alcuzcuz y\nproductos farináceos similares',
  },
  {
    code: 107901,
    label: 'Elaboración de té, café, mate e infusiones de hierbas',
  },
  {
    code: 107902,
    label: 'Elaboración de levaduras naturales o artificiales',
  },
  {
    code: 107903,
    label: 'Elaboración de vinagres, mostazas, mayonesas y condimentos en general',
  },
  {
    code: 107909,
    label: 'Elaboración de otros productos alimenticios n.c.p.',
  },
  {
    code: 110110,
    label: 'Elaboración de pisco (industrias pisqueras)',
  },
  {
    code: 110120,
    label: 'Destilación, rectificación y mezclas de bebidas alcohólicas; excepto pisco',
  },
  {
    code: 201109,
    label: 'Fabricación de otras sustancias químicas básicas n.c.p.',
  },
  {
    code: 110300,
    label: 'Elaboración de bebidas malteadas y de malta',
  },
  {
    code: 110401,
    label: 'Elaboración de bebidas no alcohólicas',
  },
  {
    code: 110402,
    label: 'Producción de aguas minerales y otras aguas\nembotelladas',
  },
  {
    code: 353002,
    label: 'Elaboración de hielo (excepto fabricación de hielo seco)',
  },
  {
    code: 120001,
    label: 'Elaboración de cigarros y cigarrillos',
  },
  {
    code: 120009,
    label: 'Elaboración de otros productos de tabaco n.c.p.',
  },
  {
    code: 131200,
    label: 'Tejedura de productos textiles',
  },
  {
    code: 131100,
    label: 'Preparación e hilatura de fibras textiles',
  },
  {
    code: 131300,
    label: 'Acabado de productos textiles',
  },
  {
    code: 952900,
    label: 'Reparación de otros efectos personales y enseres domésticos',
  },
  {
    code: 139200,
    label: 'Fabricación de artículos confeccionados de\nmateriales textiles, excepto prendas de vestir',
  },
  {
    code: 325009,
    label: 'Fabricación de instrumentos y materiales médicos, oftalmológicos y odontológicos n.c.p.',
  },
  {
    code: 331900,
    label: 'Reparación de otros tipos de equipo',
  },
  {
    code: 139300,
    label: 'Fabricación de tapices y alfombras',
  },
  {
    code: 139400,
    label: 'Fabricación de cuerdas, cordeles, bramantes y redes',
  },
  {
    code: 139900,
    label: 'Fabricación de otros productos textiles n.c.p.',
  },
  {
    code: 170900,
    label: 'Fabricación de otros artículos de papel y cartón',
  },
  {
    code: 143000,
    label: 'Fabricación de artículos de punto y ganchillo',
  },
  {
    code: 139100,
    label: 'Fabricación de tejidos de punto y ganchillo',
  },
  {
    code: 141001,
    label: 'Fabricación de prendas de vestir de materiales textiles y similares',
  },
  {
    code: 141002,
    label: 'Fabricación de prendas de vestir de cuero natural o artificial',
  },
  {
    code: 141003,
    label: 'Fabricación de accesorios de vestir',
  },
  {
    code: 141004,
    label: 'Fabricación de ropa de trabajo',
  },
  {
    code: 329000,
    label: 'Otras industrias manufactureras n.c.p.',
  },
  {
    code: 151100,
    label: 'Curtido y adobo de cueros; adobo y teñido de pieles',
  },
  {
    code: 142000,
    label: 'Fabricación de artículos de piel',
  },
  {
    code: 151200,
    label: 'Fabricación de maletas, bolsos y artículos similares, artículos de talabartería y guarnicionería',
  },
  {
    code: 152000,
    label: 'Fabricación de calzado',
  },
  {
    code: 162900,
    label: 'Fabricación de otros productos de madera, de artículos de corcho, paja y materiales trenzables',
  },
  {
    code: 221900,
    label: 'Fabricación de otros productos de caucho',
  },
  {
    code: 222000,
    label: 'Fabricación de productos de plástico',
  },
  {
    code: 323000,
    label: 'Fabricación de artículos de deporte',
  },
  {
    code: 161000,
    label: 'Aserrado y acepilladura de madera',
  },
  {
    code: 162100,
    label: 'Fabricación de hojas de madera para enchapado y tableros a base de madera',
  },
  {
    code: 162200,
    label: 'Fabricación de partes y piezas de carpintería para edificios y construcciones',
  },
  {
    code: 162300,
    label: 'Fabricación de recipientes de madera',
  },
  {
    code: 170110,
    label: 'Fabricación de celulosa y otras pastas de madera',
  },
  {
    code: 170190,
    label: 'Fabricación de papel y cartón para su posterior uso industrial n.c.p.',
  },
  {
    code: 170190,
    label: 'Fabricación de papel y cartón para su posterior uso\nindustrial n.c.p.',
  },
  {
    code: 170200,
    label: 'Fabricación de papel y cartón ondulado y de envases de papel y cartón',
  },
  {
    code: 181109,
    label: 'Otras actividades de impresión n.c.p.',
  },
  {
    code: 581100,
    label: 'Edición de libros',
  },
  {
    code: 581200,
    label: 'Edición de directorios y listas de correo',
  },
  {
    code: 592000,
    label: 'Actividades de grabación de sonido y edición de música',
  },
  {
    code: 581300,
    label: 'Edición de diarios, revistas y otras publicaciones periódicas',
  },
  {
    code: 581900,
    label: 'Otras actividades de edición',
  },
  {
    code: 181101,
    label: 'Impresión de libros',
  },
  {
    code: 181200,
    label: 'Actividades de servicios relacionadas con la impresión',
  },
  {
    code: 182000,
    label: 'Reproducción de grabaciones',
  },
  {
    code: 191000,
    label: 'Fabricación de productos de hornos de coque',
  },
  {
    code: 210000,
    label: 'Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos',
  },
  {
    code: 242009,
    label: 'Fabricación de productos primarios de metales preciosos y de otros metales no ferrosos n.c.p.',
  },
  {
    code: 381200,
    label: 'Recogida de desechos peligrosos',
  },
  {
    code: 382200,
    label: 'Tratamiento y eliminación de desechos peligrosos',
  },
  {
    code: 201101,
    label: 'Fabricación de carbón vegetal (excepto activado); fabricación de briquetas de carbón vegetal',
  },
  {
    code: 201200,
    label: 'Fabricación de abonos y compuestos de nitrógeno',
  },
  {
    code: 382100,
    label: 'Tratamiento y eliminación de desechos no peligrosos',
  },
  {
    code: 201300,
    label: 'Fabricación de plásticos y caucho sintético en formas primarias',
  },
  {
    code: 202100,
    label: 'Fabricación de plaguicidas y otros productos químicos de uso agropecuario',
  },
  {
    code: 202200,
    label: 'Fabricación de pinturas, barnices y productos de revestimiento, tintas de imprenta y masillas',
  },
  {
    code: 202300,
    label: 'Fabricación de jabones y detergentes, preparados para limpiar, perfumes y preparados de tocador',
  },
  {
    code: 202901,
    label: 'Fabricación de explosivos y productos pirotécnicos',
  },
  {
    code: 202909,
    label: 'Fabricación de otros productos químicos n.c.p.',
  },
  {
    code: 261000,
    label: 'Fabricación de componentes y tableros electrónicos',
  },
  {
    code: 268000,
    label: 'Fabricación de soportes magnéticos y ópticos',
  },
  {
    code: 281700,
    label: 'Fabricación de maquinaria y equipo de oficina\n(excepto computadores y equipo periférico)',
  },
  {
    code: 203000,
    label: 'Fabricación de fibras artificiales',
  },
  {
    code: 221100,
    label: 'Fabricación de cubiertas y cámaras de caucho; recauchutado y renovación de cubiertas de caucho',
  },
  {
    code: 281200,
    label: 'Fabricación de equipo de propulsión de fluidos',
  },
  {
    code: 273300,
    label: 'Fabricación de dispositivos de cableado',
  },
  {
    code: 331400,
    label: 'Reparación de equipo eléctrico (excepto reparación de equipo y enseres domésticos)',
  },
  {
    code: 231001,
    label: 'Fabricación de vidrio plano',
  },
  {
    code: 231002,
    label: 'Fabricación de vidrio hueco',
  },
  {
    code: 231003,
    label: 'Fabricación de fibras de vidrio',
  },
  {
    code: 231009,
    label: 'Fabricación de productos de vidrio n.c.p.',
  },
  {
    code: 239300,
    label: 'Fabricación de otros productos de porcelana y de cerámica',
  },
  {
    code: 239300,
    label: 'Fabricación de otros productos de porcelana y de\ncerámica',
  },
  {
    code: 239200,
    label: 'Fabricación de materiales de construcción de arcilla',
  },
  {
    code: 239100,
    label: 'Fabricación de productos refractarios',
  },
  {
    code: 239400,
    label: 'Fabricación de cemento, cal y yeso',
  },
  {
    code: 239500,
    label: 'Fabricación de artículos de hormigón, cemento y yeso',
  },
  {
    code: 239600,
    label: 'Corte, talla y acabado de la piedra',
  },
  {
    code: 239900,
    label: 'Fabricación de otros productos minerales no metálicos n.c.p.',
  },
  {
    code: 241000,
    label: 'Industrias básicas de hierro y acero',
  },
  {
    code: 243100,
    label: 'Fundición de hierro y acero',
  },
  {
    code: 242001,
    label: 'Fabricación de productos primarios de cobre',
  },
  {
    code: 242002,
    label: 'Fabricación de productos primarios de aluminio',
  },
  {
    code: 242009,
    label: 'Fabricación de productos primarios de metales\npreciosos y de otros metales no ferrosos n.c.p.',
  },
  {
    code: 243200,
    label: 'Fundición de metales no ferrosos',
  },
  {
    code: 251100,
    label: 'Fabricación de productos metálicos para uso estructural',
  },
  {
    code: 331100,
    label: 'Reparación de productos elaborados de metal',
  },
  {
    code: 251201,
    label: 'Fabricación de recipientes de metal para gases comprimidos o licuados',
  },
  {
    code: 251209,
    label: 'Fabricación de tanques, depósitos y recipientes de metal n.c.p.',
  },
  {
    code: 251300,
    label: 'Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central',
  },
  {
    code: 332000,
    label: 'Instalación de maquinaria y equipos industriales',
  },
  {
    code: 259100,
    label: 'Forja, prensado, estampado y laminado de metales; pulvimetalurgia',
  },
  {
    code: 259200,
    label: 'Tratamiento y revestimiento de metales; maquinado',
  },
  {
    code: 259300,
    label: 'Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería',
  },
  {
    code: 259900,
    label: 'Fabricación de otros productos elaborados de metal n.c.p.',
  },
  {
    code: 259900,
    label: 'Fabricación de otros productos elaborados de metal\nn.c.p.',
  },
  {
    code: 281700,
    label: 'Fabricación de maquinaria y equipo de oficina (excepto computadores y equipo periférico)',
  },
  {
    code: 281100,
    label: 'Fabricación de motores y turbinas, excepto para aeronaves, vehículos automotores y motocicletas',
  },
  {
    code: 331209,
    label: 'Reparación de otro tipo de maquinaria y equipos industriales n.c.p.',
  },
  {
    code: 281300,
    label: 'Fabricación de otras bombas, compresores, grifos y válvulas',
  },
  {
    code: 281400,
    label: 'Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión',
  },
  {
    code: 281500,
    label: 'Fabricación de hornos, calderas y quemadores',
  },
  {
    code: 331209,
    label: 'Reparación de otro tipo de maquinaria y equipos\nindustriales n.c.p.',
  },
  {
    code: 281600,
    label: 'Fabricación de equipo de elevación y manipulación',
  },
  {
    code: 281900,
    label: 'Fabricación de otros tipos de maquinaria de uso general',
  },
  {
    code: 282100,
    label: 'Fabricación de maquinaria agropecuaria y forestal',
  },
  {
    code: 331201,
    label: 'Reparación de maquinaria agropecuaria y forestal',
  },
  {
    code: 282200,
    label: 'Fabricación de maquinaria para la conformación de metales y de máquinas herramienta',
  },
  {
    code: 279000,
    label: 'Fabricación de otros tipos de equipo eléctrico',
  },
  {
    code: 281800,
    label: 'Fabricación de herramientas de mano motorizadas',
  },
  {
    code: 282300,
    label: 'Fabricación de maquinaria metalúrgica',
  },
  {
    code: 331202,
    label: 'Reparación de maquinaria metalúrgica, para la\nminería, extracción de petróleo y para la construcción',
  },
  {
    code: 282400,
    label: 'Fabricación de maquinaria para la explotación de\nminas y canteras y para obras de construcción',
  },
  {
    code: 282400,
    label: 'Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción',
  },
  {
    code: 331202,
    label: 'Reparación de maquinaria metalúrgica, para la minería, extracción de petróleo y para la construcción',
  },
  {
    code: 282500,
    label: 'Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco',
  },
  {
    code: 331203,
    label: 'Reparación de maquinaria para la elaboración de alimentos, bebidas y tabaco',
  },
  {
    code: 282600,
    label: 'Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros',
  },
  {
    code: 331204,
    label: 'Reparación de maquinaria para producir textiles, prendas de vestir, artículos de cuero y calzado',
  },
  {
    code: 252000,
    label: 'Fabricación de armas y municiones',
  },
  {
    code: 303000,
    label: 'Fabricación de aeronaves, naves espaciales y\nmaquinaria conexa',
  },
  {
    code: 304000,
    label: 'Fabricación de vehículos militares de combate',
  },
  {
    code: 282900,
    label: 'Fabricación de otros tipos de maquinaria de uso especial',
  },
  {
    code: 275000,
    label: 'Fabricación de aparatos de uso doméstico',
  },
  {
    code: 262000,
    label: 'Fabricación de computadores y equipo periférico',
  },
  {
    code: 271000,
    label: 'Fabricación de motores, generadores y transformadores eléctricos, aparatos de distribución y\ncontrol',
  },
  {
    code: 331400,
    label: 'Reparación de equipo eléctrico (excepto reparación\nde equipo y enseres domésticos)',
  },
  {
    code: 273200,
    label: 'Fabricación de otros hilos y cables eléctricos',
  },
  {
    code: 273100,
    label: 'Fabricación de cables de fibra óptica',
  },
  {
    code: 272000,
    label: 'Fabricación de pilas, baterías y acumuladores',
  },
  {
    code: 274000,
    label: 'Fabricación de equipo eléctrico de iluminación',
  },
  {
    code: 263000,
    label: 'Fabricación de equipo de comunicaciones',
  },
  {
    code: 265100,
    label: 'Fabricación de equipo de medición, prueba, navegación y control',
  },
  {
    code: 293000,
    label: 'Fabricación de partes, piezas y accesorios para vehículos automotores',
  },
  {
    code: 302000,
    label: 'Fabricación de locomotoras y material rodante',
  },
  {
    code: 331309,
    label: 'Reparación de otros equipos electrónicos y ópticos n.c.p.',
  },
  {
    code: 265100,
    label: 'Fabricación de equipo de medición, prueba,\nnavegación y control',
  },
  {
    code: 951200,
    label: 'Reparación de equipo de comunicaciones (incluye la reparación teléfonos celulares)',
  },
  {
    code: 331309,
    label: 'Reparación de otros equipos electrónicos y ópticos\nn.c.p.',
  },
  {
    code: 264000,
    label: 'Fabricación de aparatos electrónicos de consumo',
  },
  {
    code: 267000,
    label: 'Fabricación de instrumentos ópticos y equipo fotográfico',
  },
  {
    code: 952100,
    label: 'Reparación de aparatos electrónicos de consumo (incluye aparatos de televisión y radio)',
  },
  {
    code: 266000,
    label: 'Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico',
  },
  {
    code: 325001,
    label: 'Actividades de laboratorios dentales',
  },
  {
    code: 267000,
    label: 'Fabricación de instrumentos ópticos y equipo\nfotográfico',
  },
  {
    code: 282900,
    label: 'Fabricación de otros tipos de maquinaria de uso\nespecial',
  },
  {
    code: 331301,
    label: 'Reparación de equipo de medición, prueba, navegación y control',
  },
  {
    code: 265200,
    label: 'Fabricación de relojes',
  },
  {
    code: 321100,
    label: 'Fabricación de joyas y artículos conexos',
  },
  {
    code: 321200,
    label: 'Fabricación de bisutería y artículos conexos',
  },
  {
    code: 291000,
    label: 'Fabricación de vehículos automotores',
  },
  {
    code: 292000,
    label: 'Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques',
  },
  {
    code: 139200,
    label: 'Fabricación de artículos confeccionados de materiales textiles, excepto prendas de vestir',
  },
  {
    code: 301100,
    label: 'Construcción de buques, embarcaciones menores y estructuras flotantes',
  },
  {
    code: 331501,
    label: 'Reparación de buques, embarcaciones menores y estructuras flotantes',
  },
  {
    code: 301200,
    label: 'Construcción de embarcaciones de recreo y de deporte',
  },
  {
    code: 331509,
    label: 'Reparación de otros equipos de transporte n.c.p.,\nexcepto vehículos automotores',
  },
  {
    code: 303000,
    label: 'Fabricación de aeronaves, naves espaciales y maquinaria conexa',
  },
  {
    code: 281100,
    label: 'Fabricación de motores y turbinas, excepto para\naeronaves, vehículos automotores y motocicletas',
  },
  {
    code: 331502,
    label: 'Reparación de aeronaves y naves espaciales',
  },
  {
    code: 309100,
    label: 'Fabricación de motocicletas',
  },
  {
    code: 309200,
    label: 'Fabricación de bicicletas y de sillas de ruedas',
  },
  {
    code: 309900,
    label: 'Fabricación de otros tipos de equipo de transporte n.c.p.',
  },
  {
    code: 310009,
    label: 'Fabricación de colchones; fabricación de otros muebles n.c.p.',
  },
  {
    code: 331509,
    label: 'Reparación de otros equipos de transporte n.c.p., excepto vehículos automotores',
  },
  {
    code: 310001,
    label: 'Fabricación de muebles principalmente de madera',
  },
  {
    code: 952400,
    label: 'Reparación de muebles y accesorios domésticos',
  },
  {
    code: 322000,
    label: 'Fabricación de instrumentos musicales',
  },
  {
    code: 324000,
    label: 'Fabricación de juegos y juguetes',
  },
  {
    code: 162900,
    label: 'Fabricación de otros productos de madera, de\nartículos de corcho, paja y materiales trenzables',
  },
  {
    code: 383001,
    label: 'Recuperación y reciclamiento de desperdicios y desechos metálicos',
  },
  {
    code: 383002,
    label: 'Recuperación y reciclamiento de papel',
  },
  {
    code: 383003,
    label: 'Recuperación y reciclamiento de vidrio',
  },
  {
    code: 383009,
    label: 'Recuperación y reciclamiento de otros desperdicios y\ndesechos n.c.p.',
  },
  {
    code: 351011,
    label: 'Generación de energía eléctrica en centrales hidroeléctricas',
  },
  {
    code: 351012,
    label: 'Generación de energía eléctrica en centrales\ntermoeléctricas',
  },
  {
    code: 351012,
    label: 'Generación de energía eléctrica en centrales termoeléctricas',
  },
  {
    code: 351019,
    label: 'Generación de energía eléctrica en otras centrales\nn.c.p.',
  },
  {
    code: 351020,
    label: 'Transmisión de energía eléctrica',
  },
  {
    code: 351030,
    label: 'Distribución de energía eléctrica',
  },
  {
    code: 352020,
    label: 'Fabricación de gas; distribución de combustibles\ngaseosos por tubería, excepto regasificación de GNL',
  },
  {
    code: 352010,
    label: 'Regasificación de Gas Natural Licuado (GNL)',
  },
  {
    code: 353001,
    label: 'Suministro de vapor y de aire acondicionado',
  },
  {
    code: 360000,
    label: 'Captación, tratamiento y distribución de agua',
  },
  {
    code: 431200,
    label: 'Preparación del terreno',
  },
  {
    code: 390000,
    label: 'Actividades de descontaminación y otros servicios de gestión de desechos',
  },
  {
    code: 431100,
    label: 'Demolición',
  },
  {
    code: 410010,
    label: 'Construcción de edificios para uso residencial',
  },
  {
    code: 410020,
    label: 'Construcción de edificios para uso no residencial',
  },
  {
    code: 439000,
    label: 'Otras actividades especializadas de construcción',
  },
  {
    code: 421000,
    label: 'Construcción de carreteras y líneas de ferrocarril',
  },
  {
    code: 422000,
    label: 'Construcción de proyectos de servicio público',
  },
  {
    code: 429000,
    label: 'Construcción de otras obras de ingeniería civil',
  },
  {
    code: 432100,
    label: 'Instalaciones eléctricas',
  },
  {
    code: 432200,
    label: 'Instalaciones de gasfitería, calefacción y aire acondicionado',
  },
  {
    code: 432900,
    label: 'Otras instalaciones para obras de construcción',
  },
  {
    code: 433000,
    label: 'Terminación y acabado de edificios',
  },
  {
    code: 451001,
    label: 'Venta al por mayor de vehículos automotores',
  },
  {
    code: 451002,
    label: 'Venta al por menor de vehículos automotores nuevos o usados (incluye compraventa)',
  },
  {
    code: 452001,
    label: 'Servicio de lavado de vehículos automotores',
  },
  {
    code: 522190,
    label: 'Actividades de servicios vinculadas al transporte terrestre n.c.p.',
  },
  {
    code: 452002,
    label: 'Mantenimiento y reparación de vehículos automotores',
  },
  {
    code: 453000,
    label: 'Venta de partes, piezas y accesorios para vehículos automotores',
  },
  {
    code: 454001,
    label: 'Venta de motocicletas',
  },
  {
    code: 454002,
    label: 'Venta de partes, piezas y accesorios de motocicletas',
  },
  {
    code: 454003,
    label: 'Mantenimiento y reparación de motocicletas',
  },
  {
    code: 473000,
    label: 'Venta al por menor de combustibles para vehículos automotores en comercios especializados',
  },
  {
    code: 461001,
    label: 'Corretaje al por mayor de productos agrícolas',
  },
  {
    code: 461002,
    label: 'Corretaje al por mayor de ganado',
  },
  {
    code: 461009,
    label: 'Otros tipos de corretajes o remates al por mayor n.c.p.',
  },
  {
    code: 619090,
    label: 'Otras actividades de telecomunicaciones n.c.p.',
  },
  {
    code: 462020,
    label: 'Venta al por mayor de animales vivos',
  },
  {
    code: 462090,
    label: 'Venta al por mayor de otras materias primas agropecuarias n.c.p.',
  },
  {
    code: 462010,
    label: 'Venta al por mayor de materias primas agrícolas',
  },
  {
    code: 463011,
    label: 'Venta al por mayor de frutas y verduras',
  },
  {
    code: 463012,
    label: 'Venta al por mayor de carne y productos cárnicos',
  },
  {
    code: 463013,
    label: 'Venta al por mayor de productos del mar (pescados, mariscos y algas)',
  },
  {
    code: 463020,
    label: 'Venta al por mayor de bebidas alcohólicas y no alcohólicas',
  },
  {
    code: 463014,
    label: 'Venta al por mayor de productos de confitería',
  },
  {
    code: 463030,
    label: 'Venta al por mayor de tabaco',
  },
  {
    code: 463019,
    label: 'Venta al por mayor de huevos, lácteos, abarrotes y de otros alimentos n.c.p.',
  },
  {
    code: 464100,
    label: 'Venta al por mayor de productos textiles, prendas de\nvestir y calzado',
  },
  {
    code: 464901,
    label: 'Venta al por mayor de muebles, excepto muebles de oficina',
  },
  {
    code: 464902,
    label: 'Venta al por mayor de artículos eléctricos y\nelectrónicos para el hogar',
  },
  {
    code: 464903,
    label: 'Venta al por mayor de artículos de perfumería, de tocador y cosméticos',
  },
  {
    code: 464904,
    label: 'Venta al por mayor de artículos de papelería y\nescritorio',
  },
  {
    code: 464905,
    label: 'Venta al por mayor de libros',
  },
  {
    code: 464906,
    label: 'Venta al por mayor de diarios y revistas',
  },
  {
    code: 464907,
    label: 'Venta al por mayor de productos farmacéuticos y medicinales',
  },
  {
    code: 464908,
    label: 'Venta al por mayor de instrumentos científicos y\nquirúrgicos',
  },
  {
    code: 464909,
    label: 'Venta al por mayor de otros enseres domésticos n.c.p.',
  },
  {
    code: 466302,
    label: 'Venta al por mayor de materiales de construcción,\nartículos de ferretería, gasfitería y calefacción',
  },
  {
    code: 466100,
    label: 'Venta al por mayor de combustibles sólidos, líquidos y gaseosos y productos conexos',
  },
  {
    code: 466100,
    label: 'Venta al por mayor de combustibles sólidos, líquidos\ny gaseosos y productos conexos',
  },
  {
    code: 466200,
    label: 'Venta al por mayor de metales y minerales metalíferos',
  },
  {
    code: 466301,
    label: 'Venta al por mayor de madera en bruto y productos\nprimarios de la elaboración de madera',
  },
  {
    code: 466302,
    label: 'Venta al por mayor de materiales de construcción, artículos de ferretería, gasfitería y calefacción',
  },
  {
    code: 466901,
    label: 'Venta al por mayor de productos químicos',
  },
  {
    code: 466902,
    label: 'Venta al por mayor de desechos metálicos (chatarra)',
  },
  {
    code: 464908,
    label: 'Venta al por mayor de instrumentos científicos y quirúrgicos',
  },
  {
    code: 466909,
    label: 'Venta al por mayor de desperdicios, desechos y otros productos n.c.p.',
  },
  {
    code: 465300,
    label: 'Venta al por mayor de maquinaria, equipo y materiales agropecuarios',
  },
  {
    code: 465901,
    label: 'Venta al por mayor de maquinaria metalúrgica, para la minería, extracción de petróleo y construcción',
  },
  {
    code: 465902,
    label: 'Venta al por mayor de maquinaria para la elaboración de alimentos, bebidas y tabaco',
  },
  {
    code: 465903,
    label: 'Venta al por mayor de maquinaria para la industria textil, del cuero y del calzado',
  },
  {
    code: 465100,
    label: 'Venta al por mayor de computadores, equipo periférico y programas informáticos',
  },
  {
    code: 465200,
    label: 'Venta al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones',
  },
  {
    code: 465904,
    label: 'Venta al por mayor de maquinaria y equipo de oficina; venta al por mayor de muebles de oficina',
  },
  {
    code: 465905,
    label: 'Venta al por mayor de equipo de transporte(excepto vehículos automotores, motocicletas y bicicletas)',
  },
  {
    code: 465909,
    label: 'Venta al por mayor de otros tipos de maquinaria y equipo n.c.p.',
  },
  {
    code: 469000,
    label: 'Venta al por mayor no especializada',
  },
  {
    code: 471100,
    label: 'Venta al por menor en comercios de alimentos,\nbebidas o tabaco (supermercados e hipermercados)',
  },
  {
    code: 472101,
    label: 'Venta al por menor de alimentos en comercios especializados (almacenes pequeños y minimarket)',
  },
  {
    code: 471100,
    label: 'Venta al por menor en comercios de alimentos, bebidas o tabaco (supermercados e hipermercados)',
  },
  {
    code: 471990,
    label: 'Otras actividades de venta al por menor en comercios no especializados n.c.p.',
  },
  {
    code: 471910,
    label: 'Venta al por menor en comercios de vestuario y productos para el hogar (grandes tiendas)',
  },
  {
    code: 477399,
    label: 'Venta al por menor de otros productos en comercios especializados n.c.p.',
  },
  {
    code: 472200,
    label: 'Venta al por menor de bebidas alcohólicas y no alcohólicas en comercios especializados (botillerías)',
  },
  {
    code: 472102,
    label: 'Venta al por menor en comercios especializados de carne y productos cárnicos',
  },
  {
    code: 472103,
    label: 'Venta al por menor en comercios especializados de frutas y verduras (verdulerías)',
  },
  {
    code: 472104,
    label: 'Venta al por menor en comercios especializados de pescado, mariscos y productos conexos',
  },
  {
    code: 472105,
    label: 'Venta al por menor en comercios especializados de productos de panadería y pastelería',
  },
  {
    code: 477391,
    label: 'Venta al por menor de alimento y accesorios para mascotas en comercios especializados',
  },
  {
    code: 472109,
    label: 'Venta al por menor en comercios especializados de huevos, confites y productos alimenticios n.c.p.',
  },
  {
    code: 472300,
    label: 'Venta al por menor de tabaco y productos de tabaco en comercios especializados',
  },
  {
    code: 477201,
    label: 'Venta al por menor de productos farmacéuticos y medicinales en comercios especializados',
  },
  {
    code: 477202,
    label: 'Venta al por menor de artículos ortopédicos en comercios especializados',
  },
  {
    code: 477203,
    label: 'Venta al por menor de artículos de perfumería, de tocador y cosméticos en comercios especializados',
  },
  {
    code: 477101,
    label: 'Venta al por menor de calzado en comercios especializados',
  },
  {
    code: 477102,
    label: 'Venta al por menor de prendas y accesorios de vestir en comercios especializados',
  },
  {
    code: 475100,
    label: 'Venta al por menor de telas, lanas, hilos y similares en comercios especializados',
  },
  {
    code: 477103,
    label: 'Venta al por menor de carteras, maletas y otros accesorios de viaje en comercios especializados',
  },
  {
    code: 475300,
    label: 'Venta al por menor de tapices, alfombras y\ncubrimientos para paredes y pisos',
  },
  {
    code: 475909,
    label: 'Venta al por menor de aparatos eléctricos, textiles para el hogar y otros enseres domésticos n.c.p.',
  },
  {
    code: 477103,
    label: 'Venta al por menor de carteras, maletas y otros\naccesorios de viaje en comercios especializados',
  },
  {
    code: 474200,
    label: 'Venta al por menor de equipo de sonido y de video en comercios especializados',
  },
  {
    code: 475901,
    label: 'Venta al por menor de muebles y colchones en comercios especializados',
  },
  {
    code: 475902,
    label: 'Venta al por menor de instrumentos musicales en comercios especializados',
  },
  {
    code: 476200,
    label: 'Venta al por menor de grabaciones de música y de video en comercios especializados',
  },
  {
    code: 475201,
    label: 'Venta al por menor de artículos de ferretería y materiales de construcción',
  },
  {
    code: 475202,
    label: 'Venta al por menor de pinturas, barnices y lacas en comercios especializados',
  },
  {
    code: 475203,
    label: 'Venta al por menor de productos de vidrio en comercios especializados',
  },
  {
    code: 477393,
    label: 'Venta al por menor de artículos ópticos en comercios especializados',
  },
  {
    code: 476400,
    label: 'Venta al por menor de juegos y juguetes en comercios especializados',
  },
  {
    code: 474100,
    label: 'Venta al por menor de computadores, equipo periférico, programas informáticos y equipo de\ntelecom.',
  },
  {
    code: 476101,
    label: 'Venta al por menor de libros en comercios especializados',
  },
  {
    code: 476102,
    label: 'Venta al por menor de diarios y revistas en comercios especializados',
  },
  {
    code: 476103,
    label: 'Venta al por menor de artículos de papelería y escritorio en comercios especializados',
  },
  {
    code: 474100,
    label: 'Venta al por menor de computadores, equipo periférico, programas informáticos y equipo de telecom.',
  },
  {
    code: 476301,
    label: 'Venta al por menor de artículos de caza y pesca en\ncomercios especializados',
  },
  {
    code: 477392,
    label: 'Venta al por menor de armas y municiones en comercios especializados',
  },
  {
    code: 476302,
    label: 'Venta al por menor de bicicletas y sus repuestos en\ncomercios especializados',
  },
  {
    code: 476309,
    label: 'Venta al por menor de otros artículos y equipos de deporte n.c.p.',
  },
  {
    code: 477394,
    label: 'Venta al por menor de artículos de joyería, bisutería y\nrelojería en comercios especializados',
  },
  {
    code: 477310,
    label: 'Venta al por menor de gas licuado en bombonas (cilindros) en comercios especializados',
  },
  {
    code: 477395,
    label: 'Venta al por menor de carbón, leña y otros combustibles de uso doméstico en comercios especializados',
  },
  {
    code: 477396,
    label: 'Venta al por menor de recuerdos, artesanías y\nartículos religiosos en comercios especializados',
  },
  {
    code: 477397,
    label: 'Venta al por menor de flores, plantas, arboles, semillas y abonos en comercios especializados',
  },
  {
    code: 477398,
    label: 'Venta al por menor de mascotas en comercios\nespecializados',
  },
  {
    code: 475300,
    label: 'Venta al por menor de tapices, alfombras y cubrimientos para paredes y pisos',
  },
  {
    code: 477401,
    label: 'Venta al por menor de antigüedades en comercios',
  },
  {
    code: 477402,
    label: 'Venta al por menor de ropa usada en comercios',
  },
  {
    code: 477409,
    label: 'Venta al por menor de otros artículos de segunda\nmano en comercios n.c.p.',
  },
  {
    code: 649209,
    label: 'Otras actividades de concesión de crédito n.c.p.',
  },
  {
    code: 479100,
    label: 'Venta al por menor por correo, por Internet y vía\ntelefónica',
  },
  {
    code: 479100,
    label: 'Venta al por menor por correo, por Internet y vía telefónica',
  },
  {
    code: 478100,
    label: 'Venta al por menor de alimentos, bebidas y tabaco en puestos de venta y mercados (incluye ferias)',
  },
  {
    code: 478200,
    label: 'Venta al por menor de productos textiles, prendas de\nvestir y calzado en puestos de venta y mercados',
  },
  {
    code: 478900,
    label: 'Venta al por menor de otros productos en puestos de venta y mercados (incluye ferias)',
  },
  {
    code: 479901,
    label: 'Venta al por menor realizada por independientes en la locomoción colectiva (Ley 20.388)',
  },
  {
    code: 479909,
    label: 'Otras actividades de venta por menor no realizadas en comercios, puestos de venta o mercados n.c.p.',
  },
  {
    code: 479902,
    label: 'Venta al por menor mediante máquinas expendedoras',
  },
  {
    code: 479903,
    label: 'Venta al por menor por comisionistas (no dependientes de comercios)',
  },
  {
    code: 952300,
    label: 'Reparación de calzado y de artículos de cuero',
  },
  {
    code: 952200,
    label: 'Reparación de aparatos de uso doméstico, equipo doméstico y de jardinería',
  },
  {
    code: 802000,
    label: 'Actividades de servicios de sistemas de seguridad (incluye servicios de cerrajería)',
  },
  {
    code: 551001,
    label: 'Actividades de hoteles',
  },
  {
    code: 551002,
    label: 'Actividades de moteles',
  },
  {
    code: 559001,
    label: 'Actividades de residenciales para estudiantes y trabajadores',
  },
  {
    code: 551003,
    label: 'Actividades de residenciales para turistas',
  },
  {
    code: 559009,
    label: 'Otras actividades de alojamiento n.c.p.',
  },
  {
    code: 551009,
    label: 'Otras actividades de alojamiento para turistas n.c.p.',
  },
  {
    code: 552000,
    label: 'Actividades de camping y de parques para casas rodantes',
  },
  {
    code: 561000,
    label: 'Actividades de restaurantes y de servicio móvil de comidas',
  },
  {
    code: 563009,
    label: 'Otras actividades de servicio de bebidas n.c.p.',
  },
  {
    code: 562900,
    label: 'Suministro industrial de comidas por encargo; concesión de servicios de alimentación',
  },
  {
    code: 562100,
    label: 'Suministro de comidas por encargo (Servicios de banquetería)',
  },
  {
    code: 491100,
    label: 'Transporte interurbano de pasajeros por ferrocarril',
  },
  {
    code: 491200,
    label: 'Transporte de carga por ferrocarril',
  },
  {
    code: 492110,
    label: 'Transporte urbano y suburbano de pasajeros vía\nmetro y metrotren',
  },
  {
    code: 492120,
    label: 'Transporte urbano y suburbano de pasajeros vía locomoción colectiva',
  },
  {
    code: 492250,
    label: 'Transporte de pasajeros en buses interurbanos',
  },
  {
    code: 492130,
    label: 'Transporte de pasajeros vía taxi colectivo',
  },
  {
    code: 492210,
    label: 'Servicios de transporte de escolares',
  },
  {
    code: 492220,
    label: 'Servicios de transporte de trabajadores',
  },
  {
    code: 492290,
    label: 'Otras actividades de transporte de pasajeros por vía terrestre n.c.p.',
  },
  {
    code: 492190,
    label: 'Otras actividades de transporte urbano y suburbano de pasajeros por vía terrestre n.c.p.',
  },
  {
    code: 492230,
    label: 'Servicios de transporte de pasajeros en taxis libres y radiotaxis',
  },
  {
    code: 492240,
    label: 'Servicios de transporte a turistas',
  },
  {
    code: 492290,
    label: 'Otras actividades de transporte de pasajeros por vía\nterrestre n.c.p.',
  },
  {
    code: 492300,
    label: 'Transporte de carga por carretera',
  },
  {
    code: 493090,
    label: 'Otras actividades de transporte por tuberías n.c.p.',
  },
  {
    code: 493010,
    label: 'Transporte por oleoductos',
  },
  {
    code: 493020,
    label: 'Transporte por gasoductos',
  },
  {
    code: 501100,
    label: 'Transporte de pasajeros marítimo y de cabotaje',
  },
  {
    code: 501200,
    label: 'Transporte de carga marítimo y de cabotaje',
  },
  {
    code: 502100,
    label: 'Transporte de pasajeros por vías de navegación interiores',
  },
  {
    code: 502200,
    label: 'Transporte de carga por vías de navegación interiores',
  },
  {
    code: 511000,
    label: 'Transporte de pasajeros por vía aérea',
  },
  {
    code: 512000,
    label: 'Transporte de carga por vía aérea',
  },
  {
    code: 522400,
    label: 'Manipulación de la carga',
  },
  {
    code: 521009,
    label: 'Otros servicios de almacenamiento y depósito n.c.p.',
  },
  {
    code: 522110,
    label: 'Explotación de terminales terrestres de pasajeros',
  },
  {
    code: 522120,
    label: 'Explotación de estacionamientos de vehículos automotores y parquímetros',
  },
  {
    code: 522200,
    label: 'Actividades de servicios vinculadas al transporte acuático',
  },
  {
    code: 522300,
    label: 'Actividades de servicios vinculadas al transporte aéreo',
  },
  {
    code: 522130,
    label: 'Servicios prestados por concesionarios de carreteras',
  },
  {
    code: 522300,
    label: 'Actividades de servicios vinculadas al transporte\naéreo',
  },
  {
    code: 791100,
    label: 'Actividades de agencias de viajes',
  },
  {
    code: 791200,
    label: 'Actividades de operadores turísticos',
  },
  {
    code: 799000,
    label: 'Otros servicios de reservas y actividades conexas (incluye venta de entradas para teatro, y otros)',
  },
  {
    code: 522910,
    label: 'Agencias de aduanas',
  },
  {
    code: 522990,
    label: 'Otras actividades de apoyo al transporte n.c.p.',
  },
  {
    code: 522920,
    label: 'Agencias de naves',
  },
  {
    code: 531000,
    label: 'Actividades postales',
  },
  {
    code: 821900,
    label: 'Fotocopiado, preparación de documentos y otras actividades especializadas de apoyo de oficina',
  },
  {
    code: 532000,
    label: 'Actividades de mensajería',
  },
  {
    code: 611010,
    label: 'Telefonía fija',
  },
  {
    code: 612010,
    label: 'Telefonía móvil celular',
  },
  {
    code: 613010,
    label: 'Telefonía móvil satelital',
  },
  {
    code: 611020,
    label: 'Telefonía larga distancia',
  },
  {
    code: 613020,
    label: 'Televisión de pago satelital',
  },
  {
    code: 611030,
    label: 'Televisión de pago por cable',
  },
  {
    code: 612030,
    label: 'Televisión de pago inalámbrica',
  },
  {
    code: 611090,
    label: 'Otros servicios de telecomunicaciones alámbricas n.c.p.',
  },
  {
    code: 612090,
    label: 'Otros servicios de telecomunicaciones inalámbricas n.c.p.',
  },
  {
    code: 613090,
    label: 'Otros servicios de telecomunicaciones por satélite n.c.p.',
  },
  {
    code: 619010,
    label: 'Centros de llamados y centros de acceso a Internet',
  },
  {
    code: 612020,
    label: 'Radiocomunicaciones móviles',
  },
  {
    code: 641100,
    label: 'Banca central',
  },
  {
    code: 641910,
    label: 'Actividades bancarias',
  },
  {
    code: 649201,
    label: 'Financieras',
  },
  {
    code: 641990,
    label: 'Otros tipos de intermediación monetaria n.c.p.',
  },
  {
    code: 649100,
    label: 'Leasing financiero',
  },
  {
    code: 649202,
    label: 'Actividades de crédito prendario',
  },
  {
    code: 649900,
    label: 'Otras actividades de servicios financieros, excepto las de seguros y fondos de pensiones n.c.p.',
  },
  {
    code: 661201,
    label: 'Actividades de securitizadoras',
  },
  {
    code: 663091,
    label: 'Administradoras de fondos de inversión',
  },
  {
    code: 663092,
    label: 'Administradoras de fondos mutuos',
  },
  {
    code: 663093,
    label: 'Administradoras de fices (fondos de inversión de capital extranjero)',
  },
  {
    code: 663094,
    label: 'Administradoras de fondos para la vivienda',
  },
  {
    code: 663099,
    label: 'Administradoras de fondos para otros fines n.c.p.',
  },
  {
    code: 643000,
    label: 'Fondos y sociedades de inversión y entidades financieras similares',
  },
  {
    code: 642000,
    label: 'Actividades de sociedades de cartera',
  },
  {
    code: 774000,
    label: 'Arrendamiento de propiedad intelectual y similares, excepto obras protegidas por derechos de autor',
  },
  {
    code: 651100,
    label: 'Seguros de vida',
  },
  {
    code: 652000,
    label: 'Reaseguros',
  },
  {
    code: 663010,
    label: 'Administradoras de Fondos de Pensiones (AFP)',
  },
  {
    code: 653000,
    label: 'Fondos de pensiones',
  },
  {
    code: 651210,
    label: 'Seguros generales, excepto actividades de Isapres',
  },
  {
    code: 651220,
    label: 'Actividades de Isapres',
  },
  {
    code: 661100,
    label: 'Administración de mercados financieros',
  },
  {
    code: 661202,
    label: 'Corredores de bolsa',
  },
  {
    code: 661203,
    label: 'Agentes de valores',
  },
  {
    code: 661209,
    label: 'Otros servicios de corretaje de valores y commodities n.c.p.',
  },
  {
    code: 661901,
    label: 'Actividades de cámaras de compensación',
  },
  {
    code: 661902,
    label: 'Administración de tarjetas de crédito',
  },
  {
    code: 661903,
    label: 'Empresas de asesoría y consultoría en inversión financiera; sociedades de apoyo al giro',
  },
  {
    code: 661904,
    label: 'Actividades de clasificadoras de riesgo',
  },
  {
    code: 661204,
    label: 'Actividades de casas de cambio y operadores de divisa',
  },
  {
    code: 661909,
    label: 'Otras actividades auxiliares de las actividades de servicios financieros n.c.p.',
  },
  {
    code: 662200,
    label: 'Actividades de agentes y corredores de seguros',
  },
  {
    code: 662100,
    label: 'Evaluación de riesgos y daños (incluye actividades de liquidadores de seguros)',
  },
  {
    code: 662900,
    label: 'Otras actividades auxiliares de las actividades de seguros y fondos de pensiones',
  },
  {
    code: 681011,
    label: 'Alquiler de bienes inmuebles amoblados o con equipos y maquinarias',
  },
  {
    code: 681012,
    label: 'Compra, venta y alquiler (excepto amoblados) de inmuebles',
  },
  {
    code: 681020,
    label: 'Servicios imputados de alquiler de viviendas',
  },
  {
    code: 682000,
    label: 'Actividades inmobiliarias realizadas a cambio de una retribución o por contrata',
  },
  {
    code: 811000,
    label: 'Actividades combinadas de apoyo a instalaciones',
  },
  {
    code: 771000,
    label: 'Alquiler de vehículos automotores sin chofer',
  },
  {
    code: 773001,
    label: 'Alquiler de equipos de transporte sin operario, excepto vehículos automotores',
  },
  {
    code: 773002,
    label: 'Alquiler de maquinaria y equipo agropecuario, forestal, de construcción e ing. civil, sin operarios',
  },
  {
    code: 773003,
    label: 'Alquiler de maquinaria y equipo de oficina, sin operarios (sin servicio administrativo)',
  },
  {
    code: 773009,
    label: 'Alquiler de otros tipos de maquinarias y equipos sin operario n.c.p.',
  },
  {
    code: 772100,
    label: 'Alquiler y arrendamiento de equipo recreativo y deportivo',
  },
  {
    code: 772900,
    label: 'Alquiler de otros efectos personales y enseres domésticos (incluye mobiliario para eventos)',
  },
  {
    code: 772200,
    label: 'Alquiler de cintas de video y discos',
  },
  {
    code: 620100,
    label: 'Actividades de programación informática',
  },
  {
    code: 631100,
    label: 'Procesamiento de datos, hospedaje y actividades conexas',
  },
  {
    code: 582000,
    label: 'Edición de programas informáticos',
  },
  {
    code: 601000,
    label: 'Transmisiones de radio',
  },
  {
    code: 602000,
    label: 'Programación y transmisiones de televisión',
  },
  {
    code: 620200,
    label: 'Actividades de consultoría de informática y de gestión de instalaciones informáticas',
  },
  {
    code: 631200,
    label: 'Portales web',
  },
  {
    code: 951100,
    label: 'Reparación de computadores y equipo periférico',
  },
  {
    code: 620900,
    label: 'Otras actividades de tecnología de la información y de servicios informáticos',
  },
  {
    code: 721000,
    label: 'Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería',
  },
  {
    code: 722000,
    label: 'Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades',
  },
  {
    code: 691001,
    label: 'Servicios de asesoramiento y representación jurídica',
  },
  {
    code: 691002,
    label: 'Servicio notarial',
  },
  {
    code: 691003,
    label: 'Conservador de bienes raíces',
  },
  {
    code: 691004,
    label: 'Receptores judiciales',
  },
  {
    code: 691009,
    label: 'Servicios de arbitraje; síndicos de quiebra y peritos judiciales; otras actividades jurídicas n.c.p.',
  },
  {
    code: 692000,
    label: 'Actividades de contabilidad, teneduría de libros y auditoría; consultoría fiscal',
  },
  {
    code: 732000,
    label: 'Estudios de mercado y encuestas de opinión pública',
  },
  {
    code: 702000,
    label: 'Actividades de consultoría de gestión',
  },
  {
    code: 701000,
    label: 'Actividades de oficinas principales',
  },
  {
    code: 855000,
    label: 'Actividades de apoyo a la enseñanza',
  },
  {
    code: 711001,
    label: 'Servicios de arquitectura (diseño de edificios, dibujo de planos de construcción, entre otros)',
  },
  {
    code: 711002,
    label: 'Empresas de servicios de ingeniería y actividades\nconexas de consultoría técnica',
  },
  {
    code: 99002,
    label: 'Actividades de apoyo para la explotación de otras minas y canteras prestados por profesionales',
  },
  {
    code: 91002,
    label: 'Actividades de apoyo para la extracción de petróleo y\ngas natural prestados por profesionales',
  },
  {
    code: 711003,
    label: 'Servicios profesionales de ingeniería y actividades conexas de consultoría técnica',
  },
  {
    code: 711002,
    label: 'Empresas de servicios de ingeniería y actividades conexas de consultoría técnica',
  },
  {
    code: 741009,
    label: 'Otras actividades especializadas de diseño n.c.p.',
  },
  {
    code: 749009,
    label: 'Otras actividades profesionales, científicas y técnicas n.c.p.',
  },
  {
    code: 712001,
    label: 'Actividades de plantas de revisión técnica para vehículos automotores',
  },
  {
    code: 712009,
    label: 'Otros servicios de ensayos y análisis técnicos (excepto actividades de plantas de revisión técnica)',
  },
  {
    code: 731001,
    label: 'Servicios de publicidad prestados por empresas',
  },
  {
    code: 731002,
    label: 'Servicios de publicidad prestados por profesionales',
  },
  {
    code: 783000,
    label: 'Otras actividades de dotación de recursos humanos',
  },
  {
    code: 782000,
    label: 'Actividades de agencias de empleo temporal (incluye empresas de servicios transitorios)',
  },
  {
    code: 781000,
    label: 'Actividades de agencias de empleo',
  },
  {
    code: 803000,
    label: 'Actividades de investigación (incluye actividades de investigadores y detectives privados)',
  },
  {
    code: 801001,
    label: 'Servicios de seguridad privada prestados por empresas',
  },
  {
    code: 801002,
    label: 'Servicio de transporte de valores en vehículos blindados',
  },
  {
    code: 801003,
    label: 'Servicios de seguridad privada prestados por independientes',
  },
  {
    code: 812100,
    label: 'Limpieza general de edificios',
  },
  {
    code: 812909,
    label: 'Otras actividades de limpieza de edificios e instalaciones industriales n.c.p.',
  },
  {
    code: 812901,
    label: 'Desratización, desinfección y exterminio de plagas no agrícolas',
  },
  {
    code: 742001,
    label: 'Servicios de revelado, impresión y ampliación de fotografías',
  },
  {
    code: 742002,
    label: 'Servicios y actividades de fotografía',
  },
  {
    code: 742003,
    label: 'Servicios personales de fotografía',
  },
  {
    code: 829200,
    label: 'Actividades de envasado y empaquetado',
  },
  {
    code: 829110,
    label: 'Actividades de agencias de cobro',
  },
  {
    code: 829120,
    label: 'Actividades de agencias de calificación crediticia',
  },
  {
    code: 749001,
    label: 'Asesoría y gestión en la compra o venta de pequeñas y medianas empresas',
  },
  {
    code: 741001,
    label: 'Actividades de diseño de vestuario',
  },
  {
    code: 741002,
    label: 'Actividades de diseño y decoración de interiores',
  },
  {
    code: 821100,
    label: 'Actividades combinadas de servicios administrativos de oficina',
  },
  {
    code: 749003,
    label: 'Servicios personales de traducción e interpretación',
  },
  {
    code: 749002,
    label: 'Servicios de traducción e interpretación prestados por empresas',
  },
  {
    code: 749004,
    label: 'Actividades de agencias y agentes de representación de actores, deportistas y otras figuras públicas',
  },
  {
    code: 829900,
    label: 'Otras actividades de servicios de apoyo a las empresas n.c.p.',
  },
  {
    code: 823000,
    label: 'Organización de convenciones y exposiciones comerciales',
  },
  {
    code: 822000,
    label: 'Actividades de call-center',
  },
  {
    code: 639900,
    label: 'Otras actividades de servicios de información n.c.p.',
  },
  {
    code: 841100,
    label: 'Actividades de la administración pública en general',
  },
  {
    code: 841200,
    label: 'Regulación de las actividades de organismos que prestan servicios sanitarios, educativos, culturales',
  },
  {
    code: 841300,
    label: 'Regulación y facilitación de la actividad económica',
  },
  {
    code: 910100,
    label: 'Actividades de bibliotecas y archivos',
  },
  {
    code: 842300,
    label: 'Actividades de mantenimiento del orden público y de seguridad',
  },
  {
    code: 842100,
    label: 'Relaciones exteriores',
  },
  {
    code: 889000,
    label: 'Otras actividades de asistencia social sin alojamiento',
  },
  {
    code: 842200,
    label: 'Actividades de defensa',
  },
  {
    code: 843090,
    label: 'Otros planes de seguridad social de afiliación obligatoria n.c.p.',
  },
  {
    code: 843010,
    label: 'Fondo Nacional de Salud (FONASA)',
  },
  {
    code: 843020,
    label: 'Instituto de Previsión Social (IPS)',
  },
  {
    code: 649203,
    label: 'Cajas de compensación',
  },
  {
    code: 850021,
    label: 'Enseñanza preescolar privada',
  },
  {
    code: 850011,
    label: 'Enseñanza preescolar pública',
  },
  {
    code: 850022,
    label: 'Enseñanza primaria, secundaria científico humanista y técnico profesional privada',
  },
  {
    code: 850012,
    label: 'Enseñanza primaria, secundaria científico humanista y técnico profesional pública',
  },
  {
    code: 853120,
    label: 'Enseñanza superior en universidades privadas',
  },
  {
    code: 853110,
    label: 'Enseñanza superior en universidades públicas',
  },
  {
    code: 853201,
    label: 'Enseñanza superior en institutos profesionales',
  },
  {
    code: 853202,
    label: 'Enseñanza superior en centros de formación técnica',
  },
  {
    code: 850012,
    label: 'Enseñanza primaria, secundaria científico humanista\ny técnico profesional pública',
  },
  {
    code: 854901,
    label: 'Enseñanza preuniversitaria',
  },
  {
    code: 854909,
    label: 'Otros tipos de enseñanza n.c.p.',
  },
  {
    code: 854902,
    label: 'Servicios personales de educación',
  },
  {
    code: 861020,
    label: 'Actividades de hospitales y clínicas privadas',
  },
  {
    code: 861010,
    label: 'Actividades de hospitales y clínicas públicas',
  },
  {
    code: 862031,
    label: 'Servicios de médicos prestados de forma independiente',
  },
  {
    code: 862021,
    label: 'Centros médicos privados (establecimientos de atención ambulatoria)',
  },
  {
    code: 862010,
    label: 'Actividades de centros de salud municipalizados (servicios de salud pública)',
  },
  {
    code: 862032,
    label: 'Servicios de odontólogos prestados de forma independiente',
  },
  {
    code: 862022,
    label: 'Centros de atención odontológica privados (establecimientos de atención ambulatoria)',
  },
  {
    code: 869010,
    label: 'Actividades de laboratorios clínicos y bancos de sangre',
  },
  {
    code: 869092,
    label: 'Servicios prestados de forma independiente por otros profesionales de la salud',
  },
  {
    code: 869091,
    label: 'Otros servicios de atención de la salud humana prestados por empresas',
  },
  {
    code: 871000,
    label: 'Actividades de atención de enfermería en instituciones',
  },
  {
    code: 872000,
    label: 'Actividades de atención en instituciones para personas con discapacidad mental y toxicómanos',
  },
  {
    code: 873000,
    label: 'Actividades de atención en instituciones para personas de edad y personas con discapacidad física',
  },
  {
    code: 750001,
    label: 'Actividades de clínicas veterinarias',
  },
  {
    code: 750002,
    label: 'Actividades de veterinarios, técnicos y otro personal auxiliar, prestados de forma independiente',
  },
  {
    code: 879000,
    label: 'Otras actividades de atención en instituciones',
  },
  {
    code: 881000,
    label: 'Actividades de asistencia social sin alojamiento para\npersonas de edad y personas con discapacidad',
  },
  {
    code: 813000,
    label: 'Actividades de paisajismo, servicios de jardinería y servicios conexos',
  },
  {
    code: 381100,
    label: 'Recogida de desechos no peligrosos',
  },
  {
    code: 370000,
    label: 'Evacuación y tratamiento de aguas servidas',
  },
  {
    code: 941100,
    label: 'Actividades de asociaciones empresariales y de empleadores',
  },
  {
    code: 941200,
    label: 'Actividades de asociaciones profesionales',
  },
  {
    code: 942000,
    label: 'Actividades de sindicatos',
  },
  {
    code: 949100,
    label: 'Actividades de organizaciones religiosas',
  },
  {
    code: 949200,
    label: 'Actividades de organizaciones políticas',
  },
  {
    code: 949901,
    label: 'Actividades de centros de madres',
  },
  {
    code: 949902,
    label: 'Actividades de clubes sociales',
  },
  {
    code: 949903,
    label: 'Fundaciones y corporaciones; asociaciones que promueven actividades culturales o recreativas',
  },
  {
    code: 591100,
    label: 'Actividades de producción de películas cinematográficas, videos y programas de televisión',
  },
  {
    code: 591200,
    label: 'Actividades de postproducción de películas cinematográficas, videos y programas de televisión',
  },
  {
    code: 591300,
    label: 'Actividades de distribución de películas cinematográficas, videos y programas de televisión',
  },
  {
    code: 591400,
    label: 'Actividades de exhibición de películas cinematográficas y cintas de video',
  },
  {
    code: 591100,
    label: 'Actividades de producción de películas\ncinematográficas, videos y programas de televisión',
  },
  {
    code: 900001,
    label: 'Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod. escénicas',
  },
  {
    code: 900009,
    label: 'Otras actividades creativas, artísticas y de\nentretenimiento n.c.p.',
  },
  {
    code: 900001,
    label: 'Servicios de producción de obras de teatro,\nconciertos, espectáculos de danza, otras prod. escénicas',
  },
  {
    code: 900009,
    label: 'Otras actividades creativas, artísticas y de entretenimiento n.c.p.',
  },
  {
    code: 900002,
    label: 'Actividades artísticas realizadas por bandas de música, compañías de teatro, circenses y similares',
  },
  {
    code: 900003,
    label: 'Actividades de artistas realizadas de forma independiente: actores, músicos, escritores, entre\notros',
  },
  {
    code: 854200,
    label: 'Enseñanza cultural',
  },
  {
    code: 563001,
    label: 'Actividades de discotecas y cabaret (night club), con\npredominio del servicio de bebidas',
  },
  {
    code: 932909,
    label: 'Otras actividades de esparcimiento y recreativas n.c.p.',
  },
  {
    code: 932100,
    label: 'Actividades de parques de atracciones y parques\ntemáticos',
  },
  {
    code: 900001,
    label: 'Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod.\nescénicas',
  },
  {
    code: 639100,
    label: 'Actividades de agencias de noticias',
  },
  {
    code: 900004,
    label: 'Servicios prestados por periodistas independientes',
  },
  {
    code: 910200,
    label: 'Actividades de museos, gestión de lugares y edificios\nhistóricos',
  },
  {
    code: 910300,
    label: 'Actividades de jardines botánicos, zoológicos y reservas naturales',
  },
  {
    code: 931109,
    label: 'Gestión de otras instalaciones deportivas n.c.p.',
  },
  {
    code: 931909,
    label: 'Otras actividades deportivas n.c.p.',
  },
  {
    code: 931209,
    label: 'Actividades de otros clubes deportivos n.c.p.',
  },
  {
    code: 931201,
    label: 'Actividades de clubes de fútbol amateur y profesional',
  },
  {
    code: 931101,
    label: 'Hipódromos',
  },
  {
    code: 931901,
    label: 'Promoción y organización de competencias deportivas',
  },
  {
    code: 854100,
    label: 'Enseñanza deportiva y recreativa',
  },
  {
    code: 920090,
    label: 'Otras actividades de juegos de azar y apuestas n.c.p.',
  },
  {
    code: 920010,
    label: 'Actividades de casinos de juegos',
  },
  {
    code: 932901,
    label: 'Gestión de salas de pool; gestión (explotación) de juegos electrónicos',
  },
  {
    code: 931102,
    label: 'Gestión de salas de billar; gestión de salas de bolos\n(bowling)',
  },
  {
    code: 960100,
    label: 'Lavado y limpieza, incluida la limpieza en seco, de productos textiles y de piel',
  },
  {
    code: 960200,
    label: 'Peluquería y otros tratamientos de belleza',
  },
  {
    code: 960310,
    label: 'Servicios funerarios',
  },
  {
    code: 960320,
    label: 'Servicios de cementerios',
  },
  {
    code: 960902,
    label: 'Actividades de salones de masajes, baños turcos, saunas, servicio de baños públicos',
  },
  {
    code: 960909,
    label: 'Otras actividades de servicios personales n.c.p.',
  },
  {
    code: 970000,
    label: 'Actividades de los hogares como empleadores de personal doméstico',
  },
  {
    code: 949904,
    label: 'Consejo de administración de edificios y condominios',
  },
  {
    code: 990000,
    label: 'Actividades de organizaciones y órganos extraterritoriales',
  },
];
