/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import breakpoints from './breakpoints';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d4275ad;
  z-index: 1090;
  pointer-events: none;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  &.open {
    opacity: 1;
    pointer-events: initial;
  }
`;

export const RightSideBarStyles = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
  padding: 0 32px;
  background-color: #fff;
  color: #323a44;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  z-index: 1090;
  position: fixed;
  top: 0;

  @media ${breakpoints.sm} {
    max-width: ${props => props.$width}px;
    &.right-sidebar-open {
      box-shadow: 3px 0px 15px grey;
    }
  }
`;
export const SlideRightToLeftTransition = styled.div`
  .slide-rl-enter {
    right: ${props => -props.$width}px;
    transition: right 250ms ease-in-out;
  }
  .slide-rl-enter-active {
    right: 0px;
  }

  .slide-rl-enter-done {
    right: 0px;
  }
  .slide-rl-exit {
    right: 0px;
    transition: right 250ms ease-in-out;
  }
  .slide-rl-exit-active {
    right: ${props => -props.$width}px;
  }
`;
