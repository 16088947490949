import React from 'react';
import { IWithChildren } from '../interfaces';
import { CSSTransition } from 'react-transition-group';
import { SlideTransition } from './styles';

const SlideTransitionGroup = ({
  children,
  direction,
  startOffset,
  endOffset,
  trigger,
  duration,
}: IWithChildren & {
  direction: 'rtl' | 'ltr';
  startOffset: string;
  endOffset: string;
  trigger: boolean;
  duration: number;
}): JSX.Element => (
  <SlideTransition startOffset={startOffset} endOffset={endOffset} duration={duration}>
    <CSSTransition in={trigger} timeout={duration} classNames={`slide-${direction}`} unmountOnExit={true}>
      {children}
    </CSSTransition>
  </SlideTransition>
);

export default SlideTransitionGroup;
