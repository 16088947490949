import React from 'react';

const PostVentaIcon = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="hc-icon">
      <g clip-path="url(#clip0_1729_104259)">
        <path
          d="M16.5 30C16.5 27.7909 14.7091 26 12.5 26C10.2909 26 8.5 27.7909 8.5 30V34C8.5 36.2091 10.2909 38 12.5 38C14.7091 38 16.5 36.2091 16.5 34V30Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.5 30C40.5 27.7909 38.7091 26 36.5 26C34.2909 26 32.5 27.7909 32.5 30V34C32.5 36.2091 34.2909 38 36.5 38C38.7091 38 40.5 36.2091 40.5 34V30Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.5 30V24C8.5 19.7565 10.1857 15.6869 13.1863 12.6863C16.1869 9.68571 20.2565 8 24.5 8C28.7435 8 32.8131 9.68571 35.8137 12.6863C38.8143 15.6869 40.5 19.7565 40.5 24V30"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M36.5 38C36.5 39.5913 35.2357 41.1174 32.9853 42.2426C30.7348 43.3679 27.6826 44 24.5 44"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1729_104259">
          <rect width="48" height="48" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PostVentaIcon;
