import React from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import t from 'utils/translate';
import { ShoppingSvg } from './shopping-svg';
import './style.scss';

export function Header() {
  return (
    <>
      <div className="container-message">
        <ShoppingSvg />
        <div className="content-message">
          <Typography color="#166DC2" propsElement={{}} typeElement="h3" weight="bold">
            {t('step_to_become_a_seller')}
          </Typography>
          <Typography color="#000000" propsElement={{}} typeElement="body3" weight="regular">
            {t('review_the_stages_process_become')}
          </Typography>
        </div>
      </div>
    </>
  );
}
