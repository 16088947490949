import React from 'react';
import { Input, Dropdown } from '@cencosud-ds/bigbang';
import { FormSection, ToolTip } from '../../../../components';
import { Categorias, TypeSeller } from 'utils/signup';
import t from '../../../../../../../utils/translate';
import { allowAlphanumeric, allownumber } from 'utils/patterns';
import '../index.css';

export const CompanyAboutProducts: React.FC<{ formik: any; inputsFocused: boolean }> = props => {
  const { formik, inputsFocused } = props;

  const getMessageTooltip = (message: string) => {
    return (
      <>
        <span className="font-message">{t(message)}</span>
      </>
    );
  };

  return (
    <>
      <FormSection
        label={t('sign_up_about_product_information')}
        description={
          <div className="content-description">
            <div className="text-description">
              <span>{t('sign_up_about_product_description')}</span>
            </div>
            <div className="list-description">
              <ul>
                <li itemType="circle">{t('sign_up_item1_about_product_description')}</li>
                <li itemType="circle">{t('sign_up_item2_about_product_description')}</li>
                <li itemType="circle">{t('sign_up_item3_about_product_description')}</li>
              </ul>
            </div>
            <div className="text-description-space">
              <span>{t('sign_up_about_product_description2')}</span>
            </div>
          </div>
        }
      >
        <div className="row mb-8">
          <div className="col-6">
            <div className="label-rut">
              <Dropdown
                dropdown-options={TypeSeller}
                disabled={false}
                width="100%"
                label-text={t('sign_up_product_loading_mode')}
                placeholder={t('sign_up_product_loading_mode')}
                variant={formik.touched.productMode && formik.errors.productMode ? 'error' : 'default'}
                text-feedback={formik.touched.productMode && formik.errors.productMode ? formik.errors.productMode : ''}
                onBlur={value => formik.setFieldValue('categoriesSellers', value)}
                onChange={value => formik.setFieldValue('productMode', value)}
                default-value={formik.values.productMode ? formik.values.productMode : ''}
              />
              <ToolTip msg={getMessageTooltip('sign_up_tooltip_company_product_loading_mode')} position="right" />
            </div>
          </div>
          <div className="col-6">
            <div className="label-rut">
              <Input
                name="nProducts"
                feedback-text={formik.touched.nProducts && formik.errors.nProducts ? formik.errors.nProducts : ''}
                icon-end={null}
                input-state={formik.touched.nProducts && formik.errors.nProducts ? 'error' : 'default'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width="100%"
                maxLength={7}
                label-text={t('sign_up_n_products')}
                value={formik.values.nProducts ? formik.values.nProducts : ''}
                placeholder={t('sign_up_n_products')}
                input-focused={inputsFocused}
                onKeyPress={event => {
                  if (!allownumber.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allownumber.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              <ToolTip msg={getMessageTooltip('sign_up_tooltip_company_n_products')} position="left" />
            </div>
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <div className="label-rut">
              <Dropdown
                dropdown-options={Categorias}
                disabled={false}
                width="100%"
                default-value={formik.values.categoriesSellers ? formik.values.categoriesSellers : ''}
                label-text={t('sign_up_choose_one_or_more_categories')}
                placeholder={t('sign_up_choose_one_or_more_categories')}
                variant={formik.touched.categoriesSellers && formik.errors.categoriesSellers ? 'error' : 'default'}
                text-feedback={
                  formik.touched.categoriesSellers && formik.errors.categoriesSellers
                    ? formik.errors.categoriesSellers
                    : ''
                }
                onBlur={value => formik.setFieldValue('categoriesSellers', value)}
                onChange={value => formik.setFieldValue('categoriesSellers', value)}
              />
              <ToolTip msg={getMessageTooltip('sign_up_tooltip_company_one_or_more_categories')} position="right" />
            </div>
          </div>
          <div className="col-6">
            <div className="label-rut">
              <Input
                name="sellerWorkUs"
                feedback-text={
                  formik.touched.sellerWorkUs && formik.errors.sellerWorkUs ? formik.errors.sellerWorkUs : ''
                }
                icon-end={null}
                input-state={formik.touched.sellerWorkUs && formik.errors.sellerWorkUs ? 'error' : 'default'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width="100%"
                value={formik.values.sellerWorkUs ? formik.values.sellerWorkUs : ''}
                maxLength={255}
                label-text={t('sign_up_will_work_with_us')}
                placeholder={t('sign_up_will_work_with_us')}
                input-focused={inputsFocused}
                onKeyPress={event => {
                  if (!allowAlphanumeric.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              <ToolTip msg={getMessageTooltip('sign_up_tooltip_company_will_work_with_us')} position="left" />
            </div>
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6" />
          <div className="col-6" />
        </div>
      </FormSection>
    </>
  );
};
