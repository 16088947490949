import React from 'react';
import './style.css';

export const ButtonBack: React.FC<{ onClick: () => void; disabled: boolean; label: string }> = props => {
  return (
    <div className="bg-button-back ml-3" onClick={props.onClick}>
      <p className="button-text">{props.label}</p>
    </div>
  );
};
