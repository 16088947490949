import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { AppStyled } from './styles';
import Guest from '../views/layouts/guest';
import Logout from '../views/pages/logout';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import SessionProvider from '../api/session/provider';
import PrivateRoute from '../routes/PrivateRoute';
import ForgotPassword from '../views/pages/forgotPassword';
import ChangePassword from '../views/pages/changePassword';
import EventPageWrapper from './EventPageWrapper';
import SignUp from '../views/pages/signup';

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'spa',
  seed: `${new Date().getTime()}`,
});

const Root = () => {
  return (
    <SessionProvider>
      <StylesProvider generateClassName={generateClassName}>
        <Provider>
          <BrowserRouter>
            <EventPageWrapper>
              <Switch>
                <Route exact key={'logout'} component={Logout} path={'/logout'} />
                <Route exact key={'forgetPassword'} component={ForgotPassword} path={'/forgot/password'} />
                <Route exact key={'changePassword'} component={ChangePassword} path={'/change/password/:hash'} />
                <Route exact key={'guest'} component={Guest} path={'/login'} />
                <Route exact key={'signup'} component={SignUp} path={'/registrate-ahora'} />
                <PrivateRoute key={'app'} component={AppStyled} path={'/'} />
              </Switch>
            </EventPageWrapper>
          </BrowserRouter>
        </Provider>
      </StylesProvider>
    </SessionProvider>
  );
};

export default Root;
