/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

interface IProperties {
  className?: string;
}

const MetricsIcon = ({ className }: IProperties) => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5 8C23.4 8 22.5 8.9 22.5 10V40C22.5 41.1 23.4 42 24.5 42C25.6 42 26.5 41.1 26.5 40V10C26.5 8.9 25.6 8 24.5 8ZM38.5 24C37.4 24 36.5 24.9 36.5 26V40C36.5 41.1 37.4 42 38.5 42C39.6 42 40.5 41.1 40.5 40V26C40.5 24.9 39.6 24 38.5 24ZM8.5 18C8.5 16.9 9.4 16 10.5 16C11.6 16 12.5 16.9 12.5 18V40C12.5 41.1 11.6 42 10.5 42C9.4 42 8.5 41.1 8.5 40V18Z"
        fill="#020B13"
      />
    </svg>
  );
};

export default MetricsIcon;
