import React from 'react';
import Step from '../Step';
import { StepsInterface } from './interfaces';
import StepsStyled from './styles';

const Steps = (props: StepsInterface) => {
  const { orientation, data, spikes } = props;

  const getSpikesValue = (index: number) =>
    spikes === 'both' || (index !== 0 && index + 1 !== data.length) || (index === 0 && spikes === 'first')
      ? 'both'
      : index === 0
      ? 'last'
      : 'first';

  const getNextState = (index: number) => (index + 1 !== data.length ? data[index + 1].state : '');

  return (
    <StepsStyled className={`Steps--${orientation}`}>
      {data.length > 0 &&
        data.map((d, i) => (
          <Step
            key={i}
            statePosition={orientation === 'vertical' ? 'left' : 'top'}
            lines={getSpikesValue(i)}
            state={d.state}
            label={d.label}
            description={d.description}
            description2={d.description2}
            nextState={getNextState(i)}
          />
        ))}
    </StepsStyled>
  );
};

Steps.defaultProps = {
  data: [],
  direction: 'vertical',
  spikes: 'none',
};

export default Steps;
