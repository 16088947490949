import React from 'react';
import Card from './cards/card';
import './style.scss';
import t from '../../../../../../utils/translate';

export function Content() {
  const dataCards = [
    {
      icon: 0,
      step: t('step_card1'),
      title: t('title_card1'),
      description: t('description_card1'),
    },
    {
      icon: 1,
      step: t('step_card2'),
      title: t('title_card2'),
      description: t('description_card2'),
    },
    {
      icon: 2,
      step: t('step_card3'),
      title: t('title_card3'),
      description: t('description_card3'),
    },
    {
      icon: 3,
      step: t('step_card4'),
      title: t('title_card4'),
      description: t('description_card4'),
    },
  ];

  const getCards = () => {
    const cards: JSX.Element[] = [];

    dataCards.forEach(card => {
      cards.push(<Card icon={card.icon} step={card.step} title={card.title} description={card.description} />);
    });

    return cards;
  };

  return (
    <div className="content-cards">
      <div className="cards-container">{getCards()}</div>
    </div>
  );
}
