import React from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import { IconThumbsDown, IconThumbsUp } from 'components/icons';
import t from 'utils/translate';
import './style.css';

export const Ratings: React.FC<{ onClick: (e: string) => void }> = props => {
  return (
    <div className="ratings">
      <Typography color="#166DC2" weight="semiBold" typeElement="subtitle2">
        {t('learningguide_ratings_title')}
      </Typography>
      <div className="buttongroup">
        <button data-button="0" className="buttongroup__button--left" onClick={() => props.onClick('yes')}>
          <p className="buttongroup__button--text">{t('yes')}</p>
          <div className="buttongroup__button--icon">
            <IconThumbsUp />
          </div>
        </button>
        <div className="buttongroup__line" />
        <button data-button="1" className="buttongroup__button--right" onClick={() => props.onClick('no')}>
          <p className="buttongroup__button--text">{t('no')}</p>
          <div className="buttongroup__button--icon">
            <IconThumbsDown />
          </div>
        </button>
      </div>
    </div>
  );
};
