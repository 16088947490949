import * as React from 'react';

function SvgNivelThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4041 1.81184L13.9304 4.76982L13.6083 5.41286L11.7724 1.18488C11.8851 1.00804 12.0864 0.895508 12.3038 0.895508H14.8404C15.3074 0.903546 15.6134 1.39386 15.4041 1.81184Z"
        fill="#0041D6"
      />
      <path
        d="M15.4604 7.66352V6.31314C15.4604 5.07529 14.4458 4.0625 13.2057 4.0625H10.4195C9.17941 4.0625 8.16479 5.07529 8.16479 6.31314V7.66352C5.57993 8.98979 3.80838 11.6825 3.80838 14.7852C3.80838 19.198 7.39175 22.775 11.8126 22.775C16.2334 22.775 19.8168 19.198 19.8168 14.7852C19.8168 11.6745 18.0452 8.98175 15.4604 7.66352ZM14.0109 7.09282C13.3104 6.89187 12.5776 6.78738 11.8126 6.78738C11.0476 6.78738 10.3148 6.89187 9.61425 7.09282V6.31314C9.61425 5.87105 9.97661 5.50934 10.4195 5.50934H13.2057C13.5117 5.50934 13.7774 5.67814 13.9143 5.92731L13.9223 5.93535C13.9787 6.04788 14.0109 6.17649 14.0109 6.3051V7.09282Z"
        fill="url(#paint0_linear_500_9584)"
      />
      <path
        d="M11.8126 22.8955C7.32736 22.8955 3.68762 19.2543 3.68762 14.7851C3.68762 11.7468 5.35449 8.99779 8.04403 7.59114V6.3131C8.04403 5.00291 9.10697 3.94189 10.4195 3.94189H13.2057C14.5183 3.94189 15.5812 5.00291 15.5812 6.3131V7.59114C18.2707 8.99779 19.9376 11.7468 19.9376 14.7851C19.9457 19.2543 16.2979 22.8955 11.8126 22.8955ZM11.8126 6.66677C12.5132 6.66677 13.2138 6.75519 13.8902 6.93203V6.3131C13.8902 5.93532 13.5842 5.62987 13.2057 5.62987H10.4195C10.0411 5.62987 9.73506 5.93532 9.73506 6.3131V6.93203C10.4115 6.75519 11.112 6.66677 11.8126 6.66677Z"
        fill="#FF8A00"
      />
      <path
        d="M11.7241 21.9553C15.6021 21.9553 18.7459 18.8172 18.7459 14.9461C18.7459 11.0751 15.6021 7.93701 11.7241 7.93701C7.84607 7.93701 4.7023 11.0751 4.7023 14.9461C4.7023 18.8172 7.84607 21.9553 11.7241 21.9553Z"
        fill="url(#paint1_linear_500_9584)"
      />
      <path
        d="M12.1584 11.6207L12.9481 13.2219C12.989 13.3051 13.0685 13.3618 13.1587 13.3751L14.9235 13.6319C15.1535 13.6657 15.2449 13.949 15.0788 14.1106L13.8016 15.3573C13.7354 15.4224 13.7053 15.5153 13.7209 15.6057L14.0219 17.3661C14.0616 17.5952 13.8208 17.77 13.615 17.6627L12.0368 16.8319C11.9549 16.7885 11.8574 16.7885 11.7756 16.8319L10.1974 17.6627C9.99153 17.7712 9.75077 17.5964 9.79049 17.3661L10.0914 15.6057C10.1071 15.5141 10.077 15.4212 10.0108 15.3573L8.73355 14.1106C8.56742 13.9478 8.65891 13.6644 8.88884 13.6319L10.6536 13.3751C10.7451 13.3618 10.8234 13.3039 10.8643 13.2219L11.654 11.6207C11.7587 11.4109 12.0549 11.4109 12.1584 11.6207Z"
        fill="url(#paint2_linear_500_9584)"
      />
      <path
        d="M12.1088 12.4523L12.7024 13.6518C12.7331 13.7142 12.7928 13.7566 12.8607 13.7665L14.1872 13.9589C14.3601 13.9842 14.4288 14.1965 14.304 14.3175L13.3439 15.2515C13.2941 15.3002 13.2715 15.3698 13.2833 15.4375L13.5095 16.7562C13.5394 16.9278 13.3584 17.0588 13.2037 16.9784L12.0174 16.3561C11.9558 16.3236 11.8826 16.3236 11.821 16.3561L10.6347 16.9784C10.48 17.0597 10.299 16.9288 10.3289 16.7562L10.5551 15.4375C10.5669 15.3689 10.5443 15.2993 10.4945 15.2515L9.53444 14.3175C9.40956 14.1956 9.47833 13.9833 9.65116 13.9589L10.9777 13.7665C11.0465 13.7566 11.1053 13.7133 11.136 13.6518L11.7296 12.4523C11.8084 12.2952 12.0309 12.2952 12.1088 12.4523Z"
        fill="#A65A00"
      />
      <path
        d="M11.8126 21.9953C7.82662 21.9953 4.58145 18.756 4.58145 14.7772C4.58145 10.7984 7.82662 7.55908 11.8126 7.55908C15.7986 7.55908 19.0438 10.7984 19.0438 14.7772C19.0438 18.756 15.7986 21.9953 11.8126 21.9953ZM11.8126 8.12174C8.14066 8.12174 5.14513 11.1038 5.14513 14.7772C5.14513 18.4506 8.13261 21.4327 11.8126 21.4327C15.4846 21.4327 18.4801 18.4506 18.4801 14.7772C18.4801 11.1038 15.4926 8.12174 11.8126 8.12174Z"
        fill="white"
      />
      <path
        d="M12.7387 6.29726H10.3632C10.1216 6.29726 9.90417 6.16062 9.79949 5.95163L9.59012 5.52562L7.77831 1.90048C7.56894 1.48251 7.87494 0.992188 8.34198 0.992188H10.8785C11.1282 0.992188 11.3536 1.13687 11.4503 1.36194L13.3104 5.42112C13.3265 5.46131 13.3426 5.5015 13.3507 5.54169C13.4473 5.91144 13.1493 6.29726 12.7387 6.29726Z"
        fill="#166DC2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9584"
          x1="3.80581"
          y1="13.4167"
          x2="19.8207"
          y2="13.4167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#558895" />
          <stop offset="0.2368" stop-color="#588A97" />
          <stop offset="0.392" stop-color="#60909C" />
          <stop offset="0.5238" stop-color="#6F9BA5" />
          <stop offset="0.6425" stop-color="#85A9B3" />
          <stop offset="0.7524" stop-color="#A0BCC4" />
          <stop offset="0.8559" stop-color="#C2D4D9" />
          <stop offset="0.9525" stop-color="#E9EFF1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_9584"
          x1="11.7241"
          y1="7.93701"
          x2="11.7241"
          y2="21.9553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDC431" />
          <stop offset="1" stop-color="#FDC431" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_500_9584"
          x1="9.60162"
          y1="18.129"
          x2="15.4519"
          y2="10.4817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC700" />
          <stop offset="1" stop-color="#E54717" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNivelThree;
