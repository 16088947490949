import decodeJwt from '../utils/decodeJwt';
import { SELLER } from 'variables/roles';
import { fetchIsInPublishHistory, getSellerConfiguration } from 'api/sellers';
import { INewReputationSeller } from 'api/session/interfaces';
import { getIsNewReputacion } from 'api/reputation';
import { ISellerConfiguration } from 'api/sellers/interfaces';
import { stateConfigSeller } from 'utils/patterns';
import getCheckExpiration from '../utils/getCheckExpiration';

const setAuthFromCheckExpiration = async (jwt: string) => {
  const firstTimeTour = localStorage.getItem('firstTimeTour') || '0';
  const decodedJwt = decodeJwt(jwt);
  if (await getCheckExpiration(jwt)) {
    let responseIsInPublishHistory = null;
    let sellerConfiguration: ISellerConfiguration = {
      topsortLandingStatus: 0,
      tourGuideStatus: 0,
      updateMailingData: 0,
    };
    let newReputationSeller: INewReputationSeller = {
      reputationCategory: 'Bloqueo',
      isNewReputation: false,
      isFirstLogin: false,
    };

    if (decodedJwt.role === SELLER) {
      responseIsInPublishHistory = await fetchIsInPublishHistory(jwt, decodedJwt.sellerId);
      newReputationSeller = await getIsNewReputacion(jwt, decodedJwt.sellerId);
      const dataSellerConfiguration = await getSellerConfiguration(jwt, decodedJwt.sellerId);
      sellerConfiguration = dataSellerConfiguration;
    }

    return {
      ...decodedJwt,
      isAuthenticated: true,
      loginError: '',
      isChecking: false,
      sellerIsInPublishHistory: responseIsInPublishHistory,
      statusCompleteTourGuide: stateConfigSeller[firstTimeTour],
      sellerConfiguration,
      newReputationSeller,
    };
  } else {
    return decodedJwt;
  }
};

export default setAuthFromCheckExpiration;
