import { MicrofrontendInterface } from '../interfaces/microfrontend.interface';
import { ManifestCollection } from '../interfaces/manifest.interface';

const manifests: ManifestCollection = {};

export function getManifest(microfrontend: MicrofrontendInterface) {
  return manifests[microfrontend.name];
}

export function setManifest(microfrontend: MicrofrontendInterface, manifest: any) {
  manifests[microfrontend.name] = manifest;
}
