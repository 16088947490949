export const Categorias = [
  { value: 'Femeninos', label: 'Femeninos', children: 'Femeninos' },
  { value: 'Alfombras', label: 'Alfombras', children: 'Alfombras' },
  { value: 'Bicicletas', label: 'Bicicletas', children: 'Bicicletas' },
  { value: 'Blanco', label: 'Blanco', children: 'Blanco' },
  { value: 'Calzado Escolar', label: 'Calzado Escolar', children: 'Calzado Escolar' },
  { value: 'Camping', label: 'Camping', children: 'Camping' },
  { value: 'Carteras', label: 'Carteras', children: 'Carteras' },
  { value: 'Casual', label: 'Casual', children: 'Casual' },
  { value: 'Cocina', label: 'Cocina', children: 'Cocina' },
  { value: 'Colchones', label: 'Colchones', children: 'Colchones' },
  { value: 'Complementos Bebe', label: 'Complementos Bebe', children: 'Complementos Bebe' },
  { value: 'Computacion', label: 'Computacion', children: 'Computacion' },
  { value: 'Comunicaciones', label: 'Comunicaciones', children: 'Comunicaciones' },
  { value: 'Contemporaneo', label: 'Contemporaneo', children: 'Contemporaneo' },
  { value: 'Corseteria', label: 'Corseteria', children: 'Corseteria' },
  { value: 'Cortinas', label: 'Cortinas', children: 'Cortinas' },
  { value: 'Decoracion', label: 'Decoracion', children: 'Decoracion' },
  { value: 'Electrodomesticos', label: 'Electrodomesticos', children: 'Electrodomesticos' },
  { value: 'Electronica Mayor', label: 'Electronica Mayor', children: 'Electronica Mayor' },
  { value: 'Electronica Menor', label: 'Electronica Menor', children: 'Electronica Menor' },
  { value: 'Equipamiento Deportivo', label: 'Equipamiento Deportivo', children: 'Equipamiento Deportivo' },
  { value: 'Escolar', label: 'Escolar', children: 'Escolar' },
  { value: 'Formal Hombres', label: 'Formal Hombres', children: 'Formal Hombres' },
  { value: 'Fotografia', label: 'Fotografia', children: 'Fotografia' },
  { value: 'Gourmet', label: 'Gourmet', children: 'Gourmet' },
  { value: 'Instrumentos Musicales', label: 'Instrumentos Musicales', children: 'Instrumentos Musicales' },
  { value: 'Interior Dormir Infantil', label: 'Interior Dormir Infantil', children: 'Interior Dormir Infantil' },
  { value: 'Jeans Hombres', label: 'Jeans Hombres', children: 'Jeans Hombres' },
  { value: 'Jeans Mujeres', label: 'Jeans Mujeres', children: 'Jeans Mujeres' },
  { value: 'Jugueteria', label: 'Jugueteria', children: 'Jugueteria' },
  { value: 'Junior Hombres', label: 'Junior Hombres', children: 'Junior Hombres' },
  { value: 'Junior Mujeres', label: 'Junior Mujeres', children: 'Junior Mujeres' },
  { value: 'Juvenil Hombres', label: 'Juvenil Hombres', children: 'Juvenil Hombres' },
  { value: 'Juvenil Mujeres', label: 'Juvenil Mujeres', children: 'Juvenil Mujeres' },
  { value: 'Lenceria', label: 'Lenceria', children: 'Lenceria' },
  { value: 'Libros y Mascotas', label: 'Libros y Mascotas', children: 'Libros y Mascotas' },
  { value: 'Linea Blanca', label: 'Linea Blanca', children: 'Linea Blanca' },
  { value: 'Maleteria', label: 'Maleteria', children: 'Maleteria' },
  { value: 'Medias Calcetin', label: 'Medias Calcetin', children: 'Medias Calcetin' },
  { value: 'Menaje Fino', label: 'Menaje Fino', children: 'Menaje Fino' },
  { value: 'Mesa', label: 'Mesa', children: 'Mesa' },
  { value: 'Muebles', label: 'Muebles', children: 'Muebles' },
  { value: 'Navidad', label: 'Navidad', children: 'Navidad' },
  { value: 'Multicategoría', label: 'Multicategoría', children: 'Multicategoría' },
  { value: 'Ninas', label: 'Ninas', children: 'Ninas' },
  { value: 'Ninos', label: 'Ninos', children: 'Ninos' },
  { value: 'Perfumeria Selectiva', label: 'Perfumeria Selectiva', children: 'Perfumeria Selectiva' },
  { value: 'Perfumeria Semiselectiva', label: 'Perfumeria Semiselectiva', children: 'Perfumeria Semiselectiva' },
  { value: 'Perifericos PC', label: 'Perifericos PC', children: 'Perifericos PC' },
  { value: 'Recien Nacidos', label: 'Recien Nacidos', children: 'Recien Nacidos' },
  { value: 'Relojeria y Anteojos', label: 'Relojeria y Anteojos', children: 'Relojeria y Anteojos' },
  { value: 'Ropa Interior y Acc Hombre', label: 'Ropa Interior y Acc Hombre', children: 'Ropa Interior y Acc Hombre' },
  { value: 'Sport Hombres', label: 'Sport Hombres', children: 'Sport Hombres' },
  { value: 'Terraza', label: 'Terraza', children: 'Terraza' },
  { value: 'Trajes de Bano', label: 'Trajes de Bano', children: 'Trajes de Bano' },
  { value: 'Vehiculos Motorizados', label: 'Vehiculos Motorizados', children: 'Vehiculos Motorizados' },
  { value: 'Vestir Forma', label: 'Vestir Forma', children: 'Vestir Forma' },
  { value: 'Vestuario Deportivo', label: 'Vestuario Deportivo', children: 'Vestuario Deportivo' },
  { value: 'Video Juegos', label: 'Video Juegos', children: 'Video Juegos' },
  { value: 'Zapatillas Deportivas', label: 'Zapatillas Deportivas', children: 'Zapatillas Deportivas' },
  { value: 'Zapatos Hombre', label: 'Zapatos Hombre', children: 'Zapatos Hombre' },
  { value: 'Zapatos Mujer', label: 'Zapatos Mujer', children: 'Zapatos Mujer' },
  { value: 'Zapatos Ninos', label: 'Zapatos Ninos', children: 'Zapatos Ninos' },
  { value: 'Otro', label: 'Otro', children: 'Otro' },
].sort((a, b) => a.value.localeCompare(b.value));
