import * as Yup from 'yup';
import t from '../../../../../utils/translate';
import { validate } from 'rut.js';
import { PERSON_NATURAL } from 'variables/society';

export default (rut: string) =>
  Yup.object({
    societyTypePartherOne: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    rutNumberPartherOne: Yup.string()
      .required(t('sign_up_legal_required_field'))
      .test('rutFormat', 'sign_up_legal_invalid_rut_format', function test(value) {
        const { path, createError } = this;
        if (value) {
          return validate(value) || createError({ path, message: t('sign_up_legal_rut_is_invalid') });
        }
        return true;
      })
      .required(t('sign_up_legal_required_field'))
      .test('rut no puede ser el mismo del paso 1', t('sign_up_legal_rut_is_repeated'), function test(value) {
        if (value === rut) {
          return false;
        }
        return true;
      }),
    namePartherOne: Yup.string().when('societyTypePartherOne', societyTypePartherOne => {
      if (![PERSON_NATURAL].includes(societyTypePartherOne)) {
        return Yup.string()
          .trim()
          .required(t('sign_up_legal_required_field'));
      }
    }),
    firstPartherOne: Yup.string().when('societyTypePartherOne', societyTypePartherOne => {
      if ([PERSON_NATURAL].includes(societyTypePartherOne)) {
        return Yup.string()
          .trim()
          .required(t('sign_up_legal_required_field'));
      }
    }),
    secondNamePartherOne: Yup.string().when('societyTypePartherOne', societyTypePartherOne => {
      if ([PERSON_NATURAL].includes(societyTypePartherOne)) {
        return Yup.string()
          .trim()
          .required(t('sign_up_legal_required_field'));
      }
    }),
    nationalityPartherOne: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    addressPartherOne: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    regionPartherOne: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    communePartherOne: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    showPartherTwo: Yup.boolean(),
    societyTypePartherTwo: Yup.string()
      .trim()
      .when('showPartherTwo', showPartherTwo => {
        if (showPartherTwo) {
          return Yup.string().required(t('sign_up_legal_required_field'));
        }
      }),
    rutNumberPartherTwo: Yup.string()
      .trim()
      .when('showPartherTwo', showPartherTwo => {
        if (showPartherTwo) {
          return Yup.string()
            .required(t('sign_up_legal_required_field'))
            .test('rutFormat', 'sign_up_legal_invalid_rut_format', function test(value) {
              const { path, createError } = this;
              if (value) {
                return validate(value) || createError({ path, message: t('sign_up_legal_rut_is_invalid') });
              }
              return true;
            })
            .test('rut no puede ser el mismo del paso 1', t('sign_up_legal_rut_is_repeated'), function test(value) {
              if (value === rut) {
                return false;
              }
              return true;
            });
        }
      }),
    namePartherTwo: Yup.string()
      .trim()
      .when(['societyTypePartherTwo', 'showPartherTwo'], (societyTypePartherTwo, showPartherTwo) => {
        if (![PERSON_NATURAL].includes(societyTypePartherTwo) && showPartherTwo) {
          return Yup.string().required(t('sign_up_legal_required_field'));
        }
      }),
    firstPartherTwo: Yup.string().when(
      ['societyTypePartherTwo', 'showPartherTwo'],
      (societyTypePartherTwo, showPartherTwo) => {
        if ([PERSON_NATURAL].includes(societyTypePartherTwo) && showPartherTwo) {
          return Yup.string()
            .trim()
            .required(t('sign_up_legal_required_field'));
        }
      },
    ),
    secondNamePartherTwo: Yup.string().when(
      ['societyTypePartherTwo', 'showPartherTwo'],
      (societyTypePartherTwo, showPartherTwo) => {
        if ([PERSON_NATURAL].includes(societyTypePartherTwo) && showPartherTwo) {
          return Yup.string()
            .trim()
            .required(t('sign_up_legal_required_field'));
        }
      },
    ),
    nationalityPartherTwo: Yup.string()
      .trim()
      .when('showPartherTwo', showPartherTwo => {
        if (showPartherTwo) {
          return Yup.string().required(t('sign_up_legal_required_field'));
        }
      }),
    addressPartherTwo: Yup.string()
      .trim()
      .when('showPartherTwo', showPartherTwo => {
        if (showPartherTwo) {
          return Yup.string().required(t('sign_up_legal_required_field'));
        }
      }),
    regionPartherTwo: Yup.string()
      .trim()
      .when('showPartherTwo', showPartherTwo => {
        if (showPartherTwo) {
          return Yup.string().required(t('sign_up_legal_required_field'));
        }
      }),
    communePartherTwo: Yup.string()
      .trim()
      .when('showPartherTwo', showPartherTwo => {
        if (showPartherTwo) {
          return Yup.string().required(t('sign_up_legal_required_field'));
        }
      }),
    societyTypeRepresentative: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    rutNumberRepresentative: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field'))
      .test('rutFormat', 'sign_up_legal_invalid_rut_format', function test(value) {
        const { path, createError } = this;
        if (value) {
          return validate(value) || createError({ path, message: t('sign_up_legal_rut_is_invalid') });
        }
        return true;
      })
      .test('rut no puede ser el mismo del paso 1', t('sign_up_legal_rut_is_repeated'), function test(value) {
        if (value === rut) {
          return false;
        }
        return true;
      }),
    nameRepresentative: Yup.string().when('societyTypeRepresentative', societyTypeRepresentative => {
      if (![PERSON_NATURAL].includes(societyTypeRepresentative)) {
        return Yup.string()
          .trim()
          .required(t('sign_up_legal_required_field'));
      }
    }),
    firstPartherRepresentative: Yup.string().when('societyTypeRepresentative', societyTypeRepresentative => {
      if ([PERSON_NATURAL].includes(societyTypeRepresentative)) {
        return Yup.string()
          .trim()
          .required(t('sign_up_legal_required_field'));
      }
    }),
    secondNamePartherRepresentative: Yup.string().when('societyTypeRepresentative', societyTypeRepresentative => {
      if ([PERSON_NATURAL].includes(societyTypeRepresentative)) {
        return Yup.string()
          .trim()
          .required(t('sign_up_legal_required_field'));
      }
    }),
    nationalityRepresentative: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    addressRepresentative: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    regionRepresentative: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
    communeRepresentative: Yup.string()
      .trim()
      .required(t('sign_up_legal_required_field')),
  });
