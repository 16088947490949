export const TypeSeller = [
  { value: 'multivende', label: 'multivende', children: 'multivende' },
  { value: 'centry', label: 'centry', children: 'centry' },
  { value: 'pim', label: 'carga directa', children: 'carga directa' },
  { value: 'relbase', label: 'relbase', children: 'relbase' },
  { value: 'starkoms', label: 'starkoms', children: 'starkoms' },
  { value: 'yuju', label: 'yuju', children: 'yuju' },
  { value: 'producteca', label: 'producteca', children: 'producteca' },
  { value: 'ecomexperts', label: 'ecomexperts', children: 'ecomexperts' },
  { value: 'ecomsur', label: 'ecomsur', children: 'ecomsur' },
  { value: 'astroselling', label: 'astroselling', children: 'astroselling' },
  { value: 'anymarket', label: 'anymarket', children: 'anymarket' },
  { value: 'pivot', label: 'pivot', children: 'pivot' },
  { value: 'ventiapp', label: 'Ventiapp', children: 'ventiapp' },
  { value: 'defontana', label: 'Defontana', children: 'defontana' },
].sort((a, b) => a.value.localeCompare(b.value));
