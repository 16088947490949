/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

interface IProperties {
  className?: string;
}

const HelpCenterIcon = ({ className }: IProperties) => {
  return (
    <svg viewBox="0 0 50 50" width="48" height="48" xmlns="http://www.w3.org/2000/svg" {...{ className }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1661 15.1657C18.5221 15.1657 17.9995 14.643 17.9995 13.999C17.9995 13.355 18.5221 12.8324 19.1661 12.8324C19.8101 12.8324 20.3328 13.355 20.3328 13.999C20.3328 14.643 19.8101 15.1657 19.1661 15.1657M14.4995 15.1657C13.8555 15.1657 13.3328 14.643 13.3328 13.999C13.3328 13.355 13.8555 12.8324 14.4995 12.8324C15.1435 12.8324 15.6661 13.355 15.6661 13.999C15.6661 14.643 15.1435 15.1657 14.4995 15.1657M9.83279 15.1657C9.18879 15.1657 8.66613 14.643 8.66613 13.999C8.66613 13.355 9.18879 12.8324 9.83279 12.8324C10.4768 12.8324 10.9995 13.355 10.9995 13.999C10.9995 14.643 10.4768 15.1657 9.83279 15.1657M22.749 5.74953C20.0843 3.08487 16.3965 1.89603 12.6293 2.4782C7.87279 3.2202 3.92946 7.05153 3.03929 11.7964C2.61113 14.0807 2.85729 16.407 3.75213 18.5222C3.86646 18.7905 3.90146 19.0425 3.85596 19.2677L2.85613 24.2704C2.77913 24.653 2.89929 25.0485 3.17463 25.3239C3.39513 25.5444 3.69263 25.6657 3.99946 25.6657C4.07529 25.6657 4.15229 25.6587 4.22813 25.6424L9.22496 24.6437C9.51196 24.5889 9.79079 24.6694 9.97629 24.7464C12.0926 25.6412 14.419 25.8862 16.7021 25.4592C21.447 24.569 25.2783 20.6257 26.0203 15.8692C26.6071 12.1032 25.4148 8.41537 22.749 5.74953"
      />
    </svg>
  );
};

export default HelpCenterIcon;
