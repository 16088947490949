import React from 'react';
import SessionContext from 'api/session/context';
import Satelite from './components/satelite';
import Drawer from './components/drawer';

export default function Reputation() {
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    auth: { jwt, sellerId, sellerName },
  } = React.useContext(SessionContext);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Satelite sellerName={sellerName} onClick={toggleDrawer} />
      <Drawer
        isLoader={false}
        isLoading={false}
        isOpen={isOpen}
        onClose={toggleDrawer}
        sellerId={sellerId}
        jwt={jwt}
        onOk={toggleDrawer}
      />
    </>
  );
}
