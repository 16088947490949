import React from 'react';

const ModalIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M41.9941 21.9482H42.0001C43.1021 21.9482 43.9981 22.8402 44.0001 23.9422C44.0161 29.2842 41.9501 34.3142 38.1821 38.1022C34.4161 41.8902 29.4001 43.9842 24.0581 44.0002H24.0001C18.6781 44.0002 13.6721 41.9362 9.89809 38.1822C6.11009 34.4162 4.01609 29.4002 4.00009 24.0582C3.98409 18.7142 6.05009 13.6862 9.81809 9.8982C13.5841 6.1102 18.6001 4.0162 23.9421 4.0002C25.5321 4.0242 27.1521 4.1842 28.7041 4.5562C29.7761 4.8162 30.4381 5.8962 30.1781 6.9702C29.9201 8.0422 28.8341 8.7022 27.7661 8.4462C26.5241 8.1462 25.2061 8.0202 23.9541 8.0002C19.6801 8.0122 15.6661 9.6882 12.6541 12.7182C9.64009 15.7482 7.98809 19.7722 8.00009 24.0462C8.01209 28.3202 9.68809 32.3322 12.7181 35.3462C15.7381 38.3482 19.7421 40.0002 24.0001 40.0002H24.0461C28.3201 39.9882 32.3341 38.3122 35.3461 35.2822C38.3601 32.2502 40.0121 28.2282 40.0001 23.9542C39.9981 22.8502 40.8901 21.9502 41.9941 21.9482ZM16.5861 22.586C17.3681 21.804 18.6321 21.804 19.4141 22.586L23.9021 27.074L36.4961 12.682C37.2241 11.856 38.4861 11.768 39.3181 12.496C40.1481 13.222 40.2321 14.486 39.5041 15.318L25.5041 31.318C25.1401 31.734 24.6201 31.98 24.0661 32H24.0001C23.4701 32 22.9621 31.79 22.5861 31.414L16.5861 25.414C15.8041 24.632 15.8041 23.368 16.5861 22.586Z"
        fill="#166DC2"
      />
    </svg>
  );
};

export default ModalIcon;
