const base = 4;
const transformToPx = (multiplier: number) => (base * multiplier).toString() + 'px';
const baseSpace = {
  'space-1': transformToPx(1),
  'space-2': transformToPx(2),
  'space-3': transformToPx(4),
  'space-4': transformToPx(8),
  'space-5': transformToPx(10),
  'space-6': transformToPx(12),
  'space-7': transformToPx(14),
  'space-8': transformToPx(16),
  'space-9': transformToPx(18),
  'space-10': transformToPx(20),
  'space-11': transformToPx(22),
  'space-12': transformToPx(24),
};

export default baseSpace;
