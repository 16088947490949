import React, { useState } from 'react';
import './index.css';
import { Icon, ToggleButton, Tooltip } from '@cencosud-ds/bigbang';
import { TextTooltip, TextTooltipLink, TooltipPopover } from './styles';
import t from '../../../../src/utils/translate';
import TriangleSvg from './triangle';
import { ITooltipProps } from './intefaces/popover';

const TooltipReputation: React.FC<ITooltipProps> = props => {
  const { text, isLastItem, link } = props;
  const [open, setOpen] = useState(false);
  return (
    <div>
      {isLastItem ? (
        <Tooltip
          TooltipComponent={
            <div style={{ marginTop: '-124px' }}>
              <TooltipPopover style={{ marginLeft: '-95px', width: '254px', height: '104px' }}>
                <TextTooltip>{text}</TextTooltip>
              </TooltipPopover>
              <TriangleSvg
                className="triangle-position-left"
                {...{
                  border: '#166dc2',
                  background: '#166dc2',
                }}
              />
            </div>
          }
          isOpen={open}
          with-triangle={true}
          position="right"
          version="v2"
        >
          <ToggleButton onClick={() => setOpen(!open)} icon={<Icon name="QuestionMarkCircle" />} size="s" />
        </Tooltip>
      ) : (
        <>
          {link ? (
            <Tooltip
              TooltipComponent={
                <div style={{ marginTop: '-81px', marginLeft: '-125px' }}>
                  <TooltipPopover
                    style={{
                      width: '310px',
                      height: '88px',
                    }}
                  >
                    <TextTooltip>
                      {text}
                      <TextTooltipLink href="/invoice" target="_blank" rel="noreferrer">
                        {t('reputation_components_tooltip_claims2')}
                      </TextTooltipLink>
                      {t('reputation_components_tooltip_claims3')}
                    </TextTooltip>
                  </TooltipPopover>
                  <TriangleSvg
                    className="triangle-position"
                    {...{
                      border: '#166dc2',
                      background: '#166dc2',
                    }}
                  />
                </div>
              }
              isOpen={open}
              position="top"
              version="v2"
            >
              <ToggleButton onClick={() => setOpen(!open)} icon={<Icon name="QuestionMarkCircle" />} size="s" />
            </Tooltip>
          ) : (
            <Tooltip
              TooltipComponent={
                <div style={{ marginTop: '-65px', marginLeft: '-125px' }}>
                  <TooltipPopover
                    style={{
                      width: '273px',
                      height: '72px',
                    }}
                  >
                    <TextTooltip>{text}</TextTooltip>
                  </TooltipPopover>
                  <TriangleSvg
                    className="triangle-position"
                    {...{
                      border: '#166dc2',
                      background: '#166dc2',
                    }}
                  />
                </div>
              }
              isOpen={open}
              position="top"
              version="v2"
            >
              <ToggleButton onClick={() => setOpen(!open)} icon={<Icon name="QuestionMarkCircle" />} size="s" />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default TooltipReputation;
