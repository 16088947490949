import React, { useState, useEffect } from 'react';
import { Input, Dropdown, Typography, colors } from '@cencosud-ds/bigbang';
import { BottomBar, FormSection } from '../../components';
import { IWizardFormProps } from '../../interfaces/wizard.interface';
import { useWizardFormControl } from '../../context';
import { Banks } from '../../../../../utils/signup';
import validationSchema from './bank.schema';
import { useFormik } from 'formik';
import { BankAccounts } from 'utils/signup';
import { allownumber } from 'utils/patterns';
import t from '../../../../../utils/translate';
import { trackGTMPageViewCuston } from 'utils/analytics';

const BankStep: React.FC<IWizardFormProps> = props => {
  const { position } = props;
  const [inputsFocused, setInputFocused] = useState(false);
  const formWizard = useWizardFormControl(position);
  const formik = useFormik({
    initialValues: {
      bankType: formWizard ? formWizard.data.bankType : '',
      bankChooseBank: formWizard ? formWizard.data.bankChooseBank : '',
      accountNumber: formWizard ? formWizard.data.accountNumber : '',
    },
    onSubmit: values => {
      console.log(values);
    },
    validationSchema,
  });

  const handleOnBack = () => {
    formWizard.data = formik.values;
  };

  const handleOnNext = async (): Promise<boolean> => {
    setInputFocused(false);
    await formik.submitForm();
    const isFormValid = formik.isValid && Object.keys(formik.touched).length > 0;

    if (isFormValid) {
      formWizard.data = formik.values;
      return true;
    }
    return false;
  };

  useEffect(() => {
    trackGTMPageViewCuston({
      page: '/registrate-ahora',
      title: 'Información bancaria',
    });
  }, []);

  return (
    <>
      <div className="wizard-form-section pb-3">
        <div className="mb-5">
          <Typography
            typeElement="subtitle1"
            weight="semiBold"
            color={colors.primary[500]}
            children={t('sign_up_bank_where_do_you_want_to_receive_your_payments')}
          />
        </div>
        <div className="mb-4">
          <FormSection
            label={t('bank_bank_data')}
            description={
              <div className="content-description">
                <div className="text-description">
                  <span>{t('bank_bank_data_description')}</span>
                </div>
                <br />
                <div className="text-description">
                  <span>{t('bank_bank_data_description2')}</span>
                </div>
                <div className="list-description">
                  <ul>
                    <li itemType="circle">{t('sign_up_item1_bank_bank_data')}</li>
                    <li itemType="circle"> {t('sign_up_item2_bank_bank_data')}</li>
                    <li itemType="circle">{t('sign_up_item3_bank_bank_data')}</li>
                  </ul>
                </div>
              </div>
            }
          >
            <div className="row mb-8">
              <div className="col-6">
                <Dropdown
                  dropdown-options={Banks}
                  variant={formik.touched.bankChooseBank && formik.errors.bankChooseBank ? 'error' : 'default'}
                  disabled={false}
                  returnObjectSelected={false}
                  width="100%"
                  onBlur={formik.handleBlur}
                  label-text={t('bank_choose_bank')}
                  placeholder={t('bank_choose_bank')}
                  default-value={formik.values.bankChooseBank ? formik.values.bankChooseBank : ''}
                  onChange={value => formik.setFieldValue('bankChooseBank', value)}
                  text-feedback={formik.touched.bankChooseBank ? formik.errors.bankChooseBank : ''}
                />
              </div>
              <div className="col-6">
                <Dropdown
                  text-feedback={formik.touched.bankType && formik.errors.bankType ? formik.errors.bankType : ''}
                  onChange={value => formik.setFieldValue('bankType', value)}
                  width="100%"
                  label-text={t('bank_current_account_type')}
                  placeholder={t('bank_current_account_type')}
                  dropdown-options={BankAccounts}
                  variant={formik.touched.bankType && formik.errors.bankType ? 'error' : 'default'}
                  disabled={false}
                  default-value={formik.values.bankType !== '' ? formik.values.bankType : ''}
                />
              </div>
            </div>
            <div className="row mb-8 rows-margin-top">
              <div className="col-12">
                <Input
                  name="accountNumber"
                  feedback-text={
                    formik.touched.accountNumber && formik.errors.accountNumber ? formik.errors.accountNumber : ''
                  }
                  icon-end={null}
                  input-state={formik.touched.accountNumber && formik.errors.accountNumber ? 'error' : 'default'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  width="100%"
                  className="input-account-number"
                  label-text={`${t('bank_account_number')}`}
                  placeholder={`${t('bank_account_number')}`}
                  value={formik.values.accountNumber ? formik.values.accountNumber : ''}
                  input-focused={inputsFocused}
                  maxLength={20}
                  onKeyPress={e => {
                    if (!allownumber.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onPaste={e => {
                    if (!allownumber.test(e.clipboardData.getData('Text'))) {
                      e.preventDefault();
                    }
                  }}
                  onCopy={e => {
                    if (!allownumber.test(e.clipboardData.getData('Text'))) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
          </FormSection>
        </div>
      </div>
      <BottomBar onBack={() => handleOnBack()} onNext={() => handleOnNext()} />
    </>
  );
};

export default BankStep;
