import styled from '@emotion/styled';

export const Container = styled.div`
  min-height: 85vh;
  width: 900px;
  padding: 2vh;
  span {
    font-weight: bold;
  }
  .buttons {
    margin-top: 2vh;
    display: flex;
    justify-content: flex-end;
  }
  .button-accept {
    width: 263.5px;
    height: 48px;
  }
  .button-exit {
    margin-right: 10px;
    width: 263.5px;
    height: 48px;
  }
  .content {
    overflow-y: auto;
    min-height: 60vh;
    border: solid 1px #d6d6d6;
    margin: 10px 40px 20px;
    padding: 20px;
  }
  .content-pdf {
    overflow-y: auto;
    min-height: 60vh;
    border: solid 1px #d6d6d6;
  }
  .description-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1%;
  }
  .description-complement {
    width: 584px;
    text-align: center;
  }
  .notification-button-close {
    visibility: hidden;
  }
  .notification {
    width: 100%;
    margin: 1% 0 1% 0;
  }
  .notification-disabled {
    visibility: hidden;
    padding: 12px 0px;
    height: 52px;
  }
`;
