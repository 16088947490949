import jwt_decode from 'jwt-decode';

const decodeJwt = jwt => {
  const {
    email,
    first_name: firstName,
    last_name: lastName,
    seller_id: sellerId,
    seller_name: sellerName,
    role,
    permissions,
    id: userId,
    facility_id: facilityId,
    seller_type: sellerType,
    api_key: apiKey,
    is_collector: isCollector,
    sellerSapClient,
    sellerSapProvider,
    sellerIsPublished,
  } = jwt_decode(jwt);
  return {
    jwt,
    email,
    firstName,
    lastName,
    sellerId,
    role,
    permissions,
    userId,
    facilityId,
    sellerName,
    sellerType,
    apiKey,
    isCollector,
    sellerSapClient,
    sellerSapProvider,
    sellerIsPublished,
    sellerIsInPublishHistory: true,
    statusCompleteTourGuide: false,
    sellerConfiguration: {
      tourGuideStatus: 1,
      updateMailingData: 0,
      topsortLandingStatus: 0,
    },
    newReputationSeller: {
      reputationCategory: 'Bloqueo',
      isNewReputation: false,
      isFirstLogin: false,
    },
  };
};

export default decodeJwt;
