import React, { useRef, useState, useEffect } from 'react';
import { WrapperTooltip, WrapperChildren } from './styles';
import { TooltipInterface, position as PositionType } from './interfaces';
import ReactDOM from 'react-dom';

export default function Tooltip(props: TooltipInterface) {
  const { children, position, isOpen, TooltipComponent } = props;

  const wrapperTooltipTemplate = useRef<any>(null);
  const wrapperChildren = useRef<any>(null);

  const [heightChildren, setHeightChildren] = useState<number>(0);
  const [widthChildren, setWidthChildren] = useState<number>(0);
  const [xChildren, setXChildren] = useState<number>(0);
  const [yChildren, setYChildren] = useState<number>(0);

  const [heightTooltip, setHightTooltipTemplate] = useState<number>(0);
  const [widthTooltip, setWidthTooltipTemplate] = useState<number>(0);

  const [showTooltip, setShowTooltip] = useState<boolean>(true);
  const [visibilityTooltip, setVisibility] = useState<boolean>(false);

  const [positionCurrent, setPositionCurrent] = useState<PositionType>(position);

  const top = (coordinated: DOMRect, height: number, width: number) => {
    return {
      top: coordinated.top - height - 12,
      left: coordinated.left - (width / 2 - coordinated.width / 2),
    };
  };

  const right = (coordinated: DOMRect, height: number) => {
    return {
      top: coordinated.top - (height / 2 - coordinated.height / 2),
      left: coordinated.left + coordinated.width + 12,
    };
  };

  const bottom = (coordinated: DOMRect, width: number) => {
    return {
      top: coordinated.top + coordinated.height + 12,
      left: coordinated.left - (width / 2 - coordinated.width / 2),
    };
  };

  const left = (coordinated: DOMRect, height: number, width: number) => {
    return {
      top: coordinated.top - (height / 2 - coordinated.height / 2),
      left: coordinated.left - width - 12,
    };
  };

  const directionsArrow = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  };

  const setPosition = () => {
    let coordinatedWrap = wrapperChildren.current.getBoundingClientRect();
    let direction = position;
    const height = coordinatedWrap.height;
    const width = coordinatedWrap.width;
    const widthWindow = window.innerWidth;
    const heightWindow = window.innerHeight;
    const coordinatedInitial = JSON.parse(JSON.stringify(coordinatedWrap));

    coordinatedWrap = top(coordinatedInitial, height, width);
    if (coordinatedWrap.top < 0) {
      direction = 'bottom';
    }
    coordinatedWrap = bottom(coordinatedInitial, width);
    if (coordinatedWrap.top + height > heightWindow) {
      direction = 'top';
    }
    coordinatedWrap = right(coordinatedInitial, height);
    if (coordinatedWrap.left + width > widthWindow) {
      direction = 'left';
    }
    coordinatedWrap = left(coordinatedInitial, height, width);
    if (coordinatedWrap.left < 0) {
      direction = 'right';
    }
    setPositionCurrent(direction);
  };

  const mouseOverHandler = () => {
    if (typeof isOpen === 'undefined') {
      const coordinated = wrapperChildren.current.getBoundingClientRect();
      setVisibility(true);
      setXChildren(coordinated.x);
      setYChildren(coordinated.y);
      setPosition();
    }
  };

  const mouseOutHandler = () => {
    if (typeof isOpen === 'undefined') {
      setVisibility(false);
    }
  };

  useEffect(() => {
    setHightTooltipTemplate(wrapperTooltipTemplate.current.children[0].offsetHeight);
    setWidthTooltipTemplate(wrapperTooltipTemplate.current.children[0].offsetWidth);
    setShowTooltip(!false);
    setTimeout(() => {
      setShowTooltip(true);
      if (typeof isOpen === 'boolean') {
        setVisibility(isOpen);
      }
    });
  }, [wrapperTooltipTemplate]);

  useEffect(() => {
    const coordinated = wrapperChildren.current.getBoundingClientRect();
    setHeightChildren(wrapperChildren.current.offsetHeight);
    setWidthChildren(wrapperChildren.current.offsetWidth);
    setXChildren(coordinated.x);
    setYChildren(coordinated.y);
  }, [wrapperChildren]);

  useEffect(() => {
    const coordinated = wrapperChildren.current.getBoundingClientRect();
    if (typeof isOpen === 'boolean') {
      setVisibility(isOpen);
    }
    setXChildren(coordinated.x);
    setYChildren(coordinated.y);
    setPosition();
  }, [isOpen]);

  return (
    <>
      <WrapperChildren>
        <div
          ref={wrapperChildren}
          className="wrapper-children"
          onMouseOver={mouseOverHandler}
          onMouseOut={mouseOutHandler}
        >
          <>{children ? children : <h1>Hover me</h1>}</>
        </div>
      </WrapperChildren>
      {ReactDOM.createPortal(
        <WrapperTooltip
          ref={wrapperTooltipTemplate}
          isOpen={showTooltip}
          {...{
            heightChildren,
            widthChildren,
            heightTooltip,
            widthTooltip,
            position: positionCurrent,
            visibilityTooltip,
            xChildren,
            yChildren,
          }}
        >
          <div className={`Tooltip--${directionsArrow[positionCurrent]}`}>{TooltipComponent}</div>
        </WrapperTooltip>,
        document.body,
      )}
    </>
  );
}

Tooltip.defaultProps = {
  position: 'top',
  TooltipComponent: <p>Example Template Tooltip</p>,
};
