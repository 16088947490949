import * as React from 'react';

function SvgNivelOutstanding(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_607_8764)">
        <circle cx="36" cy="36" r="35.5" stroke="#B8DCFF" />
        <circle cx="36" cy="36" r="24" fill="white" />
        <path
          d="M41.8458 19.1892L39.3527 24.1482L38.8077 25.2262L35.688 18.1516C35.8787 17.8552 36.2193 17.6665 36.5871 17.6665H40.8785C41.6823 17.6665 42.2 18.4885 41.8458 19.1892Z"
          fill="#0041D6"
        />
        <path
          d="M41.9276 28.9994V26.7355C41.9276 24.6603 40.2111 22.9624 38.1131 22.9624H33.3994C31.3014 22.9624 29.5848 24.6603 29.5848 26.7355V28.9994C25.2117 31.2228 22.2146 35.7371 22.2146 40.9386C22.2146 48.3366 28.277 54.3332 35.7562 54.3332C43.2355 54.3332 49.2979 48.3366 49.2979 40.9386C49.3115 35.7236 46.3144 31.2094 41.9276 28.9994ZM39.4754 28.0426C38.2902 27.7058 37.0505 27.5306 35.7562 27.5306C34.462 27.5306 33.2223 27.7058 32.0371 28.0426V26.7355C32.0371 25.9944 32.6501 25.388 33.3994 25.388H38.1131C38.6308 25.388 39.0803 25.671 39.3119 26.0887L39.3256 26.1022C39.4209 26.2908 39.4754 26.5064 39.4754 26.722V28.0426Z"
          fill="#1AC5EB"
        />
        <path
          d="M35.6069 52.8864C42.1062 52.8864 47.3749 47.5843 47.3749 41.0438C47.3749 34.5033 42.1062 29.2012 35.6069 29.2012C29.1076 29.2012 23.8389 34.5033 23.8389 41.0438C23.8389 47.5843 29.1076 52.8864 35.6069 52.8864Z"
          fill="url(#paint0_linear_607_8764)"
        />
        <path
          d="M35.7697 53.0261C29.0261 53.0261 23.5359 47.5955 23.5359 40.9252C23.5359 34.2548 29.0125 28.8242 35.7697 28.8242C42.5269 28.8242 48.0035 34.2548 48.0035 40.9252C47.9899 47.5955 42.5133 53.0261 35.7697 53.0261ZM35.7697 29.7675C29.5574 29.7675 24.4895 34.7669 24.4895 40.9252C24.4895 47.0834 29.5438 52.0828 35.7697 52.0828C41.982 52.0828 47.0499 47.0834 47.0499 40.9252C47.0499 34.7669 41.982 29.7675 35.7697 29.7675Z"
          fill="white"
        />
        <path
          d="M35.3456 44.7128L31.7026 41.1093C31.5272 40.9358 31.5272 40.6546 31.7026 40.4826L33.8198 37.5418C33.9045 37.458 34.0179 37.4116 34.1373 37.4116H37.1876C37.307 37.4116 37.4205 37.458 37.5051 37.5418L39.6223 40.4826C39.7977 40.6561 39.7977 40.9373 39.6223 41.1093L35.9793 44.7128C35.8054 44.8863 35.5211 44.8863 35.3456 44.7128Z"
          fill="#04758E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.1245 36.9602L33.1781 36.9072C33.4331 36.6549 33.7773 36.5142 34.1372 36.5142H37.1875C37.5474 36.5142 37.8916 36.6549 38.1466 36.9072L38.2002 36.9602L40.329 39.9171C40.7909 40.4425 40.7731 41.2429 40.2627 41.7451C40.2621 41.7456 40.2616 41.7462 40.261 41.7467L36.6235 45.3447C36.6229 45.3453 36.6224 45.3459 36.6218 45.3465C36.0912 45.8739 35.2316 45.8694 34.7039 45.3475L31.0609 41.744C30.5558 41.2443 30.53 40.4437 30.9966 39.9159L33.1245 36.9602ZM31.7025 40.4826C31.5271 40.6546 31.5271 40.9358 31.7025 41.1094L35.3455 44.7128C35.5209 44.8863 35.8052 44.8863 35.9792 44.7128L39.6222 41.1094C39.7976 40.9373 39.7976 40.6561 39.6222 40.4826L37.505 37.5418C37.4203 37.458 37.3069 37.4117 37.1875 37.4117H34.1372C34.0178 37.4117 33.9043 37.458 33.8197 37.5418L31.7025 40.4826Z"
          fill="url(#paint1_linear_607_8764)"
        />
        <path
          d="M37.3229 26.7085H33.3041C32.8953 26.7085 32.5275 26.4794 32.3504 26.1291L31.9962 25.4149L28.9309 19.324C28.5767 18.6233 29.0944 17.8013 29.8846 17.8013H34.1759C34.5983 17.8013 34.9797 18.0438 35.1432 18.4211L38.2902 25.2262C38.3175 25.2936 38.3447 25.361 38.3583 25.4284C38.5218 26.0617 38.0314 26.7085 37.3229 26.7085Z"
          fill="#1C8AF5"
        />
        <mask id="path-10-inside-1_607_8764" fill="white">
          <path d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917V6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131V33.2131Z" />
        </mask>
        <path
          d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917V6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131V33.2131Z"
          stroke="#166DC2"
          stroke-width="6"
          mask="url(#path-10-inside-1_607_8764)"
        />
        <mask id="path-11-inside-2_607_8764" fill="white">
          <path d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008V36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111V65.2111Z" />
        </mask>
        <path
          d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008V36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111V65.2111Z"
          stroke="#166DC2"
          stroke-width="6"
          mask="url(#path-11-inside-2_607_8764)"
        />
        <mask id="path-12-inside-3_607_8764" fill="white">
          <path d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086V65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36V36Z" />
        </mask>
        <path
          d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086V65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36V36Z"
          stroke="#166DC2"
          stroke-width="6"
          mask="url(#path-12-inside-3_607_8764)"
        />
        <mask id="path-13-inside-4_607_8764" fill="white">
          <path d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996V33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855V6.78855Z" />
        </mask>
        <path
          d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996V33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855V6.78855Z"
          stroke="#166DC2"
          stroke-width="8"
          mask="url(#path-13-inside-4_607_8764)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_607_8764"
          x1="35.559"
          y1="33.0737"
          x2="35.559"
          y2="54.9129"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E1F0FF" />
          <stop offset="1" stop-color="#068DB7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_607_8764"
          x1="39.037"
          y1="36.645"
          x2="35.7641"
          y2="42.3602"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1AC5EB" />
          <stop offset="1" stop-color="#E1F0FF" />
        </linearGradient>
        <clipPath id="clip0_607_8764">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNivelOutstanding;
