import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { BACKEND_URL } from '../../config';
import getAxios from '../../clients';
import t from '../../utils/translate';
import { trackGTMEventAction } from '../../utils/analytics';
import HeaderMarquetPlace from '../../components/HeaderMarquetPlace/HeaderMarquetPlace';
import Footer from '../../components/Footer/Footer';
import { Input, Typography, Button } from '@cencosud-ds/bigbang';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({
    sendMessage: false,
  });

  const handleForgotPassword = async values => {
    setLoading(true);
    try {
      const response = await getAxios.instance().get(`${BACKEND_URL}/auth/forgot/password/${values.email}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = response;
      setForgotPassword({ sendMessage: data.result });
    } catch (error) {
      console.log('handleForgotPassword', error);
      setForgotPassword({ sendMessage: true });
    }
  };

  const onSubmit = async values => {
    trackGTMEventAction({
      category: 'Login',
      action: 'Cambiar contraseña: botón enviar correo',
      tag: '/forgot/password',
    });
    handleForgotPassword(values);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('Must be a valid email'))
        .required(t('Required field')),
    }),
    onSubmit,
  });

  if (forgotPassword.sendMessage) {
    return <Redirect to={`/login?type=success&message=Email sent. Check your email to change your password.`} />;
  }

  return (
    <div className="App">
      <HeaderMarquetPlace />
      <div className="App-forgot">
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className="row justify-content-md-center mb-5">
              <div className="col-6 text-center" align="center">
                <div style={{ marginBottom: '16px' }}>
                  <Typography color="#166DC2" propsElement={{}} typeElement="h3" weight="bold">
                    {t('olvidasteContraseña')}
                  </Typography>
                </div>
                <Typography color="#394C67" propsElement={{}} typeElement="body1" weight="regular">
                  {t('restablecerContraseñaFirst')}
                </Typography>
                <Typography color="#394C67" propsElement={{}} typeElement="body1" weight="regular">
                  {t('restablecerContraseñaSecond').toLowerCase()}
                </Typography>
              </div>
            </div>
            <div className="row justify-content-md-center mb-5">
              <div className="col-6 text-center" align="center">
                <Input
                  name="email"
                  feedback-text={formik.touched.email && formik.errors.email ? formik.errors.email : ''}
                  icon-end={null}
                  input-state={formik.touched.email && formik.errors.email ? 'error' : 'default'}
                  label-text={t('correoElectronico')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder=""
                  width="100%"
                />
              </div>
            </div>
            <div className="row justify-content-md-center mb-5">
              <div className="col-6 text-center" align="center">
                <Button
                  type="submit"
                  disabled={!formik.dirty || !formik.isValid}
                  type-button="primary"
                  variant-button="m"
                  width="100%"
                  loading={loading}
                >
                  {t('restablecer')}
                </Button>
              </div>
            </div>
            <div className="row justify-content-md-center mb-5">
              <div className="col-6 text-center" align="center">
                <Typography
                  color="#166DC2"
                  propsElement={{}}
                  typeElement="body1"
                  weight="semiBold"
                  onClick={() => {
                    trackGTMEventAction({ category: 'Login', action: 'Clic volver a iniciar sesión', tag: '/login' });
                    history.push('/login');
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>{t('volverIniciarSesion')}</span>
                </Typography>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
