import * as React from 'react';

export default function SvgComponent(props: {
  background?: string;
  border?: string;
  className?: string;
  strokeWidth?: string;
}) {
  return (
    <svg width={10} height={10} viewBox="0 0 50 50" {...props}>
      <title>{'Page 1'}</title>
      <g fill="none" fillRule="evenodd">
        <path d="M49.087 0v49.088L0 49.087 49.087 0z" fill={props.background || 'transparent'} />
        <path
          d="M49.5 15.365v34.27m.135-.135h-34.27"
          strokeWidth={props.strokeWidth || 10}
          stroke={props.border || 'black'}
        />
      </g>
    </svg>
  );
}
