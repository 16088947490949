import React from 'react';
import { Typography, colors } from '@cencosud-ds/bigbang';
import Nivele from '../Nivele';
import { nameIconOutlineType } from '../Nivele/interfaces';
import './style.css';

interface PropsAccordionName {
  id: string;
  IconNivel: nameIconOutlineType;
  children?: React.ReactNode;
  title: string;
  opacity: boolean;
}

export default function Accordion({ children, id, IconNivel, title, opacity }: PropsAccordionName): JSX.Element {
  return (
    <div
      className={`accordion-container ${opacity ? `accordion-container--opacity` : ``}  ${
        IconNivel === 'NotFatBloqueo' ? `accordion-container--lock` : ``
      }`}
    >
      <input className="input-accordion" type="checkbox" name={id} value={id} id={id} />
      <label htmlFor={id}>
        <div className="accordion-header">
          <Nivele name={IconNivel} size={'xl'} type={'NotFlat'} />
          <div>
            <Typography color={colors.primary[700]} weight={'bold'} typeElement="body1">
              {title}
            </Typography>
          </div>
        </div>
      </label>
      <div className={`accordion-content`}>{children}</div>
    </div>
  );
}
