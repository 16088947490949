import React, { useEffect, useState } from 'react';
import { Input, Dropdown } from '@cencosud-ds/bigbang';
import { FormSection } from '../../../../components';
import { getRegions, getCommunesByRegion } from 'utils/signup';
import t from '../../../../../../../utils/translate';
import { allowAlphanumeric, allownumber } from 'utils/patterns';
import '../index.css';
interface IDataDrop {
  value: string;
  label: string;
  children: string;
}

export const CompanyHeadAddress: React.FC<{ formik: any; inputsFocused: boolean }> = props => {
  const { formik, inputsFocused } = props;
  const [optionsCommune, setOptionsCommune] = useState<IDataDrop[]>([]);

  useEffect(() => {
    setOptionsCommune(getCommunesByRegion(formik.values.companyHeadAddressRegion));
  }, [formik.values.companyHeadAddressRegion]);

  const setRegion = (region: string) => {
    if (region !== formik.values.companyHeadAddressRegion) {
      setOptionsCommune([]);
      formik.setFieldValue('companyHeadAddressRegion', region);
    }
  };

  return (
    <>
      <FormSection
        label={t('sign_up_matrix_address_information_title')}
        description={<span>{t('sign_up_matrix_address_descriptio')}</span>}
      >
        <div className="row mb-8">
          <div className="col-6">
            <Input
              name="companyHeadAddress"
              feedback-text={
                formik.touched.companyHeadAddress && formik.errors.companyHeadAddress
                  ? formik.errors.companyHeadAddress
                  : ''
              }
              icon-end={null}
              input-state={formik.touched.companyHeadAddress && formik.errors.companyHeadAddress ? 'error' : 'default'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              width="100%"
              value={formik.values.companyHeadAddress ? formik.values.companyHeadAddress : ''}
              maxLength={255}
              label-text={t('sign_up_matrix_address_company')}
              placeholder={t('sign_up_matrix_address_company')}
              input-focused={inputsFocused}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <Dropdown
              dropdown-options={getRegions()}
              disabled={false}
              width="100%"
              default-value={formik.values.companyHeadAddressRegion ? formik.values.companyHeadAddressRegion : ''}
              label-text={t('sign_up_matrix_address_choose_region')}
              placeholder={t('sign_up_matrix_address_choose_region')}
              variant={
                formik.touched.companyHeadAddressRegion && formik.errors.companyHeadAddressRegion ? 'error' : 'default'
              }
              text-feedback={
                formik.touched.companyHeadAddressRegion && formik.errors.companyHeadAddressRegion
                  ? formik.errors.companyHeadAddressRegion
                  : ''
              }
              onBlur={value => formik.setFieldValue('companyHeadAddressRegion', value)}
              onChange={value => setRegion(value)}
            />
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            {optionsCommune && optionsCommune.length > 0 && (
              <Dropdown
                dropdown-options={optionsCommune}
                disabled={false}
                width="100%"
                label-text={t('sign_up_matrix_address_choose_commune')}
                placeholder={t('sign_up_matrix_address_choose_commune')}
                variant={
                  formik.touched.companyHeadAddressCommunes && formik.errors.companyHeadAddressCommunes
                    ? 'error'
                    : 'default'
                }
                text-feedback={
                  formik.touched.companyHeadAddressCommunes && formik.errors.companyHeadAddressCommunes
                    ? formik.errors.companyHeadAddressCommunes
                    : ''
                }
                onBlur={value => formik.setFieldValue('companyHeadAddressCommunes', value)}
                onChange={value => formik.setFieldValue('companyHeadAddressCommunes', value)}
                default-value={formik.values.companyHeadAddressCommunes ? formik.values.companyHeadAddressCommunes : ''}
              />
            )}
          </div>
          <div className="col-6">
            <Input
              name="companyHeadAddressRegionsCodePostal"
              feedback-text={
                formik.touched.companyHeadAddressRegionsCodePostal && formik.errors.companyHeadAddressRegionsCodePostal
                  ? formik.errors.companyHeadAddressRegionsCodePostal
                  : ''
              }
              icon-end={null}
              input-state={
                formik.touched.companyHeadAddressRegionsCodePostal && formik.errors.companyHeadAddressRegionsCodePostal
                  ? 'error'
                  : 'default'
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              width="100%"
              maxLength={7}
              label-text={t('sign_up_enter_postal_code')}
              value={
                formik.values.companyHeadAddressRegionsCodePostal
                  ? formik.values.companyHeadAddressRegionsCodePostal
                  : ''
              }
              placeholder={t('sign_up_enter_postal_code')}
              input-focused={inputsFocused}
              onKeyPress={event => {
                if (!allownumber.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allownumber.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
      </FormSection>
    </>
  );
};
