import React from 'react';
import styled from '@emotion/styled';
import { StepInterface } from './interfaces';
import { newColors } from '../styles/css';
import { Icon } from '@cencosud-ds/bigbang';

interface IVariantInterface {
  [key: string]: { icon: string | JSX.Element; colorIcon: string; colorLine: string };
}

const variants: IVariantInterface = {
  active: {
    icon: '',
    colorIcon: newColors.secondary[500],
    colorLine: newColors.secondary[500],
  },
  inactive: {
    icon: '',
    colorIcon: newColors.neutral[200],
    colorLine: newColors.secondary[500],
  },
  complete: {
    icon: <Icon isFill={true} name="CheckmarkCircle" />,
    colorIcon: newColors.secondary[500],
    colorLine: newColors.secondary[500],
  },
  completeDisabled: {
    icon: <Icon isFill={true} name="CheckmarkCircle" />,
    colorIcon: newColors.neutral[200],
    colorLine: newColors.neutral[200],
  },
  error: {
    icon: <Icon isFill={true} name="AlertCircle" />,
    colorIcon: newColors.feedback.error.dark,
    colorLine: newColors.neutral[200],
  },
  warning: {
    icon: <Icon isFill={true} name="AlertCircle" />,
    colorIcon: newColors.feedback.warning.dark,
    colorLine: newColors.neutral[200],
  },
  incomplete: {
    icon: '',
    colorIcon: newColors.neutral[200],
    colorLine: newColors.neutral[200],
  },
  disabled: {
    icon: '',
    colorIcon: newColors.neutral[200],
    colorLine: newColors.neutral[200],
  },
};

const StepStyled = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  .Step__state {
    display: flex;

    &__line--first,
    &__line--last {
      display: block;
      background-color: transparent;
    }

    ${(props: StepInterface) => {
      const { state, lines, nextState } = props;
      const colorLines = {
        both: '&__line--first, &__line--last',
        first: '&__line--first',
        last: '&__line--last',
      };

      return `
        ${colorLines[lines]} { background-color: ${variants[state].colorLine};}
        &__line--last.nextState { background-color: ${variants[nextState || state].colorLine};}
      `;
    }};

    svg {
      height: 20px;
      width: 20px;

      path {
        transform: translateX(-2px) translateY(-2px);
        fill: ${(props: StepInterface) => variants[props.state].colorIcon};
      }
    }

    &__not-icon {
      height: 20px;
      width: 20px;
      border-radius: 100%;
      border: 2px solid;
      border-color: ${(props: StepInterface) => variants[props.state].colorIcon};
    }
  }

  .Step__texts {
    display: flex;
    flex-direction: column;

    &__desc--2 {
      margin-top: 4px;
    }

    & > * {
      text-align: center;
    }
  }

  &.Step--left {
    flex-direction: row;

    .Step__state {
      align-items: center;
      flex-direction: column;
      margin-right: 8px;

      &__line--first,
      &__line--last {
        width: 2px;
        height: 40px;
      }
    }

    .Step__texts {
      align-items: flex-start;
    }
  }

  &.Step--top {
    flex-direction: column;

    .Step__state {
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      &__line--first,
      &__line--last {
        height: 2px;
        max-width: ${(props: StepInterface) => `${Math.round((props.widthLine + 10) / 2)}px`};
        min-width: ${(props: StepInterface) => `${Math.round((props.widthLine + 4) / 2)}px`};
        width: ${(props: StepInterface) => `${Math.round((props.widthLine + 5) / 2)}px`};
      }
    }

    .Step__texts {
      align-items: center;
    }
  }
`;

export { StepStyled, variants };
