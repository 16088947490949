import { Service, ServicesStatus } from './interfaces/serviceStatus.interface';
import { SERVICES_STATUS_URL, SPA_SERVICES_STATUS_READY } from '../config';

const MAX_SERVICES_UNAVAILABLE_COUNT = 2;

export async function getServicesStatus(
  { dispatchEvent }: { dispatchEvent: boolean } = { dispatchEvent: false },
): Promise<ServicesStatus> {
  const services: Service[] = await fetch(SERVICES_STATUS_URL)
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      return [];
    })
    .catch(error => {
      console.warn('Fetch Services Status Error: ', error);
      return [];
    });

  const getService = (servicePath: string) => {
    const exactPath = services.find(s => servicePath === s.key_module);
    const partialPath = services.find(s => servicePath.startsWith(s.key_module));
    return exactPath && !exactPath.active ? exactPath : partialPath;
  };

  const getIsServiceUnavailable = (servicePath: string) => {
    const service = getService(servicePath);
    return !!service && !service.active;
  };

  const modulesUnavailable: string[] = [];
  for (const serv of services) {
    if (!serv.active && serv.count_to_close_page && !modulesUnavailable.some(v => v === serv.module)) {
      modulesUnavailable.push(serv.module);
    }
  }
  const unavailableCount = modulesUnavailable.length;
  const hasAllServicesUnavailable = unavailableCount >= MAX_SERVICES_UNAVAILABLE_COUNT;

  const servicesStatus = { services, getService, getIsServiceUnavailable, hasAllServicesUnavailable };

  if (dispatchEvent) {
    const servicesStatusEvent = new Event(SPA_SERVICES_STATUS_READY);
    // @ts-ignore
    servicesStatusEvent.forceUpdateServicesStatus = true;
    // @ts-ignore
    servicesStatusEvent.servicesStatus = servicesStatus;
    window.dispatchEvent(servicesStatusEvent);
  }

  return servicesStatus;
}
