import { useCallback, useState } from 'react';

export function useApi<T, U>(apiFunction: (payload: U) => Promise<T>) {
  /**
   * State
   */

  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setResult] = useState<T>();

  /**
   * Functions
   */
  const execute = useCallback(
    async (payload: U) => {
      try {
        setIsLoading(true);

        const response = await apiFunction(payload);

        setResult(response);
        return response;
      } catch (apiError) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [apiFunction],
  );

  return {
    error,
    isLoading,
    data,
    execute,
  };
}
