import React, { ReactNode } from 'react';
import styled from '@emotion/styled';

interface IProps {
  id: string;
  children: ReactNode;
  label: string;
  className?: string;
  onClick: () => void;
}

const Button = (props: IProps) => {
  const { id, children, label, className, onClick } = props;
  return (
    <button id={id} className={`${className} sub-button`} onClick={onClick}>
      {children}
      <p className="label-sub-menu">{label}</p>
    </button>
  );
};

export const Container = styled(Button)`
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  height: 100px;
  min-width: 100px;
  padding: 0px;
  border: 0px;
  padding-top: 12px;
  width: 100px;
  cursor: pointer !important;
  :hover {
    cursor: pointer;
    background: #00b0eb !important;
  }
  :hover svg {
    fill: #ffffff;
  }
  p {
    color: #4d4d4d !important;
    cursor: pointer !important;
    font-size: 12px;
    margin: 0px;
  }
  :hover p {
    color: #ffffff !important;
  }
`;
