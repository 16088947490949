import React from 'react';

export const IconSuccess = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1081_15729)">
        <path
          d="M58.8227 70.1626C58.4502 70.1626 58.08 70.0199 57.797 69.7368C57.2696 69.2094 57.2333 68.3748 57.6905 67.8038C57.7195 67.3296 57.2502 65.549 54.7003 61.8959C52.385 58.5791 49.0222 54.6647 45.2336 50.8785C41.445 47.0899 37.533 43.7295 34.2162 41.4118C30.5631 38.8619 28.7849 38.3925 28.3083 38.4216C27.7397 38.8788 26.9027 38.8425 26.3753 38.3151C25.8092 37.749 25.8092 36.8297 26.3753 36.2611C27.8389 34.7975 30.5897 35.4942 35.0339 38.4554C38.6725 40.8796 43.0248 44.5617 47.2876 48.8245C51.5504 53.0873 55.2325 57.4396 57.6566 61.0782C60.6179 65.5224 61.3146 68.2731 59.8509 69.7368C59.5655 70.0223 59.1929 70.1626 58.8227 70.1626Z"
          fill="#166DC2"
        />
        <path
          d="M73.9287 71.7787C73.128 71.7787 72.4772 71.1279 72.4772 70.3271C72.4772 63.4491 68.4224 60.1758 65.0209 58.6395C58.2397 55.5791 49.4456 57.0307 45.7924 60.2508C45.19 60.7806 44.2731 60.7225 43.7433 60.1226C43.2135 59.5201 43.2715 58.6032 43.8715 58.0734C48.819 53.7114 59.0501 52.7582 66.2136 55.9928C72.124 58.6613 75.3803 63.7515 75.3803 70.3271C75.3803 71.1279 74.7295 71.7787 73.9287 71.7787Z"
          fill="#FF8A00"
        />
        <path
          d="M37.0015 54.3041C36.7572 54.3041 36.508 54.2412 36.2806 54.1106C35.5863 53.7114 35.3467 52.8235 35.7459 52.1292C39.1862 46.1414 38.7386 33.5345 32.5912 25.0162C29.4727 20.6954 23.739 15.956 14.1248 17.9132C13.3385 18.0729 12.5716 17.5673 12.4119 16.781C12.2522 15.9947 12.7603 15.2278 13.5441 15.0681C22.2294 13.2996 29.8308 16.2294 34.9451 23.3155C41.1942 31.9741 42.7135 45.8318 38.262 53.5759C37.9934 54.0428 37.5047 54.3041 37.0015 54.3041Z"
          fill="#FF8A00"
        />
        <path
          d="M58.8231 70.1626C58.4505 70.1626 58.0804 70.0223 57.7973 69.7368C57.2312 69.1707 57.2288 68.2514 57.7973 67.6852L57.7997 67.6828C58.3659 67.1167 59.2852 67.1167 59.8537 67.6828C60.4198 68.2489 60.4198 69.1683 59.8537 69.7368C59.5682 70.0199 59.1957 70.1626 58.8231 70.1626Z"
          fill="#166DC2"
        />
        <path
          d="M27.399 38.7361C27.0265 38.7361 26.6563 38.5958 26.3732 38.3103C25.8071 37.7442 25.8047 36.8249 26.3732 36.2588L26.3757 36.2564C26.9418 35.6903 27.8611 35.6903 28.4296 36.2564C28.9958 36.8225 28.9958 37.7418 28.4296 38.3103C28.1417 38.5958 27.7692 38.7361 27.399 38.7361Z"
          fill="#166DC2"
        />
        <path d="M20 83L32.5 49L27 39.5L9 84L10 86.5L20 83Z" fill="#E1F0FF" />
        <path
          d="M59.8476 67.6853C59.3202 67.1579 58.4904 67.1216 57.9218 67.574C57.9049 67.574 57.888 67.5764 57.871 67.5788C57.346 67.5812 55.563 67.0732 52.0067 64.5886C48.6898 62.2733 44.7754 58.9105 40.9892 55.1219C37.2031 51.3333 33.8403 47.4213 31.5226 44.1045C29.038 40.5433 28.5299 38.7651 28.5323 38.2377C28.5348 38.2208 28.5348 38.2063 28.5372 38.1893C28.9896 37.6208 28.9533 36.7886 28.4259 36.2636C27.8598 35.6975 26.9405 35.6975 26.3719 36.2636C25.9679 36.6676 25.7356 37.1732 25.6631 37.7829L8.31678 83.7372C7.87405 84.9082 8.14985 86.188 9.03531 87.0734C9.64255 87.6807 10.4312 88 11.2465 88C11.6215 88 12.0014 87.9323 12.3715 87.792L58.3138 70.4554C58.9307 70.3852 59.4387 70.1481 59.8476 69.7392C60.4137 69.1707 60.4137 68.2514 59.8476 67.6853ZM11.3457 85.0751C11.3094 85.0896 11.1982 85.1307 11.0893 85.0195C10.978 84.9082 11.0191 84.7993 11.0336 84.763L27.0251 42.3868C27.4437 43.1634 27.9541 44.0053 28.5638 44.9222C30.9879 48.5608 34.6701 52.9131 38.9329 57.1759C43.1956 61.4387 47.5479 65.1208 51.1865 67.545C52.101 68.1546 52.9429 68.6675 53.7171 69.086L11.3457 85.0751Z"
          fill="#166DC2"
        />
        <path
          d="M56.2361 45.5658C55.8925 45.5658 55.5466 45.4448 55.2683 45.1956C54.6708 44.661 54.62 43.7441 55.1546 43.1465C56.8844 41.2111 59.3182 41.6901 61.094 42.0385C62.8528 42.3844 63.7455 42.4788 64.3116 41.8449C64.8778 41.2111 64.6842 40.3377 64.1447 38.6273C63.6004 36.8999 62.8528 34.5338 64.5826 32.6008C66.3124 30.6678 68.7462 31.1444 70.5219 31.4952C72.2808 31.8412 73.1735 31.9355 73.7372 31.3017C74.2718 30.7041 75.1888 30.6533 75.7863 31.188C76.3839 31.7226 76.4347 32.6395 75.9 33.2371C74.1702 35.1701 71.7364 34.6935 69.9607 34.3427C68.2018 33.9968 67.3091 33.9024 66.7454 34.5363C66.1793 35.1701 66.3729 36.0435 66.9124 37.7539C67.4567 39.4813 68.2043 41.8473 66.4745 43.7803C64.7447 45.7134 62.3109 45.2368 60.5351 44.8884C58.7763 44.5424 57.8836 44.4481 57.3199 45.0819C57.032 45.4013 56.6352 45.5658 56.2361 45.5658Z"
          fill="#FF8A00"
        />
        <path
          d="M49.873 35.7265C49.094 35.7265 48.448 35.1071 48.4238 34.3209C48.3392 31.7274 50.4319 30.3944 51.9584 29.4194C53.4705 28.4541 54.1793 27.9073 54.1527 27.0582C54.1261 26.209 53.381 25.7106 51.8108 24.8445C50.2238 23.9712 48.0513 22.776 47.9666 20.1826C47.8819 17.5891 49.9746 16.2561 51.5012 15.2811C53.0132 14.3158 53.7221 13.769 53.6955 12.9223C53.6689 12.1215 54.2979 11.4514 55.0987 11.4247C55.897 11.4006 56.5696 12.0272 56.5962 12.8279C56.6809 15.4214 54.5882 16.7544 53.0616 17.7294C51.5496 18.6947 50.8407 19.2415 50.8673 20.0882C50.8939 20.9374 51.6391 21.4382 53.2092 22.3019C54.7962 23.1752 56.9688 24.3704 57.0534 26.9638C57.1381 29.5573 55.0454 30.8903 53.5189 31.8653C52.0068 32.8306 51.298 33.3774 51.3246 34.2265C51.3512 35.0273 50.7222 35.6975 49.9214 35.7241C49.9045 35.7241 49.8875 35.7265 49.873 35.7265Z"
          fill="#FF8A00"
        />
        <path
          d="M38.7962 14.8626C38.5446 14.8626 38.2882 14.7973 38.0559 14.6594C37.3665 14.2505 37.1415 13.3578 37.5503 12.6707L39.3116 9.70951C39.7204 9.02001 40.6131 8.79502 41.3002 9.20388C41.9897 9.61274 42.2147 10.5055 41.8058 11.1925L40.0446 14.1537C39.7736 14.6086 39.2922 14.8626 38.7962 14.8626Z"
          fill="#166DC2"
        />
        <path
          d="M69.646 85.6896C69.1549 85.6896 68.6759 85.4404 68.4025 84.9904C67.9864 84.3057 68.2041 83.413 68.8912 82.9969L72.5927 80.7518C73.2774 80.3357 74.1701 80.5534 74.5862 81.2405C75.0023 81.9252 74.7846 82.8179 74.0975 83.234L70.396 85.4791C70.1613 85.6218 69.9025 85.6896 69.646 85.6896Z"
          fill="#166DC2"
        />
        <path
          d="M84.1974 68.757C83.9458 68.757 83.6893 68.6917 83.4571 68.5538C82.7676 68.1449 82.5426 67.2522 82.9514 66.5651L84.7127 63.6039C85.1215 62.9144 86.0118 62.6894 86.7013 63.0983C87.3908 63.5071 87.6158 64.3999 87.207 65.0869L85.4457 68.0481C85.1748 68.503 84.6909 68.757 84.1974 68.757Z"
          fill="#FF8A00"
        />
        <path
          d="M9.45036 53.8469C9.07779 53.8469 8.70764 53.7042 8.42458 53.4211C7.85847 52.855 7.85847 51.9357 8.42458 51.3672L10.8608 48.9309C11.4269 48.3648 12.3462 48.3648 12.9148 48.9309C13.4809 49.497 13.4809 50.4164 12.9148 50.9849L10.4786 53.4211C10.1931 53.7042 9.82293 53.8469 9.45036 53.8469Z"
          fill="#FF8A00"
        />
        <path
          d="M77.4877 18.131C77.1151 18.131 76.7449 17.9883 76.4619 17.7052L74.0257 15.2714C73.4595 14.7053 73.4595 13.786 74.0257 13.2174C74.5918 12.6513 75.5111 12.6513 76.0796 13.2174L78.5159 15.6537C79.082 16.2198 79.082 17.1391 78.5159 17.7076C78.2304 17.9907 77.8602 18.131 77.4877 18.131Z"
          fill="#166DC2"
        />
        <path
          d="M47.445 85.6968C46.8619 85.6968 46.3128 85.3436 46.0902 84.7678L44.8467 81.555C44.5564 80.8075 44.9289 79.9656 45.6765 79.6777C46.424 79.3873 47.266 79.7599 47.5538 80.5075L48.7974 83.7203C49.0877 84.4678 48.7151 85.3098 47.9675 85.5977C47.7958 85.6654 47.6192 85.6968 47.445 85.6968Z"
          fill="#FF8A00"
        />
        <path
          d="M18.7888 32.8839C18.4525 32.8839 18.1162 32.7678 17.8428 32.5331L15.2324 30.2856C14.6252 29.763 14.555 28.8461 15.08 28.2389C15.6026 27.6317 16.5195 27.5615 17.1267 28.0865L19.7371 30.334C20.3444 30.8566 20.4145 31.7735 19.8896 32.3807C19.6041 32.7146 19.1976 32.8839 18.7888 32.8839Z"
          fill="#166DC2"
        />
        <path
          d="M80.3006 49.2696C79.6668 49.2696 79.0837 48.8511 78.9047 48.2099L77.9709 44.8931C77.7531 44.1213 78.2031 43.3206 78.9749 43.1028C79.7466 42.8851 80.5474 43.3351 80.7651 44.1068L81.699 47.4237C81.9167 48.1954 81.4667 48.9962 80.695 49.2139C80.5643 49.2527 80.4313 49.2696 80.3006 49.2696Z"
          fill="#166DC2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1081_15729">
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
