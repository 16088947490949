import styled from '@emotion/styled';

export const Container = styled.div`
  padding: 36px;
  margin: 0 0 41px 0;
  display: flex;
  flex-direction: row;
  border: 1px solid #b8dcff;
  border-radius: 5px;
  width: 100%;
  .container-subtitle {
    width: 29em;
    margin-right: 3em;
    margin-bottom: 8px;
  }
  .container-textbody {
    width: 404px;
  }
  .container-forms {
    width: 100%;
  }

  .container-forms input {
    width: 360px;
  }

  .container-forms div.Dropdown {
    width: 360px;
  }
`;
