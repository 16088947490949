import React from 'react';

interface IProperties {
  className?: string;
}

const IconReputacion = ({ className }: IProperties) => {
  return (
    <svg width="48" height="48" viewBox="12 0 55 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_10294_85438)">
        <path
          d="M48.908 2.40002L46.412 6.62402C45.452 5.95202 44.204 5.56802 42.86 5.56802H40.172L39.5 4.32002L40.076 3.36002L41.228 1.34402C41.42 1.15202 41.708 0.960022 42.092 0.960022H48.14C48.812 0.960022 49.292 1.72802 48.908 2.40002Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M36.428 10.08C35.468 10.08 34.7 10.848 34.7 11.712V13.152H34.604C33.452 13.536 32.3 13.92 31.34 14.496C30.86 14.784 30.38 15.072 29.9 15.36V11.424C29.9 9.40802 30.956 7.68002 32.492 6.62402L34.604 10.08H36.428Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linejoin="bevel"
        />
        <path
          d="M49.0041 11.424V15.36C48.5241 15.072 48.1401 14.784 47.6601 14.592C46.6041 14.016 45.5481 13.536 44.3961 13.248H44.3001V11.808C44.3001 10.848 43.5321 10.176 42.5721 10.176H42.9561L40.2681 5.664H42.9561C44.3001 5.664 45.4521 6.048 46.5081 6.72C47.9481 7.68 49.0041 9.408 49.0041 11.424Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linejoin="bevel"
        />
        <path
          d="M42.9561 10.08H34.7001L32.5881 6.62402L30.0921 2.40002C29.7081 1.72802 30.0921 0.960022 30.8601 0.960022H36.9081C37.2921 0.960022 37.5801 1.15202 37.7721 1.44002L39.5001 4.32002L40.1721 5.47202L42.9561 10.08Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M51.212 17.088C50.54 16.416 49.772 15.84 49.004 15.36C48.524 15.072 48.14 14.784 47.66 14.592C46.604 14.016 45.548 13.536 44.396 13.248H44.3C42.764 12.768 41.132 12.576 39.5 12.576C37.868 12.576 36.236 12.864 34.7 13.248H34.604C33.452 13.632 32.3 14.016 31.34 14.592C30.86 14.784 30.38 15.072 29.9 15.36C29.132 15.84 28.364 16.416 27.692 17.088C24.332 20.256 22.22 24.768 22.22 29.76C22.22 39.36 29.996 47.0401 39.5 47.0401C49.004 47.0401 56.78 39.36 56.78 29.76C56.78 24.768 54.668 20.256 51.212 17.088ZM39.5 41.6641C32.876 41.6641 27.596 36.288 27.596 29.76C27.596 27.936 27.98 26.112 28.748 24.576C29.324 23.328 30.188 22.08 31.244 21.12C32.3 20.064 33.644 19.2 35.084 18.624C36.428 18.048 37.964 17.76 39.5 17.76C41.036 17.76 42.572 18.048 43.916 18.624C45.356 19.2 46.7 20.064 47.756 21.12C48.812 22.08 49.676 23.232 50.252 24.576C51.02 26.112 51.404 27.936 51.404 29.76C51.404 36.384 46.124 41.6641 39.5 41.6641Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M45.452 29.184L43.916 30.816L43.532 31.2C43.34 31.392 43.244 31.68 43.244 32.064L43.724 34.848C43.82 35.616 42.956 36.288 42.284 35.904L39.98 34.56C39.692 34.368 39.308 34.368 39.02 34.56L36.812 35.808C36.14 36.192 35.276 35.616 35.372 34.752L35.852 31.968C35.948 31.68 35.756 31.392 35.564 31.104L35.18 30.72L33.644 29.088C33.068 28.512 33.356 27.552 34.22 27.456L36.812 27.072C37.1 26.976 37.388 26.784 37.58 26.496L38.732 24.096C38.828 24 38.924 23.808 39.02 23.808C39.404 23.52 39.884 23.52 40.268 23.808C40.364 23.904 40.46 24 40.556 24.096L41.708 26.496C41.804 26.784 42.092 26.976 42.476 27.072L45.068 27.456C45.644 27.648 45.932 28.608 45.452 29.184Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M45.452 29.184L43.916 30.816L43.532 31.2C43.34 31.392 43.244 31.68 43.244 32.064L43.724 34.848C43.82 35.616 42.956 36.288 42.284 35.904L39.98 34.56C39.692 34.368 39.308 34.368 39.02 34.56L36.812 35.808C36.14 36.192 35.276 35.616 35.372 34.752L35.852 31.968C35.948 31.68 35.756 31.392 35.564 31.104L35.18 30.72L33.644 29.088C33.068 28.512 33.356 27.552 34.22 27.456L36.812 27.072C37.1 26.976 37.388 26.784 37.58 26.496L38.732 24.096C38.828 24 38.924 23.808 39.02 23.808C39.404 23.52 39.884 23.52 40.268 23.808C40.364 23.904 40.46 24 40.556 24.096L41.708 26.496C41.804 26.784 42.092 26.976 42.476 27.072L45.068 27.456C45.644 27.648 45.932 28.608 45.452 29.184Z"
          stroke="black"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </g>
    </svg>
  );
};

export default IconReputacion;
