import React from 'react';
import moment from 'moment';
import { Typography, colors, Button } from '@cencosud-ds/bigbang';
import { LOCK, NEW } from 'variables/nivele';
import t from 'utils/translate';
import Progress from '../Progress';
import Popover from '../Popover';
import TooltipR from '../Tooltip';

import { PropsSatelite, dataTextNivel } from './interface';
import { calculatePorcentage, formatFecha } from './utils';

import {
  Container,
  ContentSeller,
  MeasuringSeller,
  ContentDataSeller,
  ContentNameNivelSeller,
  ContentNivelSeller,
  MeasuringPorcentajesReputacion,
  MeasuringPorcentajes,
  MeasuringPorcentajesItems,
} from './style';

const textType = {
  Bajo: t(`reputation_components_satelite_seller_nivel_low_text`),
  Nuevo: t(`reputation_components_satelite_seller_nivel_new_text`),
  Medio: t(`reputation_components_satelite_seller_nivel_half_text`),
  Bueno: t(`reputation_components_satelite_seller_nivel_good_text`),
  Destacado: t(`reputation_components_satelite_seller_nivel_outstanding_text`),
};

export default function Satelite(props: PropsSatelite): JSX.Element {
  moment.locale('es');
  const dateSatelite = new Date();
  const startDatetNotFormat = new Date(dateSatelite.getFullYear(), dateSatelite.getMonth() - 1, 1);
  const endDateNotFormat = new Date(dateSatelite.getFullYear(), dateSatelite.getMonth(), 0);

  const rangeByDateReputation = () => {
    let startDate = formatFecha(startDatetNotFormat);
    const endDate = formatFecha(endDateNotFormat);
    if (props.nivel === LOCK) {
      return `00/00/0000 - 00/00/0000`;
    }
    if (props.nivel === NEW) {
      startDate = formatFecha(new Date(dateSatelite.getFullYear(), dateSatelite.getMonth() - 2, 1));
      return `${startDate} - ${endDate}`;
    }
    return `${startDate} - ${endDate}`;
  };

  const measuringByDateReputation = () => {
    let startDateNew = moment(startDatetNotFormat).format('MMMM');
    if (props.nivel === LOCK) {
      return `${dataTextNivel[props.nivel].textMeasuringPorcentajes}`;
    }
    if (props.nivel === NEW) {
      startDateNew = moment(new Date(dateSatelite.getFullYear(), dateSatelite.getMonth() - 2, 1)).format('MMMM');
      const endDateNew = moment(endDateNotFormat).format('MMMM');
      return `${dataTextNivel[props.nivel].textMeasuringPorcentajes} ${t(startDateNew)} ${t('and')} ${t(endDateNew)}`;
    }
    return `${dataTextNivel[props.nivel].textMeasuringPorcentajes} ${t(startDateNew)}`;
  };

  return (
    <Container>
      <ContentSeller>
        <Progress name={props.nivel} size="m" type={props.nivel} />
        <ContentDataSeller>
          <ContentNameNivelSeller>
            <Typography typeElement="subtitle1" weight="bold" color={colors.primary[500]}>
              {props.name}
            </Typography>
            <ContentNivelSeller type={props.nivel}>
              <Typography typeElement="body3" weight="semiBold" color={colors.neutral[400]}>
                {![LOCK].includes(props.nivel) && <span>{`${textType[props.nivel]} | `}</span>}
                {dataTextNivel[props.nivel].messageNivel}
              </Typography>
              <Popover />
            </ContentNivelSeller>
            <Button
              type="button"
              type-button="primary"
              variant-button="xs"
              children={props.buttonNivel}
              onClick={props.onClick}
            />
          </ContentNameNivelSeller>
        </ContentDataSeller>
      </ContentSeller>
      <MeasuringSeller>
        <MeasuringPorcentajesReputacion>
          <Typography typeElement="body1" weight="bold" color={colors.neutral[400]}>
            {`${measuringByDateReputation()} `}
          </Typography>
          <Typography typeElement="body1" weight="regular" color={colors.neutral[400]}>
            {rangeByDateReputation(props.nivel)}
          </Typography>
        </MeasuringPorcentajesReputacion>
        <MeasuringPorcentajes>
          <MeasuringPorcentajesItems>
            <Typography typeElement="body2" weight="semiBold" color={colors.primary[500]}>
              {t(`reputation_components_satelite_deliveries`)}
              &nbsp;
              <span>{`${calculatePorcentage(parseFloat(props.deliveries))}%`}</span>
            </Typography>
            <TooltipR text={t('reputation_components_tooltip_deliveries')} link={false} isLastItem={false} />
          </MeasuringPorcentajesItems>
          <MeasuringPorcentajesItems>
            <Typography typeElement="body2" weight="semiBold" color={colors.primary[500]}>
              {t(`reputation_components_satelite_claims`)}
              &nbsp;
              <span>{`${calculatePorcentage(parseFloat(props.claims))}%`}</span>
            </Typography>
            <TooltipR text={t('reputation_components_tooltip_claims')} link={true} isLastItem={false} />
          </MeasuringPorcentajesItems>
          <MeasuringPorcentajesItems>
            <Typography typeElement="body2" weight="semiBold" color={colors.primary[500]}>
              {t(`reputation_components_satelite_canceled_by_you`)}
              &nbsp;
              {<span>{`${calculatePorcentage(parseFloat(props.cancel))}%`}</span>}
            </Typography>

            <TooltipR text={t('reputation_components_tooltip_canceled')} link={false} isLastItem={true} />
          </MeasuringPorcentajesItems>
        </MeasuringPorcentajes>
      </MeasuringSeller>
    </Container>
  );
}
