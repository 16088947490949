import React from 'react';
import { Typography, Button } from '@cencosud-ds/bigbang';
import t from '../../../../../../utils/translate';
import './style.scss';

export function Footer() {
  return (
    <div className="container-upper-footer">
      <div className="container-footer">
        <div className="footer-first-typography">
          <Typography color="#166DC2" propsElement={{}} typeElement="subtitle2" weight="bold">
            {t('saveTimeLearn')}
          </Typography>
        </div>
        <div className="footer-second-typography">
          <Typography color="#323A44" propsElement={{}} typeElement="body3" weight="regular">
            {t('reviewingRequest')} <span className="text-days">{t('reviewingRequestComplement')}</span>
          </Typography>
          <br />
          <Typography color="#323A44" propsElement={{}} typeElement="body3" weight="regular">
            {t('reviewingRequestComplement1')}{' '}
            <a
              style={{ textDecoration: 'underline', fontWeight: 'bold' }}
              href="https://www.youtube.com/@parismarketplace"
              rel="noreferrer"
              target="_blank"
            >
              {t('reviewingRequestComplement2')}
            </a>
            {t('reviewingRequestComplement3')}
          </Typography>
        </div>
        <div className="footer-buttons">
          <a
            className="footer-first-button"
            href="https://ayuda.marketplace.paris.cl/capacitaciones-frecuentes/"
            target="_blank"
            rel="noreferrer"
          >
            <Button type="submit" type-button="secondary" variant-button="m" width="100%">
              {t('onlineTraining')}
            </Button>
          </a>
          <a
            className="footer-second-button"
            href="https://ayuda.marketplace.paris.cl/2021/03/29/guia-rapida/"
            target="_blank"
            rel="noreferrer"
          >
            <Button type="submit" type-button="primary" variant-button="m" width="100%">
              {t('reviewQuickGuide')}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
