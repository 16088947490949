import * as React from 'react';

function SvgNivelLow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_607_8759)">
        <circle cx="36" cy="36" r="35.5" stroke="#B8DCFF" />
        <circle cx="36" cy="36" r="24" fill="white" />
        <mask id="path-3-inside-1_607_8759" fill="white">
          <path d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z" />
        </mask>
        <path
          d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-3-inside-1_607_8759)"
        />
        <mask id="path-4-inside-2_607_8759" fill="white">
          <path d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z" />
        </mask>
        <path
          d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-4-inside-2_607_8759)"
        />
        <mask id="path-5-inside-3_607_8759" fill="white">
          <path d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z" />
        </mask>
        <path
          d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-5-inside-3_607_8759)"
        />
        <mask id="path-6-inside-4_607_8759" fill="white">
          <path d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z" />
        </mask>
        <path
          d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z"
          stroke="#E54717"
          stroke-width="8"
          mask="url(#path-6-inside-4_607_8759)"
        />
        <path
          d="M42.5795 17.675L39.8371 23.1447L39.2377 24.3305L35.8209 16.5336C36.0307 16.2075 36.4054 16 36.81 16H41.5305C42.3997 16 42.9691 16.9042 42.5795 17.675Z"
          fill="#F7E6E1"
        />
        <path
          d="M42.6844 28.4664V25.9761C42.6844 23.6934 40.7962 21.8257 38.4884 21.8257H33.3033C30.9955 21.8257 29.1073 23.6934 29.1073 25.9761V28.4664C24.2969 30.9122 21 35.8778 21 41.5995C21 49.7373 27.6687 56.3335 35.8958 56.3335C44.123 56.3335 50.7917 49.7373 50.7917 41.5995C50.7917 35.863 47.4948 30.8973 42.6844 28.4664ZM39.9869 27.4139C38.6832 27.0434 37.3195 26.8507 35.8958 26.8507C34.4722 26.8507 33.1085 27.0434 31.8047 27.4139V25.9761C31.8047 25.1609 32.4791 24.4938 33.3033 24.4938H38.4884C39.0578 24.4938 39.5524 24.8051 39.8071 25.2646L39.8221 25.2794C39.927 25.487 39.9869 25.7241 39.9869 25.9613V27.4139Z"
          fill="#F7E6E1"
        />
        <path
          d="M35.896 54.8955C28.4781 54.8955 22.4388 48.9218 22.4388 41.5845C22.4388 34.2471 28.4781 28.2734 35.896 28.2734C43.314 28.2734 49.3532 34.2471 49.3532 41.5845C49.3383 48.9218 43.314 54.8955 35.896 54.8955ZM35.896 29.311C29.0625 29.311 23.4878 34.8104 23.4878 41.5845C23.4878 48.3585 29.0476 53.8579 35.896 53.8579C42.7295 53.8579 48.3042 48.3585 48.3042 41.5845C48.3042 34.8104 42.7295 29.311 35.896 29.311Z"
          fill="white"
        />
        <path
          d="M37.6042 25.9462H33.1834C32.7338 25.9462 32.3292 25.6942 32.1344 25.3089L31.7448 24.5232L28.373 17.8381C27.9833 17.0673 28.5528 16.1631 29.422 16.1631H34.1425C34.607 16.1631 35.0266 16.4299 35.2065 16.8449L38.6832 24.3009C38.7131 24.375 38.7431 24.4491 38.7581 24.5232C38.9229 25.2347 38.3834 25.9462 37.6042 25.9462Z"
          fill="#F7E6E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_8759">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNivelLow;
