import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import bootstrap from './single-spa/bootstrap';
import './index.css';
import './assets/scss/material-dashboard-pro-react.scss?v=1.5.0';
import Root from './root';
import * as serviceWorker from './serviceWorker';
import { GTM_ID, LOAD_SPA_MESSAGE_EVENT } from './config';
import '@cencosud-ds/bigbang/css/main.css';

const tagManagerArgs = {
  gtmId: GTM_ID,
};

if (tagManagerArgs.gtmId) {
  TagManager.initialize(tagManagerArgs);
}

window.addEventListener(LOAD_SPA_MESSAGE_EVENT, async event => {
  if (window.env.LOGS) {
    console.log('bootstrap spa 1');
  }

  bootstrap();
});

ReactDOM.render(<Root />, document.getElementById('spa'));
serviceWorker.unregister();
