import React, { useState } from 'react';
import './index.css';
import { Icon, ToggleButton, Tooltip } from '@cencosud-ds/bigbang';
import TablePopover from './components/TablePopover';

const PopoverReputation: React.FC<{}> = props => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <>
        <Tooltip TooltipComponent={<TablePopover />} isOpen={open} position="right" version="v2">
          <ToggleButton style={{ zIndex: 1090 }} onClick={() => setOpen(!open)} icon={<Icon name="Info" />} size="s" />
        </Tooltip>
      </>
    </div>
  );
};

export default PopoverReputation;
