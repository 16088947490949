const sizes = {
  xs: '0px', // mobile
  sm: '360px', // mobile
  md: '768px', // tablet + desktop
  lg: '992px', // tablet + desktop
  xl: '1440px', // desktop
};
const breakpoints = {
  xs: `(min-width: ${sizes.xs})`, // mobile
  sm: `(min-width: ${sizes.sm})`, // mobile
  md: `(min-width: ${sizes.md})`, // tablet + desktop
  lg: `(min-width: ${sizes.lg})`, // tablet + desktop
  xl: `(min-width: ${sizes.xl})`, // desktop
};
export default breakpoints;
export { sizes };
