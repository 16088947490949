import React, { useState, useEffect } from 'react';
import { Input, Dropdown, Typography, colors } from '@cencosud-ds/bigbang';
import * as RUTJS from 'rut.js';
import { Society } from 'utils/signup';
import { FormSection, ToolTip, GlobalSearch } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { allowAlphanumericAndDots, allowedTextForBusinessName, allownumberAndKV20 } from 'utils/patterns';
import { IModalContenidoSellerMulticuenta, ModalContenidoSellerMulticuenta } from './ModalContenidoSellerMulticuenta';
import * as sellerServices from 'api/sellers';
import '../index.css';
export const CompanyInfo: React.FC<{ formik: any; inputsFocused: boolean }> = props => {
  const [businessNameExists, setBusinessNameExists] = useState<boolean>(false);
  const { formik, inputsFocused } = props;

  const [modalSellerMulticuenta, setModalSellerMulticuenta] = useState<IModalContenidoSellerMulticuenta>({
    open: false,
    sellerLegalName: '-',
    sellerDocumentNumber: '-',
  });

  const handleBlurDocumentNumber = async (documentNumber: string) => {
    if (documentNumber && RUTJS.validate(documentNumber)) {
      const getSellerByDocumentNumber = await sellerServices.getSellerByDocumentNumber(RUTJS.clean(documentNumber));

      if (getSellerByDocumentNumber && getSellerByDocumentNumber.data.statusCode === 'SELLER_NOT_EXISTS') {
        await setModalSellerMulticuenta({
          open: true,
          cases: 'document_no_exists',
          sellerLegalName: '',
          sellerDocumentNumber: RUTJS.format(documentNumber),
        });
      } else if (getSellerByDocumentNumber) {
        await setModalSellerMulticuenta({
          open: true,
          cases: 'document_exists',
          sellerLegalName: getSellerByDocumentNumber.data.legalName,
          sellerDocumentNumber: getSellerByDocumentNumber.data.documentNumber,
        });
      }
    }
  };

  const getMessageTooltip = (message: string) => {
    return (
      <>
        <span className="font-message">{t(message)}</span>
      </>
    );
  };

  const handleOnBlurBusinessName = async (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.value;
    if (name) {
      const hasError = formik.errors.businessName ? true : false;
      if (!hasError) {
        const existsSeller = await sellerServices.getSellerByName(name);

        if (existsSeller) {
          setBusinessNameExists(true);
        }
      }
    }
    formik.handleBlur(event);
  };

  const handleOnChangeBusinessName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBusinessNameExists(false);
    formik.handleChange(e);
  };

  const buildErrorStyle = (field?: string): string => {
    const defaultFieldErrorStyle = 'error-field';
    if (field && formik.touched[field] && formik.errors[field]) {
      if (formik.errors[field] === t('sign_up_company_invalid_company_name')) {
        return `${defaultFieldErrorStyle} company-name-format`;
      }
      if (formik.errors[field] === t('sign_up_company_invalid_business_name')) {
        return `${defaultFieldErrorStyle} business-name-format`;
      }
      return defaultFieldErrorStyle;
    }
    return '';
  };

  useEffect(() => {
    if (formik.values.societyType) {
      formik.setFieldTouched('societyType', true);
    }
  }, [formik.values.societyType]);

  const composeFieldStyle = (styleName: string, fieldName?: string): string => {
    return `${styleName} ${buildErrorStyle(fieldName)}`;
  };

  return (
    <>
      <FormSection
        label={t('sign_up_company_information')}
        description={
          <div className="content-description">
            <div className="text-description">
              <span>{t('sign_up_company_information_descriptio1')}</span>
            </div>
            <div className="list-description">
              <ul>
                <li itemType="circle">{t('sign_up_item1_company_information_description')}</li>
                <li itemType="circle">
                  {t('sign_up_item5_company_information_description')}
                  <a rel="noreferrer" href="https://zeus.sii.cl/cvc/stc/stc.html" target={'_blank'}>
                    https://zeus.sii.cl/cvc/stc/stc.html
                  </a>
                </li>
                <li itemType="circle">{t('sign_up_item2_company_information_description')}</li>
                <li itemType="circle">{t('sign_up_item3_company_information_description')}</li>
                <li itemType="circle">{t('sign_up_item4_company_information_description')}</li>
              </ul>
            </div>
            <div className="text-description-p">
              <span>{t('sign_up_company_information_descriptio2')}</span>
            </div>
          </div>
        }
      >
        <div className="row mb-8">
          <div className="col-6">
            <div className={composeFieldStyle('label-company-name', 'companyName')}>
              <Input
                name="companyName"
                feedback-text={formik.touched.companyName && formik.errors.companyName ? formik.errors.companyName : ''}
                icon-end={null}
                input-state={formik.touched.companyName && formik.errors.companyName ? 'error' : 'default'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                width="100%"
                maxLength={150}
                label-text={t('sign_up_company_name')}
                placeholder={t('sign_up_company_name')}
                input-focused={inputsFocused}
                value={formik.values.companyName ? formik.values.companyName : ''}
                onKeyPress={event => {
                  if (!allowAlphanumericAndDots.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAlphanumericAndDots.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              <ToolTip msg={getMessageTooltip('sign_up_tooltip_company_name')} position="right" />
            </div>
          </div>
          <div className="col-6">
            <div className={composeFieldStyle('label-business-name', 'businessName')}>
              <Input
                name="businessName"
                feedback-text={
                  formik.touched.businessName && formik.errors.businessName ? formik.errors.businessName : ''
                }
                icon-end={null}
                input-state={
                  (formik.touched.businessName && formik.errors.businessName) || businessNameExists
                    ? 'error'
                    : 'default'
                }
                onChange={handleOnChangeBusinessName}
                onBlur={handleOnBlurBusinessName}
                width="100%"
                maxLength={150}
                label-text={t('sign_up_business_name')}
                placeholder={t('sign_up_business_name')}
                input-focused={inputsFocused}
                value={formik.values.businessName ? formik.values.businessName : ''}
                onKeyPress={event => {
                  if (!allowedTextForBusinessName.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowedTextForBusinessName.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              {businessNameExists && (
                <div className="mt-1">
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#e54717"
                    children={
                      <>
                        {t('businessNameExistsError')}
                        <a className="onboardingEmail" href={`mailto:${t('onboardingEmail')}`}>
                          {t('onboardingEmail')}
                        </a>
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <div className="label-rut">
              <ModalContenidoSellerMulticuenta
                open={modalSellerMulticuenta.open}
                cases={modalSellerMulticuenta.cases}
                modalSellerMulticuenta={modalSellerMulticuenta}
                setModalSellerMulticuenta={setModalSellerMulticuenta}
                sellerLegalName={modalSellerMulticuenta.sellerLegalName}
                sellerDocumentNumber={modalSellerMulticuenta.sellerDocumentNumber}
              />

              <Input
                name="rutNumber"
                feedback-text={formik.touched.rutNumber && formik.errors.rutNumber ? formik.errors.rutNumber : ''}
                icon-end={null}
                input-state={formik.touched.rutNumber && formik.errors.rutNumber ? 'error' : 'default'}
                onChange={event => {
                  let value = event.target.value;
                  if (value.length > 7) {
                    value = RUTJS.format(value);
                    if (RUTJS.validate(value)) {
                      handleBlurDocumentNumber(value);
                    }
                  }
                  return formik.setFieldValue('rutNumber', value);
                }}
                onBlur={formik.handleBlur}
                width="100%"
                maxLength={12}
                label-text={`${t('sign_up_tax_ID_number')} (RUT)`}
                placeholder={`${t('sign_up_tax_ID_number')} (RUT)`}
                input-focused={inputsFocused}
                value={formik.values.rutNumber ? formik.values.rutNumber : ''}
                onKeyUp={() => {
                  if (formik.values.rutNumber && !allownumberAndKV20.test(formik.values.rutNumber)) {
                    formik.setFieldValue('rutNumber', formik.values.rutNumber.slice(0, -1).toUpperCase());
                  }
                }}
              />
              <ToolTip msg={getMessageTooltip('sign_up_tooltip_company_rut')} position="right" />
            </div>
          </div>
          <div className="col-6">
            <Dropdown
              dropdown-options={Society}
              variant={formik.touched.societyType && formik.errors.societyType ? 'error' : 'default'}
              text-feedback={formik.touched.societyType && formik.errors.societyType ? formik.errors.societyType : ''}
              disabled={false}
              width="100%"
              label-text={t('sign_up_choose_company_type')}
              placeholder={t('sign_up_choose_company_type')}
              onChange={value => formik.setFieldValue('societyType', value)}
              default-value={formik.values.societyType ? formik.values.societyType : ''}
              sortOptions={true}
            />
          </div>
        </div>
        <div className="row mb-12 rows-margin-top">
          <div className="col-12">
            <div className="label-tooltip">
              <Typography color={colors.neutral[400]} propsElement={{}} typeElement="body1" weight="semiBold">
                {t('sign_up_activity_code')}
              </Typography>
            </div>
            <div className="label-activity-code">
              <GlobalSearch
                formik={formik}
                feedbackText={
                  formik.touched.codeUploadFile && formik.errors.codeUploadFile ? formik.errors.codeUploadFile : ''
                }
                inputState={formik.touched.codeUploadFile && formik.errors.codeUploadFile ? 'error' : 'default'}
              />
              <ToolTip
                msg={
                  <>
                    {getMessageTooltip('sign_up_tooltip_company_code_ramo')} <b>Servicio de Impuestos Internos (SII)</b>
                    .
                  </>
                }
                position="left"
              />
            </div>
          </div>
        </div>
      </FormSection>
    </>
  );
};
