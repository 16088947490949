import React, { useContext, useEffect, useState } from 'react';
import SessionContext from 'api/session/context';
import { Satelite } from 'components/Reputation';

import { getSellerReputacionHome, ISatelite } from 'api/reputation';

export default function ReputacionSatelite({ onClick, sellerName }: { onClick: () => void; sellerName: string }) {
  const [sateliteData, setSateliteData] = useState<ISatelite>({
    categoriaReputacion: 'Bloqueo',
    cancelQuiebre: '00',
    porcentajeReclamos: '00',
    entregasRetraso: '0,00',
  });
  const {
    auth: { jwt, sellerId },
  } = useContext(SessionContext);

  const fetchReportHome = async () => {
    try {
      const data = await getSellerReputacionHome(jwt, sellerId);
      setSateliteData(data);
    } catch (error) {
      console.log('Ocurrio un error al cargar reporte en Home', error);
    }
  };

  useEffect(() => {
    fetchReportHome();
  }, []);

  return (
    <>
      <div style={{ margin: 40 }}>
        <Satelite
          name={sellerName}
          nivel={
            sateliteData.categoriaReputacion === 'Plan Growth' ? 'Nuevo' : sateliteData.categoriaReputacion || 'Bloqueo'
          }
          buttonNivel="Mi reputación"
          cancel={sateliteData.cancelQuiebre}
          claims={sateliteData.porcentajeReclamos}
          deliveries={sateliteData.entregasRetraso}
          endDate={'25/02/2023'}
          startDate={'25/02/2023'}
          onClick={() => onClick()}
        />
      </div>
    </>
  );
}
