import t from 'utils/translate';
export type typeType = 'Nuevo' | 'Bloqueo' | 'Bajo' | 'Medio' | 'Bueno' | 'Destacado';

export interface PropsSatelite {
  nivel: typeType;
  name: string;
  buttonNivel: string;
  deliveries: string;
  claims: string;
  cancel: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const dataTextNivel = {
  Nuevo: {
    messageNivel: t('reputation_components_satelite_seller_nivel_new'),
    textMeasuringPorcentajes: t('reputation_components_satelite_seller_nivel_new_text_measuring_porcentajes'),
  },
  Bloqueo: {
    messageNivel: t('reputation_components_satelite_seller_nivel_block'),
    textMeasuringPorcentajes: t('reputation_components_satelite_seller_nivel_block_text_measuring_porcentajes'),
  },
  Bajo: {
    messageNivel: t('reputation_components_satelite_seller_nivel_low'),
    textMeasuringPorcentajes: t('reputation_components_satelite_seller_nivel_low_text_measuring_porcentajes'),
  },
  Medio: {
    messageNivel: t('reputation_components_satelite_seller_nivel_half'),
    textMeasuringPorcentajes: t('reputation_components_satelite_seller_nivel_half_text_measuring_porcentajes'),
  },
  Bueno: {
    messageNivel: t('reputation_components_satelite_seller_nivel_well'),
    textMeasuringPorcentajes: t('reputation_components_satelite_seller_nivel_well_text_measuring_porcentajes'),
  },
  Destacado: {
    messageNivel: t('reputation_components_satelite_seller_nivel_outstanding'),
    textMeasuringPorcentajes: t('reputation_components_satelite_seller_nivel_outstanding_text_measuring_porcentajes'),
  },
};
