import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, colors } from '@cencosud-ds/bigbang';
import { Wizard } from './components';
import { WizardProvider } from './context';
import { LogisticStep, BankStep, LegalStep, ContactStep, CompanyStep, ConfirmationStep } from './steps';
import t from '../../../utils/translate';
import Head from '../../../components/HeadSignUp/HeadSignUp';
import Footer from '../../../components/FooterSignup/FooterSignup';
import Sending from './components/sending';
import Fail from './components/fail';
import Success from './components/success';
import { IWizardForm } from './interfaces/wizard-form.interface';
import { IGoogleForm } from './interfaces/google-form-interface';
import './index.css';
import { ICompanyStep } from './interfaces/forms/company.interface';
import { IContactStep } from './interfaces/forms/contact.interface';
import { ILegalStep } from './interfaces/forms/legal.interface';
import { IBankStep } from './interfaces/forms/bank.interface';
import { ILogisticStep } from './interfaces/forms/logistic.interface';
import getAxios from 'clients';
import { SELLERS_URL, API_KEY_WEBHOOK_FORM } from 'config';
import { PERSON_NATURAL } from 'variables/society';
import * as RUTJS from 'rut.js';
import { trackGTMPageViewCuston, trackGTMEventActionCuston } from 'utils/analytics';
import { normalizeCompanyName } from 'utils/signup';
/* eslint-disable */
enum wizardSteps {
  Collect,
  Sending,
  Success,
  Fail,
}
/* eslint-enable */

const SignUp: React.FC<{}> = () => {
  const [sending, setSending] = useState(wizardSteps.Collect);
  const defaultData: IWizardForm[] = [];
  const [data, setData] = useState(defaultData);
  const [comertialAreaEmail, setComertialAreaEmail] = useState<string>('');
  const history = useHistory();

  const [documentNumber, setDocumentNumber] = useState<string>('');

  useEffect(() => {
    trackGTMPageViewCuston({
      page: '/registrate-ahora',
      title: 'Empieza a vender en Paris Marketplace',
    });
  }, []);

  const handleOnFinish = async (forms: IWizardForm[]) => {
    setSending(wizardSteps.Sending);

    const googleFormData = await googleFormMapping(forms);
    const result = await handleSendDataForm(googleFormData);

    if (result && result.status === 201) {
      setComertialAreaEmail(forms[1].data.comertialAreaEmail);
      setDocumentNumber(forms[0].data.rutNumber);
      setSending(wizardSteps.Success);
    } else {
      setData(forms);
      setSending(wizardSteps.Fail);
    }
  };

  const handleSendDataForm = async (form: IGoogleForm) => {
    try {
      const resp = await getAxios
        .instance(`${API_KEY_WEBHOOK_FORM}`)
        .post(`${SELLERS_URL}/web-hooks/register-form`, form, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      return resp;
    } catch (error) {
      console.log('handleSendDataForm', error);
      const message = error.message ? error.message : JSON.stringify(error);

      trackGTMEventActionCuston({
        category: 'Formulario de registro seller MKP',
        action: 'Respuesta errores',
        tag: message,
      });
    }
  };
  // TODO: revisar nuevas implementaciones
  const googleFormMapping = async (forms: IWizardForm[]) => {
    const stepCompany: ICompanyStep = forms[0].data;
    const stepContact: IContactStep = forms[1].data;
    const stepLegal: ILegalStep = forms[2].data;
    const stepBank: IBankStep = forms[3].data;
    const stepLogistic: ILogisticStep = forms[4].data;

    let nameFirstPartner = '';
    let nameSecondPartner = '';
    let nameRepresentative = '';

    if (stepLegal.societyTypePartherOne === PERSON_NATURAL) {
      nameFirstPartner = stepLegal.firstPartherOne;
    } else {
      nameFirstPartner = stepLegal.namePartherOne;
    }
    if (stepLegal.societyTypePartherTwo === PERSON_NATURAL) {
      nameSecondPartner = stepLegal.firstPartherTwo;
    } else {
      nameSecondPartner = stepLegal.namePartherTwo;
    }
    if (stepLegal.societyTypeRepresentative === PERSON_NATURAL) {
      nameRepresentative = stepLegal.firstPartherRepresentative;
    } else {
      nameRepresentative = stepLegal.nameRepresentative;
    }

    const companyNameNormalized = normalizeCompanyName(stepCompany.companyName);

    const businessNameNormalized = stepCompany.businessName ? stepCompany.businessName.trim() : '';

    const googleFormData: IGoogleForm = {
      payload: [
        {
          question: '5.2 Socio',
          response: stepLegal.showPartherTwo,
        },
        {
          question: '1.7 Comuna',
          response: stepCompany.companyHeadAddressCommunes,
        },
        {
          question: '4.7 Comuna',
          response: stepLegal.communePartherOne,
        },
        {
          question: '5.8 Comuna',
          response: stepLegal.communeRepresentative,
        },
        {
          question: '6.3 Comuna',
          response: stepLogistic.communeWarehouse,
        },
        {
          question: '1.6 Región',
          response: stepCompany.companyHeadAddressRegion,
        },
        {
          question: '4.14 Comuna',
          response: stepLegal.communePartherTwo,
        },
        {
          question: '4.6 Región',
          response: stepLegal.regionPartherOne,
        },
        {
          question: '5.7 Región',
          response: stepLegal.regionRepresentative,
        },
        {
          question: '6.2 Región',
          response: stepLogistic.regionWarehouse,
        },
        {
          question: '4.13 Región',
          response: stepLegal.regionPartherTwo,
        },
        {
          question: '4.5 Dirección ',
          response: stepLegal.addressPartherOne,
        },
        {
          question: '5.6 Dirección ',
          response: stepLegal.addressRepresentative,
        },
        {
          question: '4.12 Dirección ',
          response: stepLegal.addressPartherTwo,
        },
        {
          question: '4.4 Nacionalidad',
          response: stepLegal.nationalityPartherOne,
        },
        {
          question: '5.5 Nacionalidad',
          response: stepLegal.nationalityRepresentative,
        },
        {
          question: '4.11 Nacionalidad',
          response: stepLegal.nationalityPartherTwo,
        },
        {
          question: '1.8 Código Postal',
          response: stepCompany.companyHeadAddressRegionsCodePostal,
        },
        {
          question: '3.2 Tipo de cuenta',
          response: stepBank.bankType,
        },
        {
          question: '1.1 Nombre Comercial',
          response: businessNameNormalized,
        },
        {
          question: '1.4 Tipo de sociedad',
          response: stepCompany.societyType,
        },
        {
          question: '3.1 Nombre del banco',
          response: stepBank.bankChooseBank,
        },
        {
          question: '4.1 Tipo de Sociedad',
          response: stepLegal.societyTypePartherOne,
        },
        {
          question: '4.8 Tipo de Sociedad',
          response: stepLegal.societyTypePartherTwo,
        },
        {
          question: '5.1 Tipo de Sociedad',
          response: stepLegal.societyTypeRepresentative,
        },
        {
          question: '3.3 Número de cuenta',
          response: stepBank.accountNumber,
        },
        {
          question: '6.1 Dirección Bodega',
          response: stepLogistic.addressWarehouse,
        },
        {
          question: '2.8 Cargo área finanzas',
          response: stepContact.financeAreaCharge,
        },
        {
          question: '2.3 Cargo área comercial',
          response: stepContact.comertialAreaCharge,
        },
        {
          question: '1.5 Dirección de la empresa',
          response: stepCompany.companyHeadAddress,
        },
        {
          question: '2.9 Teléfono área finanzas',
          response: stepContact.financeAreaPhone,
        },
        {
          question: '1.13 Categorías de productos',
          response: stepCompany.categoriesSellers,
        },
        {
          question: '2.4 Teléfono área comercial',
          response: stepContact.comertialAreaPhone,
        },
        {
          question: '1.2 Razón social de la empresa',
          response: companyNameNormalized,
        },
        {
          question: '2.20 ¿Poseen servicio técnico?',
          response: stepLogistic.technicalService,
        },
        {
          question: '2.14 Correo área bodega/ despacho',
          response: stepLogistic.emailWarehouse,
        },
        {
          question: '4.2 Razón social o Nombre completo',
          response: nameFirstPartner,
        },
        {
          question: '4.2.1 Apellido primer socio',
          response: stepLegal.secondNamePartherOne,
        },
        {
          question: '4.9 Razón social o Nombre completo',
          response: nameSecondPartner,
        },
        {
          question: '4.9.1 Apellido segundo socio',
          response: stepLegal.secondNamePartherTwo,
        },
        {
          question: '5.3 Razón social / Nombre completo',
          response: nameRepresentative,
        },
        {
          question: '5.3.1 Apellido representante legal',
          response: stepLegal.secondNamePartherRepresentative,
        },
        {
          question: '2.12 Cargo área de bodega/ despachos',
          response: stepLogistic.rolWarehouse,
        },
        {
          question: '2.13 Teléfono área bodega/ despacho',
          response: stepLogistic.phoneWarehouse,
        },
        {
          question: '2.18 Correo para gestiones post-venta',
          response: stepContact.postSellEmail,
        },
        {
          question: '2.10 Correo electrónico área finanzas',
          response: stepContact.financeAreaEmail,
        },
        {
          question: '2.5 Correo electrónico área comercial',
          response: stepContact.comertialAreaEmail,
        },
        {
          question: '2.17 Teléfono para gestiones post-venta',
          response: stepContact.postSellPhone,
        },
        {
          question: '1.11 Modalidad para la carga de productos',
          response: stepCompany.productMode,
        },
        {
          question: '2.19 E-mail para notificaciones de ventas',
          response: stepContact.notificationInfoEmail,
        },
        {
          question: '2.16 Cargo área para gestiones post-venta',
          response: stepContact.postSellCharge,
        },
        {
          question: '2.7 Nombre contacto en el área de finanzas',
          response: stepContact.financeAreaName,
        },
        {
          question: '2.7.1 Apellido contacto en el área de finanzas',
          response: stepContact.financeAreaSecondName,
        },
        {
          question: '1.12 Número aproximado de skus a incorporar',
          response: stepCompany.nProducts,
        },
        {
          question: '2.1 Nombre contacto en el área de comercial',
          response: stepContact.comertialAreaName,
        },
        {
          question: '1.3 Número de Identificación Fiscal (R.U.T)',
          response: RUTJS.clean(stepCompany.rutNumber),
        },
        {
          question: '5.4 Número de Identificación Fiscal (R.U.T)',
          response: RUTJS.clean(stepLegal.rutNumberRepresentative),
        },
        {
          question: '2.15 Nombre contacto para gestiones post-venta',
          response: stepContact.postSellName,
        },
        {
          question: '2.15.1 Apellido contacto para gestiones post-venta',
          response: stepContact.postSellSecondName,
        },
        {
          question: '2.2 Apellido contacto en el área de comercial',
          response: stepContact.comertialAreaSecondName,
        },
        {
          question: '2.6 Confirmar correo electrónico área comercial',
          response: stepContact.comertialAreaConfirmEmail,
        },
        {
          question: '2.11 Nombre contacto en el área de bodega / despachos',
          response: stepLogistic.nameContact,
        },
        {
          question: '4.3 Número de Identificación Fiscal (R.U.T) del socio',
          response: RUTJS.clean(stepLegal.rutNumberPartherOne),
        },
        {
          question: '4.10 Número de Identificación Fiscal (R.U.T) del socio',
          response: RUTJS.clean(stepLegal.rutNumberPartherTwo),
        },
        {
          question: '1.15 ¿Cuáles son las marcas que quieres trabajar con nosotros?',
          response: stepCompany.sellerWorkUs,
        },
        {
          question: '1.9 Ingresa tu Código de Actividad/Ramo (Solo números, ejemplo: 471990)',
          response: stepCompany.codeUploadFile,
        },
        {
          question: '1.10 Ingresa la categoría de tu Código de Actividad/Ramo',
          response: stepCompany.codeUploadFileLabel,
        },
        {
          question:
            '1.14 Si seleccionaste "Multicategoria", por favor dinos cual es la que posee mayor cantidad de productos.',
          response: stepCompany.oneCategoriesSellers,
        },
      ],
      source: 'form',
    };

    return googleFormData;
  };

  const handleRetry = async () => {
    handleOnFinish(data);
  };

  const isCollecting = () => sending === wizardSteps.Collect;
  const isSending = () => sending === wizardSteps.Sending;
  const isSuccess = () => sending === wizardSteps.Success;
  const isFail = () => sending === wizardSteps.Fail;

  const handleGoToBegin = () => {
    trackGTMEventActionCuston({
      category: 'Formulario de registro seller MKP',
      action: 'Step 6 feedback:  Clic en volver al inicio',
      tag: btoa(documentNumber),
    });

    history.push('/login');
  };

  return (
    <div>
      <Head />
      <div className="App-signup">
        {isCollecting() && (
          <>
            <div className="App-signup_title">
              <Typography
                typeElement="h3"
                weight="bold"
                color={colors.feedback.information.dark}
                children={t('sign_up_main_becomePartOfMarketplaceParis')}
              />
            </div>
            <WizardProvider>
              <Wizard onFinish={handleOnFinish}>
                <CompanyStep
                  position={0}
                  label={t('sign_up_main_company')}
                  description={t('sign_up_main_company_enterData')}
                />
                <ContactStep
                  position={1}
                  label={t('sign_up_main_contact')}
                  description={t('sign_up_main_contact_howToContactThem')}
                />
                <LegalStep
                  position={2}
                  label={t('sign_up_main_legal')}
                  description={t('sign_up_main_legal_dataOfPartnersAndOrOwners')}
                />
                <BankStep
                  position={3}
                  label={t('sign_up_main_bank')}
                  description={t('sign_up_main_bank_whereToRecivePayment')}
                />
                <LogisticStep
                  position={4}
                  label={t('sign_up_main_logistic')}
                  description={t('sign_up_main_logistic_warehouseInformation')}
                />
                <ConfirmationStep
                  position={5}
                  label={t('sign_up_main_confirmation')}
                  description={t('sign_up_main_confirmatioInformation')}
                />
              </Wizard>
            </WizardProvider>
          </>
        )}

        {isSending() && <Sending />}
        {isSuccess() && (
          <Success handleGoToBegin={handleGoToBegin} email={comertialAreaEmail} documentNumber={documentNumber} />
        )}
        {isFail() && <Fail handleRetry={() => handleRetry()} />}
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
