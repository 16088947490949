import React from 'react';
import { typeType } from 'components/Reputation/Satelite/interface';
import { Typography, Notification, colors, Icon } from '@cencosud-ds/bigbang';
import { Accordion } from 'components/Reputation';
import { LOCK, NEW, LOW, MEDIUM, GOOD, OUTSTANDING } from 'variables/nivele';
import Tooltip from './Tooltip';
import BigTooltipTextOnly from './Tooltip/BigTooltipTextOnly';
import t from 'utils/translate';
import './style.css';

export default function MyBenefits({ nivele }: { nivele: typeType | string }) {
  return (
    <div className="benefits">
      <div className="benefits__container--text">
        <Typography color={colors.primary[500]} weight="bold" typeElement="body2">
          {t('reputation_components_drawer_my_benefits')}
        </Typography>
      </div>
      {[LOCK, NEW, LOW, MEDIUM].includes(nivele) && (
        <Notification className="notification reputation__container--notification " extended={true} variant="info">
          <p className="notification-message">
            <span>{t('reputation_components_drawer_my_benefits_notification_message')}</span>
          </p>
        </Notification>
      )}
      <div className="reputation__container--accordion">
        <Accordion
          opacity={[OUTSTANDING].includes(nivele)}
          IconNivel={[GOOD, OUTSTANDING].includes(nivele) ? 'NotFlatBuena' : 'NotFatBloqueo'}
          title={t('reputation_components_satelite_seller_nivel_good_text')}
          id={GOOD}
        >
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_good_lvl')}</p>
          </div>
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_good_lvl2')}</p>
            <Tooltip
              position="left"
              TooltipComponent={
                <BigTooltipTextOnly
                  position="right"
                  with-triangle={true}
                  content={t(
                    'reputation_components_drawer_my_benefits_you_may_be_a_candidate_to_receive_co-financing_in_offers_for_clients',
                  )}
                  showCloseButton={false}
                />
              }
            >
              <Icon name="QuestionMarkCircle" />
            </Tooltip>
          </div>
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_good_lvl3')}</p>
            <Tooltip
              position="left"
              TooltipComponent={
                <BigTooltipTextOnly
                  position="right"
                  with-triangle={true}
                  content={t('reputation_components_drawer_my_benefits_access_to_advertising_campaigns_tooltip')}
                  showCloseButton={false}
                />
              }
            >
              <Icon name="QuestionMarkCircle" />
            </Tooltip>
          </div>
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_good_lvl4')}</p>
            <Tooltip
              position="left"
              TooltipComponent={
                <BigTooltipTextOnly
                  position="right"
                  with-triangle={true}
                  content={t(
                    'reputation_components_drawer_my_benefits_scheduled_dispatch_available_for_your_products_tooltip_outstanding',
                  )}
                  showCloseButton={false}
                />
              }
            >
              <Icon name="QuestionMarkCircle" />
            </Tooltip>
          </div>
        </Accordion>
        {[GOOD].includes(nivele) && (
          <div className="reputation__container--accordion__no--good">
            <Typography color={colors.primary[500]} weight="bold" typeElement="body2">
              {t('reputation_components_drawer_reputation__container_accordion__no_good')}
            </Typography>
          </div>
        )}
        <Accordion
          opacity={false}
          IconNivel={nivele === OUTSTANDING ? 'NotFlatDestacado' : 'NotFatBloqueo'}
          title={t('reputation_components_satelite_seller_nivel_outstanding_text')}
          id={OUTSTANDING}
        >
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_1')}</p>
          </div>
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_2')}</p>
            <Tooltip
              position="left"
              TooltipComponent={
                <BigTooltipTextOnly
                  position="right"
                  with-triangle={true}
                  content={t(
                    'reputation_components_drawer_my_benefits_you_may_be_a_candidate_to_receive_co-financing_in_offers_for_clients',
                  )}
                  showCloseButton={false}
                />
              }
            >
              <Icon name="QuestionMarkCircle" />
            </Tooltip>
          </div>
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_3')}</p>
            <Tooltip
              position="left"
              TooltipComponent={
                <BigTooltipTextOnly
                  position="right"
                  with-triangle={true}
                  content={t('reputation_components_drawer_my_benefits_access_to_advertising_campaigns_tooltip')}
                  showCloseButton={false}
                />
              }
            >
              <Icon name="QuestionMarkCircle" />
            </Tooltip>
          </div>
          <div>
            <Icon name="Checkmark" />
            <p>{t('reputation_components_drawer_my_benefits_outstanding_4')}</p>
            <Tooltip
              position="left"
              TooltipComponent={
                <BigTooltipTextOnly
                  position="right"
                  with-triangle={true}
                  content={t(
                    'reputation_components_drawer_my_benefits_scheduled_dispatch_available_for_your_products_tooltip',
                  )}
                  showCloseButton={false}
                />
              }
            >
              <Icon name="QuestionMarkCircle" />
            </Tooltip>
          </div>
        </Accordion>
      </div>
    </div>
  );
}
