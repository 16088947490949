import * as React from 'react';

function SvgNotFlatNivelTwo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4218 1.912L13.9697 4.856L13.6523 5.496L11.8433 1.288C11.9543 1.112 12.1527 1 12.3669 1H14.8663C15.3265 1 15.6281 1.496 15.4218 1.912Z"
        fill="#0041D6"
      />
      <path
        d="M15.4773 7.72804V6.38404C15.4773 5.15204 14.4776 4.14404 13.2556 4.14404H10.5103C9.28836 4.14404 8.2886 5.15204 8.2886 6.38404V7.72804C5.74161 9.04804 3.996 11.728 3.996 14.816C3.996 19.208 7.52688 22.768 11.883 22.768C16.239 22.768 19.7699 19.208 19.7699 14.816C19.7699 11.72 18.0243 9.04804 15.4773 7.72804ZM14.0491 7.16004C13.3588 6.96004 12.6367 6.85604 11.883 6.85604C11.1292 6.85604 10.4071 6.96004 9.71683 7.16004V6.38404C9.71683 5.94404 10.0739 5.58404 10.5103 5.58404H13.2556C13.5572 5.58404 13.819 5.75204 13.9539 6.00004L13.9618 6.00804C14.0174 6.12004 14.0491 6.24804 14.0491 6.37604V7.16004Z"
        fill="url(#paint0_linear_500_9510)"
      />
      <path
        d="M13.2556 4.14381C14.4775 4.14381 15.4773 5.15181 15.4773 6.38381V7.72781C18.0243 9.04781 19.7699 11.7198 19.7699 14.8078C19.7699 19.1998 16.239 22.7598 11.8829 22.7598C7.52686 22.7598 3.99597 19.1998 3.99597 14.8078C3.99597 11.7198 5.74158 9.03981 8.28858 7.72781V6.38381C8.28858 5.15181 9.28833 4.14381 10.5103 4.14381H13.2556ZM9.7168 7.15981C10.4071 6.95981 11.1292 6.85581 11.8829 6.85581C12.6367 6.85581 13.3588 6.95981 14.0491 7.15981V6.38381C14.0491 5.94381 13.692 5.58381 13.2556 5.58381H10.5103C10.0739 5.58381 9.7168 5.94381 9.7168 6.38381V7.15981ZM13.2556 3.90381H10.5103C9.15344 3.90381 8.05054 5.01581 8.05054 6.38381V7.57581C6.79688 8.25581 5.73364 9.24781 4.97986 10.4718C4.17847 11.7678 3.75 13.2638 3.75 14.8078C3.75 19.3278 7.3999 22.9998 11.875 22.9998C16.358 22.9998 20 19.3198 20 14.8078C20 13.2718 19.5715 11.7758 18.7701 10.4718C18.0164 9.24781 16.9531 8.25581 15.6995 7.57581V6.38381C15.7153 5.01581 14.6124 3.90381 13.2556 3.90381ZM9.95484 6.84781V6.38381C9.95484 6.07181 10.2008 5.82381 10.5103 5.82381H13.2556C13.5651 5.82381 13.811 6.07181 13.811 6.38381V6.84781C13.1842 6.69581 12.5336 6.61581 11.8829 6.61581C11.2323 6.61581 10.5817 6.69581 9.95484 6.84781Z"
        fill="url(#paint1_linear_500_9510)"
      />
      <path
        d="M11.9782 21.9599C15.9002 21.9599 19.0796 18.7148 19.0796 14.7119C19.0796 10.7089 15.9002 7.46387 11.9782 7.46387C8.05616 7.46387 4.87674 10.7089 4.87674 14.7119C4.87674 18.7148 8.05616 21.9599 11.9782 21.9599Z"
        fill="url(#paint2_linear_500_9510)"
      />
      <path
        d="M10.2215 16.1806L10.2215 13.7173C10.2215 13.487 10.4062 13.3008 10.634 13.3014L13.0773 13.3014C13.3057 13.3014 13.4904 13.4876 13.4897 13.7173L13.4897 16.1806C13.4897 16.4109 13.3051 16.5971 13.0773 16.5965H10.634C10.4056 16.5965 10.2209 16.4103 10.2215 16.1806Z"
        fill="#515151"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70603 16.1789L9.70603 13.7169C9.70603 13.2006 10.1206 12.7804 10.6345 12.7813C10.6348 12.7813 10.6351 12.7813 10.6353 12.7813L13.0773 12.7813C13.5893 12.7813 14.0062 13.1993 14.0052 13.7175C14.0052 13.7177 14.0052 13.718 14.0052 13.7183L14.0052 16.1803C14.0052 16.6965 13.5907 17.1168 13.0767 17.1158C13.0764 17.1158 13.0762 17.1158 13.0759 17.1158L10.634 17.1158C10.1219 17.1158 9.70507 16.6979 9.70603 16.1797C9.70603 16.1794 9.70603 16.1791 9.70603 16.1789ZM10.2215 16.1803L10.2215 13.7169C10.2215 13.4866 10.4062 13.3004 10.634 13.301L13.0773 13.301C13.3057 13.301 13.4904 13.4872 13.4897 13.7169L13.4897 16.1803C13.4897 16.4106 13.3051 16.5968 13.0773 16.5961H10.634C10.4056 16.5961 10.2209 16.4099 10.2215 16.1803Z"
        fill="url(#paint3_linear_500_9510)"
      />
      <path
        opacity="0.5"
        d="M11.7639 22.0641C7.88394 22.0641 4.72598 18.8881 4.66251 14.9521C4.66251 14.9921 4.66251 15.0401 4.66251 15.0801C4.66251 19.0801 7.84427 22.3281 11.7639 22.3281C15.6836 22.3281 18.8654 19.0881 18.8654 15.0801C18.8654 15.0401 18.8654 14.9921 18.8654 14.9521C18.8019 18.8881 15.6519 22.0641 11.7639 22.0641Z"
        fill="white"
      />
      <path
        d="M11.8829 21.992C7.95526 21.992 4.75763 18.768 4.75763 14.808C4.75763 10.848 7.95526 7.62402 11.8829 7.62402C15.8105 7.62402 19.0081 10.848 19.0081 14.808C19.0081 18.776 15.8105 21.992 11.8829 21.992ZM11.8829 8.19202C8.26471 8.19202 5.31305 11.16 5.31305 14.816C5.31305 18.472 8.25677 21.44 11.8829 21.44C15.501 21.44 18.4527 18.472 18.4527 14.816C18.4527 11.16 15.501 8.19202 11.8829 8.19202Z"
        fill="url(#paint4_linear_500_9510)"
      />
      <path
        d="M13.954 6.00786C13.8191 5.75986 13.5572 5.59186 13.2557 5.59186H12.3988L11.6767 4.15186H13.1208L13.954 6.00786Z"
        fill="#501717"
      />
      <path
        d="M12.7875 6.3757H10.4469C10.2088 6.3757 9.99458 6.2397 9.89143 6.0317L9.68513 5.6077L7.89985 1.9997C7.69355 1.5837 7.99506 1.0957 8.45527 1.0957H10.9547C11.2006 1.0957 11.4228 1.2397 11.518 1.4637L13.3509 5.5037C13.3668 5.5437 13.3826 5.5837 13.3906 5.6237C13.4858 5.9837 13.2001 6.3757 12.7875 6.3757Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M5.44803 14.7839C5.44803 11.2559 8.28067 8.3999 11.7798 8.3999C15.279 8.3999 18.1116 11.2559 18.1116 14.7839C18.1116 18.3119 15.4932 13.8239 12.002 13.8239C8.50284 13.8159 5.44803 18.3039 5.44803 14.7839Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M14.8346 5.07173C15.1203 5.45573 15.1837 5.89573 14.9774 6.05573C14.7711 6.20773 14.7473 5.74373 14.4617 5.35173C14.1761 4.95973 13.7397 4.80773 13.946 4.64773C14.1523 4.48773 14.549 4.68773 14.8346 5.07173Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M16.0013 11.1369C16.2212 10.7143 15.7231 10.014 14.8889 9.57268C14.0546 9.13134 13.2 9.1161 12.9801 9.53864C12.7602 9.96118 13.2583 10.6615 14.0926 11.1028C14.9269 11.5442 15.7815 11.5594 16.0013 11.1369Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9510"
          x1="6.08525"
          y1="6.62767"
          x2="18.4238"
          y2="17.0897"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.0111016" stop-color="#5B5B5B" />
          <stop offset="0.0992964" stop-color="#AAAAAA" />
          <stop offset="0.1398" stop-color="#C9C9C9" />
          <stop offset="0.2581" stop-color="#F9F9F9" />
          <stop offset="0.3602" stop-color="#C9C9C9" />
          <stop offset="0.4892" stop-color="#505050" />
          <stop offset="0.4991" stop-color="#5B5B5B" />
          <stop offset="0.5771" stop-color="#AAAAAA" />
          <stop offset="0.6129" stop-color="#C9C9C9" />
          <stop offset="0.7366" stop-color="#F9F9F9" />
          <stop offset="1" stop-color="#C9C9C9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_9510"
          x1="10.9537"
          y1="22.3944"
          x2="16.262"
          y2="18.5033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.5" stop-color="#F9F9F9" />
          <stop offset="1" stop-color="#C9C9C9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_500_9510"
          x1="4.8764"
          y1="14.7093"
          x2="19.0838"
          y2="14.7093"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#696969" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_500_9510"
          x1="11.8522"
          y1="12.1682"
          x2="11.8494"
          y2="17.2573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.0111016" stop-color="#5B5B5B" />
          <stop offset="0.0992964" stop-color="#AAAAAA" />
          <stop offset="0.1398" stop-color="#C9C9C9" />
          <stop offset="0.2581" stop-color="#F9F9F9" />
          <stop offset="0.3602" stop-color="#C9C9C9" />
          <stop offset="0.4892" stop-color="#505050" />
          <stop offset="0.4991" stop-color="#5B5B5B" />
          <stop offset="0.5771" stop-color="#AAAAAA" />
          <stop offset="0.6129" stop-color="#C9C9C9" />
          <stop offset="0.7366" stop-color="#F9F9F9" />
          <stop offset="0.8387" stop-color="#C9C9C9" />
          <stop offset="1" stop-color="#505050" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_500_9510"
          x1="6.6451"
          y1="9.54009"
          x2="16.2936"
          y2="19.1203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#505050" />
          <stop offset="0.0111016" stop-color="#5B5B5B" />
          <stop offset="0.0992964" stop-color="#AAAAAA" />
          <stop offset="0.1398" stop-color="#C9C9C9" />
          <stop offset="0.2581" stop-color="#F9F9F9" />
          <stop offset="0.3602" stop-color="#C9C9C9" />
          <stop offset="0.4892" stop-color="#505050" />
          <stop offset="0.4991" stop-color="#5B5B5B" />
          <stop offset="0.5771" stop-color="#AAAAAA" />
          <stop offset="0.6129" stop-color="#C9C9C9" />
          <stop offset="0.7366" stop-color="#F9F9F9" />
          <stop offset="0.8387" stop-color="#C9C9C9" />
          <stop offset="1" stop-color="#505050" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNotFlatNivelTwo;
