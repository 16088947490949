import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// core components
import customInputStyle from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle';
import objectHasProperty from '../../utils/objectHasProperty';

function CustomSelect({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    inputProps,
    error,
    success,
    options,
    defaultValue,
    ...rest
  } = props;

  const marginTop = classNames({
    [classes.marginTop]: true,
  });
  let className =
    formControlProps && objectHasProperty(formControlProps, 'className') ? formControlProps.className : '';
  className += classes && objectHasProperty(classes, 'formControl') ? ` ${classes.formControl}` : '';

  return (
    <FormControl {...formControlProps} className={className}>
      <Select
        classes={{
          root: marginTop,
        }}
        native
        id={id}
        defaultValue={defaultValue}
        {...inputProps}
        {...rest}
      >
        {options.map(obj => {
          return (
            <option key={`${id}-${obj.value}`} value={obj.value}>
              {obj.label}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  options: PropTypes.array,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default withStyles(customInputStyle)(CustomSelect);
