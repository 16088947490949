import getAxios from '../../clients';
import { IAnswersByUser } from './interfaces';
import { BACKEND_URL } from '../../config';

export const getQuestionsByModule = async (jwt: string, topic: string, userId: string) => {
  const result = await getAxios.instance(jwt).get(`${BACKEND_URL}/learning-guide/questions/${topic}/${userId}`);
  return result.data;
};

export const postAnswersByUser = async (jwt: string, data: IAnswersByUser) => {
  try {
    const result = await getAxios.instance(jwt).post(`${BACKEND_URL}/learning-guide/answer`, { ...data });
    return result.data && true;
  } catch (error) {
    return false;
  }
};
