import React from 'react';

export default function cart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.2049 3.39443L12.4209 8.36343L10.7879 6.27743C10.4469 5.84143 9.8189 5.76543 9.3839 6.10643C8.9489 6.44643 8.8719 7.07543 9.2129 7.50943L11.6439 10.6164C11.8339 10.8584 12.1239 10.9994 12.4319 10.9994H12.4389C12.7479 10.9984 13.0399 10.8524 13.2269 10.6054L17.7959 4.60543C18.1309 4.16543 18.0459 3.53943 17.6059 3.20443C17.1659 2.86943 16.5379 2.95543 16.2049 3.39443ZM2 4C2 3.44772 2.44772 3 3 3H5C5.45095 3 5.84611 3.30182 5.96476 3.73688L6.58198 6H7C7.55228 6 8 6.44772 8 7C8 7.50985 7.61845 7.93058 7.12531 7.99223L8.7638 14H17L20 8H19.118C18.5658 8 18.118 7.55229 18.118 7C18.118 6.44772 18.5658 6 19.118 6H20C21.4868 6 22.4538 7.56463 21.7889 8.89443L18.5125 15.4472C18.3431 15.786 17.9968 16 17.618 16H8C7.54905 16 7.15389 15.6982 7.03524 15.2631L4.2362 5H3C2.44772 5 2 4.55229 2 4ZM7.5002 18C6.6722 18 6.0002 18.671 6.0002 19.5C6.0002 20.329 6.6722 21 7.5002 21C8.3282 21 9.0002 20.329 9.0002 19.5C9.0002 18.671 8.3282 18 7.5002 18ZM17.5002 18C16.6722 18 16.0002 18.671 16.0002 19.5C16.0002 20.329 16.6722 21 17.5002 21C18.3282 21 19.0002 20.329 19.0002 19.5C19.0002 18.671 18.3282 18 17.5002 18Z"
        fill="#E17C02"
      />
    </svg>
  );
}
