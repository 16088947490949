import React, { useEffect, useState, useRef } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Breadcrumbs } from '@cencosud-ds/bigbang/antiguo';

import { RouteInterface } from '../../single-spa/interfaces/route.interface';

import { SPA_BREADCRUMB_EVENT } from '../../config';

interface IProps {
  routes: RouteInterface[];
}

interface DataEvent {
  id: string;
  name: string;
}
interface BreadcrumbEvent extends Event {
  data?: DataEvent;
}

const getActiveRoute = (routePath: string, routes: RouteInterface[]) => {
  for (const route of routes) {
    const match = matchPath(routePath, {
      path: route.path,
      exact: route.exact,
    });

    if (match) {
      let text: string = route.text;
      let path: string = route.path;
      if (Object.keys(match.params).length) {
        // @ts-ignore
        const params: any[] = Object.keys(match.params).map(key => match.params[key]);
        text = `${text} / ${params.join(' / ')}`;

        for (const key of Object.keys(match.params)) {
          // @ts-ignore
          path = path.replace(`:${key}`, match.params[key]);
        }
      }

      return {
        ...route,
        text,
        path,
      };
    }
  }

  return undefined;
};

const getFullPath = (path: string, routes: RouteInterface[]) => {
  let activeRoute = getActiveRoute(path, routes);
  const fullPath: RouteInterface[] = [];

  if (activeRoute) {
    fullPath.unshift(activeRoute);
    while (activeRoute !== undefined) {
      if (activeRoute.parentId) {
        // @ts-ignore
        // eslint-disable-next-line
        const parent = routes.find(r => r.id === activeRoute.parentId);
        if (parent && parent.path) {
          const routePath: string = parent.path;
          activeRoute = getActiveRoute(routePath, routes);

          if (activeRoute) {
            fullPath.unshift(activeRoute);
          }

          continue;
        }
      }
      activeRoute = undefined;
    }
  }

  return fullPath;
};

const RouteBreadcrumb = (props: IProps) => {
  const { routes } = props;

  const location = useLocation();

  const [fullPath, setFullPath] = useState<RouteInterface[]>([]);
  const [namedPath, setNamedPath] = useState<DataEvent>();
  const fullPathRef = useRef<RouteInterface[] | undefined>(fullPath);

  const changeBreadcrumb = (e: BreadcrumbEvent) => {
    const data = e.data;
    if (data) {
      setNamedPath({ id: data.id, name: data.name });
    }
  };

  const changeCurrentPath = (named?: boolean) => {
    let path;
    if (named && namedPath && fullPathRef.current) {
      path = fullPathRef.current.map(itemRoute => {
        return itemRoute.text.includes(namedPath.id)
          ? { ...itemRoute, text: itemRoute.text.replace(namedPath.id, namedPath.name) }
          : itemRoute;
      });
    } else {
      path = getFullPath(location.pathname, routes);
    }
    setFullPath(path);
    fullPathRef.current = path;
  };

  useEffect(() => {
    window.addEventListener(SPA_BREADCRUMB_EVENT, changeBreadcrumb);
    return () => {
      window.removeEventListener(SPA_BREADCRUMB_EVENT, changeBreadcrumb);
    };
  }, []);

  useEffect(() => {
    changeCurrentPath();
  }, [location.pathname]);

  useEffect(() => {
    changeCurrentPath(true);
  }, [namedPath]);

  return <Breadcrumbs pathsArray={fullPath} />;
};

export default RouteBreadcrumb;
