import React, { useState } from 'react';
import { Input, Typography } from '@cencosud-ds/bigbang';
import { FormSection, ToolTip } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { allowAlphanumeric, allowAplphabetic, allownumber, allowEmail } from 'utils/patterns';
import * as sellerServices from 'api/sellers';

export const ComertialArea: React.FC<{ formik: any }> = props => {
  const { formik } = props;
  const [comertialAreaEmailExists, setComertialAreaEmailExists] = useState<boolean>(false);
  const getMessageTooltip = (message: string) => {
    return (
      <>
        <span className="font-message">{t(message)}</span>
      </>
    );
  };

  const handleOnBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const email = event.target.value;
    if (email) {
      const existsSeller = await sellerServices.getSellerByEmail(email);
      if (existsSeller) {
        setComertialAreaEmailExists(true);
      }
    } else {
      formik.handleBlur(event);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComertialAreaEmailExists(false);
    formik.handleChange(event);
  };

  return (
    <>
      <FormSection
        label={t('sign_up_contact_comertial_area_label')}
        description={
          <div className="content-description">
            <div className="text-description">
              <span>{t('sign_up_contact_comertial_area_description1')}</span>
            </div>
            <br />
            <div className="text-description-p">
              <span>{t('sign_up_contact_comertial_area_description2')}</span>
            </div>
          </div>
        }
      >
        <div className="row mb-8">
          <div className="col-6">
            <Input
              name="comertialAreaName"
              input-state={formik.touched.comertialAreaName && formik.errors.comertialAreaName ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_comertial_area_name')}
              feedback-text={
                formik.touched.comertialAreaName && formik.errors.comertialAreaName
                  ? formik.errors.comertialAreaName
                  : ''
              }
              onBlur={formik.handleBlur}
              value={formik.values.comertialAreaName ? formik.values.comertialAreaName : ''}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <Input
              name="comertialAreaSecondName"
              input-state={
                formik.touched.comertialAreaSecondName && formik.errors.comertialAreaSecondName ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_comertial_area_second_name')}
              feedback-text={
                formik.touched.comertialAreaSecondName && formik.errors.comertialAreaSecondName
                  ? formik.errors.comertialAreaSecondName
                  : ''
              }
              value={formik.values.comertialAreaSecondName ? formik.values.comertialAreaSecondName : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <Input
              name="comertialAreaEmail"
              input-state={
                (formik.touched.comertialAreaEmail && formik.errors.comertialAreaEmail) || comertialAreaEmailExists
                  ? 'error'
                  : 'default'
              }
              disabled={false}
              onChange={handleOnChange}
              label-text={t('sign_up_contact_comertial_area_email')}
              feedback-text={
                formik.touched.comertialAreaEmail && formik.errors.comertialAreaEmail
                  ? formik.errors.comertialAreaEmail
                  : ''
              }
              value={formik.values.comertialAreaEmail ? formik.values.comertialAreaEmail : ''}
              onBlur={handleOnBlur}
              width="100%"
              placeholder=""
              maxLength={100}
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
            {comertialAreaEmailExists && (
              <div className="mt-1">
                <Typography
                  typeElement="body3"
                  weight="regular"
                  color="#e54717"
                  children={
                    <>
                      {t('comertialAreaEmailExistsError')}
                      <a className="onboardingEmail" href={`mailto:${t('onboardingEmail')}`}>
                        {t('onboardingEmail')}
                      </a>
                    </>
                  }
                />
              </div>
            )}
          </div>
          <div className="col-6 ">
            <Input
              name="comertialAreaConfirmEmail"
              input-state={
                formik.touched.comertialAreaConfirmEmail && formik.errors.comertialAreaConfirmEmail
                  ? 'error'
                  : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_comertial_area_confirm_email')}
              feedback-text={
                formik.touched.comertialAreaConfirmEmail && formik.errors.comertialAreaConfirmEmail
                  ? formik.errors.comertialAreaConfirmEmail
                  : ''
              }
              value={formik.values.comertialAreaConfirmEmail ? formik.values.comertialAreaConfirmEmail : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={100}
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <Input
              name="comertialAreaCharge"
              input-state={
                formik.touched.comertialAreaCharge && formik.errors.comertialAreaCharge ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_comertial_area_charge')}
              feedback-text={
                formik.touched.comertialAreaCharge && formik.errors.comertialAreaCharge
                  ? formik.errors.comertialAreaCharge
                  : ''
              }
              value={formik.values.comertialAreaCharge ? formik.values.comertialAreaCharge : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <div className="label-rut">
              <div className="phone-area">
                <Typography typeElement="body1" weight="regular" color="#212C39" children={t('phone_area')} />
              </div>
              <Input
                name="comertialAreaPhone"
                input-state={
                  formik.touched.comertialAreaPhone && formik.errors.comertialAreaPhone ? 'error' : 'default'
                }
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_contact_comertial_area_phone')}
                feedback-text={
                  formik.touched.comertialAreaPhone && formik.errors.comertialAreaPhone
                    ? formik.errors.comertialAreaPhone
                    : ''
                }
                value={formik.values.comertialAreaPhone ? formik.values.comertialAreaPhone : ''}
                onBlur={formik.handleBlur}
                width="323px"
                placeholder=""
                onKeyPress={event => {
                  if (!allownumber.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={9}
                onPaste={event => {
                  if (!allownumber.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              <div className="tooltip-space">
                <ToolTip msg={getMessageTooltip('phone_information')} position="top-left" />
              </div>
            </div>
          </div>
        </div>
      </FormSection>
    </>
  );
};
