import React from 'react';
import { Typography, colors } from '@cencosud-ds/bigbang';
import t from 'utils/translate';
import Progress from '../Progress';
import './style.css';
import { IPropsSteps, IDataSteps } from './interface';
import { dataSteps } from './data';

export default function Steps(props: IPropsSteps) {
  return (
    <ul className="step-progress">
      {dataSteps.map(({ nivele, description, label }: IDataSteps, i) => {
        return (
          <>
            {i !== 0 && <li className={`step-progress__between--level ${props.nivel === nivele ? `is-next` : ``}`} />}
            <li key={i} className={`step-progress-item  ${props.nivel !== nivele ? `is-disable` : ``}`}>
              {props.nivel !== nivele ? (
                <Progress name={'Bloqueo'} size="xs" type={'Bloqueo'} />
              ) : (
                <Progress name={nivele} size="xs" type={nivele} />
              )}
              <div className="step-progress__text">
                <Typography typeElement="body2" color={colors.primary[500]} weight="bold">
                  {t(label)}
                </Typography>
                <Typography typeElement="body3" color={colors.neutral[400]} weight="regular">
                  {t(description)}
                </Typography>
              </div>
            </li>
          </>
        );
      })}
    </ul>
  );
}
