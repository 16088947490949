import * as React from 'react';

function SvgNotFlatNivelOne(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.4218 1.904L13.9698 4.856L13.6524 5.496L11.8434 1.288C11.9544 1.112 12.1528 1 12.367 1H14.8664C15.3266 1 15.6281 1.488 15.4218 1.904Z"
        fill="#0041D6"
      />
      <path
        d="M15.4773 7.72804V6.38404C15.4773 5.15204 14.4776 4.14404 13.2556 4.14404H10.5103C9.28836 4.14404 8.28861 5.15204 8.28861 6.38404V7.72804C5.74161 9.04804 3.996 11.728 3.996 14.816C3.996 19.208 7.52689 22.768 11.883 22.768C16.239 22.768 19.7699 19.208 19.7699 14.816C19.7699 11.72 18.0243 9.04004 15.4773 7.72804ZM14.0491 7.16004C13.3588 6.96004 12.6368 6.85604 11.883 6.85604C11.1292 6.85604 10.4071 6.96004 9.71683 7.16004V6.38404C9.71683 5.94404 10.0739 5.58404 10.5103 5.58404H13.2556C13.5572 5.58404 13.819 5.75204 13.9539 6.00004L13.9618 6.00804C14.0174 6.12004 14.0491 6.24804 14.0491 6.37604V7.16004Z"
        fill="url(#paint0_linear_500_9493)"
      />
      <path
        d="M13.2556 4.14381C14.4775 4.14381 15.4773 5.15181 15.4773 6.38381V7.7278C18.0243 9.0478 19.7699 11.7198 19.7699 14.8078C19.7699 19.1998 16.239 22.7598 11.8829 22.7598C7.52686 22.7598 3.99597 19.1998 3.99597 14.8078C3.99597 11.7198 5.74158 9.0398 8.28857 7.7278V6.38381C8.28857 5.15181 9.28833 4.14381 10.5103 4.14381H13.2556ZM9.7168 7.15981C10.4071 6.95981 11.1292 6.85581 11.8829 6.85581C12.6367 6.85581 13.3588 6.95981 14.0491 7.15981V6.38381C14.0491 5.94381 13.692 5.58381 13.2556 5.58381H10.5103C10.0739 5.58381 9.7168 5.94381 9.7168 6.38381V7.15981ZM13.2556 3.90381H10.5103C9.15344 3.90381 8.05054 5.01581 8.05054 6.38381V7.5758C6.79687 8.2558 5.73364 9.2478 4.97986 10.4718C4.17847 11.7678 3.75 13.2638 3.75 14.8078C3.75 19.3278 7.3999 22.9998 11.875 22.9998C16.358 22.9998 20 19.3198 20 14.8078C20 13.2718 19.5715 11.7758 18.7701 10.4718C18.0164 9.2478 16.9531 8.2558 15.6995 7.5758V6.38381C15.7153 5.01581 14.6045 3.90381 13.2556 3.90381ZM9.95483 6.84781V6.38381C9.95483 6.07181 10.2008 5.82381 10.5103 5.82381H13.2556C13.5651 5.82381 13.811 6.07181 13.811 6.38381V6.84781C13.1842 6.69581 12.5336 6.61581 11.8829 6.61581C11.2244 6.61581 10.5817 6.68781 9.95483 6.84781Z"
        fill="#632424"
      />
      <path
        d="M11.9782 21.952C15.9002 21.952 19.0796 18.707 19.0796 14.704C19.0796 10.7011 15.9002 7.45605 11.9782 7.45605C8.05616 7.45605 4.87674 10.7011 4.87674 14.704C4.87674 18.707 8.05616 21.952 11.9782 21.952Z"
        fill="url(#paint1_linear_500_9493)"
      />
      <path
        d="M11.9056 17.1852C13.1833 17.1852 14.2191 16.1409 14.2191 14.8526C14.2191 13.5644 13.1833 12.52 11.9056 12.52C10.6278 12.52 9.59198 13.5644 9.59198 14.8526C9.59198 16.1409 10.6278 17.1852 11.9056 17.1852Z"
        fill="#632424"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7345 14.8528C14.7345 16.4281 13.4679 17.7051 11.9055 17.7051C10.3431 17.7051 9.07645 16.4281 9.07645 14.8528C9.07645 13.2775 10.3431 12.0005 11.9055 12.0005C13.4679 12.0005 14.7345 13.2775 14.7345 14.8528ZM14.2191 14.8528C14.2191 16.1411 13.1833 17.1854 11.9055 17.1854C10.6277 17.1854 9.59191 16.1411 9.59191 14.8528C9.59191 13.5645 10.6277 12.5202 11.9055 12.5202C13.1833 12.5202 14.2191 13.5645 14.2191 14.8528Z"
        fill="url(#paint2_linear_500_9493)"
      />
      <path
        opacity="0.5"
        d="M11.7639 22.0558C7.88385 22.0558 4.72589 18.8798 4.66241 14.9438C4.66241 14.9838 4.66241 15.0318 4.66241 15.0718C4.66241 19.0718 7.84418 22.3198 11.7639 22.3198C15.6835 22.3198 18.8653 19.0798 18.8653 15.0718C18.8653 15.0318 18.8653 14.9838 18.8653 14.9438C18.8018 18.8878 15.6439 22.0558 11.7639 22.0558Z"
        fill="white"
      />
      <path
        d="M11.8829 21.992C7.95526 21.992 4.75763 18.768 4.75763 14.808C4.75763 10.848 7.95526 7.62402 11.8829 7.62402C15.8105 7.62402 19.0081 10.848 19.0081 14.808C19.0002 18.768 15.8105 21.992 11.8829 21.992ZM11.8829 8.18402C8.26471 8.18402 5.31305 11.152 5.31305 14.808C5.31305 18.464 8.25678 21.432 11.8829 21.432C15.501 21.432 18.4527 18.464 18.4527 14.808C18.4527 11.152 15.501 8.18402 11.8829 8.18402Z"
        fill="#501717"
      />
      <path
        opacity="0.18"
        d="M5.60669 15.056C5.60669 18.584 8.43933 21.44 11.9385 21.44C15.4376 21.44 18.2703 18.584 18.2703 15.056C18.2703 11.528 18.2703 21.44 11.7242 20.872C5.63049 20.344 5.60669 11.528 5.60669 15.056Z"
        fill="#BE8CC5"
      />
      <path
        d="M13.9459 6.00004C13.811 5.75204 13.5492 5.58404 13.2477 5.58404H12.3907L11.6687 4.14404H13.1128L13.9459 6.00004Z"
        fill="#501717"
      />
      <path
        d="M12.7875 6.36789H10.4468C10.2088 6.36789 9.99458 6.23189 9.89143 6.02389L9.68513 5.59989L7.89985 1.99189C7.69355 1.57589 7.99506 1.08789 8.45527 1.08789H10.9547C11.2006 1.08789 11.4228 1.23189 11.518 1.45589L13.3588 5.47989C13.3747 5.51989 13.3906 5.55989 13.3985 5.59989C13.4858 5.98389 13.2001 6.36789 12.7875 6.36789Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M5.44794 14.7761C5.44794 11.2481 8.28058 8.39209 11.7797 8.39209C15.2789 8.39209 18.1115 11.2481 18.1115 14.7761C18.1115 18.3041 15.4931 13.8161 12.0019 13.8161C8.51068 13.8161 5.44794 18.3041 5.44794 14.7761Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M14.8346 5.07227C15.1203 5.45627 15.1837 5.89627 14.9774 6.05627C14.7711 6.20827 14.7473 5.74427 14.4617 5.35227C14.1761 4.96027 13.7397 4.80827 13.946 4.64827C14.1523 4.48827 14.549 4.68027 14.8346 5.07227Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M15.9529 11.1754C16.1728 10.7529 15.6747 10.0526 14.8404 9.61126C14.0062 9.16992 13.1516 9.15468 12.9317 9.57721C12.7118 9.99975 13.2099 10.7001 14.0442 11.1414C14.8785 11.5827 15.733 11.598 15.9529 11.1754Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9493"
          x1="11.9116"
          y1="9.05544"
          x2="11.9116"
          y2="23.1741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ED9191" />
          <stop offset="1" stop-color="#632424" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_9493"
          x1="5.55415"
          y1="9.40195"
          x2="3.80792"
          y2="24.6434"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEBCBC" />
          <stop offset="1" stop-color="#632424" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_500_9493"
          x1="14.2311"
          y1="12.5202"
          x2="9.55392"
          y2="17.1592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B81F1F" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNotFlatNivelOne;
