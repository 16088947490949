import React from 'react';

const SignUpFailIcon = () => {
  return (
    <svg width="292" height="226" viewBox="0 0 292 226" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3302_30467)">
        <path
          d="M267.087 67.8797C259.264 46.2705 237.431 33.5606 217.772 21.5231C198.897 9.95631 178.476 0.743219 156.418 0.0483175C134.328 -0.491214 112.59 5.65812 94.0565 17.6898C81.2455 26.0206 70.3499 36.9773 62.091 49.8347C58.6491 55.1028 55.5999 60.6171 52.9677 66.333C46.4251 80.913 42.9022 96.6668 42.6115 112.645C42.4548 117.93 42.5668 123.219 42.9475 128.493C44.4942 150.797 47.8566 173.908 61.7098 191.55C76.0619 209.425 96.4571 221.432 119.051 225.309C139.987 228.94 159.4 217.441 179.866 211.859L181.928 211.343C201.721 206.457 224.25 207.219 238.618 192.94C241.699 189.858 244.285 186.32 246.285 182.449C253.862 168.192 254.691 150.125 258.39 133.783C263.456 111.793 274.798 89.2423 267.087 67.8797Z"
          fill="white"
        />
        <path
          d="M268.502 183.232C268.476 181.629 268.132 180.048 267.492 178.579C266.851 177.111 265.925 175.783 264.768 174.675C263.611 173.566 262.246 172.697 260.752 172.119C259.257 171.541 257.663 171.265 256.061 171.306C255.359 171.271 254.655 171.347 253.976 171.53C252.862 168.271 250.743 165.45 247.923 163.472C245.103 161.494 241.728 160.462 238.285 160.524C237.36 160.487 236.434 160.562 235.528 160.748C233.971 158.526 231.895 156.718 229.481 155.479C227.067 154.241 224.388 153.611 221.675 153.642C217.409 153.582 213.293 155.213 210.227 158.179C207.161 161.144 205.393 165.203 205.311 169.468C205.274 170.319 205.349 171.17 205.535 172.001C204.64 171.725 203.713 171.567 202.778 171.53C201.167 171.46 199.558 171.717 198.049 172.286C196.54 172.854 195.161 173.722 193.997 174.838C192.832 175.953 191.905 177.292 191.272 178.775C190.639 180.258 190.312 181.854 190.312 183.467C190.312 185.08 190.639 186.675 191.272 188.158C191.905 189.641 192.832 190.981 193.997 192.096C195.161 193.212 196.54 194.08 198.049 194.648C199.558 195.216 201.167 195.473 202.778 195.404C203.226 195.404 203.697 195.404 203.921 195.157H254.626C255.012 195.216 255.394 195.298 255.77 195.404C256.175 195.466 256.59 195.379 256.935 195.157H257.383C260.371 194.863 263.149 193.49 265.195 191.295C267.242 189.099 268.418 186.232 268.502 183.232Z"
          fill="#B8DCFF"
        />
        <path
          d="M180.921 71.3962H181.279C181.54 71.5592 181.85 71.6228 182.154 71.5755C182.442 71.5001 182.734 71.4403 183.028 71.3962H221.696C221.853 71.5756 222.211 71.5755 222.57 71.5755C223.802 71.6367 225.033 71.447 226.189 71.0178C227.345 70.5886 228.402 69.9289 229.295 69.0788C230.189 68.2287 230.9 67.206 231.386 66.0727C231.872 64.9393 232.122 63.719 232.122 62.4858C232.122 61.2526 231.872 60.0323 231.386 58.8989C230.9 57.7656 230.189 56.7428 229.295 55.8927C228.402 55.0426 227.345 54.383 226.189 53.9538C225.033 53.5246 223.802 53.3349 222.57 53.396C221.857 53.4296 221.151 53.5424 220.463 53.7323C220.593 53.106 220.645 52.466 220.62 51.8269C220.561 48.5767 219.216 45.4823 216.88 43.2218C214.544 40.9613 211.407 39.719 208.156 39.767C206.093 39.741 204.055 40.2197 202.219 41.1612C200.382 42.1027 198.804 43.4787 197.621 45.1693C196.926 45.0399 196.22 44.9873 195.514 45.0123C192.891 44.9661 190.322 45.7528 188.175 47.2593C186.028 48.7658 184.414 50.9144 183.566 53.396C183.049 53.2483 182.511 53.1877 181.974 53.2167C180.752 53.1839 179.536 53.3941 178.395 53.8352C177.255 54.2762 176.213 54.9392 175.331 55.7858C174.449 56.6324 173.744 57.6458 173.257 58.767C172.77 59.8883 172.51 61.0952 172.492 62.3176C172.556 64.5959 173.447 66.7731 174.998 68.4434C176.548 70.1138 178.653 71.1632 180.921 71.3962V71.3962Z"
          fill="#B8DCFF"
        />
        <g opacity="0.39">
          <path
            opacity="0.39"
            d="M234 109.057H275.291C276.313 109.182 277.35 109.091 278.334 108.79C279.319 108.488 280.229 107.984 281.007 107.308C282.727 105.733 283.805 103.577 284.033 101.256C284.795 91.0341 271.099 89.2183 271.099 99.1263"
            stroke="#176CC1"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            opacity="0.39"
            d="M243 98H222"
            stroke="#176CC1"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            opacity="0.39"
            d="M256.118 120.672H245"
            stroke="#176CC1"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </g>
        <g opacity="0.39">
          <path
            opacity="0.39"
            d="M51.5117 152.002H92.8246C93.8467 152.127 94.8838 152.036 95.8685 151.735C96.8532 151.434 97.7636 150.929 98.5408 150.254C100.261 148.678 101.339 146.523 101.567 144.201C102.329 133.979 88.6328 132.164 88.6328 142.072"
            stroke="#176CC1"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            opacity="0.39"
            d="M64.4912 140.547H33.6914"
            stroke="#176CC1"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            opacity="0.39"
            d="M54.6497 163.617H43.5312"
            stroke="#176CC1"
            stroke-width="4"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </g>
        <path d="M159.292 142.391L146.492 163.619" stroke="#80C0FF" stroke-width="4.48324" stroke-miterlimit="10" />
        <path d="M131.358 134.359L130.91 159.129" stroke="#80C0FF" stroke-width="4.48324" stroke-miterlimit="10" />
        <path
          d="M144.56 140.569L126.224 135.301C126.224 135.301 121.741 121.448 118.916 110.509C116.092 99.5698 113.626 79.6867 118.625 62.359C122.462 51.0074 130.527 41.5712 141.142 36.0119C151.757 30.4526 164.106 29.1979 175.622 32.5084C187.138 35.819 196.935 43.4402 202.978 53.788C209.02 64.1357 210.842 76.4139 208.065 88.0704C203.067 105.398 190.446 120.955 182.219 128.711C173.993 136.467 162.874 145.837 162.874 145.837L144.56 140.569Z"
          fill="#176CC1"
        />
        <path
          d="M144.539 140.573L154.537 143.442C154.537 143.442 162.136 133.041 167.83 124.568C173.523 116.095 185.516 95.7857 190.515 78.4579C194.029 66.2902 194.628 53.9864 192.5 41.5012C181 32.5012 171.5 29.5012 156.5 31.0011C148.077 40.4563 142.15 51.3543 138.666 63.5288C133.667 80.8565 133.04 104.438 133.376 114.66C133.712 124.882 134.631 137.704 134.631 137.704L144.539 140.573Z"
          fill="#125596"
        />
        <path
          d="M125.306 186.925L136.515 190.152C137.79 190.5 139.145 190.411 140.364 189.9C141.583 189.388 142.595 188.482 143.239 187.328L151.78 171.995L121.608 163.32L120.958 181.007C120.906 182.34 121.308 183.651 122.098 184.725C122.888 185.8 124.019 186.576 125.306 186.925V186.925Z"
          fill="#FF8A00"
        />
        <path
          d="M155.254 162.807L124.08 153.845C121.272 153.037 118.342 154.659 117.534 157.467C116.727 160.275 118.349 163.206 121.157 164.013L152.33 172.975C155.138 173.783 158.069 172.161 158.876 169.353C159.684 166.545 158.062 163.614 155.254 162.807Z"
          fill="#FFDCB2"
        />
        <path
          d="M149.72 24.723C150.565 28.4682 151.097 32.2771 151.311 36.1104C151.418 37.8163 150.887 39.501 149.823 40.8385C148.759 42.176 147.236 43.0712 145.55 43.3509C143.826 43.5463 142.095 43.0493 140.738 41.969C139.38 40.8888 138.507 39.3137 138.31 37.5899C138.249 36.5637 138.405 35.5361 138.768 34.5743C139.131 33.6125 139.693 32.7381 140.417 32.0083C143.097 29.2616 146.006 26.7493 149.115 24.4989C149.185 24.4343 149.277 24.3984 149.372 24.3984C149.468 24.3984 149.56 24.4343 149.63 24.4989C149.671 24.5687 149.701 24.6442 149.72 24.723V24.723Z"
          fill="#80C0FF"
        />
        <path
          d="M88.6711 85.2799C88.6534 83.812 88.3455 82.3621 87.7651 81.0138C87.1847 79.6654 86.3432 78.4451 85.2892 77.4233C84.2352 76.4015 82.9895 75.5983 81.6238 75.0599C80.2581 74.5216 78.7993 74.2588 77.3315 74.2867C76.6936 74.2525 76.0545 74.3235 75.4397 74.4969C74.4456 71.5097 72.5272 68.9161 69.962 67.091C67.3967 65.266 64.3174 64.3041 61.1694 64.3445C60.3395 64.3125 59.5088 64.3831 58.6962 64.5547C57.2945 62.5125 55.412 60.8465 53.2146 59.7035C51.0171 58.5606 48.5721 57.9758 46.0953 58.0008C42.2022 58.0002 38.4649 59.5296 35.689 62.2592C32.9132 64.9888 31.3211 68.7 31.2562 72.5925C31.2222 73.3734 31.2929 74.1553 31.4665 74.9173C30.6617 74.6793 29.8315 74.5381 28.9933 74.4969C26.0777 74.4969 23.2816 75.6551 21.2199 77.7167C19.1583 79.7784 18 82.5746 18 85.4902C18 88.4058 19.1583 91.2019 21.2199 93.2636C23.2816 95.3252 26.0777 96.4834 28.9933 96.4834C29.3575 96.5388 29.7295 96.4644 30.0444 96.2732H76.2187C76.5729 96.3226 76.9239 96.3928 77.2698 96.4834C77.634 96.5388 78.006 96.4644 78.3209 96.2732H78.7413C81.4405 95.9383 83.9293 94.6433 85.7525 92.6249C87.5757 90.6064 88.6116 87.9992 88.6711 85.2799V85.2799Z"
          fill="#B8DCFF"
        />
        <path
          d="M144.724 72.1542C145.868 70.0704 146.047 67.9707 145.125 67.4644C144.202 66.958 142.527 68.2369 141.383 70.3207C140.24 72.4044 140.06 74.5042 140.983 75.0105C141.905 75.5168 143.58 74.2379 144.724 72.1542Z"
          fill="white"
        />
        <path
          d="M183.884 86.9591C185.028 84.8754 185.207 82.7756 184.285 82.2693C183.362 81.763 181.687 83.0418 180.543 85.1256C179.4 87.2094 179.221 89.3089 180.143 89.8152C181.066 90.3215 182.74 89.0429 183.884 86.9591Z"
          fill="white"
        />
        <path
          d="M140.438 94.997C140.438 94.997 145.66 84.1027 158.55 89.6395C170.385 94.728 164.647 106.586 164.647 106.586"
          stroke="white"
          stroke-width="2.24162"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3302_30467">
          <rect width="291.254" height="226" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignUpFailIcon;
