import { typeType } from './interface';

export function calculatePorcentage(value: number) {
  const result = value * 100;
  return result.toFixed(0);
}

export function formatFecha(fecha: Date) {
  const month = fecha.getMonth() + 1;
  const day = fecha.getDate();
  return `${(day < 10 ? '0' : '').concat(day)}/${(month < 10 ? '0' : '').concat(month)}/${fecha.getFullYear()}`;
}

export function rangeByDateReputation(nivel: typeType) {
  const dateSatelite = new Date('2023/05/01');
  let startDate = formatFecha(new Date(dateSatelite.getFullYear(), dateSatelite.getMonth() - 1, 1));
  const endDate = formatFecha(new Date(dateSatelite.getFullYear(), dateSatelite.getMonth(), 0));
  if (nivel === 'Nuevo') {
    startDate = formatFecha(new Date(dateSatelite.getFullYear(), dateSatelite.getMonth() - 2, 1));
    return `${startDate} - ${endDate}`;
  }
  return `${startDate} - ${endDate}`;
}
