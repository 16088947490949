import React, { useState } from 'react';
import { Typography, RadioButton, Button } from '@cencosud-ds/bigbang';
import t from 'utils/translate';
import './style.css';

export const RatingsNo: React.FC<{ onClick: (e: string) => void }> = props => {
  const [isChecked, setIsChecked] = useState<string>();
  return (
    <div className="ratings__no">
      <Typography color="#166DC2" weight="semiBold" typeElement="subtitle2">
        {t('learningguide_ratings_no_title')}
      </Typography>
      <RadioButton
        darkMode={false}
        disabled={false}
        text={t('learningguide_ratings_no_radiobutton1')}
        onChange={e => setIsChecked(e.name)}
        checked={isChecked === t('learningguide_ratings_no_radiobutton1')}
        name={t('learningguide_ratings_no_radiobutton1')}
      />
      <RadioButton
        darkMode={false}
        disabled={false}
        text={t('learningguide_ratings_no_radiobutton2')}
        onChange={e => setIsChecked(e.name)}
        checked={isChecked === t('learningguide_ratings_no_radiobutton2')}
        name={t('learningguide_ratings_no_radiobutton2')}
      />
      <RadioButton
        darkMode={false}
        disabled={false}
        text={t('learningguide_ratings_no_radiobutton3')}
        onChange={e => setIsChecked(e.name)}
        checked={isChecked === t('learningguide_ratings_no_radiobutton3')}
        name={t('learningguide_ratings_no_radiobutton3')}
      />
      <Button
        type="submit"
        type-button="primary"
        onClick={() => props.onClick(isChecked)}
        variant-button="m"
        disabled={!isChecked}
        width="100%"
      >
        {t('learningguide_ratings_no_button')}
      </Button>
    </div>
  );
};
