import * as React from 'react';

function SvgNivelOutstanding(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="227" height="228" viewBox="0 0 227 228" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.8" filter="url(#filter0_f_1049_20485)">
        <ellipse cx="113.719" cy="114.165" rx="63.148" ry="63.3962" fill="#B8DCFF" />
      </g>
      <path
        d="M133.927 29.2888L125.759 45.8427L123.974 49.4414L113.798 25.7801C114.422 24.7904 115.538 24.1606 116.743 24.1606H130.802C133.391 24.2056 135.087 26.9496 133.927 29.2888Z"
        fill="#0041D6"
      />
      <path
        d="M134.239 62.0369V54.4797C134.239 47.5522 128.615 41.8843 121.742 41.8843H106.299C99.426 41.8843 93.8024 47.5522 93.8024 54.4797V62.0369C79.4755 69.4592 69.6565 84.5287 69.6565 101.892C69.6565 126.588 89.5177 146.606 114.021 146.606C138.524 146.606 158.385 126.588 158.385 101.892C158.385 84.4837 148.566 69.4142 134.239 62.0369ZM126.205 58.8431C122.322 57.7185 118.261 57.1337 114.021 57.1337C109.781 57.1337 105.719 57.7185 101.836 58.8431V54.4797C101.836 52.0056 103.845 49.9813 106.299 49.9813H121.742C123.438 49.9813 124.911 50.926 125.67 52.3205L125.714 52.3654C126.027 52.9952 126.205 53.715 126.205 54.4347V58.8431Z"
        fill="url(#paint0_linear_1049_20485)"
      />
      <path
        d="M114.021 147.281C89.1609 147.281 68.9873 126.903 68.9873 101.892C68.9873 84.8885 78.2261 69.5041 93.1332 61.632V54.4796C93.1332 47.1473 99.0246 41.2095 106.3 41.2095H121.742C129.017 41.2095 134.909 47.1473 134.909 54.4796V61.632C149.816 69.5041 159.055 84.8885 159.055 101.892C159.099 126.903 138.881 147.281 114.021 147.281ZM114.021 56.4589C117.904 56.4589 121.787 56.9537 125.536 57.9434V54.4796C125.536 52.3654 123.84 50.656 121.742 50.656H106.3C104.202 50.656 102.506 52.3654 102.506 54.4796V57.9434C106.255 56.9537 110.138 56.4589 114.021 56.4589Z"
        fill="url(#paint1_radial_1049_20485)"
      />
      <path
        d="M121.742 41.8844C128.615 41.8844 134.239 47.5524 134.239 54.4798V62.0371C148.566 69.4593 158.385 84.4839 158.385 101.848C158.385 126.543 138.524 146.561 114.021 146.561C89.5178 146.561 69.6566 126.543 69.6566 101.848C69.6566 84.4839 79.4756 69.4144 93.8024 62.0371V54.4798C93.8024 47.5524 99.4261 41.8844 106.299 41.8844H121.742ZM101.836 58.8432C105.719 57.7186 109.781 57.1338 114.021 57.1338C118.261 57.1338 122.322 57.7186 126.205 58.8432V54.4798C126.205 52.0057 124.197 49.9815 121.742 49.9815H106.299C103.845 49.9815 101.836 52.0057 101.836 54.4798V58.8432M121.742 40.5349H106.299C98.6673 40.5349 92.4635 46.7876 92.4635 54.4798V61.1824C85.4116 65.006 79.4309 70.5839 75.1909 77.4664C70.6831 84.7538 68.2729 93.1657 68.2729 101.848C68.2729 127.263 88.8037 147.911 113.976 147.911C139.193 147.911 159.679 127.218 159.679 101.848C159.679 93.2107 157.269 84.7987 152.761 77.4664C148.521 70.5839 142.54 65.006 135.489 61.1824V54.4798C135.578 46.7876 129.374 40.5349 121.742 40.5349ZM103.175 57.0889V54.4798C103.175 52.7255 104.559 51.331 106.299 51.331H121.742C123.483 51.331 124.866 52.7255 124.866 54.4798V57.0889C121.34 56.2342 117.681 55.7843 114.021 55.7843C110.361 55.7843 106.701 56.1892 103.175 57.0889Z"
        fill="#1AC5EB"
      />
      <path
        d="M113.53 142.018C135.024 142.018 152.449 124.456 152.449 102.792C152.449 81.1281 135.024 63.5662 113.53 63.5662C92.0355 63.5662 74.6108 81.1281 74.6108 102.792C74.6108 124.456 92.0355 142.018 113.53 142.018Z"
        fill="url(#paint2_radial_1049_20485)"
      />
      <path
        d="M113.566 115.34L101.782 103.463C101.214 102.891 101.214 101.964 101.782 101.397L108.63 91.7041C108.904 91.428 109.271 91.2751 109.658 91.2751H119.524C119.911 91.2751 120.278 91.428 120.552 91.7041L127.4 101.397C127.968 101.969 127.968 102.896 127.4 103.463L115.616 115.34C115.053 115.912 114.134 115.912 113.566 115.34Z"
        fill="#006277"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.382 89.7867L106.555 89.612C107.38 88.7807 108.493 88.3167 109.658 88.3167H119.524C120.689 88.3167 121.802 88.7807 122.627 89.612L122.8 89.7867L129.686 99.5329C131.181 101.265 131.123 103.903 129.472 105.558C129.47 105.56 129.468 105.562 129.467 105.563L117.7 117.423C117.698 117.424 117.696 117.426 117.695 117.428C115.978 119.167 113.198 119.152 111.491 117.432L99.7064 105.554C98.0724 103.908 97.9891 101.269 99.4984 99.5288L106.382 89.7867ZM101.782 101.397C101.214 101.964 101.214 102.891 101.782 103.463L113.566 115.34C114.134 115.912 115.053 115.912 115.616 115.34L127.4 103.463C127.968 102.896 127.968 101.969 127.4 101.397L120.552 91.7038C120.278 91.4277 119.911 91.2749 119.524 91.2749H109.658C109.271 91.2749 108.904 91.4277 108.63 91.7038L101.782 101.397Z"
        fill="url(#paint3_linear_1049_20485)"
      />
      <path
        d="M113.843 141.703C92.0175 141.703 74.254 123.844 73.897 101.712C73.897 101.937 73.897 102.207 73.897 102.432C73.897 124.924 91.7944 143.187 113.843 143.187C135.891 143.187 153.788 124.969 153.788 102.432C153.788 102.207 153.788 101.937 153.788 101.712C153.386 123.889 135.668 141.703 113.843 141.703Z"
        fill="white"
      />
      <path
        d="M137.631 69.7743C120.135 56.6391 95.32 60.2827 81.8857 77.8263C82.0196 77.6464 82.1535 77.4215 82.3321 77.2415C95.677 59.2031 120.805 55.3345 138.524 68.6047C156.243 81.8748 159.768 107.291 146.424 125.329C146.29 125.509 146.111 125.734 145.977 125.914C158.787 107.92 155.127 82.9095 137.631 69.7743Z"
        fill="#03ACD1"
      />
      <path
        d="M114.021 142.242C91.9278 142.242 73.9412 124.114 73.9412 101.847C73.9412 79.5803 91.9278 61.4519 114.021 61.4519C136.113 61.4519 154.1 79.5803 154.1 101.847C154.1 124.114 136.113 142.242 114.021 142.242ZM114.021 64.6008C93.6685 64.6008 77.0654 81.2897 77.0654 101.847C77.0654 122.405 93.6238 139.094 114.021 139.094C134.373 139.094 150.976 122.405 150.976 101.847C150.976 81.2897 134.417 64.6008 114.021 64.6008Z"
        fill="url(#paint4_linear_1049_20485)"
      />
      <path
        d="M125.67 52.3205C124.911 50.926 123.438 49.9813 121.742 49.9813H116.922L112.86 41.8843H120.983L125.67 52.3205Z"
        fill="#501717"
      />
      <path
        d="M119.154 54.3896H105.987C104.648 54.3896 103.443 53.6249 102.863 52.4553L101.703 50.0712L91.6604 29.7836C90.4999 27.4444 92.196 24.7004 94.7846 24.7004H108.844C110.227 24.7004 111.477 25.5101 112.013 26.7697L122.323 49.4864C122.412 49.7113 122.501 49.9362 122.546 50.1611C123.081 52.2304 121.43 54.3896 119.154 54.3896Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M78.2705 102.117C78.2705 82.2796 94.2041 66.2205 113.887 66.2205C133.569 66.2205 149.503 82.2796 149.503 102.117C149.503 121.955 134.775 96.7193 115.136 96.7193C95.4984 96.7193 78.2705 121.955 78.2705 102.117Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M130.624 47.1025C132.231 49.2617 132.588 51.7358 131.427 52.6354C130.267 53.4901 130.133 50.8811 128.526 48.6769C126.92 46.4727 124.465 45.618 125.625 44.7183C126.786 43.8636 129.017 44.8983 130.624 47.1025Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M135.859 80.5478C137.095 78.1719 134.294 74.2341 129.601 71.7525C124.908 69.2709 120.101 69.1852 118.864 71.5611C117.627 73.937 120.429 77.8748 125.122 80.3564C129.815 82.838 134.622 82.9237 135.859 80.5478Z"
        fill="white"
      />
      <path
        d="M169.668 21.7015C170.191 20.2829 172.197 20.2829 172.72 21.7015L176.171 31.0653C176.335 31.5107 176.686 31.8621 177.131 32.0274L186.476 35.499C187.889 36.024 187.889 38.0231 186.476 38.5482L177.131 42.0197C176.686 42.185 176.335 42.5365 176.171 42.9818L172.72 52.3457C172.197 53.7642 170.191 53.7642 169.668 52.3457L166.216 42.9818C166.052 42.5365 165.702 42.185 165.257 42.0197L155.912 38.5482C154.498 38.0231 154.498 36.024 155.912 35.499L165.257 32.0274C165.702 31.8621 166.052 31.5107 166.216 31.0653L169.668 21.7015Z"
        fill="#1C8AF5"
      />
      <path
        d="M32.7058 107.236C33.2287 105.818 35.235 105.818 35.7578 107.236L37.682 112.457C37.8462 112.902 38.1967 113.253 38.6417 113.419L43.8597 115.357C45.273 115.882 45.273 117.881 43.8597 118.406L38.6417 120.345C38.1967 120.51 37.8462 120.861 37.682 121.307L35.7578 126.527C35.235 127.946 33.2287 127.946 32.7058 126.527L30.7816 121.307C30.6174 120.861 30.2669 120.51 29.822 120.345L24.604 118.406C23.1906 117.881 23.1906 115.882 24.604 115.357L29.822 113.419C30.2669 113.253 30.6174 112.902 30.7816 112.457L32.7058 107.236Z"
        fill="#B8DCFF"
      />
      <path
        d="M193.665 70.0032C194.188 68.5847 196.195 68.5847 196.717 70.0032L197.987 73.448C198.151 73.8934 198.502 74.2448 198.947 74.4101L202.396 75.6915C203.809 76.2165 203.809 78.2156 202.396 78.7406L198.947 80.022C198.502 80.1873 198.151 80.5387 197.987 80.9841L196.717 84.4288C196.195 85.8474 194.188 85.8474 193.665 84.4288L192.396 80.9841C192.232 80.5387 191.881 80.1873 191.436 80.022L187.987 78.7406C186.573 78.2156 186.573 76.2165 187.987 75.6915L191.436 74.4101C191.881 74.2448 192.232 73.8934 192.396 73.448L193.665 70.0032Z"
        fill="#B8DCFF"
      />
      <path
        d="M62.3577 158.557C62.8806 157.138 64.8869 157.138 65.4098 158.557L66.6795 162.002C66.8436 162.447 67.1942 162.799 67.6391 162.964L71.0883 164.245C72.5017 164.77 72.5017 166.769 71.0883 167.294L67.6391 168.576C67.1942 168.741 66.8436 169.092 66.6795 169.538L65.4098 172.983C64.8869 174.401 62.8806 174.401 62.3577 172.983L61.088 169.538C60.9239 169.092 60.5733 168.741 60.1284 168.576L56.6792 167.294C55.2658 166.769 55.2658 164.77 56.6792 164.245L60.1284 162.964C60.5733 162.799 60.9239 162.447 61.088 162.002L62.3577 158.557Z"
        fill="#1C8AF5"
      />
      <defs>
        <filter
          id="filter0_f_1049_20485"
          x="0.570557"
          y="0.768799"
          width="226.296"
          height="226.792"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="25" result="effect1_foregroundBlur_1049_20485" />
        </filter>
        <linearGradient
          id="paint0_linear_1049_20485"
          x1="69.6423"
          y1="94.2339"
          x2="158.406"
          y2="94.2339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#558895" />
          <stop offset="0.2368" stop-color="#588A97" />
          <stop offset="0.392" stop-color="#60909C" />
          <stop offset="0.5238" stop-color="#6F9BA5" />
          <stop offset="0.6425" stop-color="#85A9B3" />
          <stop offset="0.7524" stop-color="#A0BCC4" />
          <stop offset="0.8559" stop-color="#C2D4D9" />
          <stop offset="0.9525" stop-color="#E9EFF1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_1049_20485"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(122.083 69.0268) rotate(95.9624) scale(75.3488 74.7726)"
        >
          <stop stop-color="#E2F1FF" />
          <stop offset="1" stop-color="#02ABD0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1049_20485"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(122.23 78.4668) scale(38.9075 39.214)"
        >
          <stop stop-color="#E2F1FF" />
          <stop offset="0.520833" stop-color="#02ABD0" />
          <stop offset="1" stop-color="#058CB7" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_1049_20485"
          x1="114.591"
          y1="88.3167"
          x2="114.591"
          y2="118.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.015625" stop-color="#0689A6" />
          <stop offset="0.197917" stop-color="#7DD3E8" />
          <stop offset="0.359375" stop-color="white" />
          <stop offset="0.671875" stop-color="#B2E5F1" />
          <stop offset="0.838542" stop-color="#C6E9FA" />
          <stop offset="1" stop-color="#2EA2BC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1049_20485"
          x1="114.021"
          y1="61.4519"
          x2="114.021"
          y2="142.242"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.015625" stop-color="#0689A6" />
          <stop offset="0.197917" stop-color="#7DD3E8" />
          <stop offset="0.359375" stop-color="white" />
          <stop offset="0.671875" stop-color="#B2E5F1" />
          <stop offset="0.838542" stop-color="#C6E9FA" />
          <stop offset="1" stop-color="#2EA2BC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNivelOutstanding;
