import * as React from 'react';

function SvgNivelThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.6157 3.52286L23.123 8.49574L22.5781 9.57387L19.4724 2.48516C19.6631 2.18867 20.0036 2 20.3714 2H24.6622C25.4523 2 25.9699 2.82208 25.6157 3.52286Z"
        fill="#B8DCFF"
      />
      <path
        d="M25.7106 13.3339V11.0698C25.7106 8.99444 23.9943 7.29639 21.8966 7.29639H17.1834C15.0857 7.29639 13.3694 8.99444 13.3694 11.0698V13.3339C8.99678 15.5576 6 20.0722 6 25.2742C6 32.6729 12.0617 38.67 19.54 38.67C27.0183 38.67 33.08 32.6729 33.08 25.2742C33.08 20.0588 30.0832 15.5441 25.7106 13.3339ZM23.2587 12.3771C22.0736 12.0402 20.8341 11.865 19.54 11.865C18.2459 11.865 17.0064 12.0402 15.8213 12.3771V11.0698C15.8213 10.3286 16.4342 9.72218 17.1834 9.72218H21.8966C22.4142 9.72218 22.8637 10.0052 23.0953 10.423L23.1089 10.4364C23.2042 10.6251 23.2587 10.8407 23.2587 11.0564V12.3771Z"
        fill="#B8DCFF"
      />
      <path
        d="M19.5394 37.3622C12.7967 37.3622 7.30713 31.9312 7.30713 25.2602C7.30713 18.5893 12.7967 13.1582 19.5394 13.1582C26.2822 13.1582 31.7718 18.5893 31.7718 25.2602C31.7581 31.9312 26.2822 37.3622 19.5394 37.3622ZM19.5394 14.1016C13.3279 14.1016 8.26065 19.1014 8.26065 25.2602C8.26065 31.419 13.3143 36.4189 19.5394 36.4189C25.751 36.4189 30.8182 31.419 30.8182 25.2602C30.8182 19.1014 25.751 14.1016 19.5394 14.1016Z"
        fill="white"
      />
      <path
        d="M21.0924 11.0425H17.074C16.6654 11.0425 16.2976 10.8134 16.1205 10.463L15.7663 9.74877L12.7014 3.67081C12.3473 2.97002 12.8649 2.14795 13.655 2.14795H17.9458C18.3681 2.14795 18.7495 2.39053 18.9129 2.76787L22.0732 9.54662C22.1004 9.61401 22.1277 9.68139 22.1413 9.74877C22.2911 10.3957 21.8008 11.0425 21.0924 11.0425Z"
        fill="#B8DCFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7475 27.3054C19.413 27.3054 19.1416 27.0339 19.1416 26.6993C19.1416 26.3647 19.413 26.0932 19.7475 26.0932C20.082 26.0932 20.3535 26.3647 20.3535 26.6993C20.3535 27.0339 20.082 27.3054 19.7475 27.3054ZM19.7477 24.881C18.7454 24.881 17.9298 25.6968 17.9298 26.6993C17.9298 27.7018 18.7454 28.5177 19.7477 28.5177C20.75 28.5177 21.5657 27.7018 21.5657 26.6993C21.5657 25.6968 20.75 24.881 19.7477 24.881ZM23.3835 29.1238C23.3835 29.4583 23.112 29.7299 22.7775 29.7299H16.7176C16.3831 29.7299 16.1116 29.4583 16.1116 29.1238V24.2748C16.1116 23.9403 16.3831 23.6687 16.7176 23.6687H17.3236H18.5356H20.9595H22.1715H22.7775C23.112 23.6687 23.3835 23.9403 23.3835 24.2748V29.1238ZM18.5357 21.3115C18.5357 20.606 19.0792 20.032 19.7476 20.032C20.416 20.032 20.9596 20.606 20.9596 21.3115V22.4565H18.5357V21.3115ZM22.7774 22.4565H22.1714V21.3116C22.1714 19.9381 21.0843 18.8198 19.7475 18.8198C18.4107 18.8198 17.3235 19.9381 17.3235 21.3116V22.4565H16.7176C15.7153 22.4565 14.8996 23.2723 14.8996 24.2749V29.1238C14.8996 30.1263 15.7153 30.9421 16.7176 30.9421H22.7774C23.7797 30.9421 24.5954 30.1263 24.5954 29.1238V24.2749C24.5954 23.2723 23.7797 22.4565 22.7774 22.4565Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgNivelThree;
