import React from 'react';
import './style.css';

interface ServiceLevelTable {
  children?: React.ReactNode;
}

export default function ServiceLevelTableContainer({ children }: ServiceLevelTable): JSX.Element {
  return <div className="level--container">{children}</div>;
}
