import React from 'react';
import * as IconsProgress from './svg';
import { IconInterface, sizeType, namesIconsProgress } from './interfaces';
import styled from '@emotion/styled';

function Progress(props: IconInterface) {
  const { name, type, size, ...res } = props;
  const DefaultComponent = () => <p>El icono de nivel no encontrado</p>;

  const variantsIconSize = (value: sizeType = 'm') => {
    const scales = {
      xs: '0.803', // 20x20
      s: '1', // 24x24
      m: '1.3333', // 32x32
      l: '1.6667', // 40x40
      xl: '1.6667', // 40x40
      xxl: '2.3333', // 56x56
      xxxl: '2.9999', // 72x72
      xxxxl: '4.0000', // 96x96
    };

    return `transform: scale(${scales[value]});`;
  };

  const IconStyled = styled(IconsProgress[name as namesIconsProgress] || DefaultComponent)`
    ${variantsIconSize(size)};
  `;

  return <IconStyled {...res} />;
}

Progress.defaultProps = {
  name: 'Nuevo',
  type: 'Nuevo',
  size: 'xl',
};

export default Progress;
