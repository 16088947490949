interface Commune {
  value: string;
  children: string;
  identifier: string;
}

interface Regions {
  value: string;
  children: string;
  romanNumber: string;
  number: string;
  abbreviation: string;
  communes: Commune[];
}

export const Region: Regions[] = [
  {
    value: 'Arica y Parinacota',
    children: 'Arica y Parinacota',
    romanNumber: 'XV',
    number: '15',
    abbreviation: 'AP',
    communes: [
      { value: 'Arica', children: 'Arica', identifier: 'XV-1' },
      { value: 'Camarones', children: 'Camarones', identifier: 'XV-2' },
      { value: 'General Lagos', children: 'General Lagos', identifier: 'XV-3' },
      { value: 'Putre', children: 'Putre', identifier: 'XV-4' },
    ],
  },
  {
    value: 'Tarapacá',
    children: 'Tarapacá',
    romanNumber: 'I',
    number: '1',
    abbreviation: 'TA',
    communes: [
      { value: 'Alto Hospicio', children: 'Alto Hospicio', identifier: 'I-1' },
      { value: 'Camiña', children: 'Camiña', identifier: 'I-2' },
      { value: 'Colchane', children: 'Colchane', identifier: 'I-3' },
      { value: 'Huara', children: 'Huara', identifier: 'I-4' },
      { value: 'Iquique', children: 'Iquique', identifier: 'I-5' },
      { value: 'Pica', children: 'Pica', identifier: 'I-6' },
      { value: 'Pozo Almonte', children: 'Pozo Almonte', identifier: 'I-7' },
    ],
  },
  {
    value: 'Antofagasta',
    children: 'Antofagasta',
    romanNumber: 'II',
    number: '2',
    abbreviation: 'AN',
    communes: [
      { value: 'Antofagasta', children: 'Antofagasta', identifier: 'II-1' },
      { value: 'Calama', children: 'Calama', identifier: 'II-2' },
      { value: 'María Elena', children: 'María Elena', identifier: 'II-3' },
      { value: 'Mejillones', children: 'Mejillones', identifier: 'II-4' },
      { value: 'Ollagüe', children: 'Ollagüe', identifier: 'II-5' },
      { value: 'San Pedro de Atacama', children: 'San Pedro de Atacama', identifier: 'II-6' },
      { value: 'Sierra Gorda', children: 'Sierra Gorda', identifier: 'II-7' },
      { value: 'Taltal', children: 'Taltal', identifier: 'II-8' },
      { value: 'Tocopilla', children: 'Tocopilla', identifier: 'II-9' },
    ],
  },
  {
    value: 'Atacama',
    children: 'Atacama',
    romanNumber: 'III',
    number: '3',
    abbreviation: 'AT',
    communes: [
      { value: 'Alto del Carmen', children: 'Alto del Carmen', identifier: 'III-1' },
      { value: 'Caldera', children: 'Caldera', identifier: 'III-2' },
      { value: 'Chañaral', children: 'Chañaral', identifier: 'III-3' },
      { value: 'Copiapó', children: 'Copiapó', identifier: 'III-4' },
      { value: 'Diego de Almagro', children: 'Diego de Almagro', identifier: 'III-5' },
      { value: 'Freirina', children: 'Freirina', identifier: 'III-6' },
      { value: 'Huasco', children: 'Huasco', identifier: 'III-7' },
      { value: 'Tierra Amarilla', children: 'Tierra Amarilla', identifier: 'III-8' },
      { value: 'Vallenar', children: 'Vallenar', identifier: 'III-9' },
    ],
  },
  {
    value: 'Coquimbo',
    children: 'Coquimbo',
    romanNumber: 'IV',
    number: '4',
    abbreviation: 'CO',
    communes: [
      { value: 'Andacollo', children: 'Andacollo', identifier: 'IV-1' },
      { value: 'Canela', children: 'Canela', identifier: 'IV-2' },
      { value: 'Combarbalá', children: 'Combarbalá', identifier: 'IV-3' },
      { value: 'Coquimbo', children: 'Coquimbo', identifier: 'IV-4' },
      { value: 'Illapel', children: 'Illapel', identifier: 'IV-5' },
      { value: 'La Higuera', children: 'La Higuera', identifier: 'IV-6' },
      { value: 'La Serena', children: 'La Serena', identifier: 'IV-7' },
      { value: 'Los Vilos', children: 'Los Vilos', identifier: 'IV-8' },
      { value: 'Monte Patria', children: 'Monte Patria', identifier: 'IV-9' },
      { value: 'Ovalle', children: 'Ovalle', identifier: 'IV-10' },
      { value: 'Paiguano', children: 'Paiguano', identifier: 'IV-11' },
      { value: 'Punitaqui', children: 'Punitaqui', identifier: 'IV-12' },
      { value: 'Río Hurtado', children: 'Río Hurtado', identifier: 'IV-13' },
      { value: 'Salamanca', children: 'Salamanca', identifier: 'IV-14' },
      { value: 'Vicuña', children: 'Vicuña', identifier: 'IV-15' },
    ],
  },
  {
    value: 'Valparaíso',
    children: 'Valparaíso',
    romanNumber: 'V',
    number: '5',
    abbreviation: 'VA',
    communes: [
      { value: 'Algarrobo', children: 'Algarrobo', identifier: 'V-1' },
      { value: 'Cabildo', children: 'Cabildo', identifier: 'V-2' },
      { value: 'Calera', children: 'Calera', identifier: 'V-3' },
      { value: 'Calle Larga', children: 'Calle Larga', identifier: 'V-4' },
      { value: 'Cartagena', children: 'Cartagena', identifier: 'V-5' },
      { value: 'Casablanca', children: 'Casablanca', identifier: 'V-6' },
      { value: 'Catemu', children: 'Catemu', identifier: 'V-7' },
      { value: 'Concón', children: 'Concón', identifier: 'V-8' },
      { value: 'El Quisco', children: 'El Quisco', identifier: 'V-9' },
      { value: 'El Tabo', children: 'El Tabo', identifier: 'V-10' },
      { value: 'Hijuelas', children: 'Hijuelas', identifier: 'V-11' },
      { value: 'Isla de Pascua', children: 'Isla de Pascua', identifier: 'V-12' },
      { value: 'Juan Fernández', children: 'Juan Fernández', identifier: 'V-13' },
      { value: 'La Cruz', children: 'La Cruz', identifier: 'V-14' },
      { value: 'La Ligua', children: 'La Ligua', identifier: 'V-15' },
      { value: 'Limache', children: 'Limache', identifier: 'V-16' },
      { value: 'Llaillay', children: 'Llaillay', identifier: 'V-17' },
      { value: 'Los Andes', children: 'Los Andes', identifier: 'V-18' },
      { value: 'Nogales', children: 'Nogales', identifier: 'V-19' },
      { value: 'Olmué', children: 'Olmué', identifier: 'V-20' },
      { value: 'Panquehue', children: 'Panquehue', identifier: 'V-21' },
      { value: 'Papudo', children: 'Papudo', identifier: 'V-22' },
      { value: 'Petorca', children: 'Petorca', identifier: 'V-23' },
      { value: 'Puchuncaví', children: 'Puchuncaví', identifier: 'V-24' },
      { value: 'Putaendo', children: 'Putaendo', identifier: 'V-25' },
      { value: 'Quillota', children: 'Quillota', identifier: 'V-26' },
      { value: 'Quilpué', children: 'Quilpué', identifier: 'V-27' },
      { value: 'Quintero', children: 'Quintero', identifier: 'V-28' },
      { value: 'Rinconada', children: 'Rinconada', identifier: 'V-29' },
      { value: 'San Antonio', children: 'San Antonio', identifier: 'V-30' },
      { value: 'San Esteban', children: 'San Esteban', identifier: 'V-31' },
      { value: 'San Felipe', children: 'San Felipe', identifier: 'V-32' },
      { value: 'Santa María', children: 'Santa María', identifier: 'V-33' },
      { value: 'Santo Domingo', children: 'Santo Domingo', identifier: 'V-34' },
      { value: 'Valparaíso', children: 'Valparaíso', identifier: 'V-35' },
      { value: 'Villa Alemana', children: 'Villa Alemana', identifier: 'V-36' },
      { value: 'Viña del Mar', children: 'Viña del Mar', identifier: 'V-37' },
      { value: 'Zapallar', children: 'Zapallar', identifier: 'V-38' },
    ],
  },
  {
    value: 'Metropolitana de Santiago',
    children: 'Metropolitana de Santiago',
    romanNumber: 'XIII',
    number: '13',
    abbreviation: 'RM',
    communes: [
      { value: 'Alhué', children: 'Alhué', identifier: 'XIII-1' },
      { value: 'Buin', children: 'Buin', identifier: 'XIII-2' },
      { value: 'Calera de Tango', children: 'Calera de Tango', identifier: 'XIII-3' },
      { value: 'Cerrillos', children: 'Cerrillos', identifier: 'XIII-4' },
      { value: 'Cerro Navia', children: 'Cerro Navia', identifier: 'XIII-5' },
      { value: 'Colina', children: 'Colina', identifier: 'XIII-6' },
      { value: 'Conchalí', children: 'Conchalí', identifier: 'XIII-7' },
      { value: 'Curacaví', children: 'Curacaví', identifier: 'XIII-8' },
      { value: 'El Bosque', children: 'El Bosque', identifier: 'XIII-9' },
      { value: 'El Monte', children: 'El Monte', identifier: 'XIII-10' },
      { value: 'Estación Central', children: 'Estación Central', identifier: 'XIII-11' },
      { value: 'Huechuraba', children: 'Huechuraba', identifier: 'XIII-12' },
      { value: 'Independencia', children: 'Independencia', identifier: 'XIII-13' },
      { value: 'Isla de Maipo', children: 'Isla de Maipo', identifier: 'XIII-14' },
      { value: 'La Cisterna', children: 'La Cisterna', identifier: 'XIII-15' },
      { value: 'La Florida', children: 'La Florida', identifier: 'XIII-16' },
      { value: 'La Granja', children: 'La Granja', identifier: 'XIII-17' },
      { value: 'La Pintana', children: 'La Pintana', identifier: 'XIII-18' },
      { value: 'La Reina', children: 'La Reina', identifier: 'XIII-19' },
      { value: 'Lampa', children: 'Lampa', identifier: 'XIII-20' },
      { value: 'Las Condes', children: 'Las Condes', identifier: 'XIII-21' },
      { value: 'Lo Barnechea', children: 'Lo Barnechea', identifier: 'XIII-22' },
      { value: 'Lo Espejo', children: 'Lo Espejo', identifier: 'XIII-23' },
      { value: 'Lo Prado', children: 'Lo Prado', identifier: 'XIII-24' },
      { value: 'Macul', children: 'Macul', identifier: 'XIII-25' },
      { value: 'Maipú', children: 'Maipú', identifier: 'XIII-26' },
      { value: 'María Pinto', children: 'María Pinto', identifier: 'XIII-27' },
      { value: 'Melipilla', children: 'Melipilla', identifier: 'XIII-28' },
      { value: 'Ñuñoa', children: 'Ñuñoa', identifier: 'XIII-29' },
      { value: 'Padre Hurtado', children: 'Padre Hurtado', identifier: 'XIII-30' },
      { value: 'Paine', children: 'Paine', identifier: 'XIII-31' },
      { value: 'Pedro Aguirre Cerda', children: 'Pedro Aguirre Cerda', identifier: 'XIII-32' },
      { value: 'Peñaflor', children: 'Peñaflor', identifier: 'XIII-33' },
      { value: 'Peñalolén', children: 'Peñalolén', identifier: 'XIII-34' },
      { value: 'Pirque', children: 'Pirque', identifier: 'XIII-35' },
      { value: 'Providencia', children: 'Providencia', identifier: 'XIII-36' },
      { value: 'Pudahuel', children: 'Pudahuel', identifier: 'XIII-37' },
      { value: 'Puente Alto', children: 'Puente Alto', identifier: 'XIII-38' },
      { value: 'Quilicura', children: 'Quilicura', identifier: 'XIII-39' },
      { value: 'Quinta Normal', children: 'Quinta Normal', identifier: 'XIII-40' },
      { value: 'Recoleta', children: 'Recoleta', identifier: 'XIII-41' },
      { value: 'Renca', children: 'Renca', identifier: 'XIII-42' },
      { value: 'San Bernardo', children: 'San Bernardo', identifier: 'XIII-43' },
      { value: 'San Joaquín', children: 'San Joaquín', identifier: 'XIII-44' },
      { value: 'San José de Maipo', children: 'San José de Maipo', identifier: 'XIII-45' },
      { value: 'San Miguel', children: 'San Miguel', identifier: 'XIII-46' },
      { value: 'San Pedro', children: 'San Pedro', identifier: 'XIII-47' },
      { value: 'San Ramón', children: 'San Ramón', identifier: 'XIII-48' },
      { value: 'Santiago', children: 'Santiago', identifier: 'XIII-49' },
      { value: 'Talagante', children: 'Talagante', identifier: 'XIII-50' },
      { value: 'Tiltil', children: 'Tiltil', identifier: 'XIII-51' },
      { value: 'Vitacura', children: 'Vitacura', identifier: 'XIII-52' },
    ],
  },
  {
    value: `Libertador General Bernardo O'Higgins`,
    children: `Libertador General Bernardo O'Higgins`,
    romanNumber: 'VI',
    number: '6',
    abbreviation: 'LI',
    communes: [
      { value: 'Chimbarongo', children: 'Chimbarongo', identifier: 'VI-1' },
      { value: 'Chépica', children: 'Chépica', identifier: 'VI-2' },
      { value: 'Codegua', children: 'Codegua', identifier: 'VI-3' },
      { value: 'Coinco', children: 'Coinco', identifier: 'VI-4' },
      { value: 'Coltauco', children: 'Coltauco', identifier: 'VI-5' },
      { value: 'Doñihue', children: 'Doñihue', identifier: 'VI-6' },
      { value: 'Graneros', children: 'Graneros', identifier: 'VI-7' },
      { value: 'La Estrella', children: 'La Estrella', identifier: 'VI-8' },
      { value: 'Las Cabras', children: 'Las Cabras', identifier: 'VI-9' },
      { value: 'Litueche', children: 'Litueche', identifier: 'VI-10' },
      { value: 'Lolol', children: 'Lolol', identifier: 'VI-11' },
      { value: 'Machalí', children: 'Machalí', identifier: 'VI-12' },
      { value: 'Malloa', children: 'Malloa', identifier: 'VI-13' },
      { value: 'Marchihue', children: 'Marchihue', identifier: 'VI-14' },
      { value: 'Nancagua', children: 'Nancagua', identifier: 'VI-15' },
      { value: 'Navidad', children: 'Navidad', identifier: 'VI-16' },
      { value: 'Olivar', children: 'Olivar', identifier: 'VI-17' },
      { value: 'Palmilla', children: 'Palmilla', identifier: 'VI-18' },
      { value: 'Paredones', children: 'Paredones', identifier: 'VI-19' },
      { value: 'Peralillo', children: 'Peralillo', identifier: 'VI-20' },
      { value: 'Peumo', children: 'Peumo', identifier: 'VI-21' },
      { value: 'Pichidegua', children: 'Pichidegua', identifier: 'VI-22' },
      { value: 'Pichilemu', children: 'Pichilemu', identifier: 'VI-23' },
      { value: 'Placilla', children: 'Placilla', identifier: 'VI-24' },
      { value: 'Pumanque', children: 'Pumanque', identifier: 'VI-25' },
      { value: 'Quinta de Tilcoco', children: 'Quinta de Tilcoco', identifier: 'VI-26' },
      { value: 'Rancagua', children: 'Rancagua', identifier: 'VI-27' },
      { value: 'Rengo', children: 'Rengo', identifier: 'VI-28' },
      { value: 'Requínoa', children: 'Requínoa', identifier: 'VI-29' },
      { value: 'San Fernando', children: 'San Fernando', identifier: 'VI-30' },
      { value: 'San Francisco de Mostazal', children: 'San Francisco de Mostazal', identifier: 'VI-31' },
      { value: 'San Vicente de Tagua Tagua', children: 'San Vicente de Tagua Tagua', identifier: 'VI-32' },
      { value: 'Santa Cruz', children: 'Santa Cruz', identifier: 'VI-33' },
    ],
  },
  {
    value: 'Maule',
    children: 'Maule',
    romanNumber: 'VII',
    number: '7',
    abbreviation: 'ML',
    communes: [
      { value: 'Cauquenes', children: 'Cauquenes', identifier: 'VII-1' },
      { value: 'Chanco', children: 'Chanco', identifier: 'VII-2' },
      { value: 'Colbún', children: 'Colbún', identifier: 'VII-3' },
      { value: 'Constitución', children: 'Constitución', identifier: 'VII-4' },
      { value: 'Curepto', children: 'Curepto', identifier: 'VII-5' },
      { value: 'Curicó', children: 'Curicó', identifier: 'VII-6' },
      { value: 'Empedrado', children: 'Empedrado', identifier: 'VII-7' },
      { value: 'Hualañé', children: 'Hualañé', identifier: 'VII-8' },
      { value: 'Licantén', children: 'Licantén', identifier: 'VII-9' },
      { value: 'Linares', children: 'Linares', identifier: 'VII-10' },
      { value: 'Longaví', children: 'Longaví', identifier: 'VII-11' },
      { value: 'Maule', children: 'Maule', identifier: 'VII-12' },
      { value: 'Molina', children: 'Molina', identifier: 'VII-13' },
      { value: 'Parral', children: 'Parral', identifier: 'VII-14' },
      { value: 'Pelarco', children: 'Pelarco', identifier: 'VII-15' },
      { value: 'Pelluhue', children: 'Pelluhue', identifier: 'VII-16' },
      { value: 'Pencahue', children: 'Pencahue', identifier: 'VII-17' },
      { value: 'Rauco', children: 'Rauco', identifier: 'VII-18' },
      { value: 'Retiro', children: 'Retiro', identifier: 'VII-19' },
      { value: 'Romeral', children: 'Romeral', identifier: 'VII-20' },
      { value: 'Río Claro', children: 'Río Claro', identifier: 'VII-21' },
      { value: 'Sagrada Familia', children: 'Sagrada Familia', identifier: 'VII-22' },
      { value: 'San Clemente', children: 'San Clemente', identifier: 'VII-23' },
      { value: 'San Javier de Loncomilla', children: 'San Javier de Loncomilla', identifier: 'VII-24' },
      { value: 'San Rafael', children: 'San Rafael', identifier: 'VII-25' },
      { value: 'Talca', children: 'Talca', identifier: 'VII-26' },
      { value: 'Teno', children: 'Teno', identifier: 'VII-27' },
      { value: 'Vichuquén', children: 'Vichuquén', identifier: 'VII-28' },
      { value: 'Villa Alegre', children: 'Villa Alegre', identifier: 'VII-29' },
      { value: 'Yerbas Buenas', children: 'Yerbas Buenas', identifier: 'VII-30' },
    ],
  },
  {
    value: 'Ñuble',
    children: 'Ñuble',
    romanNumber: 'XVI',
    number: '16',
    abbreviation: 'NB',
    communes: [
      { value: 'Bulnes', children: 'Bulnes', identifier: 'XVI-1' },
      { value: 'Chillán Viejo', children: 'Chillán Viejo', identifier: 'XVI-2' },
      { value: 'Chillán', children: 'Chillán', identifier: 'XVI-3' },
      { value: 'Cobquecura', children: 'Cobquecura', identifier: 'XVI-4' },
      { value: 'Coelemu', children: 'Coelemu', identifier: 'XVI-5' },
      { value: 'Coihueco', children: 'Coihueco', identifier: 'XVI-6' },
      { value: 'El Carmen', children: 'El Carmen', identifier: 'XVI-7' },
      { value: 'Ninhue', children: 'Ninhue', identifier: 'XVI-8' },
      { value: 'Ñiquén', children: 'Ñiquén', identifier: 'XVI-9' },
      { value: 'Pemuco', children: 'Pemuco', identifier: 'XVI-10' },
      { value: 'Pinto', children: 'Pinto', identifier: 'XVI-11' },
      { value: 'Portezuelo', children: 'Portezuelo', identifier: 'XVI-12' },
      { value: 'Quillón', children: 'Quillón', identifier: 'XVI-13' },
      { value: 'Quirihue', children: 'Quirihue', identifier: 'XVI-14' },
      { value: 'Ránquil', children: 'Ránquil', identifier: 'XVI-15' },
      { value: 'San Carlos', children: 'San Carlos', identifier: 'XVI-16' },
      { value: 'San Fabián', children: 'San Fabián', identifier: 'XVI-17' },
      { value: 'San Ignacio', children: 'San Ignacio', identifier: 'XVI-18' },
      { value: 'San Nicolás', children: 'San Nicolás', identifier: 'XVI-19' },
      { value: 'Treguaco', children: 'Treguaco', identifier: 'XVI-20' },
      { value: 'Yungay', children: 'Yungay', identifier: 'XVI-21' },
    ],
  },
  {
    value: 'Biobío',
    children: 'Biobío',
    romanNumber: 'VIII',
    number: '8',
    abbreviation: 'BI',
    communes: [
      { value: 'Alto Biobío', children: 'Alto Biobío', identifier: 'VIII-1' },
      { value: 'Antuco', children: 'Antuco', identifier: 'VIII-2' },
      { value: 'Arauco', children: 'Arauco', identifier: 'VIII-3' },
      { value: 'Cabrero', children: 'Cabrero', identifier: 'VIII-4' },
      { value: 'Cañete', children: 'Cañete', identifier: 'VIII-5' },
      { value: 'Chiguayante', children: 'Chiguayante', identifier: 'VIII-6' },
      { value: 'Concepción', children: 'Concepción', identifier: 'VIII-7' },
      { value: 'Contulmo', children: 'Contulmo', identifier: 'VIII-8' },
      { value: 'Coronel', children: 'Coronel', identifier: 'VIII-9' },
      { value: 'Curanilahue', children: 'Curanilahue', identifier: 'VIII-10' },
      { value: 'Florida', children: 'Florida', identifier: 'VIII-11' },
      { value: 'Hualpén', children: 'Hualpén', identifier: 'VIII-12' },
      { value: 'Hualqui', children: 'Hualqui', identifier: 'VIII-13' },
      { value: 'Laja', children: 'Laja', identifier: 'VIII-14' },
      { value: 'Lebu', children: 'Lebu', identifier: 'VIII-15' },
      { value: 'Los Álamos', children: 'Los Álamos', identifier: 'VIII-16' },
      { value: 'Los Ángeles', children: 'Los Ángeles', identifier: 'VIII-17' },
      { value: 'Lota', children: 'Lota', identifier: 'VIII-18' },
      { value: 'Mulchén', children: 'Mulchén', identifier: 'VIII-19' },
      { value: 'Nacimiento', children: 'Nacimiento', identifier: 'VIII-20' },
      { value: 'Negrete', children: 'Negrete', identifier: 'VIII-21' },
      { value: 'Penco', children: 'Penco', identifier: 'VIII-22' },
      { value: 'Quilaco', children: 'Quilaco', identifier: 'VIII-23' },
      { value: 'Quilleco', children: 'Quilleco', identifier: 'VIII-24' },
      { value: 'San Pedro de la Paz', children: 'San Pedro de la Paz', identifier: 'VIII-25' },
      { value: 'San Rosendo', children: 'San Rosendo', identifier: 'VIII-26' },
      { value: 'Santa Bárbara', children: 'Santa Bárbara', identifier: 'VIII-27' },
      { value: 'Santa Juana', children: 'Santa Juana', identifier: 'VIII-28' },
      { value: 'Talcahuano', children: 'Talcahuano', identifier: 'VIII-29' },
      { value: 'Tirúa', children: 'Tirúa', identifier: 'VIII-30' },
      { value: 'Tomé', children: 'Tomé', identifier: 'VIII-31' },
      { value: 'Tucapel', children: 'Tucapel', identifier: 'VIII-32' },
      { value: 'Yumbel', children: 'Yumbel', identifier: 'VIII-33' },
    ],
  },
  {
    value: 'Araucanía',
    children: 'Araucanía',
    romanNumber: 'IX',
    number: '9',
    abbreviation: 'AR',
    communes: [
      { value: 'Angol', children: 'Angol', identifier: 'IX-1' },
      { value: 'Carahue', children: 'Carahue', identifier: 'IX-2' },
      { value: 'Cholchol', children: 'Cholchol', identifier: 'IX-3' },
      { value: 'Collipulli', children: 'Collipulli', identifier: 'IX-4' },
      { value: 'Cunco', children: 'Cunco', identifier: 'IX-5' },
      { value: 'Curacautín', children: 'Curacautín', identifier: 'IX-6' },
      { value: 'Curarrehue', children: 'Curarrehue', identifier: 'IX-7' },
      { value: 'Ercilla', children: 'Ercilla', identifier: 'IX-8' },
      { value: 'Freire', children: 'Freire', identifier: 'IX-9' },
      { value: 'Galvarino', children: 'Galvarino', identifier: 'IX-10' },
      { value: 'Gorbea', children: 'Gorbea', identifier: 'IX-11' },
      { value: 'Lautaro', children: 'Lautaro', identifier: 'IX-12' },
      { value: 'Loncoche', children: 'Loncoche', identifier: 'IX-13' },
      { value: 'Lonquimay', children: 'Lonquimay', identifier: 'IX-14' },
      { value: 'Los Sauces', children: 'Los Sauces', identifier: 'IX-15' },
      { value: 'Lumaco', children: 'Lumaco', identifier: 'IX-16' },
      { value: 'Melipeuco', children: 'Melipeuco', identifier: 'IX-17' },
      { value: 'Nueva Imperial', children: 'Nueva Imperial', identifier: 'IX-18' },
      { value: 'Padre las Casas', children: 'Padre las Casas', identifier: 'IX-19' },
      { value: 'Perquenco', children: 'Perquenco', identifier: 'IX-20' },
      { value: 'Pitrufquén', children: 'Pitrufquén', identifier: 'IX-21' },
      { value: 'Pucón', children: 'Pucón', identifier: 'IX-22' },
      { value: 'Purén', children: 'Purén', identifier: 'IX-23' },
      { value: 'Renaico', children: 'Renaico', identifier: 'IX-24' },
      { value: 'Saavedra', children: 'Saavedra', identifier: 'IX-25' },
      { value: 'Temuco', children: 'Temuco', identifier: 'IX-26' },
      { value: 'Teodoro Schmidt', children: 'Teodoro Schmidt', identifier: 'IX-27' },
      { value: 'Toltén', children: 'Toltén', identifier: 'IX-28' },
      { value: 'Traiguén', children: 'Traiguén', identifier: 'IX-29' },
      { value: 'Victoria', children: 'Victoria', identifier: 'IX-30' },
      { value: 'Vilcún', children: 'Vilcún', identifier: 'IX-31' },
      { value: 'Villarrica', children: 'Villarrica', identifier: 'IX-32' },
    ],
  },
  {
    value: 'Los Ríos',
    children: 'Los Ríos',
    romanNumber: 'XIV',
    number: '14',
    abbreviation: 'LR',
    communes: [
      { value: 'Corral', children: 'Corral', identifier: 'XIV-1' },
      { value: 'Futrono', children: 'Futrono', identifier: 'XIV-2' },
      { value: 'La Unión', children: 'La Unión', identifier: 'XIV-3' },
      { value: 'Lago Ranco', children: 'Lago Ranco', identifier: 'XIV-4' },
      { value: 'Lanco', children: 'Lanco', identifier: 'XIV-5' },
      { value: 'Los Lagos', children: 'Los Lagos', identifier: 'XIV-6' },
      { value: 'Mariquina', children: 'Mariquina', identifier: 'XIV-7' },
      { value: 'Máfil', children: 'Máfil', identifier: 'XIV-8' },
      { value: 'Paillaco', children: 'Paillaco', identifier: 'XIV-9' },
      { value: 'Panguipulli', children: 'Panguipulli', identifier: 'XIV-10' },
      { value: 'Río Bueno', children: 'Río Bueno', identifier: 'XIV-11' },
      { value: 'Valdivia', children: 'Valdivia', identifier: 'XIV-12' },
    ],
  },
  {
    value: 'Los Lagos',
    children: 'Los Lagos',
    romanNumber: 'X',
    number: '10',
    abbreviation: 'LL',
    communes: [
      { value: 'Ancud', children: 'Ancud', identifier: 'X-1' },
      { value: 'Calbuco', children: 'Calbuco', identifier: 'X-2' },
      { value: 'Castro', children: 'Castro', identifier: 'X-3' },
      { value: 'Chaitén', children: 'Chaitén', identifier: 'X-4' },
      { value: 'Chonchi', children: 'Chonchi', identifier: 'X-5' },
      { value: 'Cochamó', children: 'Cochamó', identifier: 'X-6' },
      { value: 'Curaco de Vélez', children: 'Curaco de Vélez', identifier: 'X-7' },
      { value: 'Dalcahue', children: 'Dalcahue', identifier: 'X-8' },
      { value: 'Fresia', children: 'Fresia', identifier: 'X-9' },
      { value: 'Frutillar', children: 'Frutillar', identifier: 'X-10' },
      { value: 'Futaleufú', children: 'Futaleufú', identifier: 'X-11' },
      { value: 'Hualaihué', children: 'Hualaihué', identifier: 'X-12' },
      { value: 'Llanquihue', children: 'Llanquihue', identifier: 'X-13' },
      { value: 'Los Muermos', children: 'Los Muermos', identifier: 'X-14' },
      { value: 'Maullín', children: 'Maullín', identifier: 'X-15' },
      { value: 'Osorno', children: 'Osorno', identifier: 'X-16' },
      { value: 'Palena', children: 'Palena', identifier: 'X-17' },
      { value: 'Puerto Montt', children: 'Puerto Montt', identifier: 'X-18' },
      { value: 'Puerto Octay', children: 'Puerto Octay', identifier: 'X-19' },
      { value: 'Puerto Varas', children: 'Puerto Varas', identifier: 'X-20' },
      { value: 'Puqueldón', children: 'Puqueldón', identifier: 'X-21' },
      { value: 'Purranque', children: 'Purranque', identifier: 'X-22' },
      { value: 'Puyehue', children: 'Puyehue', identifier: 'X-23' },
      { value: 'Queilén', children: 'Queilén', identifier: 'X-24' },
      { value: 'Quellón', children: 'Quellón', identifier: 'X-25' },
      { value: 'Quemchi', children: 'Quemchi', identifier: 'X-26' },
      { value: 'Quinchao', children: 'Quinchao', identifier: 'X-27' },
      { value: 'Río Negro', children: 'Río Negro', identifier: 'X-28' },
      { value: 'San Juan de la Costa', children: 'San Juan de la Costa', identifier: 'X-29' },
      { value: 'San Pablo', children: 'San Pablo', identifier: 'X-30' },
    ],
  },
  {
    value: 'Aisén del Gral. Carlos Ibáñez del Campo',
    children: 'Aisén del Gral. Carlos Ibáñez del Campo',
    romanNumber: 'XI',
    number: '11',
    abbreviation: 'AI',
    communes: [
      { value: 'Aisén', children: 'Aisén', identifier: 'XI-1' },
      { value: 'Chile Chico', children: 'Chile Chico', identifier: 'XI-2' },
      { value: 'Cisnes', children: 'Cisnes', identifier: 'XI-3' },
      { value: 'Cochrane', children: 'Cochrane', identifier: 'XI-4' },
      { value: 'Coyhaique', children: 'Coyhaique', identifier: 'XI-5' },
      { value: 'Guaitecas', children: 'Guaitecas', identifier: 'XI-6' },
      { value: 'Lago Verde', children: 'Lago Verde', identifier: 'XI-7' },
      { value: 'O’Higgins', children: 'O’Higgins', identifier: 'XI-8' },
      { value: 'Río Ibáñez', children: 'Río Ibáñez', identifier: 'XI-9' },
      { value: 'Tortel', children: 'Tortel', identifier: 'XI-10' },
    ],
  },
  {
    value: 'Magallanes y de la Antártica Chilena',
    children: 'Magallanes y de la Antártica Chilena',
    romanNumber: 'XII',
    number: '12',
    abbreviation: 'MA',
    communes: [
      { value: 'Antártica', children: 'Antártica', identifier: 'XII-1' },
      { value: 'Cabo de Hornos (Ex Navarino)', children: 'Cabo de Hornos (Ex Navarino)', identifier: 'XII-2' },
      { value: 'Laguna Blanca', children: 'Laguna Blanca', identifier: 'XII-3' },
      { value: 'Natales', children: 'Natales', identifier: 'XII-4' },
      { value: 'Porvenir', children: 'Porvenir', identifier: 'XII-5' },
      { value: 'Primavera', children: 'Primavera', identifier: 'XII-6' },
      { value: 'Punta Arenas', children: 'Punta Arenas', identifier: 'XII-7' },
      { value: 'Río Verde', children: 'Río Verde', identifier: 'XII-8' },
      { value: 'San Gregorio', children: 'San Gregorio', identifier: 'XII-9' },
      { value: 'Timaukel', children: 'Timaukel', identifier: 'XII-10' },
      { value: 'Torres del Paine', children: 'Torres del Paine', identifier: 'XII-11' },
    ],
  },
];

export const getRegions = () => {
  return Region.map(region => ({ value: region.value, label: region.value, children: region.children })).sort((a, b) =>
    a.value.localeCompare(b.value),
  );
};

export const getCommunesByRegion = (regionValue: string) => {
  const findRegion = Region.find(region => region.value === regionValue);
  if (findRegion) {
    const response = findRegion.communes
      .map(commune => ({
        value: commune.value,
        label: commune.value,
        children: commune.value,
      }))
      .sort((a, b) => a.value.localeCompare(b.value));
    return response;
  } else {
    return [{ value: 'No existe comuna', label: 'No existe comuna', children: 'No existe comuna' }];
  }
};
