import * as React from 'react';

function SvgNivelNew(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="136" height="137" viewBox="0 0 136 137" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M88.4737 11.1803L80.0972 28.0422L78.2662 31.698L67.8298 7.66167C68.4707 6.65635 69.615 6.0166 70.8509 6.0166H85.2696C87.9245 6.0166 89.6639 8.80408 88.4737 11.1803Z"
        fill="#E1F0FF"
      />
      <path
        d="M88.7929 44.4476V36.7706C88.7929 29.7333 83.0254 23.9756 75.9762 23.9756H60.1385C53.0894 23.9756 47.3219 29.7333 47.3219 36.7706V44.4476C32.6286 51.9875 22.5583 67.2957 22.5583 84.9345C22.5583 110.022 42.9276 130.357 68.0574 130.357C93.1871 130.357 113.556 110.022 113.556 84.9345C113.556 67.25 103.486 51.9418 88.7929 44.4476ZM80.5536 41.2031C76.5713 40.0607 72.4059 39.4667 68.0574 39.4667C63.7089 39.4667 59.5435 40.0607 55.5612 41.2031V36.7706C55.5612 34.2573 57.621 32.2009 60.1385 32.2009H75.9762C77.7156 32.2009 79.2262 33.1606 80.0043 34.5771L80.0501 34.6228C80.3705 35.2626 80.5536 35.9937 80.5536 36.7249V41.2031V41.2031Z"
        fill="#E1F0FF"
      />
      <path
        d="M68.0569 125.923C45.3989 125.923 26.9521 107.507 26.9521 84.8874C26.9521 62.2677 45.3989 43.8521 68.0569 43.8521C90.7149 43.8521 109.162 62.2677 109.162 84.8874C109.116 107.507 90.7149 125.923 68.0569 125.923ZM68.0569 47.0508C47.1841 47.0508 30.1563 64.0041 30.1563 84.8874C30.1563 105.771 47.1383 122.724 68.0569 122.724C88.9297 122.724 105.958 105.771 105.958 84.8874C105.958 64.0041 88.9297 47.0508 68.0569 47.0508Z"
        fill="white"
      />
      <path
        d="M73.2744 36.6786H59.7712C58.398 36.6786 57.1621 35.9018 56.567 34.7137L55.3769 32.2918L45.0778 11.6827C43.8877 9.30652 45.6271 6.51904 48.282 6.51904H62.7007C64.1197 6.51904 65.4014 7.34158 65.9506 8.62108L76.5701 31.6063C76.6617 31.8348 76.7532 32.0633 76.799 32.2918C77.3025 34.4852 75.6547 36.6786 73.2744 36.6786Z"
        fill="#E1F0FF"
      />
    </svg>
  );
}

export default SvgNivelNew;
