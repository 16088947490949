import React from 'react';
import { ListBox, Icon } from '@cencosud-ds/bigbang';
import './style.css';

interface IPropsQuestions {
  data: any[];
  onClickNext: (value: string) => void;
}

export const Questions: React.FC<IPropsQuestions> = ({ data, onClickNext }) => {
  return (
    <>
      <ListBox
        className="sm__items"
        style={{ padding: 0, textAlign: 'left' }}
        data={data.map(row => ({
          'title-text': row.title,
          value: row.value,
          slot3: <Icon name="ArrowIosRight" />,
          onClick: () => onClickNext(row.id),
        }))}
        variant="with-padding"
      />
    </>
  );
};
