import styled from '@emotion/styled';

const StepsStyled = styled.div`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &.Steps--vertical {
    flex-direction: column;
  }

  &.Steps--horizontal {
    flex-direction: row;

    .Step__texts {
      padding: 0 20px;
    }
  }
`;

export default StepsStyled;
