import React, { useEffect, ReactElement } from 'react';
import Steps from 'components/Steps';
import { IWizardFormProps } from './../../interfaces/wizard.interface';
import { useWizardControl } from './../../context';
import { IWizardForm } from './../../interfaces/wizard-form.interface';
import './index.css';
interface WizardProps {
  children?: ReactElement[] | ReactElement;
  onFinish: (forms: IWizardForm[]) => Promise<void>;
}

const Wizard: React.FC<WizardProps> = props => {
  const { children, onFinish: launchOnFinish } = props;
  const { setForms, step, forms, finished } = useWizardControl();

  const buildState = (index: number, success: boolean) => {
    if (index === step) {
      return 'active';
    }
    if (!success && index === step + 1) {
      return 'inactive';
    }
    return success ? 'complete' : 'disabled';
  };

  const buildSteps = () => {
    if (children) {
      return forms.map((form: IWizardForm, index: number) => {
        const { label, description, success } = form;
        return {
          label,
          description,
          state: buildState(index, success),
        };
      });
    }
    return [];
  };

  useEffect(() => {
    if (children) {
      const list: IWizardForm[] = [];
      React.Children.map(children, (form: React.ReactElement<IWizardFormProps>, index: number) => {
        const { label, description } = form.props;
        list.push({
          data: {},
          success: false,
          label,
          description,
        });
      });
      setForms(list);
    }
  }, [children]);

  useEffect(() => {
    if (finished) {
      launchOnFinish(forms);
    }
  }, [finished]);

  const buildForms = () => {
    if (children) {
      return React.Children.map(children, (form: React.ReactElement<IWizardFormProps>, index: number) => {
        if (index === step) {
          return form;
        }
        return <></>;
      });
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center py-4">
        <Steps data={buildSteps()} orientation="horizontal" spikes="last" />
      </div>
      <div className="wizard-content">{buildForms()}</div>
    </>
  );
};

export default Wizard;
