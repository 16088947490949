import React from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import { Container } from './styles';

interface FormSectionProps {
  label: string;
  description: React.ReactNode;
}

const FormSection: React.FC<FormSectionProps> = props => {
  const { children, label, description } = props;

  return (
    <Container>
      <div className="">
        <div className="container-subtitle">
          <Typography typeElement="subtitle1" weight="semiBold" color="#166DC2" children={label} />
        </div>
        <div className="container-textbody">
          <Typography typeElement="body2" weight="regular" color="#323A44" children={description} />
        </div>
      </div>
      <div className="container-forms">{children}</div>
    </Container>
  );
};

export default FormSection;
