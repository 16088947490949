export const SocietyOptions = [
  { value: 'S.A.', label: 'S.A.', children: 'S.A.' },
  { value: 'L.T.D.A.', label: 'L.T.D.A.', children: 'L.T.D.A.' },
  { value: 'S.P.A.', label: 'S.P.A.', children: 'S.P.A.' },
  { value: 'E.I.R.L', label: 'E.I.R.L', children: 'E.I.R.L' },
];

const optionNatural = { value: 'PERSONA_NATURAL', label: 'Persona natural', children: 'Persona natural' };

const optionOthers = { value: 'OTRAS', label: 'Otras', children: 'Otras' };

const optionForeign = { value: 'EXTRANJERA', label: 'Extranjera', children: 'Extranjera' };

export const optionFundation = { value: 'FUNDACION', label: 'Fundación', children: 'Fundación' };

export const Society = [...SocietyOptions, optionFundation];

export const SocietyExtended = [...SocietyOptions, optionForeign, optionNatural, optionOthers];
