import { IDataSteps } from './interface';

export const dataSteps: IDataSteps[] = [
  {
    nivele: 'Nuevo',
    label: 'reputation_components_steps_seller_nivel_new_text',
    description: 'reputation_components_steps_seller_nivel_new_description',
  },
  {
    nivele: 'Bajo',
    label: 'reputation_components_steps_seller_nivel_low_text',
    description: 'reputation_components_steps_seller_nivel_low_description',
  },
  {
    nivele: 'Medio',
    label: 'reputation_components_steps_seller_nivel_half_text',
    description: 'reputation_components_steps_seller_nivel_half_description',
  },
  {
    nivele: 'Bueno',
    label: 'reputation_components_steps_seller_nivel_good_text',
    description: 'reputation_components_steps_seller_nivel_good_description',
  },
  {
    nivele: 'Destacado',
    label: 'reputation_components_steps_seller_nivel_outstanding_text',
    description: 'reputation_components_steps_seller_nivel_outstanding_description',
  },
];
