import React, { useContext, useEffect, useState } from 'react';
import SessionContext from 'api/session/context';
import RCDrawer from 'components/Drawer';
import { typeType } from 'components/Reputation/Satelite/interface';
import t from 'utils/translate';
import { Typography, colors } from '@cencosud-ds/bigbang';
import { getSellerReputacionHome } from 'api/reputation';

import { IHeaderDrawer } from './interfaces';
import { HeaderDrawer } from './Header';

import Reputation from './my-reputation';
import Benefits from './my-benefits';

export default function Drawer(props: IHeaderDrawer) {
  const [nivele, setNivele] = useState<typeType | string>('Bloqueo');
  const {
    auth: { jwt, sellerId },
  } = useContext(SessionContext);

  const fetchReportHome = async () => {
    try {
      const { categoriaReputacion } = await getSellerReputacionHome(jwt, sellerId);
      if (categoriaReputacion === 'Plan Growth') {
        setNivele('Nuevo');
      } else {
        setNivele(categoriaReputacion);
      }
    } catch (error) {
      console.log('Ocurrio un error al cargar reporte en Home', error);
    }
  };

  useEffect(() => {
    fetchReportHome();
  }, []);

  return (
    <>
      <RCDrawer onOutSideClick={props.onClose} open={props.isOpen} width={490} className="drawer-reputation">
        <>
          <HeaderDrawer onClose={props.onClose} title={t('reputation_components_drawer_title')} />
          <>
            <Typography color={colors.primary[500]} weight="semiBold" typeElement="subtitle2">
              {t('reputation_components_drawer_description')}
            </Typography>
          </>
          <div className="reputation-drawer-body">
            <Reputation nivele={nivele} />
            <Benefits nivele={nivele} />
          </div>
        </>
      </RCDrawer>
    </>
  );
}
