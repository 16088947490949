export const CENTRY = 'centry';
export const MULTIVENDE = 'multivende';
export const PIM = 'pim';
export const YUJU = 'yuju';
export const STARKOMS = 'starkoms';
export const PRODUCTECA = 'producteca';
export const ECOMEXPERTS = 'ecomexperts';
export const ECOMSUR = 'ecomsur';
export const ASTROSELLING = 'astroselling';
export const RELBASE = 'relbase';
