import * as React from 'react';

function SvgNotFlatNivelThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.6207 2.08501L14.1686 5.02793L13.8513 5.6677L12.0422 1.46123C12.1533 1.2853 12.3516 1.17334 12.5659 1.17334H15.0652C15.5254 1.18134 15.827 1.66916 15.6207 2.08501Z"
        fill="#0041D6"
      />
      <path
        d="M15.6761 7.90691V6.5634C15.6761 5.33185 14.6764 4.32422 13.4545 4.32422H10.7091C9.48718 4.32422 8.48742 5.33185 8.48742 6.5634V7.90691C5.94043 9.22643 4.19482 11.9054 4.19482 14.9923C4.19482 19.3827 7.7257 22.9414 12.0818 22.9414C16.4379 22.9414 19.9687 19.3827 19.9687 14.9923C19.9687 11.8975 18.2231 9.21843 15.6761 7.90691ZM14.2479 7.33912C13.5576 7.13919 12.8356 7.03523 12.0818 7.03523C11.328 7.03523 10.606 7.13919 9.91565 7.33912V6.5634C9.91565 6.12356 10.2727 5.76369 10.7091 5.76369H13.4545C13.756 5.76369 14.0178 5.93163 14.1527 6.17954L14.1606 6.18754C14.2162 6.2995 14.2479 6.42745 14.2479 6.5554V7.33912Z"
        fill="url(#paint0_linear_500_9527)"
      />
      <path
        d="M12.0818 23.0617C7.6622 23.0617 4.07578 19.439 4.07578 14.9926C4.07578 11.9698 5.71823 9.23475 8.36837 7.83526V6.56373C8.36837 5.2602 9.41574 4.20459 10.7091 4.20459H13.4544C14.7478 4.20459 15.7951 5.2602 15.7951 6.56373V7.83526C18.4453 9.23475 20.0877 11.9698 20.0877 14.9926C20.0957 19.439 16.5013 23.0617 12.0818 23.0617ZM12.0818 6.9156C12.7721 6.9156 13.4624 7.00357 14.1289 7.1795V6.56373C14.1289 6.18787 13.8274 5.88398 13.4544 5.88398H10.7091C10.3361 5.88398 10.0346 6.18787 10.0346 6.56373V7.1795C10.7011 7.00357 11.3914 6.9156 12.0818 6.9156Z"
        fill="url(#paint1_radial_500_9527)"
      />
      <path
        d="M13.4545 4.32439C14.6765 4.32439 15.6762 5.33202 15.6762 6.56357V7.90708C18.2232 9.22659 19.9688 11.8976 19.9688 14.9845C19.9688 19.3749 16.4379 22.9336 12.0818 22.9336C7.72577 22.9336 4.19489 19.3749 4.19489 14.9845C4.19489 11.8976 5.94049 9.2186 8.48749 7.90708V6.56357C8.48749 5.33202 9.48724 4.32439 10.7092 4.32439H13.4545ZM9.91571 7.33928C10.606 7.13936 11.3281 7.03539 12.0818 7.03539C12.8356 7.03539 13.5577 7.13936 14.248 7.33928V6.56357C14.248 6.12373 13.8909 5.76386 13.4545 5.76386H10.7092C10.2728 5.76386 9.91571 6.12373 9.91571 6.56357V7.33928ZM13.4545 4.08447H10.7092C9.35236 4.08447 8.24945 5.19607 8.24945 6.56357V7.75513C6.99579 8.43488 5.93256 9.42652 5.17877 10.6501C4.37738 11.9456 3.94891 13.4411 3.94891 14.9845C3.94891 19.5028 7.59882 23.1735 12.0739 23.1735C16.5569 23.1735 20.1989 19.4948 20.1989 14.9845C20.1989 13.4491 19.7704 11.9536 18.9691 10.6501C18.2153 9.42652 17.152 8.43488 15.8984 7.75513V6.56357C15.9142 5.19607 14.8113 4.08447 13.4545 4.08447ZM10.1537 7.0274V6.56357C10.1537 6.25168 10.3997 6.00377 10.7092 6.00377H13.4545C13.764 6.00377 14.0099 6.25168 14.0099 6.56357V7.0274C13.3831 6.87545 12.7325 6.79548 12.0818 6.79548C11.4312 6.79548 10.7806 6.86746 10.1537 7.0274Z"
        fill="#A33311"
      />
      <path
        d="M11.9945 22.1256C15.8157 22.1256 18.9134 19.0035 18.9134 15.1522C18.9134 11.3008 15.8157 8.17871 11.9945 8.17871C8.17328 8.17871 5.07556 11.3008 5.07556 15.1522C5.07556 19.0035 8.17328 22.1256 11.9945 22.1256Z"
        fill="url(#paint2_radial_500_9527)"
      />
      <path
        d="M12.3401 11.932L13.099 13.4858C13.1384 13.5666 13.2147 13.6216 13.3015 13.6344L14.9976 13.8837C15.2186 13.9164 15.3065 14.1914 15.1469 14.3482L13.9193 15.558C13.8557 15.6212 13.8268 15.7113 13.8418 15.799L14.131 17.5073C14.1692 17.7296 13.9378 17.8993 13.74 17.7952L12.2232 16.989C12.1445 16.9469 12.0508 16.9469 11.9721 16.989L10.4554 17.7952C10.2575 17.9005 10.0261 17.7308 10.0643 17.5073L10.3535 15.799C10.3686 15.7101 10.3397 15.62 10.276 15.558L9.04849 14.3482C8.88882 14.1902 8.97675 13.9152 9.19773 13.8837L10.8938 13.6344C10.9818 13.6216 11.057 13.5654 11.0963 13.4858L11.8553 11.932C11.9559 11.7284 12.2406 11.7284 12.3401 11.932Z"
        fill="url(#paint3_linear_500_9527)"
      />
      <path
        d="M12.2923 12.7389L12.8628 13.9029C12.8924 13.9634 12.9498 14.0046 13.015 14.0142L14.2899 14.2009C14.456 14.2254 14.5221 14.4314 14.4021 14.5489L13.4794 15.4552C13.4316 15.5025 13.4098 15.57 13.4211 15.6357L13.6385 16.9154C13.6672 17.082 13.4933 17.2091 13.3446 17.1311L12.2045 16.5271C12.1453 16.4956 12.0749 16.4956 12.0158 16.5271L10.8756 17.1311C10.7269 17.2099 10.553 17.0829 10.5817 16.9154L10.7991 15.6357C10.8104 15.5691 10.7887 15.5016 10.7408 15.4552L9.81815 14.5489C9.69813 14.4305 9.76423 14.2246 9.93033 14.2009L11.2052 14.0142C11.2713 14.0046 11.3279 13.9625 11.3574 13.9029L11.9279 12.7389C12.0036 12.5864 12.2175 12.5864 12.2923 12.7389Z"
        fill="#A65A00"
      />
      <path
        d="M12.0501 22.0699C8.1701 22.0699 5.01215 18.895 4.94867 14.9604C4.94867 15.0004 4.94867 15.0484 4.94867 15.0884C4.94867 19.0869 8.13043 22.3338 12.0501 22.3338C15.9698 22.3338 19.1515 19.0949 19.1515 15.0884C19.1515 15.0484 19.1515 15.0004 19.1515 14.9604C19.0801 18.903 15.9301 22.0699 12.0501 22.0699Z"
        fill="white"
      />
      <path
        d="M16.2792 9.28254C13.1689 6.94739 8.75723 7.59515 6.36893 10.714C6.39273 10.682 6.41653 10.642 6.44827 10.6101C8.82071 7.40322 13.2879 6.71547 16.4379 9.07461C19.5879 11.4338 20.2147 15.9521 17.8423 19.1589C17.8185 19.1909 17.7868 19.2309 17.763 19.2629C20.0402 16.0641 19.3896 11.6177 16.2792 9.28254Z"
        fill="url(#paint4_radial_500_9527)"
      />
      <path
        d="M12.0818 22.1655C8.15417 22.1655 4.95654 18.9427 4.95654 14.9841C4.95654 11.0256 8.15417 7.80273 12.0818 7.80273C16.0094 7.80273 19.207 11.0256 19.207 14.9841C19.207 18.9427 16.0094 22.1655 12.0818 22.1655ZM12.0818 8.36253C8.46362 8.36253 5.51196 11.3294 5.51196 14.9841C5.51196 18.6388 8.45569 21.6057 12.0818 21.6057C15.6999 21.6057 18.6516 18.6388 18.6516 14.9841C18.6516 11.3294 15.7079 8.36253 12.0818 8.36253Z"
        fill="url(#paint5_linear_500_9527)"
      />
      <path
        d="M14.1528 6.17954C14.0179 5.93163 13.756 5.76369 13.4545 5.76369H12.5976L11.8755 4.32422H13.3196L14.1528 6.17954Z"
        fill="#501717"
      />
      <path
        d="M12.9942 6.54712H10.6535C10.4155 6.54712 10.2013 6.41117 10.0981 6.20324L9.89182 5.7794L8.10655 2.17271C7.90025 1.75686 8.20176 1.26904 8.66197 1.26904H11.1614C11.4073 1.26904 11.6295 1.41299 11.7247 1.63691L13.5576 5.67543C13.5735 5.71542 13.5893 5.7554 13.5973 5.79539C13.6925 6.16326 13.3989 6.54712 12.9942 6.54712Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M5.72623 15.0325C5.72623 11.5058 8.55887 8.65088 12.058 8.65088C15.5572 8.65088 18.3898 11.5058 18.3898 15.0325C18.3898 18.5593 15.7714 14.0729 12.2802 14.0729C8.78897 14.0729 5.72623 18.5593 5.72623 15.0325Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M15.0335 5.25189C15.3191 5.63575 15.3826 6.07558 15.1763 6.23553C14.97 6.38747 14.9462 5.92364 14.6605 5.53178C14.3749 5.13993 13.9385 4.98798 14.1448 4.82804C14.3511 4.6761 14.7478 4.86003 15.0335 5.25189Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M15.9641 11.1976C16.184 10.7752 15.6859 10.0752 14.8517 9.63398C14.0174 9.1928 13.1628 9.17757 12.9429 9.59995C12.723 10.0223 13.2211 10.7224 14.0554 11.1636C14.8897 11.6047 15.7443 11.62 15.9641 11.1976Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9527"
          x1="4.19229"
          y1="13.6308"
          x2="19.9726"
          y2="13.6308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#558895" />
          <stop offset="0.2368" stop-color="#588A97" />
          <stop offset="0.392" stop-color="#60909C" />
          <stop offset="0.5238" stop-color="#6F9BA5" />
          <stop offset="0.6425" stop-color="#85A9B3" />
          <stop offset="0.7524" stop-color="#A0BCC4" />
          <stop offset="0.8559" stop-color="#C2D4D9" />
          <stop offset="0.9525" stop-color="#E9EFF1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_500_9527"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.0827 13.6313) scale(8.7069 8.7755)"
        >
          <stop stop-color="#F2E520" />
          <stop offset="0.4563" stop-color="#F2E320" />
          <stop offset="0.6207" stop-color="#F2DC1F" />
          <stop offset="0.7379" stop-color="#F2D11E" />
          <stop offset="0.8327" stop-color="#F1C01C" />
          <stop offset="0.9137" stop-color="#F1AA1A" />
          <stop offset="0.9843" stop-color="#F08F17" />
          <stop offset="1" stop-color="#F08816" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_500_9527"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5412 10.8277) scale(6.91689 6.97138)"
        >
          <stop offset="0.0237017" stop-color="#F2E520" />
          <stop offset="1" stop-color="#F08816" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_500_9527"
          x1="9.88278"
          y1="18.2477"
          x2="15.5741"
          y2="10.8796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC700" />
          <stop offset="1" stop-color="#E54717" />
        </linearGradient>
        <radialGradient
          id="paint4_radial_500_9527"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.8223 13.4681) scale(6.11223 6.16038)"
        >
          <stop offset="0.0237017" stop-color="#F2E520" />
          <stop offset="1" stop-color="#F08816" />
        </radialGradient>
        <linearGradient
          id="paint5_linear_500_9527"
          x1="12.0818"
          y1="7.80273"
          x2="12.0818"
          y2="22.1655"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDEB31" />
          <stop offset="0.25" stop-color="white" />
          <stop offset="0.46875" stop-color="#FDEB31" />
          <stop offset="1" stop-color="#FD3131" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNotFlatNivelThree;
