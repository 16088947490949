import React from 'react';

interface IconsProps {
  icon: number;
}

const Icons: React.FC<IconsProps> = ({ icon }) => {
  const icons = [
    <svg key={0} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="8" fill="#ECF6FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 10H20.5C21.399 10 22.2046 10.5361 22.5 11.3286H23.8182C25.0232 11.3286 26 12.3008 26 13.5V18.3203L25.7611 18.6003C25.7634 18.6014 25.7657 18.6025 25.768 18.6037L24.5256 20.0755C24.1047 19.9269 23.6525 19.8462 23.1818 19.8462C20.9224 19.8462 19.0909 21.7059 19.0909 24C19.0909 26.0031 20.4872 27.675 22.3455 28.067C22.4524 28.0742 22.559 28.0882 22.6675 28.1024C22.8315 28.1239 22.9997 28.146 23.1792 28.146C25.2805 28.146 27.0119 26.5372 27.2437 24.4665L29.0716 23.5385C29.0826 23.6883 29.0882 23.8396 29.0882 23.9922C29.0882 24.082 29.0863 24.1713 29.0825 24.2601C29.0813 24.344 29.0762 24.4388 29.0668 24.5455C28.7953 27.6036 26.2642 30 23.1818 30H12.1818C10.9768 30 10 29.0278 10 27.8286V13.5C10 12.3008 10.9768 11.3286 12.1818 11.3286H13.5C13.5 11 14 10 15.5 10ZM18.9527 28.1905C18.3433 27.5565 17.8711 26.7861 17.5846 25.9286H14.0909C13.5888 25.9286 13.1818 25.5235 13.1818 25.0238C13.1818 24.5241 13.5888 24.119 14.0909 24.119H17.2738C17.2731 24.0795 17.2727 24.0398 17.2727 24C17.2727 23.413 17.3557 22.8457 17.5105 22.3095H14.0909C13.5888 22.3095 13.1818 21.9045 13.1818 21.4048C13.1818 20.9051 13.5888 20.5 14.0909 20.5H18.3817C18.7509 19.9788 19.2005 19.5202 19.7119 19.1429H14.0909C13.5888 19.1429 13.1818 18.7378 13.1818 18.2381C13.1818 17.7384 13.5888 17.3333 14.0909 17.3333H21.3636C21.809 17.3333 22.1796 17.6521 22.2575 18.073C22.5587 18.0249 22.8674 18 23.1818 18C23.4594 18 23.7326 18.0194 24 18.0571V13.3619C24 13.162 23.8372 13 23.6364 13L22.5 13C22.2046 13.7924 21.399 14.4952 20.5 14.4952H15.5C14.5 14.5 13.5 14 13.5 13H12.3636C12.1628 13 12 13.162 12 13.3619V27.8286C12 28.0284 12.1628 28.1905 12.3636 28.1905H18.9527ZM21.1047 12.8C21.2704 12.8 21.4047 12.6657 21.4047 12.5V11.8C21.4047 11.6343 21.2704 11.5 21.1047 11.5H15C14.8343 11.5 14.7 11.6343 14.7 11.8V12.5C14.7 12.6657 14.8343 12.8 15 12.8H21.1047Z"
        fill="#166DC2"
      />
      <path
        d="M29.7337 18.7319C30.0887 19.0924 30.0887 19.6769 29.7337 20.0374L24.2791 25.5758C23.9241 25.9363 23.3485 25.9363 22.9935 25.5758L21.1753 23.7297C20.8203 23.3692 20.8203 22.7847 21.1753 22.4242C21.5303 22.0637 22.1059 22.0637 22.461 22.4242L23.6363 23.6177L28.448 18.7319C28.803 18.3714 29.3787 18.3714 29.7337 18.7319Z"
        fill="#166DC2"
      />
    </svg>,
    <svg key={1} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" width="40" height="40" rx="8" fill="#ECF6FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.75 23H15.75C15.2 23 14.75 22.55 14.75 22C14.75 21.45 15.2 21 15.75 21H19.75C20.3 21 20.75 21.45 20.75 22C20.75 22.55 20.3 23 19.75 23ZM25.75 23H23.75C23.2 23 22.75 22.55 22.75 22C22.75 21.45 23.2 21 23.75 21H25.75C26.3 21 26.75 21.45 26.75 22C26.75 22.55 26.3 23 25.75 23ZM28.75 24C28.75 24.551 28.302 25 27.75 25H13.75C13.198 25 12.75 24.551 12.75 24V19H28.75V24ZM12.75 16C12.75 15.449 13.198 15 13.75 15H27.75C28.302 15 28.75 15.449 28.75 16V17H12.75V16ZM27.75 13H13.75C12.096 13 10.75 14.346 10.75 16V24C10.75 25.654 12.096 27 13.75 27H27.75C29.404 27 30.75 25.654 30.75 24V16C30.75 14.346 29.404 13 27.75 13Z"
        fill="#166DC2"
      />
    </svg>,
    <svg key={2} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="40" height="40" rx="8" fill="#ECF6FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5186 18.6787L21.8236 15.9837L23.7716 14.0347L26.4656 16.7287L24.5186 18.6787ZM17.5796 25.6247L14.6026 25.8957L14.8666 22.9397L20.4836 17.3227L23.1796 20.0187L17.5796 25.6247ZM27.9036 15.3377L27.9026 15.3367L25.1646 12.5987C24.4236 11.8597 23.1506 11.8247 22.4486 12.5297L13.4526 21.5257C13.1266 21.8507 12.9246 22.2827 12.8826 22.7397L12.5036 26.9097C12.4776 27.2047 12.5826 27.4967 12.7926 27.7067C12.9816 27.8957 13.2366 27.9997 13.4996 27.9997C13.5306 27.9997 13.5606 27.9987 13.5906 27.9957L17.7606 27.6167C18.2186 27.5747 18.6496 27.3737 18.9746 27.0487L27.9716 18.0517C28.6996 17.3217 28.6686 16.1037 27.9036 15.3377Z"
        fill="#166DC2"
      />
    </svg>,
    <svg key={3} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" width="40" height="40" rx="8" fill="#ECF6FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.247 18.9741H29.25C29.801 18.9741 30.249 19.4201 30.25 19.9711C30.258 22.6421 29.225 25.1571 27.341 27.0511C25.458 28.9451 22.95 29.9921 20.279 30.0001H20.25C17.589 30.0001 15.086 28.9681 13.199 27.0911C11.305 25.2081 10.258 22.7001 10.25 20.0291C10.242 17.3571 11.275 14.8431 13.159 12.9491C15.042 11.0551 17.55 10.0081 20.221 10.0001C21.016 10.0121 21.826 10.0921 22.602 10.2781C23.138 10.4081 23.469 10.9481 23.339 11.4851C23.21 12.0211 22.667 12.3511 22.133 12.2231C21.512 12.0731 20.853 12.0101 20.227 12.0001C18.09 12.0061 16.083 12.8441 14.577 14.3591C13.07 15.8741 12.244 17.8861 12.25 20.0231C12.256 22.1601 13.094 24.1661 14.609 25.6731C16.119 27.1741 18.121 28.0001 20.25 28.0001H20.273C22.41 27.9941 24.417 27.1561 25.923 25.6411C27.43 24.1251 28.256 22.1141 28.25 19.9771C28.249 19.4251 28.695 18.9751 29.247 18.9741ZM16.543 19.293C16.934 18.902 17.566 18.902 17.957 19.293L20.201 21.537L26.498 14.341C26.862 13.928 27.493 13.884 27.909 14.248C28.324 14.611 28.366 15.243 28.002 15.659L21.002 23.659C20.82 23.867 20.56 23.99 20.283 24H20.25C19.985 24 19.731 23.895 19.543 23.707L16.543 20.707C16.152 20.316 16.152 19.684 16.543 19.293Z"
        fill="#166DC2"
      />
    </svg>,
  ];
  return icons[icon];
};

export default Icons;
