import { SELLERS_URL } from '../../config';
import getAxios from '../../clients';

export const updateStatusTourGuide = async (jwt: string, sellerId: string, tourGuideStatus: number) => {
  try {
    await getAxios.instance(jwt).post(`${SELLERS_URL}/sellers/tourguide-status`, {
      sellerId,
      tourGuideStatus,
      createdAt: new Date(),
    });
    return true;
  } catch (e) {
    return false;
  }
};
