import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field } from 'formik';
import CustomSelect from '../CustomSelect/CustomSelect.jsx';
import Danger from '../Typography/Danger';

const styles = {
  errorMessage: {
    textAlign: 'left',
  },
};

const SelectWithErrors = props => {
  const { errors, name, touched, classes, defaultValue, ...rest } = props;

  return (
    <React.Fragment>
      <Field
        name={name}
        render={({ field }) => <CustomSelect name={name} defaultValue={defaultValue} {...field} {...rest} />}
      />
      {errors && touched ? <Danger className={classes.errorMessage}>{errors}</Danger> : null}
    </React.Fragment>
  );
};

export default withStyles(styles)(SelectWithErrors);
