import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { SPA_FLASH_MESSAGE_EVENT } from '../../config';
import SessionContext from '../../api/session/context';
import Login from '../pages/login';
import t from '../../utils/translate';
import './app.css';
import Footer from '../../components/Footer/Footer';
import HeaderMarquetPlace from '../../components/HeaderMarquetPlace/HeaderMarquetPlace';
interface IGuest {
  location: any;
}

const Guest = (props: IGuest) => {
  const { flashMessageTimeout } = useContext(SessionContext);

  useEffect(() => {
    showMessage();
  }, []);

  const useQuery = () => {
    return new URLSearchParams(props.location.search);
  };

  const showMessage = () => {
    let typeFlash = useQuery().get('type');
    let messageFlash = useQuery().get('message');
    if (typeFlash && messageFlash) {
      typeFlash = typeFlash === 'success' ? 'success' : 'danger';
      messageFlash = t(decodeURI(messageFlash));
      flashMessageTimeout({ type: typeFlash, message: messageFlash });
      const event = new Event(SPA_FLASH_MESSAGE_EVENT);
      window.dispatchEvent(event);
    }
  };

  return (
    <>
      <SessionContext.Consumer>
        {({ auth }) => {
          if (auth.isAuthenticated) {
            return <Redirect to={props.location.from ? props.location.from.pathname : '/'} />;
          } else {
            if (auth.isAuthenticated) {
              return null;
            } else {
              return (
                <div className="App">
                  <HeaderMarquetPlace />
                  <Login />
                  <Footer />
                </div>
              );
            }
          }
        }}
      </SessionContext.Consumer>
    </>
  );
};

export default Guest;
