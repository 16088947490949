import React from 'react';
import styled from '@emotion/styled';
import { IconCampaign, IconMetrics } from './icons';

const IconSquareButtonNewStyled = styled.button`
  background: #ffffff;
  box-shadow: 0px 0px 6px #00000029;
  height: 100px;
  min-width: 100px;
  padding: 0px;
  border: 0px;
  padding-top: 12px;
  width: 100px;
  cursor: pointer !important;
  :hover {
    cursor: pointer;
    background: #00b0eb !important;
  }
  :hover svg {
    fill: #ffffff;
  }
  p {
    color: #4d4d4d !important;
    cursor: pointer !important;
    font-size: 12px;
    margin: 0px;
  }
  :hover p {
    color: #ffffff !important;
  }
  position: relative;
`;

const ChipNew = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 4px;
  gap: 8px;
  width: 35px;
  height: 16px;
  background: #e54717;
  border: 1px solid #ffffff;
  border-radius: 20px;
  position: absolute;
  right: 13px;
  top: 24px;
`;

const ChipNewText = styled.div`
  width: 27px;
  height: 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

interface IProps {
  icon?: 'IconCampaign';
  label?: string;
  id: string;
  onClick: () => void;
  chip: boolean;
}

const getIconSvg = (icon?: string) => {
  switch (icon) {
    case 'IconCampaign':
      return <IconCampaign className="ButtonNavigation-center-icon" />;
    case 'IconMetrics':
      return <IconMetrics className="ButtonNavigation-center-icon" />;
    default:
      return null;
  }
};

const SquareButtonNew = (props: IProps) => {
  const { icon, label, onClick, id, chip } = props;
  return (
    <IconSquareButtonNewStyled id={id} className="itemsMenu" onClick={onClick}>
      {chip && (
        <ChipNew>
          <ChipNewText>Nuevo</ChipNewText>
        </ChipNew>
      )}

      {getIconSvg(icon)}
      <br />
      {label}
    </IconSquareButtonNewStyled>
  );
};

export default SquareButtonNew;
