import React from 'react';
import { Icon, Typography, colors } from '@cencosud-ds/bigbang';
import { IHeaderUpload } from './interfaces';
import './style.css';

export const HeaderDrawer: React.FC<IHeaderUpload> = props => {
  const { title, onClose } = props;

  return (
    <div className={'reputation-drawer-header-invoce'}>
      <Typography weight="bold" color={colors.neutral[500]} typeElement="subtitle1">
        {title}
      </Typography>
      <div style={{ color: '#0471cb' }}>
        <div className="reputation-drawer-header-invoce-close" onClick={onClose}>
          <Icon name="Close" size="l" />
        </div>
      </div>
    </div>
  );
};
