/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';

export const SlideTransition = styled.div`
  .slide-rtl-enter {
    right: ${props => props.startOffset};
    transition: right ${props => props.duration}ms ease-in-out;
  }
  .slide-rtl-enter-active {
    right: ${props => props.endOffset};
  }

  .slide-rtl-enter-done {
    right: ${props => props.endOffset};
  }
  .slide-rtl-exit {
    right: ${props => props.endOffset};
    transition: right ${props => props.duration}ms ease-in-out;
  }
  .slide-rtl-exit-active {
    right: ${props => props.startOffset};
  }
  .slide-ltr-enter {
    left: ${props => props.startOffset};
    transition: left ${props => props.duration}ms ease-in-out;
  }
  .slide-ltr-enter-active {
    left: ${props => props.endOffset};
  }

  .slide-ltr-enter-done {
    left: ${props => props.endOffset};
  }
  .slide-ltr-exit {
    left: ${props => props.endOffset};
    transition: left ${props => props.duration}ms ease-in-out;
  }
  .slide-ltr-exit-active {
    left: ${props => props.startOffset};
  }
`;
