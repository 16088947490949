const cookies = {
  set: (name, value, options) => {
    let cookie = `${name}=${value}`;
    if (options) {
      let keyValue;
      Object.keys(options).forEach(key => {
        keyValue = key === 'maxAge' ? 'max-age' : key;
        cookie += `;${keyValue}=${options[key]}`;
      });
    }
    document.cookie = cookie;
  },
  get: name => {
    let cookies = document.cookie.split(';');
    const regexp = new RegExp(`${name}=\\S`);
    const match = cookies.find(value => value.match(regexp));

    if (!match) {
      return false;
    }

    const splitted = match.split('=');
    return splitted[1];
  },
  delete: name => {
    var expiration = new Date();
    expiration.setDate(expiration.getDate() - 1);
    cookies.set(name, '', { expires: expiration.toUTCString() });
  },
};

export default cookies;
