import React from 'react';
import { Toast as BBToast } from '@cencosud-ds/bigbang';
import './style.css';

export const Toast: React.FC<{ message: string }> = props => {
  return (
    <BBToast variant="error" extended={false}>
      <p className="toast-message">{props.message}</p>
    </BBToast>
  );
};
