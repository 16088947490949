import React from 'react';
import { Footer, Header } from './components';
import { Content } from './components';

export function AccessHall() {
  return (
    <>
      <Header />
      <Content />
      <Footer />
    </>
  );
}
