export const Country = [
  { value: 'AFGANISTAN', label: 'AFGANISTAN', children: 'AFGANISTAN' },
  { value: 'ALBANIA', label: 'ALBANIA', children: 'ALBANIA' },
  { value: 'ALEMANIA', label: 'ALEMANIA', children: 'ALEMANIA' },
  { value: 'ALGERIA', label: 'ALGERIA', children: 'ALGERIA' },
  { value: 'ANDORRA', label: 'ANDORRA', children: 'ANDORRA' },
  { value: 'ANGOLA', label: 'ANGOLA', children: 'ANGOLA' },
  { value: 'ANGUILLA', label: 'ANGUILLA', children: 'ANGUILLA' },
  { value: 'ANTIGUA Y BARBADOS', label: 'ANTIGUA Y BARBADOS', children: 'ANTIGUA Y BARBADOS' },
  { value: 'ANTILLAS', label: 'ANTILLAS', children: 'ANTILLAS' },
  { value: 'HOLANDESAS', label: 'HOLANDESAS', children: 'HOLANDESAS' },
  { value: 'ARABIA SAUDITA', label: 'ARABIA SAUDITA', children: 'ARABIA SAUDITA' },
  { value: 'ARGELIA', label: 'ARGELIA', children: 'ARGELIA' },
  { value: 'ARGENTINA', label: 'ARGENTINA', children: 'ARGENTINA' },
  { value: 'ARMENIA', label: 'ARMENIA', children: 'ARMENIA' },
  { value: 'ARUBA', label: 'ARUBA', children: 'ARUBA' },
  { value: 'AUSTRALIA', label: 'AUSTRALIA', children: 'AUSTRALIA' },
  { value: 'AUSTRIA', label: 'AUSTRIA', children: 'AUSTRIA' },
  { value: 'AZERBAIJAN', label: 'AZERBAIJAN', children: 'AZERBAIJAN' },
  { value: 'AZORES', label: 'AZORES', children: 'AZORES' },
  { value: 'BAHAMAS', label: 'BAHAMAS', children: 'BAHAMAS' },
  { value: 'BAHRAIN', label: 'BAHRAIN', children: 'BAHRAIN' },
  { value: 'BALEARIC', label: 'BALEARIC', children: 'BALEARIC' },
  { value: 'BANGLADESH', label: 'BANGLADESH', children: 'BANGLADESH' },
  { value: 'BARBADOS', label: 'BARBADOS', children: 'BARBADOS' },
  { value: 'BELARUS', label: 'BELARUS', children: 'BELARUS' },
  { value: 'BELGICA', label: 'BELGICA', children: 'BELGICA' },
  { value: 'BELIZE', label: 'BELIZE', children: 'BELIZE' },
  { value: 'BENIN', label: 'BENIN', children: 'BENIN' },
  { value: 'BERMUDA', label: 'BERMUDA', children: 'BERMUDA' },
  { value: 'BHUTAN', label: 'BHUTAN', children: 'BHUTAN' },
  { value: 'BIRMANIA', label: 'BIRMANIA', children: 'BIRMANIA' },
  { value: 'BOLIVIA', label: 'BOLIVIA', children: 'BOLIVIA' },
  { value: 'BOSNIA', label: 'BOSNIA', children: 'BOSNIA' },
  { value: 'BOSNIA Y HERZEGOVINA', label: 'BOSNIA Y HERZEGOVINA', children: 'BOSNIA Y HERZEGOVINA' },
  { value: 'BOTSWANA', label: 'BOTSWANA', children: 'BOTSWANA' },
  { value: 'BRASIL', label: 'BRASIL', children: 'BRASIL' },
  { value: 'BRUNEI', label: 'BRUNEI', children: 'BRUNEI' },
  { value: 'BULGARIA', label: 'BULGARIA', children: 'BULGARIA' },
  { value: 'BURKINA FASO', label: 'BURKINA FASO', children: 'BURKINA FASO' },
  { value: 'BURUNDI', label: 'BURUNDI', children: 'BURUNDI' },
  { value: 'CABO VERDE', label: 'CABO VERDE', children: 'CABO VERDE' },
  { value: 'CAMBODIA', label: 'CAMBODIA', children: 'CAMBODIA' },
  { value: 'CAMERUN', label: 'CAMERUN', children: 'CAMERUN' },
  { value: 'CANADA', label: 'CANADA', children: 'CANADA' },
  { value: 'CHAD', label: 'CHAD', children: 'CHAD' },
  { value: 'CHANNEL ISLANDS', label: 'CHANNEL ISLANDS', children: 'CHANNEL ISLANDS' },
  { value: 'CHECHENIA', label: 'CHECHENIA', children: 'CHECHENIA' },
  { value: 'CHILE', label: 'CHILE', children: 'CHILE' },
  { value: 'CHINA', label: 'CHINA', children: 'CHINA' },
  { value: 'CHIPRE', label: 'CHIPRE', children: 'CHIPRE' },
  { value: 'CIUDAD DEL VATICANO', label: 'CIUDAD DEL VATICANO', children: 'CIUDAD DEL VATICANO' },
  { value: 'COCOS', label: 'COCOS', children: 'COCOS' },
  { value: 'COLOMBIA', label: 'COLOMBIA', children: 'COLOMBIA' },
  { value: 'COMOROS', label: 'COMOROS', children: 'COMOROS' },
  { value: 'CONGO', label: 'CONGO', children: 'CONGO' },
  { value: 'COREA DEL NORTE', label: 'COREA DEL NORTE', children: 'COREA DEL NORTE' },
  { value: 'COREA DEL SUR', label: 'COREA DEL SUR', children: 'COREA DEL SUR' },
  { value: 'COSTA DE MARFIL', label: 'COSTA DE MARFIL', children: 'COSTA DE MARFIL' },
  { value: 'COSTA RICA', label: 'COSTA RICA', children: 'COSTA RICA' },
  { value: 'CROACIA', label: 'CROACIA', children: 'CROACIA' },
  { value: 'CUBA', label: 'CUBA', children: 'CUBA' },
  { value: 'DINAMARCA', label: 'DINAMARCA', children: 'DINAMARCA' },
  { value: 'DJIBOUTI', label: 'DJIBOUTI', children: 'DJIBOUTI' },
  { value: 'DOMINICA', label: 'DOMINICA', children: 'DOMINICA' },
  { value: 'ECUADOR', label: 'ECUADOR', children: 'ECUADOR' },
  { value: 'EGIPTO', label: 'EGIPTO', children: 'EGIPTO' },
  { value: 'EL SALVADOR', label: 'EL SALVADOR', children: 'EL SALVADOR' },
  { value: 'EMIRATOS ARABES UNIDOS', label: 'EMIRATOS ARABES UNIDOS', children: 'EMIRATOS ARABES UNIDOS' },
  { value: 'ERITREA', label: 'ERITREA', children: 'ERITREA' },
  { value: 'ESPANA', label: 'ESPANA', children: 'ESPANA' },
  {
    value: 'ESTADOS FEDERADOS DE MICRONESIA',
    label: 'ESTADOS FEDERADOS DE MICRONESIA',
    children: 'ESTADOS FEDERADOS DE MICRONESIA',
  },
  { value: 'ESTADOS UNIDOS', label: 'ESTADOS UNIDOS', children: 'ESTADOS UNIDOS' },
  { value: 'ESTONIA', label: 'ESTONIA', children: 'ESTONIA' },
  { value: 'ETIOPIA', label: 'ETIOPIA', children: 'ETIOPIA' },
  { value: 'FIJI', label: 'FIJI', children: 'FIJI' },
  { value: 'FILIPINAS', label: 'FILIPINAS', children: 'FILIPINAS' },
  { value: 'FINLANDIA', label: 'FINLANDIA', children: 'FINLANDIA' },
  { value: 'FRANCIA', label: 'FRANCIA', children: 'FRANCIA' },
  { value: 'GABON', label: 'GABON', children: 'GABON' },
  { value: 'GAMBIA', label: 'GAMBIA', children: 'GAMBIA' },
  { value: 'GEORGIA', label: 'GEORGIA', children: 'GEORGIA' },
  { value: 'GEORGIA Y SANDWICH', label: 'GEORGIA Y SANDWICH', children: 'GEORGIA Y SANDWICH' },
  { value: 'GHANA', label: 'GHANA', children: 'GHANA' },
  { value: 'GIBRALTAR', label: 'GIBRALTAR', children: 'GIBRALTAR' },
  { value: 'GRANADA', label: 'GRANADA', children: 'GRANADA' },
  { value: 'GRECIA', label: 'GRECIA', children: 'GRECIA' },
  { value: 'GROENLANDIA', label: 'GROENLANDIA', children: 'GROENLANDIA' },
  { value: 'GUADALUPE', label: 'GUADALUPE', children: 'GUADALUPE' },
  { value: 'GUAM', label: 'GUAM', children: 'GUAM' },
  { value: 'GUATEMALA', label: 'GUATEMALA', children: 'GUATEMALA' },
  { value: 'GUAYANA FRANCESA', label: 'GUAYANA FRANCESA', children: 'GUAYANA FRANCESA' },
  { value: 'GUERNSEY', label: 'GUERNSEY', children: 'GUERNSEY' },
  { value: 'GUINEA', label: 'GUINEA', children: 'GUINEA' },
  { value: 'GUINEA ECUATORIAL', label: 'GUINEA ECUATORIAL', children: 'GUINEA ECUATORIAL' },
  { value: 'GUINEA-BISSAU', label: 'GUINEA-BISSAU', children: 'GUINEA-BISSAU' },
  { value: 'GUYANA', label: 'GUYANA', children: 'GUYANA' },
  { value: 'HAITI', label: 'HAITI', children: 'HAITI' },
  { value: 'HERZEGOVINA', label: 'HERZEGOVINA', children: 'HERZEGOVINA' },
  { value: 'HOLANDA', label: 'HOLANDA', children: 'HOLANDA' },
  { value: 'HONDURAS', label: 'HONDURAS', children: 'HONDURAS' },
  { value: 'HONG KONG', label: 'HONG KONG', children: 'HONG KONG' },
  { value: 'HUNGRIA', label: 'HUNGRIA', children: 'HUNGRIA' },
  { value: 'INDIA', label: 'INDIA', children: 'INDIA' },
  { value: 'INDONESIA', label: 'INDONESIA', children: 'INDONESIA' },
  { value: 'IRAK', label: 'IRAK', children: 'IRAK' },
  { value: 'IRAN', label: 'IRAN', children: 'IRAN' },
  { value: 'IRLANDA', label: 'IRLANDA', children: 'IRLANDA' },
  { value: 'ISLA ASCENSION', label: 'ISLA ASCENSION', children: 'ISLA ASCENSION' },
  { value: 'ISLA BOUVET', label: 'ISLA BOUVET', children: 'ISLA BOUVET' },
  { value: 'ISLA DE MADEIRA', label: 'ISLA DE MADEIRA', children: 'ISLA DE MADEIRA' },
  { value: 'ISLA DE NAVIDAD', label: 'ISLA DE NAVIDAD', children: 'ISLA DE NAVIDAD' },
  { value: 'ISLA FALKLAND', label: 'ISLA FALKLAND', children: 'ISLA FALKLAND' },
  { value: 'ISLA FAROE', label: 'ISLA FAROE', children: 'ISLA FAROE' },
  { value: 'ISLA NORFOLK', label: 'ISLA NORFOLK', children: 'ISLA NORFOLK' },
  { value: 'ISLANDIA', label: 'ISLANDIA', children: 'ISLANDIA' },
  { value: 'ISLAS CAIMAN', label: 'ISLAS CAIMAN', children: 'ISLAS CAIMAN' },
  { value: 'ISLAS COOK', label: 'ISLAS COOK', children: 'ISLAS COOK' },
  { value: 'ISLAS DE NORFOLK', label: 'ISLAS DE NORFOLK', children: 'ISLAS DE NORFOLK' },
  { value: 'ISLAS HEARD Y MCDONALD', label: 'ISLAS HEARD Y MCDONALD', children: 'ISLAS HEARD Y MCDONALD' },
  { value: 'ISLAS MARIANAS DEL NORTE', label: 'ISLAS MARIANAS DEL NORTE', children: 'ISLAS MARIANAS DEL NORTE' },
  { value: 'ISLAS MARSHALL', label: 'ISLAS MARSHALL', children: 'ISLAS MARSHALL' },
  { value: 'ISLAS PITCAIRN', label: 'ISLAS PITCAIRN', children: 'ISLAS PITCAIRN' },
  { value: 'ISLAS SALOMON', label: 'ISLAS SALOMON', children: 'ISLAS SALOMON' },
  { value: 'ISLAS VIRGENES BRITANICAS', label: 'ISLAS VIRGENES BRITANICAS', children: 'ISLAS VIRGENES BRITANICAS' },
  { value: 'ISLAS VIRGENES US', label: 'ISLAS VIRGENES US', children: 'ISLAS VIRGENES US' },
  { value: 'ISLE OF MAN', label: 'ISLE OF MAN', children: 'ISLE OF MAN' },
  { value: 'ISRAEL', label: 'ISRAEL', children: 'ISRAEL' },
  { value: 'ITALIA', label: 'ITALIA', children: 'ITALIA' },
  { value: 'JAMAICA', label: 'JAMAICA', children: 'JAMAICA' },
  { value: 'JAPON', label: 'JAPON', children: 'JAPON' },
  { value: 'JERSEY', label: 'JERSEY', children: 'JERSEY' },
  { value: 'JORDANIA', label: 'JORDANIA', children: 'JORDANIA' },
  { value: 'KAZAKHSTAN', label: 'KAZAKHSTAN', children: 'KAZAKHSTAN' },
  { value: 'KENIA', label: 'KENIA', children: 'KENIA' },
  { value: 'KIRGUIZISTAN', label: 'KIRGUIZISTAN', children: 'KIRGUIZISTAN' },
  { value: 'KIRIBATI', label: 'KIRIBATI', children: 'KIRIBATI' },
  { value: 'KOSOVO', label: 'KOSOVO', children: 'KOSOVO' },
  { value: 'KUWAIT', label: 'KUWAIT', children: 'KUWAIT' },
  { value: 'LAOS', label: 'LAOS', children: 'LAOS' },
  { value: 'LATVIA', label: 'LATVIA', children: 'LATVIA' },
  { value: 'LESOTHO', label: 'LESOTHO', children: 'LESOTHO' },
  { value: 'LIBANO', label: 'LIBANO', children: 'LIBANO' },
  { value: 'LIBERIA', label: 'LIBERIA', children: 'LIBERIA' },
  { value: 'LIBIA', label: 'LIBIA', children: 'LIBIA' },
  { value: 'LIECHTENSTEIN', label: 'LIECHTENSTEIN', children: 'LIECHTENSTEIN' },
  { value: 'LITUANIA', label: 'LITUANIA', children: 'LITUANIA' },
  { value: 'LUXEMBURGO', label: 'LUXEMBURGO', children: 'LUXEMBURGO' },
  { value: 'MACAO', label: 'MACAO', children: 'MACAO' },
  { value: 'MACEDONIA', label: 'MACEDONIA', children: 'MACEDONIA' },
  { value: 'MADAGASCAR', label: 'MADAGASCAR', children: 'MADAGASCAR' },
  { value: 'MALASIA', label: 'MALASIA', children: 'MALASIA' },
  { value: 'MALAWI', label: 'MALAWI', children: 'MALAWI' },
  { value: 'MALDIVES', label: 'MALDIVES', children: 'MALDIVES' },
  { value: 'MALI', label: 'MALI', children: 'MALI' },
  { value: 'MALTA', label: 'MALTA', children: 'MALTA' },
  { value: 'MARRUECOS', label: 'MARRUECOS', children: 'MARRUECOS' },
  { value: 'MARTINICA', label: 'MARTINICA', children: 'MARTINICA' },
  { value: 'MAURITANIA', label: 'MAURITANIA', children: 'MAURITANIA' },
  { value: 'MAURITIUS', label: 'MAURITIUS', children: 'MAURITIUS' },
  { value: 'MAYOTTE', label: 'MAYOTTE', children: 'MAYOTTE' },
  { value: 'MEXICO', label: 'MEXICO', children: 'MEXICO' },
  { value: 'MOLDOVA', label: 'MOLDOVA', children: 'MOLDOVA' },
  { value: 'MONACO', label: 'MONACO', children: 'MONACO' },
  { value: 'MONGOLIA', label: 'MONGOLIA', children: 'MONGOLIA' },
  { value: 'MONTENEGRO', label: 'MONTENEGRO', children: 'MONTENEGRO' },
  { value: 'MONTSERRAT', label: 'MONTSERRAT', children: 'MONTSERRAT' },
  { value: 'MOZAMBIQUE', label: 'MOZAMBIQUE', children: 'MOZAMBIQUE' },
  { value: 'NAMIBIA', label: 'NAMIBIA', children: 'NAMIBIA' },
  { value: 'NAURU', label: 'NAURU', children: 'NAURU' },
  { value: 'NEPAL', label: 'NEPAL', children: 'NEPAL' },
  { value: 'NICARAGUA', label: 'NICARAGUA', children: 'NICARAGUA' },
  { value: 'NIGER', label: 'NIGER', children: 'NIGER' },
  { value: 'NIGERIA', label: 'NIGERIA', children: 'NIGERIA' },
  { value: 'NIUE', label: 'NIUE', children: 'NIUE' },
  { value: 'NORUEGA', label: 'NORUEGA', children: 'NORUEGA' },
  { value: 'NUEVA CALEDONIA', label: 'NUEVA CALEDONIA', children: 'NUEVA CALEDONIA' },
  { value: 'NUEVA ZELANDIA', label: 'NUEVA ZELANDIA', children: 'NUEVA ZELANDIA' },
  { value: 'OMAN', label: 'OMAN', children: 'OMAN' },
  { value: 'PAKISTAN', label: 'PAKISTAN', children: 'PAKISTAN' },
  { value: 'PALAU', label: 'PALAU', children: 'PALAU' },
  { value: 'PALESTINA', label: 'PALESTINA', children: 'PALESTINA' },
  { value: 'PANAMA', label: 'PANAMA', children: 'PANAMA' },
  { value: 'PAPUA NUEVA GUINEA', label: 'PAPUA NUEVA GUINEA', children: 'PAPUA NUEVA GUINEA' },
  { value: 'PARAGUAY', label: 'PARAGUAY', children: 'PARAGUAY' },
  { value: 'PERU', label: 'PERU', children: 'PERU' },
  { value: 'PITCAIRN', label: 'PITCAIRN', children: 'PITCAIRN' },
  { value: 'POLINESIA FRANCESA', label: 'POLINESIA FRANCESA', children: 'POLINESIA FRANCESA' },
  { value: 'POLONIA', label: 'POLONIA', children: 'POLONIA' },
  { value: 'PORTUGAL', label: 'PORTUGAL', children: 'PORTUGAL' },
  { value: 'PUERTO RICO', label: 'PUERTO RICO', children: 'PUERTO RICO' },
  { value: 'QATAR', label: 'QATAR', children: 'QATAR' },
  { value: 'REINO UNIDO', label: 'REINO UNIDO', children: 'REINO UNIDO' },
  { value: 'REPUBLICA CHECA', label: 'REPUBLICA CHECA', children: 'REPUBLICA CHECA' },
  {
    value: 'REPUBLICA DE AFRICA CENTRAL',
    label: 'REPUBLICA DE AFRICA CENTRAL',
    children: 'REPUBLICA DE AFRICA CENTRAL',
  },
  { value: 'REPUBLICA DE SUDAN DEL SUR', label: 'REPUBLICA DE SUDAN DEL SUR', children: 'REPUBLICA DE SUDAN DEL SUR' },
  { value: 'REPUBLICA DEL CONGO', label: 'REPUBLICA DEL CONGO', children: 'REPUBLICA DEL CONGO' },
  {
    value: 'REPUBLICA DEMOCRATICA DEL CONGO',
    label: 'REPUBLICA DEMOCRATICA DEL CONGO',
    children: 'REPUBLICA DEMOCRATICA DEL CONGO',
  },
  { value: 'REPUBLICA DOMINICANA', label: 'REPUBLICA DOMINICANA', children: 'REPUBLICA DOMINICANA' },
  { value: 'REUNION', label: 'REUNION', children: 'REUNION' },
  { value: 'RUANDA', label: 'RUANDA', children: 'RUANDA' },
  { value: 'RUMANIA', label: 'RUMANIA', children: 'RUMANIA' },
  { value: 'RUSIA', label: 'RUSIA', children: 'RUSIA' },
  { value: 'SAHARA OCCIDENTAL', label: 'SAHARA OCCIDENTAL', children: 'SAHARA OCCIDENTAL' },
  { value: 'SAINT KITTS Y NEVIS', label: 'SAINT KITTS Y NEVIS', children: 'SAINT KITTS Y NEVIS' },
  { value: 'SAMOA', label: 'SAMOA', children: 'SAMOA' },
  { value: 'SAMOA AMERICANA', label: 'SAMOA AMERICANA', children: 'SAMOA AMERICANA' },
  { value: 'SAN MARINO', label: 'SAN MARINO', children: 'SAN MARINO' },
  { value: 'SAN PEDRO Y MIGUELON', label: 'SAN PEDRO Y MIGUELON', children: 'SAN PEDRO Y MIGUELON' },
  { value: 'SAN VICENTE Y GRANADA', label: 'SAN VICENTE Y GRANADA', children: 'SAN VICENTE Y GRANADA' },
  { value: 'SANTA HELENA', label: 'SANTA HELENA', children: 'SANTA HELENA' },
  { value: 'SANTA LUCIA', label: 'SANTA LUCIA', children: 'SANTA LUCIA' },
  { value: 'SAO TOME Y PRINCIPE', label: 'SAO TOME Y PRINCIPE', children: 'SAO TOME Y PRINCIPE' },
  { value: 'SENEGAL', label: 'SENEGAL', children: 'SENEGAL' },
  { value: 'SERBIA Y MONTENEGRO', label: 'SERBIA Y MONTENEGRO', children: 'SERBIA Y MONTENEGRO' },
  { value: 'SEYCHELLES', label: 'SEYCHELLES', children: 'SEYCHELLES' },
  { value: 'SIERRA LEONA', label: 'SIERRA LEONA', children: 'SIERRA LEONA' },
  { value: 'SINGAPUR', label: 'SINGAPUR', children: 'SINGAPUR' },
  { value: 'SIRIA', label: 'SIRIA', children: 'SIRIA' },
  { value: 'SLOVAKIA', label: 'SLOVAKIA', children: 'SLOVAKIA' },
  { value: 'SLOVENIA', label: 'SLOVENIA', children: 'SLOVENIA' },
  { value: 'SOMALIA', label: 'SOMALIA', children: 'SOMALIA' },
  { value: 'SRI LANKA', label: 'SRI LANKA', children: 'SRI LANKA' },
  { value: 'ST. HELENA', label: 'ST. HELENA', children: 'ST. HELENA' },
  { value: 'SUD AFRICA', label: 'SUD AFRICA', children: 'SUD AFRICA' },
  { value: 'SUDAN', label: 'SUDAN', children: 'SUDAN' },
  { value: 'SUECIA', label: 'SUECIA', children: 'SUECIA' },
  { value: 'SUIZA', label: 'SUIZA', children: 'SUIZA' },
  { value: 'SURINAME', label: 'SURINAME', children: 'SURINAME' },
  { value: 'SVALBARD', label: 'SVALBARD', children: 'SVALBARD' },
  { value: 'SWAZILANDIA', label: 'SWAZILANDIA', children: 'SWAZILANDIA' },
  { value: 'TAILANDIA', label: 'TAILANDIA', children: 'TAILANDIA' },
  { value: 'TAIWAN', label: 'TAIWAN', children: 'TAIWAN' },
  { value: 'TAJIKISTAN', label: 'TAJIKISTAN', children: 'TAJIKISTAN' },
  { value: 'TANZANIA', label: 'TANZANIA', children: 'TANZANIA' },
  { value: 'TIMOR DEL ESTE', label: 'TIMOR DEL ESTE', children: 'TIMOR DEL ESTE' },
  { value: 'TOGO', label: 'TOGO', children: 'TOGO' },
  { value: 'TOKELAU', label: 'TOKELAU', children: 'TOKELAU' },
  { value: 'TONGA', label: 'TONGA', children: 'TONGA' },
  { value: 'TRINIDAD Y TOBAGO', label: 'TRINIDAD Y TOBAGO', children: 'TRINIDAD Y TOBAGO' },
  { value: 'TUNEZ', label: 'TUNEZ', children: 'TUNEZ' },
  { value: 'TURCOS Y CAICOS', label: 'TURCOS Y CAICOS', children: 'TURCOS Y CAICOS' },
  { value: 'TURKMENISTAN', label: 'TURKMENISTAN', children: 'TURKMENISTAN' },
  { value: 'TURQUIA', label: 'TURQUIA', children: 'TURQUIA' },
  { value: 'TUVALU', label: 'TUVALU', children: 'TUVALU' },
  { value: 'UCRANIA', label: 'UCRANIA', children: 'UCRANIA' },
  { value: 'UGANDA', label: 'UGANDA', children: 'UGANDA' },
  { value: 'URUGUAY', label: 'URUGUAY', children: 'URUGUAY' },
  { value: 'UZBEKISTAN', label: 'UZBEKISTAN', children: 'UZBEKISTAN' },
  { value: 'VANUATU', label: 'VANUATU', children: 'VANUATU' },
  { value: 'VATICANO', label: 'VATICANO', children: 'VATICANO' },
  { value: 'VENEZUELA', label: 'VENEZUELA', children: 'VENEZUELA' },
  { value: 'VIETNAM', label: 'VIETNAM', children: 'VIETNAM' },
  { value: 'WALLIS Y FUTUNA', label: 'WALLIS Y FUTUNA', children: 'WALLIS Y FUTUNA' },
  { value: 'YEMEN', label: 'YEMEN', children: 'YEMEN' },
  { value: 'YIBUTI', label: 'YIBUTI', children: 'YIBUTI' },
  { value: 'YUGOSLAVIA', label: 'YUGOSLAVIA', children: 'YUGOSLAVIA' },
  { value: 'ZAIRE', label: 'ZAIRE', children: 'ZAIRE' },
  { value: 'ZAMBIA', label: 'ZAMBIA', children: 'ZAMBIA' },
  { value: 'ZIMBAWE', label: 'ZIMBAWE', children: 'ZIMBAWE' },
].sort((a, b) => a.value.localeCompare(b.value));
