import React, { useState } from 'react';
import { Typography, colors } from '@cencosud-ds/bigbang';
import { BottomBar } from '../../components';
import { IWizardFormProps } from '../../interfaces/wizard.interface';
import { useWizardFormControl } from '../../context';
import { CompanyInfo, CompanyHeadAddress, CompanyAboutProducts } from './components';
import { useFormik } from 'formik';
import validationSchema from './company.schema';
import t from '../../../../../utils/translate';
import './index.css';

const CompanyStep: React.FC<IWizardFormProps> = props => {
  const { position } = props;
  const [inputsFocused, setInputFocused] = useState(false);
  const formWizard = useWizardFormControl(position);
  const formik = useFormik({
    initialValues: {
      businessName: formWizard ? formWizard.data.businessName : '',
      companyHeadAddress: formWizard ? formWizard.data.companyHeadAddress : '',
      companyHeadAddressRegion: formWizard ? formWizard.data.companyHeadAddressRegion : '',
      companyHeadAddressRegionsCodePostal: formWizard ? formWizard.data.companyHeadAddressRegionsCodePostal : '',
      companyHeadAddressCommunes: formWizard ? formWizard.data.companyHeadAddressCommunes : '',
      companyName: formWizard ? formWizard.data.companyName : '',
      rutNumber: formWizard ? formWizard.data.rutNumber : '',
      codeUploadFile: formWizard ? formWizard.data.codeUploadFile : '',
      codeUploadFileLabel: formWizard ? formWizard.data.codeUploadFileLabel : '',
      societyType: formWizard ? formWizard.data.societyType : '',
      nProducts: formWizard ? formWizard.data.nProducts : '',
      productMode: formWizard ? formWizard.data.productMode : '',
      categoriesSellers: formWizard ? formWizard.data.categoriesSellers : '',
      oneCategoriesSellers: '',
      sellerWorkUs: formWizard ? formWizard.data.sellerWorkUs : '',
    },
    enableReinitialize: true,
    onSubmit: values => {
      // console.log(values);
    },
    validationSchema,
  });

  const handleOnBack = () => {
    formWizard.data = formik.values;
  };

  const handleOnNext = async (): Promise<boolean> => {
    setInputFocused(false);
    await formik.submitForm();
    const isFormValid = formik.isValid && Object.keys(formik.touched).length > 0;

    if (isFormValid) {
      formWizard.data = formik.values;
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="wizard-form-section pb-3">
        <div className="mb-5">
          <Typography
            typeElement="subtitle1"
            weight="semiBold"
            color={colors.primary[500]}
            children={t('sign_up_tell_us_more_about_your_company')}
          />
        </div>
        <div className="mb-4">
          <CompanyInfo formik={formik} inputsFocused={inputsFocused} />
          <CompanyHeadAddress formik={formik} inputsFocused={inputsFocused} />
          <CompanyAboutProducts formik={formik} inputsFocused={inputsFocused} />
        </div>
      </div>
      <BottomBar onBack={() => handleOnBack()} onNext={() => handleOnNext()} type={'button'} />
    </>
  );
};

export default CompanyStep;
