import { ModuleFederationData } from '../../single-spa/loadDependencies';
import { MicrofrontendInterface } from '../interfaces/microfrontend.interface';

export function getMicrofrontends() {
  const microfrontends: MicrofrontendInterface[] = window.env.MICROFRONTENDS;
  return Array.isArray(microfrontends) ? microfrontends : [];
}

export function getRemoteMicrofrontends() {
  const microfrontends: ModuleFederationData[] = window.env.REACT_APP_MODULE_FEDERATED_REMOTES;
  return Array.isArray(microfrontends) ? microfrontends : [];
}

export function getMicrofrontendByPrefix(prefix: string) {
  return getMicrofrontends().find(m => m.prefix === prefix);
}
