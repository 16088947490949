import React, { useEffect, useRef, useState } from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import { StepInterface } from './interfaces';
import { StepStyled, variants } from './styles';
import { newColors } from '../styles/css';

const Step = (props: StepInterface) => {
  const { statePosition, lines, state, label, description, description2, nextState } = props;

  const wrapperChildren = useRef(null);
  const [widthChildren, setWidth] = useState(0);

  useEffect(() => {
    setWidth(wrapperChildren.current.offsetWidth);
  }, [wrapperChildren]);

  const colorText = state === 'disabled' ? newColors.neutral[200] : newColors.neutral[400];

  return (
    <StepStyled {...props} className={`Step--${statePosition}`} widthLine={widthChildren}>
      <section className="Step__state">
        <span className="Step__state__line--first" />
        {variants[state].icon || <span className="Step__state__not-icon" />}
        <span className={`Step__state__line--last ${lines === 'both' && nextState ? 'nextState' : ''}`} />
      </section>
      <div className="Step__texts" ref={wrapperChildren}>
        <Typography typeElement="body2" weight="bold" color={colorText} children={label} />
        <Typography typeElement="body3" weight="regular" color={colorText} children={description} />
        <Typography
          propsElement={{ className: 'Step__texts__desc--2' }}
          typeElement="body3"
          weight="regular"
          color={colorText}
          children={description2}
        />
      </div>
    </StepStyled>
  );
};

Step.defaultProps = {
  statePosition: 'left',
  state: 'active',
  lines: 'both',
  label: '',
  description: '',
  description2: '',
  nextState: '',
};

export default Step;
