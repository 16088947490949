import React, { useEffect } from 'react';
import { Typography, colors, Button } from '@cencosud-ds/bigbang';
import t from '../../../../../utils/translate';
import SignupSuccessIcon from '../vectors/signup-success';
import '../../index.css';
import { trackGTMEventActionCuston } from 'utils/analytics';

const Success: React.FC<{ handleGoToBegin: () => void; email: string; documentNumber: string }> = props => {
  const { handleGoToBegin, email, documentNumber } = props;

  useEffect(() => {
    trackGTMEventActionCuston({
      category: 'Formulario de registro seller MKP',
      action: 'Step 6 feedback: Recibimos tus datos con éxito',
      tag: btoa(documentNumber),
    });
  }, []);

  return (
    <div className="sending-success">
      <div className="container-horizontal">
        <SignupSuccessIcon />
        <Typography
          typeElement="h1"
          weight="bold"
          color={colors.feedback.information.dark}
          children={
            <div className="success-title">
              <span>{t('sign_up_main_success_title')}</span>
            </div>
          }
        />
        <span className="summary">
          <Typography
            typeElement="caption"
            weight="regular"
            color={'#334661'}
            children={
              <>
                <span>{t('sign_up_main_success_summary')}</span>
                <span style={{ fontWeight: 'bold' }}>{`${email} `}</span>
                <span>{t('sign_up_main_success_summary2')}</span>
              </>
            }
          />
        </span>
        <Button
          darkMode={false}
          type-button="primary"
          variant-button="m"
          disabled={false}
          loading={false}
          width="fit-content"
          onClick={() => handleGoToBegin()}
          children={t('sign_up_main_success_go_login')}
          className="mt-5"
        />
      </div>
    </div>
  );
};

export default Success;
