import React from 'react';

interface IProperties {
  className?: string;
}

const SignupFormIcon = ({ className }: IProperties) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 152 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ className }}
    >
      <path
        d="M78.9453 128.329L83.113 120.334C83.3367 119.905 83.6609 119.536 84.058 119.259L130.339 87.0078"
        stroke="#FFDCB2"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M103.29 24.5469H22.4375C20.7806 24.5469 19.4375 25.89 19.4375 27.5469V140.823"
        stroke="#ECF6FF"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path d="M53.6992 55.2969H95.1749" stroke="#ECF6FF" stroke-width="3" stroke-linecap="round" />
      <path d="M53.6992 53.375H95.1749" stroke="#166DC2" stroke-width="3" stroke-linecap="round" />
      <path d="M53.6992 116.797H74.4371" stroke="#ECF6FF" stroke-width="3" stroke-linecap="round" />
      <path d="M53.6992 86.0469H95.1749" stroke="#ECF6FF" stroke-width="3" stroke-linecap="round" />
      <path d="M53.6992 84.125H95.1749" stroke="#166DC2" stroke-width="3" stroke-linecap="round" />
      <path d="M53.6992 114.875H74.4371" stroke="#166DC2" stroke-width="3" stroke-linecap="round" />
      <path
        d="M106.898 97.5803V22.7422C106.898 21.0853 105.555 19.7422 103.898 19.7422H18.832C17.1752 19.7422 15.832 21.0853 15.832 22.7422V142.628C15.832 144.285 17.1752 145.628 18.832 145.628H103.898C105.555 145.628 106.898 144.285 106.898 142.628V117.76"
        stroke="#166DC2"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M125.834 85.0894L80.0733 116.648C79.9274 116.748 79.8109 116.886 79.7357 117.046L74.2074 128.83C73.8963 129.493 74.3803 130.255 75.1128 130.255H88.5505C88.7565 130.255 88.9574 130.191 89.1259 130.073L133.949 98.543C136.053 96.621 139.539 91.624 136.654 87.0114C133.769 82.3987 128.239 83.8081 125.834 85.0894Z"
        stroke="#FF8A00"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M44.9857 54.3363C44.9857 60.4987 40.3209 65.3288 34.7644 65.3288C29.2078 65.3288 24.543 60.4987 24.543 54.3363C24.543 48.1738 29.2078 43.3438 34.7644 43.3438C40.3209 43.3438 44.9857 48.1738 44.9857 54.3363Z"
        stroke="#166DC2"
        stroke-width="3"
      />
      <path
        d="M44.9857 83.1644C44.9857 89.3269 40.3209 94.1569 34.7644 94.1569C29.2078 94.1569 24.543 89.3269 24.543 83.1644C24.543 77.0019 29.2078 72.1719 34.7644 72.1719C40.3209 72.1719 44.9857 77.0019 44.9857 83.1644Z"
        stroke="#166DC2"
        stroke-width="3"
      />
      <path
        d="M44.9857 113.914C44.9857 120.077 40.3209 124.907 34.7644 124.907C29.2078 124.907 24.543 120.077 24.543 113.914C24.543 107.752 29.2078 102.922 34.7644 102.922C40.3209 102.922 44.9857 107.752 44.9857 113.914Z"
        stroke="#166DC2"
        stroke-width="3"
      />
      <path
        d="M33.8633 52.4143L35.8676 54.5505C36.2518 54.96 36.8978 54.9729 37.2982 54.5791L48.2896 43.7656"
        stroke="#FF8A00"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M34.7656 81.2424L36.7699 83.3786C37.1542 83.7881 37.8002 83.801 38.2005 83.4072L49.1919 72.5938"
        stroke="#FF8A00"
        stroke-width="3"
        stroke-linecap="round"
      />
      <path
        d="M34.7656 111.992L36.7699 114.129C37.1542 114.538 37.8002 114.551 38.2005 114.157L49.1919 103.344"
        stroke="#FF8A00"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default SignupFormIcon;
