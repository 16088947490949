import React from 'react';
import { Header } from '@cencosud-ds/bigbang';
import styled from '@emotion/styled';
import HeaderLogo from '../HeaderMarquetPlace/HeaderLogo';

const HeaderContainer = styled.div`
  border-bottom-style: solid;
  border-bottom-color: #b8dcff;
  border-bottom-width: 1px;
  margin-bottom: 45px;
`;

const HeaderMarquetPlace = () => {
  return (
    <HeaderContainer>
      <Header hideBreadcrumbs={true} iconHeader={<HeaderLogo />}>
        <></>
      </Header>
    </HeaderContainer>
  );
};

export default HeaderMarquetPlace;
