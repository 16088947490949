import * as React from 'react';

function SvgNivelBlocking(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_607_8763)">
        <circle cx="36" cy="36" r="35.5" stroke="#B8DCFF" />
        <circle cx="36" cy="36" r="24" fill="white" />
        <mask id="path-3-inside-1_607_8763" fill="white">
          <path d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z" />
        </mask>
        <path
          d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-3-inside-1_607_8763)"
        />
        <mask id="path-4-inside-2_607_8763" fill="white">
          <path d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z" />
        </mask>
        <path
          d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-4-inside-2_607_8763)"
        />
        <mask id="path-5-inside-3_607_8763" fill="white">
          <path d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z" />
        </mask>
        <path
          d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-5-inside-3_607_8763)"
        />
        <mask id="path-6-inside-4_607_8763" fill="white">
          <path d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z" />
        </mask>
        <path
          d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z"
          stroke="#E1F0FF"
          stroke-width="8"
          mask="url(#path-6-inside-4_607_8763)"
        />
        <path
          d="M42.4545 15.5085L39.7121 20.9782L39.1127 22.164L35.6959 14.3671C35.9057 14.041 36.2804 13.8335 36.685 13.8335H41.4055C42.2747 13.8335 42.8441 14.7377 42.4545 15.5085Z"
          fill="#B8DCFF"
        />
        <path
          d="M42.5594 26.2999V23.8096C42.5594 21.5269 40.6712 19.6592 38.3634 19.6592H33.1783C30.8705 19.6592 28.9823 21.5269 28.9823 23.8096V26.2999C24.1719 28.7457 20.875 33.7113 20.875 39.433C20.875 47.5708 27.5437 54.167 35.7708 54.167C43.998 54.167 50.6667 47.5708 50.6667 39.433C50.6667 33.6965 47.3698 28.7308 42.5594 26.2999ZM39.8619 25.2474C38.5582 24.8769 37.1945 24.6842 35.7708 24.6842C34.3472 24.6842 32.9835 24.8769 31.6797 25.2474V23.8096C31.6797 22.9943 32.3541 22.3273 33.1783 22.3273H38.3634C38.9328 22.3273 39.4274 22.6386 39.6821 23.0981L39.6971 23.1129C39.802 23.3205 39.8619 23.5576 39.8619 23.7948V25.2474Z"
          fill="#B8DCFF"
        />
        <path
          d="M35.771 52.729C28.3531 52.729 22.3138 46.7553 22.3138 39.418C22.3138 32.0806 28.3531 26.1069 35.771 26.1069C43.189 26.1069 49.2282 32.0806 49.2282 39.418C49.2133 46.7553 43.189 52.729 35.771 52.729ZM35.771 27.1445C28.9375 27.1445 23.3628 32.6439 23.3628 39.418C23.3628 46.192 28.9226 51.6914 35.771 51.6914C42.6045 51.6914 48.1792 46.192 48.1792 39.418C48.1792 32.6439 42.6045 27.1445 35.771 27.1445Z"
          fill="white"
        />
        <path
          d="M37.4792 23.7797H33.0584C32.6088 23.7797 32.2042 23.5277 32.0094 23.1423L31.6198 22.3567L28.248 15.6716C27.8583 14.9008 28.4278 13.9966 29.297 13.9966H34.0175C34.482 13.9966 34.9016 14.2634 35.0815 14.6784L38.5582 22.1344C38.5881 22.2085 38.6181 22.2826 38.6331 22.3567C38.7979 23.0682 38.2584 23.7797 37.4792 23.7797Z"
          fill="#B8DCFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M36.0001 41.6668C35.6321 41.6668 35.3335 41.3681 35.3335 41.0001C35.3335 40.6321 35.6321 40.3335 36.0001 40.3335C36.3681 40.3335 36.6668 40.6321 36.6668 41.0001C36.6668 41.3681 36.3681 41.6668 36.0001 41.6668ZM36.0001 39.0001C34.8975 39.0001 34.0001 39.8975 34.0001 41.0001C34.0001 42.1028 34.8975 43.0001 36.0001 43.0001C37.1028 43.0001 38.0001 42.1028 38.0001 41.0001C38.0001 39.8975 37.1028 39.0001 36.0001 39.0001ZM40.0001 43.6668C40.0001 44.0348 39.7015 44.3335 39.3335 44.3335H32.6668C32.2988 44.3335 32.0001 44.0348 32.0001 43.6668V38.3335C32.0001 37.9655 32.2988 37.6668 32.6668 37.6668H33.3335H34.6668H37.3335H38.6668H39.3335C39.7015 37.6668 40.0001 37.9655 40.0001 38.3335V43.6668ZM34.6668 35.0741C34.6668 34.2981 35.2648 33.6668 36.0001 33.6668C36.7355 33.6668 37.3335 34.2981 37.3335 35.0741V36.3335H34.6668V35.0741ZM39.3333 36.3335H38.6666V35.0742C38.6666 33.5635 37.4706 32.3335 36 32.3335C34.5293 32.3335 33.3333 33.5635 33.3333 35.0742V36.3335H32.6666C31.564 36.3335 30.6666 37.2308 30.6666 38.3335V43.6668C30.6666 44.7695 31.564 45.6668 32.6666 45.6668H39.3333C40.436 45.6668 41.3333 44.7695 41.3333 43.6668V38.3335C41.3333 37.2308 40.436 36.3335 39.3333 36.3335Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_607_8763">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNivelBlocking;
