import React from 'react';
import { Modal, Button as ButtonBigbang, Icon } from '@cencosud-ds/bigbang';

export interface IModalContenidoSellerMulticuenta {
  open: boolean;
  cases?: 'document_exists' | 'document_no_exists';
  modalSellerMulticuenta?: any;
  setModalSellerMulticuenta?: any;
  sellerLegalName: string;
  sellerDocumentNumber: string;
}

export const ModalContenidoSellerMulticuenta = (props: IModalContenidoSellerMulticuenta): JSX.Element => {
  const { open, cases, modalSellerMulticuenta, setModalSellerMulticuenta, sellerLegalName } = props;

  return (
    <Modal
      open={cases === 'document_exists' ? open : false}
      persistent={false}
      onClose={() => {
        // return console.log('handler inClose');
      }}
      with-blur={false}
      id="modal-example"
    >
      <section style={{ display: 'flex', flexDirection: 'column', width: 450, padding: 20, color: '#4f4e4e' }}>
        <br />
        <div style={{ textAlign: 'center', color: '#166DC2', fontSize: 18 }}>
          <strong>El RUT ya está registrado</strong>
        </div>
        <br />
        <div style={{ fontSize: 13, marginTop: 10, textAlign: 'center' }}>
          {/* CASO: EXITE RUT */}
          {cases === 'document_exists' && (
            <>
              <p style={{ fontSize: 14 }}>
                El RUT que ingresaste pertenece a la empresa <b>{sellerLegalName}</b>, que ya tiene un nombre comercial
                en Paris Marketplace.
              </p>
              <p style={{ fontSize: 14 }}>
                El nuevo registro que realices quedará asociado al nuevo nombre comercial que ingreses.
              </p>
              <br />
              <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <span style={{ display: 'flex' }}>
                  <Icon name="Info" size="s" /> &nbsp;&nbsp;
                </span>
                <span style={{ fontSize: 11 }}>Para más información, escribenos a onboardingmarketplace@paris.cl</span>
              </div>
            </>
          )}

          {/* CASO: NO EXITE RUT */}
          {/* SE DEJÓ SOLO PARA CUANDO EXISTE EL RUT */}
          {/* como es una prueba de concepto dejé el code por si acaso lo necesitamos mientras se valida */}
          {/* {cases === 'document_no_exists' && (
            <>
              <p>
                El <b>número de documento {RUTJS.format(sellerDocumentNumber)}</b> no está registrado en nuestro seller
                center.
              </p>
              <p>
                <b>Esta cuenta se creará como principal.</b>
              </p>
              <p>Las próximas cuentas creadas con este número de documento serán asociadas como marcas.</p>
              <p>Podrá ingresar de forma independiente al seller center con un email de administrador único.</p>
            </>
          )} */}
        </div>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonBigbang
            onClick={() => setModalSellerMulticuenta({ ...modalSellerMulticuenta, open: false })}
            style={{ width: '100%', padding: 20 }}
          >
            Entendido
          </ButtonBigbang>
        </div>
      </section>
    </Modal>
  );
};
