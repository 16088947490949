import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Notification } from '@cencosud-ds/bigbang';
import { ButtonOk } from '../Button';
import { ButtonBack } from '../ButtonBack';
import { useWizardControl } from '../../context';
import t from '../../../../../utils/translate';
import { trackGTMEventActionCuston } from 'utils/analytics';

interface BottomBarProps {
  onNext: () => boolean | any | Promise<boolean>;
  onBack: () => void | Promise<void>;
  type: 'button';
  enableNext?: boolean | undefined;
}

const BottomBar: React.FC<BottomBarProps> = props => {
  const history = useHistory();
  const [isOutcoming, setIsOutcoming] = useState(false);
  const { onNext: submitNext, onBack: submitBack, enableNext } = props;
  const { step, nextStep, backStep, forms, finish } = useWizardControl();
  const stepQuit = 0;

  const handleQuitClick = () => {
    setIsOutcoming(true);
  };

  const handleQuitClickEnd = () => {
    const documentNumber = forms[0].data.rutNumber || 'NA';
    trackGTMEventActionCuston({
      category: 'Formulario de registro seller MKP',
      action: 'Step 1 datos de empresa: Clic en salir',
      tag: btoa(documentNumber),
    });
    history.push('/login');
  };

  const handleBackClick = async () => {
    if (canBack()) {
      const documentNumber = forms[0].data.rutNumber || 'NA';

      if (step === 0) {
        //
      } else if (step === 1) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 2 informacion de contacto: Clic en volver',
          tag: btoa(documentNumber),
        });
      } else if (step === 2) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 3 informacion legal: Clic en volver',
          tag: btoa(documentNumber),
        });
      } else if (step === 3) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 4 Datos bancarios: Clic en volver',
          tag: btoa(documentNumber),
        });
      } else if (step === 4) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 5 Datos logisticos: Clic en volver',
          tag: btoa(documentNumber),
        });
      }

      await submitBack();
      backStep();
    }
  };

  const handleNextClick = async () => {
    if (canNext() && (await submitNext())) {
      forms[step].success = true;
      // step 0 - datos de empresa
      // step 1 - informacion de contacto
      // step 2 - informacion legal
      // step 3 - Datos bancarios
      // step 4 - Datos logisticos
      // step 5 - Datos logisticos
      // btoa(): creates a Base64-encoded ASCII string from a "string" of binary data ("btoa" should be read as "binary to ASCII").
      // atob(): decodes a Base64-encoded string ("atob" should be read as "ASCII to binary").

      const documentNumber = forms[0].data.rutNumber || 'NA';

      if (step === 0) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 1 datos de empresa: Clic en continuar',
          tag: btoa(documentNumber),
        });
      } else if (step === 1) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 2 informacion de contacto: Clic en continuar',
          tag: btoa(documentNumber),
        });
      } else if (step === 2) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 3 informacion legal: Clic en continuar',
          tag: btoa(documentNumber),
        });
      } else if (step === 3) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 4 Datos bancarios: Clic en continuar',
          tag: btoa(documentNumber),
        });
      } else if (step === 4) {
        trackGTMEventActionCuston({
          category: 'Formulario de registro seller MKP',
          action: 'Step 5 Datos logisticos: Clic en continuar',
          tag: btoa(documentNumber),
        });
      }

      if (step === 0) {
        forms[step].success = true;
      }
      nextStep();
    }
  };

  const handleEndClick = async () => {
    if (canEnd() && (await submitNext())) {
      forms[step].success = true;
      finish();
    }
  };

  const canBack = () => {
    return step > 0;
  };

  const canNext = () => {
    return step < forms.length - 1;
  };

  const canEnd = () => {
    return step === forms.length - 1;
  };

  const isDisabled = () => {
    return enableNext === false ? true : false;
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-end w-100">
        {isOutcoming && (
          <Notification className="notification" extended={true} variant="warning">
            <p className="notification-message">{t('sign_up_tooltip_buttom_warning_quit_click')}</p>
          </Notification>
        )}
        {step === stepQuit && !isOutcoming && (
          <Button
            darkMode={false}
            type-button="secondary"
            variant-button="m"
            disabled={false}
            loading={false}
            width="fit-content"
            onClick={() => handleQuitClick()}
            children={t('sign_up_main_quit')}
            className="ml-3"
          />
        )}

        {step === stepQuit && isOutcoming && (
          <Button
            darkMode={false}
            type-button="secondary"
            variant-button="m"
            disabled={false}
            loading={false}
            width="fit-content"
            onClick={() => handleQuitClickEnd()}
            children={t('sign_up_main_quit')}
            className="ml-3"
          />
        )}

        {canBack() && <ButtonBack disabled={false} onClick={() => handleBackClick()} label={t('sign_up_main_back')} />}
        {canNext() && (
          <ButtonOk disabled={isDisabled()} onClick={() => handleNextClick()} label={t('sign_up_main_next')} />
        )}
        {canEnd() && (
          <Button
            darkMode={false}
            type-button="primary"
            variant-button="m"
            disabled={isDisabled()}
            loading={false}
            width="fit-content"
            onClick={() => handleEndClick()}
            children={t('sign_up_main_end')}
            className="ml-3"
          />
        )}
      </div>
    </>
  );
};

export default BottomBar;
