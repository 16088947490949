import { SELLERS_URL, API_KEY_VALIDATE_DOCUMENT_NUMBER } from '../../config';
import getAxios from '../../clients';
import { AxiosResponse } from 'axios';
import { ISellerConfiguration } from './interfaces';

export const getSellerByDocumentNumber = async (documentSeller: string) =>
  getAxios
    .instance(API_KEY_VALIDATE_DOCUMENT_NUMBER)
    .get(`${SELLERS_URL}/v1/sellers/document-number/${documentSeller}`);

export const getSellerByName = async (name: string): Promise<AxiosResponse<any, any> | boolean> => {
  try {
    return await getAxios.instance(API_KEY_VALIDATE_DOCUMENT_NUMBER).get(`${SELLERS_URL}/v1/sellers/by-name/${name}`);
  } catch (ex) {
    return false;
  }
};

export const getSellerByEmail = async (adminEmail: string) =>
  getAxios.instance(API_KEY_VALIDATE_DOCUMENT_NUMBER).post(`${SELLERS_URL}/v1/sellers/findByAdminEmail/${adminEmail}`);

export const fetchIsInPublishHistory = async (accessToken: string, sellerId: string) => {
  try {
    const sellerHistory = await getAxios
      .instance(accessToken)
      .get(`${SELLERS_URL}/seller-publish-history/get-movements/${sellerId}`);
    return sellerHistory.data;
  } catch (ex) {
    return false;
  }
};

export const getSellerConfiguration = async (jwt: string, sellerId: string): Promise<ISellerConfiguration> => {
  try {
    const response = await getAxios.instance(jwt).get(`${SELLERS_URL}/sellers/seller-configuration/${sellerId}`);
    return response.data;
  } catch (e) {
    return {
      tourGuideStatus: 0,
      updateMailingData: 0,
      topsortLandingStatus: 0,
    };
  }
};
