import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { trackGTMEventAction, trackGTMPageView } from '../utils/analytics';
import { homeRoute, loginRoute } from '../utils/routes';

import { SPA_GTM_EVENT, SPA_ROUTES_READY } from '../config';

const EventPageWrapper = ({ children }) => {
  const location = useLocation();

  // TODO: remove it when the re-renders was solved
  // * hack to prevent duplicate event on gtm due unhandled re-renders
  const [currentPath, setCurrentPath] = useState();
  const [routes, setRoutes] = useState([loginRoute, homeRoute]);

  const handleNamedRoutes = e => {
    setRoutes([...routes, ...e.routes]);
  };

  const handleGTMEvent = e => {
    const data = e.data;
    if (data) {
      trackGTMEventAction({ category: data.category, action: data.action, tag: data.tag });
    }
  };

  useEffect(() => {
    window.addEventListener(SPA_ROUTES_READY, handleNamedRoutes);
    window.addEventListener(SPA_GTM_EVENT, handleGTMEvent);

    return () => {
      window.removeEventListener(SPA_ROUTES_READY, handleNamedRoutes);
      window.removeEventListener(SPA_GTM_EVENT, handleGTMEvent);
    };
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [location, routes]);

  const sendPageViewEvent = () => {
    if (location.pathname && location.pathname !== currentPath) {
      const response = trackGTMPageView({ path: location.pathname, routes });
      if (response) {
        setCurrentPath(location.pathname);
      }
    }
  };

  return <React.Fragment>{children}</React.Fragment>;
};

export default EventPageWrapper;
