import styled from '@emotion/styled';
import { colors } from '@cencosud-ds/bigbang';

export const AlertCard = styled.div`
  display: flex;
  margin-top: 42px;
  padding: 16px;
  flex-direction: column;
  align-items: left;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: ${colors.neutral[100]};
`;

export const AlertCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-self: stretch;
  align-items: center;
`;
