import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Input, Typography } from '@cencosud-ds/bigbang';
import { ToolTip } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { getRegions, getCommunesByRegion, SocietyExtended, Country } from 'utils/signup';
import { allowAlphanumeric, allowAplphabetic, allownumberAndKV20 } from 'utils/patterns';
import { PERSON_NATURAL } from 'variables/society';
import * as RUTJS from 'rut.js';

interface IDataDrop {
  value: string;
  label: string;
  children: string;
}

export const Representative: React.FC<{ formik: any }> = props => {
  const { formik } = props;

  const [optionsCommune, setOptionsCommune] = useState<IDataDrop[]>([]);

  useEffect(() => {
    setOptionsCommune(getCommunesByRegion(formik.values.regionRepresentative));
  }, [formik.values.regionRepresentative]);

  const getMessageTooltip = () => {
    return (
      <>
        <span className="font-message">{t('sign_up_legal_label_first_message_rut')}</span>
        <br />
        <br />
        <span className="bold-message">{t('sign_up_legal_label_important_message')}</span>
        <span className="font-message">{t('sign_up_legal_label_second_rut')}</span>
      </>
    );
  };

  const setRegion = (region: string) => {
    if (region !== formik.values.regionRepresentative) {
      setOptionsCommune([]);
      formik.setFieldValue('regionRepresentative', region);
    }
  };

  return (
    <>
      <div className="content-row-parther">
        <div>
          <Icon name="Person" isFill={false} />
        </div>
        <div className="content-label-parther">
          <Typography
            typeElement="subtitle2"
            weight="semiBold"
            color="#212C39"
            children={t('sign_up_legal_label_representative')}
          />
        </div>
      </div>

      <div className="row mb-8 rows-margin-top">
        <div className="col-6">
          <Dropdown
            dropdown-options={SocietyExtended}
            variant={
              formik.touched.societyTypeRepresentative && formik.errors.societyTypeRepresentative ? 'error' : 'default'
            }
            text-feedback={
              formik.touched.societyTypeRepresentative && formik.errors.societyTypeRepresentative
                ? formik.errors.societyTypeRepresentative
                : ''
            }
            disabled={false}
            width="100%"
            placeholder={t('sign_up_legal_label_society')}
            onChange={value => formik.setFieldValue('societyTypeRepresentative', value)}
            default-value={formik.values.societyTypeRepresentative ? formik.values.societyTypeRepresentative : ''}
            label-text={t('sign_up_choose_company_type')}
          />
        </div>
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypeRepresentative) ? (
            <div className="label-rut">
              <Input
                name="rutNumberRepresentative"
                value={formik.values.rutNumberRepresentative ? formik.values.rutNumberRepresentative : ''}
                input-state={
                  formik.touched.rutNumberRepresentative && formik.errors.rutNumberRepresentative ? 'error' : 'default'
                }
                disabled={false}
                label-text={t('sign_up_legal_label_rut_pn')}
                feedback-text={
                  formik.touched.rutNumberRepresentative && formik.errors.rutNumberRepresentative
                    ? formik.errors.rutNumberRepresentative
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={12}
                onChange={event => {
                  let value = event.target.value;
                  if (value.length > 7) {
                    value = RUTJS.format(value);
                  }
                  return formik.setFieldValue('rutNumberRepresentative', value);
                }}
                onKeyUp={() => {
                  if (
                    formik.values.rutNumberRepresentative &&
                    !allownumberAndKV20.test(formik.values.rutNumberRepresentative)
                  ) {
                    formik.setFieldValue(
                      'rutNumberRepresentative',
                      formik.values.rutNumberRepresentative.slice(0, -1).toUpperCase(),
                    );
                  }
                }}
              />

              <ToolTip msg={getMessageTooltip()} position="right" />
            </div>
          ) : (
            <Input
              name="nameRepresentative"
              value={formik.values.nameRepresentative ? formik.values.nameRepresentative : ''}
              input-state={formik.touched.nameRepresentative && formik.errors.nameRepresentative ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_legal_label_name')}
              feedback-text={
                formik.touched.nameRepresentative && formik.errors.nameRepresentative
                  ? formik.errors.nameRepresentative
                  : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={150}
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="row mb-8 rows-margin-top">
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypeRepresentative) ? (
            <>
              <Input
                name="firstPartherRepresentative"
                value={formik.values.firstPartherRepresentative ? formik.values.firstPartherRepresentative : ''}
                input-state={
                  formik.touched.firstPartherRepresentative && formik.errors.firstPartherRepresentative
                    ? 'error'
                    : 'default'
                }
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_legal_last_name_first_partner')}
                feedback-text={
                  formik.touched.firstPartherRepresentative && formik.errors.firstPartherRepresentative
                    ? formik.errors.firstPartherRepresentative
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={150}
                onKeyPress={event => {
                  if (!allowAplphabetic.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
            </>
          ) : (
            <div className="label-rut">
              <Input
                name="rutNumberRepresentative"
                value={formik.values.rutNumberRepresentative ? formik.values.rutNumberRepresentative : ''}
                input-state={
                  formik.touched.rutNumberRepresentative && formik.errors.rutNumberRepresentative ? 'error' : 'default'
                }
                disabled={false}
                label-text={t('sign_up_legal_label_rut')}
                feedback-text={
                  formik.touched.rutNumberRepresentative && formik.errors.rutNumberRepresentative
                    ? formik.errors.rutNumberRepresentative
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={12}
                onChange={event => {
                  let value = event.target.value;
                  if (value.length > 7) {
                    value = RUTJS.format(value);
                  }
                  return formik.setFieldValue('rutNumberRepresentative', value);
                }}
                onKeyUp={() => {
                  if (
                    formik.values.rutNumberRepresentative &&
                    !allownumberAndKV20.test(formik.values.rutNumberRepresentative)
                  ) {
                    formik.setFieldValue(
                      'rutNumberRepresentative',
                      formik.values.rutNumberRepresentative.slice(0, -1).toUpperCase(),
                    );
                  }
                }}
              />

              <ToolTip msg={getMessageTooltip()} position="right" />
            </div>
          )}
        </div>
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypeRepresentative) ? (
            <>
              <Input
                name="secondNamePartherRepresentative"
                value={
                  formik.values.secondNamePartherRepresentative ? formik.values.secondNamePartherRepresentative : ''
                }
                input-state={
                  formik.touched.secondNamePartherRepresentative && formik.errors.secondNamePartherRepresentative
                    ? 'error'
                    : 'default'
                }
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_legal_last_name_second_partner')}
                feedback-text={
                  formik.touched.secondNamePartherRepresentative && formik.errors.secondNamePartherRepresentative
                    ? formik.errors.secondNamePartherRepresentative
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={150}
                onKeyPress={event => {
                  if (!allowAplphabetic.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
            </>
          ) : (
            <Dropdown
              dropdown-options={Country}
              variant={
                formik.touched.nationalityRepresentative && formik.errors.nationalityRepresentative
                  ? 'error'
                  : 'default'
              }
              text-feedback={
                formik.touched.nationalityRepresentative && formik.errors.nationalityRepresentative
                  ? formik.errors.nationalityRepresentative
                  : ''
              }
              disabled={false}
              width="100%"
              placeholder={t('sign_up_legal_label_nationality')}
              default-value={formik.values.nationalityRepresentative ? formik.values.nationalityRepresentative : ''}
              onChange={value => formik.setFieldValue('nationalityRepresentative', value)}
            />
          )}
        </div>
      </div>
      <div className="row mb-8 rows-margin-top">
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypeRepresentative) &&
          formik.values.societyTypeRepresentative ? (
            <>
              <Dropdown
                dropdown-options={Country}
                variant={
                  formik.touched.nationalityRepresentative && formik.errors.nationalityRepresentative
                    ? 'error'
                    : 'default'
                }
                text-feedback={
                  formik.touched.nationalityRepresentative && formik.errors.nationalityRepresentative
                    ? formik.errors.nationalityRepresentative
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_nationality')}
                default-value={formik.values.nationalityRepresentative ? formik.values.nationalityRepresentative : ''}
                onChange={value => formik.setFieldValue('nationalityRepresentative', value)}
              />
            </>
          ) : (
            <Input
              name="addressRepresentative"
              value={formik.values.addressRepresentative ? formik.values.addressRepresentative : ''}
              input-state={
                formik.touched.addressRepresentative && formik.errors.addressRepresentative ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_legal_label_address')}
              feedback-text={
                formik.touched.addressRepresentative && formik.errors.addressRepresentative
                  ? formik.errors.addressRepresentative
                  : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={255}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          )}
        </div>
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypeRepresentative) ? (
            <Input
              name="addressRepresentative"
              value={formik.values.addressRepresentative ? formik.values.addressRepresentative : ''}
              input-state={
                formik.touched.addressRepresentative && formik.errors.addressRepresentative ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_legal_label_address')}
              feedback-text={
                formik.touched.addressRepresentative && formik.errors.addressRepresentative
                  ? formik.errors.addressRepresentative
                  : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={255}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          ) : (
            <Dropdown
              dropdown-options={getRegions()}
              variant={formik.touched.regionRepresentative && formik.errors.regionRepresentative ? 'error' : 'default'}
              text-feedback={
                formik.touched.regionRepresentative && formik.errors.regionRepresentative
                  ? formik.errors.regionRepresentative
                  : ''
              }
              disabled={false}
              width="100%"
              placeholder={t('sign_up_legal_label_region')}
              default-value={formik.values.regionRepresentative ? formik.values.regionRepresentative : ''}
              onChange={value => setRegion(value)}
            />
          )}
        </div>
      </div>

      <div className="row mb-8 rows-margin-top">
        {[PERSON_NATURAL].includes(formik.values.societyTypeRepresentative) ? (
          <>
            <div className="col-6">
              <Dropdown
                dropdown-options={getRegions()}
                variant={
                  formik.touched.regionRepresentative && formik.errors.regionRepresentative ? 'error' : 'default'
                }
                text-feedback={
                  formik.touched.regionRepresentative && formik.errors.regionRepresentative
                    ? formik.errors.regionRepresentative
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_region')}
                default-value={formik.values.regionRepresentative ? formik.values.regionRepresentative : ''}
                onChange={value => setRegion(value)}
              />
            </div>
            <div className="col-6">
              {optionsCommune && optionsCommune.length > 0 && (
                <Dropdown
                  dropdown-options={optionsCommune}
                  variant={
                    formik.touched.communeRepresentative && formik.errors.communeRepresentative ? 'error' : 'default'
                  }
                  text-feedback={
                    formik.touched.communeRepresentative && formik.errors.communeRepresentative
                      ? formik.errors.communeRepresentative
                      : ''
                  }
                  disabled={false}
                  width="100%"
                  placeholder={t('sign_up_legal_label_commune')}
                  default-value={formik.values.communeRepresentative ? formik.values.communeRepresentative : ''}
                  onChange={value => formik.setFieldValue('communeRepresentative', value)}
                />
              )}
            </div>
          </>
        ) : (
          <div className="col-6">
            {optionsCommune && optionsCommune.length > 0 && (
              <Dropdown
                dropdown-options={optionsCommune}
                variant={
                  formik.touched.communeRepresentative && formik.errors.communeRepresentative ? 'error' : 'default'
                }
                text-feedback={
                  formik.touched.communeRepresentative && formik.errors.communeRepresentative
                    ? formik.errors.communeRepresentative
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_commune')}
                default-value={formik.values.communeRepresentative ? formik.values.communeRepresentative : ''}
                onChange={value => formik.setFieldValue('communeRepresentative', value)}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
