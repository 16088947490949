export const APP_URL = window.env.APP_URL;
export const BACKEND_URL = window.env.BACKEND_URL;
export const SELLERS_URL = window.env.SELLERS_URL;
export const API_KEY_VALIDATE_DOCUMENT_NUMBER = window.env.API_KEY_VALIDATE_DOCUMENT_NUMBER;
export const CONTENT_URL = window.env.CONTENT_URL;
export const CONTENT_URL_2 = window.env.CONTENT_URL_2;
export const IMPORTER_URL = window.env.IMPORTER_URL;
export const REPORTS_URL = window.env.REPORTS_URL;
export const GTM_ID = window.env.GTM_ID || process.env.REACT_APP_GTM_ID;
export const REGISTER_URL = window.env.REGISTER_URL;
export const SERVICES_STATUS_URL = window.env.SERVICES_STATUS_URL;

export const COOKIE_NAME = 'EIFFEL_PROD';
export const COOKIE_NAME_APPROVAL = 'jwt';
export const DEFAULT_LIMIT = 50;
export const LOG_AXIOS = false;

export const SINGLE_SPA_ROUTE_EVENT = 'single-spa:change-route';
export const SPA_FLASH_MESSAGE_EVENT = 'eiffel-spa-message';
export const LOAD_SPA_MESSAGE_EVENT = 'spa-ready';
export const SPA_ROUTES_READY = 'routes-ready';
export const SPA_SERVICES_STATUS_READY = 'services-status-ready';

export const APPROVAL_PREFIX = '/approval';
export const PIM_PREFIX = '/pim';
export const PIM_CONFIGURATION_PREFIX = '/pim-configuration';
export const CENCO_PIM_PREFIX = '/cenco-pim';
export const EIFFEL_PREFIX = '/eiffel';
export const REPORTS_PREFIX = '/reports';
export const SINGLE_SPA_ROUTE_CHANGED_EVENT = 'single-spa:routing-event';
export const INVOICE_PREFIX = '/invoice';
export const SINGLE_SPA_BEFORE_ROUTE_CHANGE_EVENT = 'single-spa:before-routing-event';

export const SPA_BREADCRUMB_EVENT = 'eiffel-spa-breadcrumb';

export const SPA_GTM_EVENT = 'eiffel-spa-gtm';
export const API_KEY_WEBHOOK_FORM = '4AYOEdUWwtGWhDM9Nzpm';
