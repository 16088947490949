export const Banks = [
  { value: 'BANCO BBVA', label: 'BANCO BBVA', children: 'BANCO BBVA' },
  { value: 'BANCO BICE', label: 'BANCO BICE', children: 'BANCO BICE' },
  { value: 'BANCO BTG PACTUAL CHILE', label: 'BANCO BTG PACTUAL CHILE', children: 'BANCO BTG PACTUAL CHILE' },
  { value: 'BANCO CONSORCIO', label: 'BANCO CONSORCIO', children: 'BANCO CONSORCIO' },
  { value: 'BANCO CORPBANCA', label: 'BANCO CORPBANCA', children: 'BANCO CORPBANCA' },
  {
    value: 'BANCO CREDITOS INVERSIONES - BCI',
    label: 'BANCO CREDITOS INVERSIONES - BCI',
    children: 'BANCO CREDITOS INVERSIONES - BCI',
  },
  { value: 'BANCO DE CHILE / EDWARDS', label: 'BANCO DE CHILE / EDWARDS', children: 'BANCO DE CHILE / EDWARDS' },
  {
    value: 'BANCO ESTADO DE CHILE',
    label: 'BANCO ESTADO DE CHILE',
    children: 'BANCO ESTADO DE CHILE',
  },
  {
    value: 'BANCO FALABELLA',
    label: 'BANCO FALABELLA',
    children: 'BANCO FALABELLA',
  },
  {
    value: 'BANCO INTERNACIONAL',
    label: 'BANCO INTERNACIONAL',
    children: 'BANCO INTERNACIONAL',
  },
  {
    value: 'BANCO PARIS',
    label: 'BANCO PARIS',
    children: 'BANCO PARIS',
  },
  {
    value: 'BANCO PENTA',
    label: 'BANCO PENTA',
    children: 'BANCO PENTA',
  },
  {
    value: 'BANCO RIPLEY',
    label: 'BANCO RIPLEY',
    children: 'BANCO RIPLEY',
  },
  {
    value: 'BANCO SANTANDER DE CHILE',
    label: 'BANCO SANTANDER DE CHILE',
    children: 'BANCO SANTANDER DE CHILE',
  },
  {
    value: 'BANCO SECURITY',
    label: 'BANCO SECURITY',
    children: 'BANCO SECURITY',
  },
  {
    value: 'HSBC BANK CHILE',
    label: 'HSBC BANK CHILE',
    children: 'HSBC BANK CHILE',
  },
  {
    value: 'ITAU CORPBANCA',
    label: 'ITAU CORPBANCA',
    children: 'ITAU CORPBANCA',
  },
  {
    value: 'RABOBANK CHILE',
    label: 'RABOBANK CHILE',
    children: 'RABOBANK CHILE',
  },
  {
    value: 'SCOTIABANK CHILE',
    label: 'SCOTIABANK CHILE',
    children: 'SCOTIABANK CHILE',
  },
  // {
  //   value: 'COOPEUCH',
  //   label: 'COOPEUCH',
  //   children: 'COOPEUCH',
  // },
  // {
  //   value: 'BANCO DEL DESARROLLO',
  //   label: 'BANCO DEL DESARROLLO',
  //   children: 'BANCO DEL DESARROLLO',
  // },
].sort((a, b) => a.value.localeCompare(b.value));
