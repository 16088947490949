import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Input, Typography } from '@cencosud-ds/bigbang';
import { ToolTip } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { getRegions, getCommunesByRegion, SocietyExtended, Country } from 'utils/signup';
import { allowAlphanumeric, allowAplphabetic, allownumberAndKV20 } from 'utils/patterns';
import { PERSON_NATURAL } from 'variables/society';
import * as RUTJS from 'rut.js';

interface IDataDrop {
  value: string;
  label: string;
  children: string;
}

export const PartherTwo: React.FC<{ formik: any }> = props => {
  const { formik } = props;

  const [optionsCommune, setOptionsCommune] = useState<IDataDrop[]>([]);

  useEffect(() => {
    setOptionsCommune(getCommunesByRegion(formik.values.regionPartherTwo));
  }, [formik.values.regionPartherTwo]);

  const getMessageTooltip = () => {
    return (
      <>
        <span className="font-message">{t('sign_up_legal_label_first_message_rut')}</span>
        <br />
        <br />
        <span className="bold-message">{t('sign_up_legal_label_important_message')}</span>
        <span className="font-message">{t('sign_up_legal_label_second_rut')}</span>
      </>
    );
  };

  const setRegion = (region: string) => {
    if (region !== formik.values.regionPartherTwo) {
      setOptionsCommune([]);
      formik.setFieldValue('regionPartherTwo', region);
    }
  };

  return (
    <>
      <div className="content-row-parther">
        <div>
          <Icon name="Person" isFill={false} />
        </div>
        <div className="content-label-parther">
          <Typography
            typeElement="subtitle2"
            weight="semiBold"
            color="#212C39"
            children={t('sign_up_legal_label_second_partner')}
          />
        </div>
      </div>

      <div className="row mb-8 rows-margin-top">
        <div className="col-6">
          <Dropdown
            dropdown-options={SocietyExtended}
            variant={formik.touched.societyTypePartherTwo && formik.errors.societyTypePartherTwo ? 'error' : 'default'}
            text-feedback={
              formik.touched.societyTypePartherTwo && formik.errors.societyTypePartherTwo
                ? formik.errors.societyTypePartherTwo
                : ''
            }
            disabled={false}
            width="100%"
            placeholder={t('sign_up_legal_label_society')}
            onChange={value => formik.setFieldValue('societyTypePartherTwo', value)}
            default-value={formik.values.societyTypePartherTwo ? formik.values.societyTypePartherTwo : ''}
            label-text={t('sign_up_choose_company_type')}
          />
        </div>
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypePartherTwo) ? (
            <div className="label-rut">
              <Input
                name="rutNumberPartherTwo"
                value={formik.values.rutNumberPartherTwo ? formik.values.rutNumberPartherTwo : ''}
                input-state={
                  formik.touched.rutNumberPartherTwo && formik.errors.rutNumberPartherTwo ? 'error' : 'default'
                }
                disabled={false}
                label-text={t('sign_up_legal_label_rut_pn')}
                feedback-text={
                  formik.touched.rutNumberPartherTwo && formik.errors.rutNumberPartherTwo
                    ? formik.errors.rutNumberPartherTwo
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={12}
                onChange={event => {
                  let value = event.target.value;
                  if (value.length > 7) {
                    value = RUTJS.format(value);
                  }
                  return formik.setFieldValue('rutNumberPartherTwo', value);
                }}
                onKeyUp={() => {
                  if (
                    formik.values.rutNumberPartherTwo &&
                    !allownumberAndKV20.test(formik.values.rutNumberPartherTwo)
                  ) {
                    formik.setFieldValue(
                      'rutNumberPartherTwo',
                      formik.values.rutNumberPartherTwo.slice(0, -1).toUpperCase(),
                    );
                  }
                }}
              />
              <ToolTip msg={getMessageTooltip()} position="right" />
            </div>
          ) : (
            <Input
              name="namePartherTwo"
              value={formik.values.namePartherTwo ? formik.values.namePartherTwo : ''}
              input-state={formik.touched.namePartherTwo && formik.errors.namePartherTwo ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_legal_label_name')}
              feedback-text={
                formik.touched.namePartherTwo && formik.errors.namePartherTwo ? formik.errors.namePartherTwo : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={150}
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          )}
        </div>
      </div>
      <div className="row mb-8 rows-margin-top">
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypePartherTwo) ? (
            <>
              <Input
                name="firstPartherTwo"
                value={formik.values.firstPartherTwo ? formik.values.firstPartherTwo : ''}
                input-state={formik.touched.firstPartherTwo && formik.errors.firstPartherTwo ? 'error' : 'default'}
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_legal_last_name_first_partner')}
                feedback-text={
                  formik.touched.firstPartherTwo && formik.errors.firstPartherTwo ? formik.errors.firstPartherOne : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={150}
                onKeyPress={event => {
                  if (!allowAplphabetic.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
            </>
          ) : (
            <div className="label-rut">
              <Input
                name="rutNumberPartherTwo"
                value={formik.values.rutNumberPartherTwo ? formik.values.rutNumberPartherTwo : ''}
                input-state={
                  formik.touched.rutNumberPartherTwo && formik.errors.rutNumberPartherTwo ? 'error' : 'default'
                }
                disabled={false}
                label-text={t('sign_up_legal_label_rut')}
                feedback-text={
                  formik.touched.rutNumberPartherTwo && formik.errors.rutNumberPartherTwo
                    ? formik.errors.rutNumberPartherTwo
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={12}
                onChange={event => {
                  let value = event.target.value;
                  if (value.length > 7) {
                    value = RUTJS.format(value);
                  }
                  return formik.setFieldValue('rutNumberPartherTwo', value);
                }}
                onKeyUp={() => {
                  if (
                    formik.values.rutNumberPartherTwo &&
                    !allownumberAndKV20.test(formik.values.rutNumberPartherTwo)
                  ) {
                    formik.setFieldValue(
                      'rutNumberPartherTwo',
                      formik.values.rutNumberPartherTwo.slice(0, -1).toUpperCase(),
                    );
                  }
                }}
              />
              <ToolTip msg={getMessageTooltip()} position="right" />
            </div>
          )}
        </div>
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypePartherTwo) ? (
            <>
              <Input
                name="secondNamePartherTwo"
                value={formik.values.secondNamePartherTwo ? formik.values.secondNamePartherTwo : ''}
                input-state={
                  formik.touched.secondNamePartherTwo && formik.errors.secondNamePartherTwo ? 'error' : 'default'
                }
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_legal_last_name_second_partner')}
                feedback-text={
                  formik.touched.secondNamePartherTwo && formik.errors.secondNamePartherTwo
                    ? formik.errors.secondNamePartherTwo
                    : ''
                }
                onBlur={formik.handleBlur}
                width="100%"
                placeholder=""
                maxLength={150}
                onKeyPress={event => {
                  if (!allowAplphabetic.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onPaste={event => {
                  if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
            </>
          ) : (
            <Dropdown
              dropdown-options={Country}
              variant={
                formik.touched.nationalityPartherTwo && formik.errors.nationalityPartherTwo ? 'error' : 'default'
              }
              text-feedback={
                formik.touched.nationalityPartherTwo && formik.errors.nationalityPartherTwo
                  ? formik.errors.nationalityPartherTwo
                  : ''
              }
              disabled={false}
              width="100%"
              placeholder={t('sign_up_legal_label_nationality')}
              default-value={formik.values.nationalityPartherTwo ? formik.values.nationalityPartherTwo : ''}
              onChange={value => formik.setFieldValue('nationalityPartherTwo', value)}
            />
          )}
        </div>
      </div>
      <div className="row mb-8 rows-margin-top">
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypePartherTwo) && formik.values.societyTypePartherTwo ? (
            <>
              <Dropdown
                dropdown-options={Country}
                variant={
                  formik.touched.nationalityPartherTwo && formik.errors.nationalityPartherTwo ? 'error' : 'default'
                }
                text-feedback={
                  formik.touched.nationalityPartherTwo && formik.errors.nationalityPartherTwo
                    ? formik.errors.nationalityPartherTwo
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_nationality')}
                default-value={formik.values.nationalityPartherTwo ? formik.values.nationalityPartherTwo : ''}
                onChange={value => formik.setFieldValue('nationalityPartherTwo', value)}
              />
            </>
          ) : (
            <Input
              name="addressPartherTwo"
              value={formik.values.addressPartherTwo ? formik.values.addressPartherTwo : ''}
              input-state={formik.touched.addressPartherTwo && formik.errors.addressPartherTwo ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_legal_label_address')}
              feedback-text={
                formik.touched.addressPartherTwo && formik.errors.addressPartherTwo
                  ? formik.errors.addressPartherTwo
                  : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={255}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          )}
        </div>
        <div className="col-6">
          {[PERSON_NATURAL].includes(formik.values.societyTypePartherTwo) ? (
            <Input
              name="addressPartherTwo"
              value={formik.values.addressPartherTwo ? formik.values.addressPartherTwo : ''}
              input-state={formik.touched.addressPartherTwo && formik.errors.addressPartherTwo ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_legal_label_address')}
              feedback-text={
                formik.touched.addressPartherTwo && formik.errors.addressPartherTwo
                  ? formik.errors.addressPartherTwo
                  : ''
              }
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={255}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          ) : (
            <Dropdown
              dropdown-options={getRegions()}
              variant={formik.touched.regionPartherTwo && formik.errors.regionPartherTwo ? 'error' : 'default'}
              text-feedback={
                formik.touched.regionPartherTwo && formik.errors.regionPartherTwo ? formik.errors.regionPartherTwo : ''
              }
              disabled={false}
              width="100%"
              placeholder={t('sign_up_legal_label_region')}
              default-value={formik.values.regionPartherTwo ? formik.values.regionPartherTwo : ''}
              onChange={value => setRegion(value)}
            />
          )}
        </div>
      </div>

      <div className="row mb-8 rows-margin-top">
        {[PERSON_NATURAL].includes(formik.values.societyTypePartherTwo) ? (
          <>
            <div className="col-6">
              <Dropdown
                dropdown-options={getRegions()}
                variant={formik.touched.regionPartherTwo && formik.errors.regionPartherTwo ? 'error' : 'default'}
                text-feedback={
                  formik.touched.regionPartherTwo && formik.errors.regionPartherTwo
                    ? formik.errors.regionPartherTwo
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_region')}
                default-value={formik.values.regionPartherTwo ? formik.values.regionPartherTwo : ''}
                onChange={value => setRegion(value)}
              />
            </div>
            <div className="col-6">
              {optionsCommune && optionsCommune.length > 0 && (
                <Dropdown
                  dropdown-options={optionsCommune}
                  variant={formik.touched.communePartherTwo && formik.errors.communePartherTwo ? 'error' : 'default'}
                  text-feedback={
                    formik.touched.communePartherTwo && formik.errors.communePartherTwo
                      ? formik.errors.communePartherTwo
                      : ''
                  }
                  disabled={false}
                  width="100%"
                  placeholder={t('sign_up_legal_label_commune')}
                  default-value={formik.values.communePartherTwo ? formik.values.communePartherTwo : ''}
                  onChange={value => formik.setFieldValue('communePartherTwo', value)}
                />
              )}
            </div>
          </>
        ) : (
          <div className="col-6">
            {optionsCommune && optionsCommune.length > 0 ? (
              <Dropdown
                dropdown-options={optionsCommune}
                variant={formik.touched.communePartherTwo && formik.errors.communePartherTwo ? 'error' : 'default'}
                text-feedback={
                  formik.touched.communePartherTwo && formik.errors.communePartherTwo
                    ? formik.errors.communePartherTwo
                    : ''
                }
                disabled={false}
                width="100%"
                placeholder={t('sign_up_legal_label_commune')}
                default-value={formik.values.communePartherTwo ? formik.values.communePartherTwo : ''}
                onChange={value => formik.setFieldValue('communePartherTwo', value)}
              />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
