import React from 'react';
import * as IconsFlat from './svg/Flat';
import * as IconsNotFlat from './svg/NotFlat';

import { IconInterface, sizeType, nameIconFillType, nameIconOutlineType } from './interfaces';

import styled from '@emotion/styled';

function Nivles(props: IconInterface) {
  const { name, type, size, ...res } = props;
  let Component = IconsFlat.FlatBaja;
  const DefaultComponent = () => <p>El icono de nivel no encontrado</p>;

  switch (type) {
    case 'Flat':
      Component = IconsFlat[name as nameIconFillType];
      break;
    case 'NotFlat':
      Component = IconsNotFlat[name as nameIconOutlineType];
      break;
    default:
      break;
  }

  const variantsIconSize = (value: sizeType = 'm') => {
    const scales = {
      xs: '0.8333', // 20x20
      s: '1', // 24x24
      m: '1.3333', // 32x32
      l: '1.6667', // 40x40
      xl: '1.6667', // 40x40
      xxl: '2.3333', // 56x56
      xxxl: '2.9999', // 72x72
      xxxxl: '4.0000', // 96x96
    };

    return `transform: scale(${scales[value]});`;
  };

  const IconStyled = styled(Component || DefaultComponent)`
    ${variantsIconSize(size)};
  `;

  return <IconStyled {...res} />;
}

Nivles.defaultProps = {
  name: 'FlatNivelOne',
  type: 'Flat',
  sizeFlat: 'xl',
};

export default Nivles;
