import React from 'react';

import { AlertCard, AlertCardTitle } from './styles';

import { Typography, Icon, colors } from '@cencosud-ds/bigbang';

interface IAlertProps {
  title: string;
  description: React.ReactNode;
}

const InformativeAlert: React.FC<IAlertProps> = props => {
  const { title, description } = props;
  return (
    <AlertCard>
      <AlertCardTitle>
        <Icon isFill={true} name="Info" />
        <Typography color={colors.primary[700]} propsElement={{}} typeElement="subtitle2" weight="bold">
          {title}
        </Typography>
      </AlertCardTitle>
      <Typography color={colors.neutral[400]} propsElement={{}} typeElement="body2" weight="regular">
        {description}
      </Typography>
    </AlertCard>
  );
};

export default InformativeAlert;
