import React from 'react';

export const IconThumbsUp = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 16.5H9.5V13.5H3.5V27.5H6.5V16.5Z" fill="#B8DCFF" stroke="#B8DCFF" />
      <path
        d="M28.1039 13.8478C27.341 12.9233 26.2326 12.3889 25.0379 12.3889H19.7695L20.9211 8.08444C21.2522 6.85667 20.9931 5.58556 20.2302 4.57444C19.4673 3.57778 18.3013 3 17.0346 3C16.6747 3 16.3437 3.17333 16.1421 3.46222L9.98131 12.3889H4.07958C3.48941 12.3889 3 12.88 3 13.4722V27.9167C3 28.5089 3.48941 29 4.07958 29H10.5571C10.6003 29 10.6435 29 10.6866 28.9856C10.7154 29 10.7586 29 10.8018 29H23.3106C25.1962 29 26.8228 27.6567 27.1971 25.8078L28.9244 17.1411C29.1547 15.9711 28.8524 14.7722 28.1039 13.8478ZM9.4775 26.8333H5.15917V14.5556H9.4775V26.8333ZM26.8084 16.7222L25.0811 25.3889C24.9084 26.2267 24.1598 26.8333 23.3106 26.8333H11.6367V13.8189L17.5528 5.23889C17.927 5.34 18.2725 5.57111 18.5172 5.90333C18.8771 6.36556 18.9922 6.94333 18.8339 7.52111L17.3081 13.1978C17.2217 13.5156 17.2937 13.8622 17.4952 14.1367C17.6967 14.3967 18.0134 14.5556 18.3589 14.5556H25.0379C25.5849 14.5556 26.0887 14.8011 26.4342 15.22C26.7652 15.6389 26.9092 16.1878 26.8084 16.7222Z"
        fill="#166DC2"
      />
      <path d="M9 24H11.6667" stroke="#FF8A00" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
