import * as React from 'react';

function SvgFlatNivelOne(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.5207 1.91363L14.0248 4.89709L13.6979 5.54391L11.8342 1.29107C11.9486 1.11319 12.153 1 12.3737 1H14.9485C15.4226 1 15.7332 1.4932 15.5207 1.91363Z"
        fill="#0041D6"
      />
      <path
        d="M15.5778 7.79993V6.4416C15.5778 5.19648 14.5479 4.17773 13.2891 4.17773H10.4609C9.20209 4.17773 8.17216 5.19648 8.17216 6.4416V7.79993C5.54829 9.13399 3.75 11.8426 3.75 14.9635C3.75 19.4023 7.38745 23.0002 11.875 23.0002C16.3626 23.0002 20 19.4023 20 14.9635C20 11.8345 18.2017 9.12591 15.5778 7.79993ZM14.1065 7.22587C13.3954 7.02374 12.6515 6.91863 11.875 6.91863C11.0985 6.91863 10.3546 7.02374 9.64349 7.22587V6.4416C9.64349 5.99692 10.0113 5.63308 10.4609 5.63308H13.2891C13.5997 5.63308 13.8695 5.80287 14.0084 6.05351L14.0166 6.0616C14.0738 6.17479 14.1065 6.30415 14.1065 6.43352V7.22587Z"
        fill="#CE6363"
      />
      <path
        d="M11.9083 22.0134C15.7402 22.0134 18.8465 18.8049 18.8465 14.847C18.8465 10.8892 15.7402 7.68066 11.9083 7.68066C8.07647 7.68066 4.97012 10.8892 4.97012 14.847C4.97012 18.8049 8.07647 22.0134 11.9083 22.0134Z"
        fill="url(#paint0_linear_500_9565)"
      />
      <path
        d="M11.8256 17.9519C13.4794 17.9519 14.82 16.6258 14.82 14.9901C14.82 13.3543 13.4794 12.0283 11.8256 12.0283C10.1719 12.0283 8.83127 13.3543 8.83127 14.9901C8.83127 16.6258 10.1719 17.9519 11.8256 17.9519Z"
        fill="url(#paint1_linear_500_9565)"
      />
      <path
        d="M11.8194 17.4009C13.169 17.4009 14.263 16.3187 14.263 14.9839C14.263 13.649 13.169 12.5669 11.8194 12.5669C10.4699 12.5669 9.37582 13.649 9.37582 14.9839C9.37582 16.3187 10.4699 17.4009 11.8194 17.4009Z"
        fill="#632424"
      />
      <path
        d="M11.8751 22.2159C7.82893 22.2159 4.53479 18.9576 4.53479 14.9554C4.53479 10.9532 7.82893 7.69482 11.8751 7.69482C15.9212 7.69482 19.2154 10.9532 19.2154 14.9554C19.2072 18.9576 15.9212 22.2159 11.8751 22.2159ZM11.8751 8.26079C8.14772 8.26079 5.10697 11.2604 5.10697 14.9554C5.10697 18.6503 8.13954 21.65 11.8751 21.65C15.6024 21.65 18.6432 18.6503 18.6432 14.9554C18.6432 11.2604 15.6024 8.26079 11.8751 8.26079Z"
        fill="white"
      />
      <path
        d="M12.8069 6.42513H10.3955C10.1503 6.42513 9.92961 6.28768 9.82334 6.07747L9.61082 5.64895L7.77166 2.0025C7.55913 1.58207 7.86975 1.08887 8.34384 1.08887H10.9187C11.1721 1.08887 11.4009 1.2344 11.499 1.46079L13.3954 5.52767C13.4117 5.5681 13.4281 5.60852 13.4363 5.64895C13.5262 6.03704 13.2319 6.42513 12.8069 6.42513Z"
        fill="#166DC2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9565"
          x1="11.9083"
          y1="7.68066"
          x2="11.9083"
          y2="22.0134"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EF9494" />
          <stop offset="1" stop-color="#CE6363" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_9565"
          x1="9.73865"
          y1="17.9519"
          x2="13.7642"
          y2="12.435"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E78888" />
          <stop offset="1" stop-color="#A73A3A" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgFlatNivelOne;
