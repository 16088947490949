import React from 'react';
import { Typography } from '@cencosud-ds/bigbang';
import { IconSuccess } from 'components/icons';
import t from 'utils/translate';
import './style.css';

export const RatingsYes: React.FC = () => {
  return (
    <div className="ratings__yes">
      <IconSuccess />
      <Typography color="#166DC2" weight="semiBold" typeElement="body1">
        {t('learningguide_ratings_yes_title')}
      </Typography>
      <Typography color="#323A44" weight="regular" typeElement="body1">
        {t('learningguide_ratings_yes_description')}
      </Typography>
    </div>
  );
};
