import * as React from 'react';

function SvgNivelLow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="136" height="136" viewBox="0 0 136 136" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M88.7118 11.1391L80.2975 27.921L78.4583 31.5593L67.9749 7.63725C68.6186 6.63671 69.7681 6 71.0095 6H85.4932C88.16 6 89.9073 8.77423 88.7118 11.1391Z"
        fill="#F7E6E1"
      />
      <path
        d="M89.0324 44.2482V36.6077C89.0324 29.6039 83.239 23.8735 76.1581 23.8735H60.249C53.1681 23.8735 47.3746 29.6039 47.3746 36.6077V44.2482C32.6151 51.7523 22.4995 66.9878 22.4995 84.5428C22.4995 109.511 42.9606 129.749 68.2036 129.749C93.4465 129.749 113.908 109.511 113.908 84.5428C113.908 66.9424 103.792 51.7068 89.0324 44.2482ZM80.7561 41.0192C76.7558 39.8822 72.5716 39.291 68.2036 39.291C63.8355 39.291 59.6513 39.8822 55.651 41.0192V36.6077C55.651 34.1064 57.7201 32.0598 60.249 32.0598H76.1581C77.9053 32.0598 79.4226 33.0149 80.2043 34.4247L80.2503 34.4702C80.5721 35.1069 80.7561 35.8346 80.7561 36.5622V41.0192V41.0192Z"
        fill="#F7E6E1"
      />
      <path
        d="M68.2028 125.336C45.4428 125.336 26.9128 107.008 26.9128 84.4961C26.9128 61.9839 45.4428 43.6558 68.2028 43.6558C90.9629 43.6558 109.493 61.9839 109.493 84.4961C109.447 107.008 90.9629 125.336 68.2028 125.336ZM68.2028 46.8393C47.236 46.8393 30.1314 63.7121 30.1314 84.4961C30.1314 105.28 47.19 122.153 68.2028 122.153C89.1696 122.153 106.274 105.28 106.274 84.4961C106.274 63.7121 89.1696 46.8393 68.2028 46.8393Z"
        fill="white"
      />
      <path
        d="M73.444 36.5163H59.8799C58.5005 36.5163 57.259 35.7431 56.6613 34.5607L55.4658 32.1503L45.1203 11.6392C43.9249 9.27423 45.6721 6.5 48.3389 6.5H62.8226C64.248 6.5 65.5354 7.31863 66.0872 8.59204L76.7545 31.4681C76.8465 31.6955 76.9384 31.9229 76.9844 32.1503C77.4902 34.3333 75.8349 36.5163 73.444 36.5163Z"
        fill="#F7E6E1"
      />
    </svg>
  );
}

export default SvgNivelLow;
