import { getManifest } from './utils/manifestFunctions';
import { MicrofrontendInterface } from './interfaces/microfrontend.interface';

export function showLoading() {
  const loader = document.getElementById('frontendLoading');
  const app = document.getElementById('root');

  if (loader) {
    loader.style.display = 'block';
  }

  if (app) {
    app.style.display = 'none';
  }
}

export function hideLoading() {
  const loader = document.getElementById('frontendLoading');
  const app = document.getElementById('root');

  if (loader) {
    loader.style.display = 'none';
  }

  if (app) {
    app.style.display = 'block';
  }
}

export async function injectStyle(microfrontend: MicrofrontendInterface): Promise<Event> {
  return new Promise((resolve, reject) => {
    const manifest = getManifest(microfrontend);
    const cssFile = manifest['main.css'];

    const link = document.createElement('link');
    link.id = `style-${microfrontend.name}`;
    link.rel = 'stylesheet';
    link.href = `${microfrontend.url}${cssFile}`;
    link.onload = result => setTimeout(() => resolve(result), 500);
    link.onerror = error => reject(error);

    document.getElementsByTagName('head')[0].appendChild(link);
  });
}

export function removeStyle(microfrontend: MicrofrontendInterface) {
  const link = document.getElementById(`style-${microfrontend.name}`);
  if (link) {
    document.getElementsByTagName('head')[0].removeChild(link);
  }
}

export async function handleStyles(
  microfrontends: MicrofrontendInterface[],
  microfrontendToLoad: MicrofrontendInterface,
) {
  for (const microfrontend of microfrontends.filter(m => m.name !== microfrontendToLoad.name)) {
    removeStyle(microfrontend);
  }
  return injectStyle(microfrontendToLoad);
}
