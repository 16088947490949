import React from 'react';
import styled from '@emotion/styled';

const FooterContainer = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 10px 40px;
  grid-auto-flow: column;
  bottom: 0;
  width: 100% !important;
  height: 48px !important ;
  background: #ffffff;
  z-index: 4;
  margin-top: 20px;
`;

const Brand = styled.div`
  font-family: 'Open Sans';
  color: #9faabd;
  font-weight: 600;
  margin-top: 16px;
  font-size: 12px;
  line-height: 16px;
`;

const today = new Date();
const Footer = () => {
  return (
    <FooterContainer>
      <Brand> {`© Paris Marketplace ${today.getFullYear()}`}</Brand>
    </FooterContainer>
  );
};

export default Footer;
