import styled from '@emotion/styled';

export const TooltipPopover = styled.div`
   {
    isolation: isolate;
    width: 273px;
    height: 72px;
    color: white;
    background: #166dc2;
    box-shadow: 0px 8px 24px rgba(50, 58, 68, 0.06);
    border-radius: 12px;
  }
`;
export const TextTooltip = styled.p`
   {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px !important;
    letter-spacing: 0.25px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 12px;
  }
`;

export const TextTooltipLink = styled.a`
   {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  &:hover {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  &:focus {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
  &:active {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
    color: #ffffff;
  }
`;
