import styled from '@emotion/styled';
import { Typography, colors, elevations, zIndex } from '@cencosud-ds/bigbang';
import { InterfaceGlobalSearch, InterfaceInput, LabelInterface } from './interfaces';

const variants = {
  default: {
    border: `0.063rem solid ${colors.neutral[200]}`,
    color: colors.neutral[400],
    padding: '0.438rem 1rem 0.438rem 0.75rem',
  },
  hover: {
    border: `0.063rem solid ${colors.neutral[400]}`,
    color: colors.neutral[400],
    padding: '0.438rem 1rem 0.438rem 0.75rem',
  },
  focused: {
    border: `0.125rem solid ${colors.primary[500]}`,
    color: colors.neutral[400],
    padding: '0.375rem 0.938rem 0.375rem 0.688rem',
  },
  disabled: {
    border: `0.063rem solid ${colors.neutral[200]}`,
    color: colors.neutral[200],
    padding: '0.438rem 1rem 0.438rem 0.75rem',
  },
  error: {
    border: `2px solid ${colors.feedback.error.medium}`,
    color: colors.feedback.error.medium,
    padding: '0.438rem 1rem 0.438rem 0.75rem',
  },
};

const StyledGlobalSearch = styled.div`
  display: inline-block;
  position: relative;
  min-width: 20rem;
  width: ${({ width }: InterfaceGlobalSearch) => width || '20rem'};
  > p.input-text-feedback-global-search {
    margin-top: 5px !important;
  }
`;

const StyledGlobalSearchInput = styled.div`
  position: relative;
  display: flex;
  border-radius: 0.5rem;
  align-items: center;
  height: 3rem;
  /* min-width: 20rem; */
  gap: 0.75rem;
  width: 100%;

  ${(props: InterfaceGlobalSearch) => {
    const { disabled, width, 'input-focused': inputFocused, 'input-value': value, 'input-state': inputState } = props;
    return `
      // width: ${width || '20rem'};
      background-color: ${disabled ? colors.neutral[100] : colors.neutral[0]};
      border: ${
        inputState === 'error'
          ? variants.error.border
          : inputFocused
          ? variants.focused.border
          : variants.default.border
      };
      padding: ${inputFocused ? variants.focused.padding : variants.default.padding};

      ${
        value === ''
          ? `
          &:hover {
            border: ${
              inputState === 'error'
                ? variants.error.border
                : inputFocused
                ? variants.focused.border
                : variants.hover.border
            };
            padding: ${inputFocused ? variants.focused.padding : variants.hover.padding};
          }
        `
          : ''
      }

      ${
        disabled
          ? `
          pointer-events: none;
          border: ${variants.disabled.border};
          padding: ${variants.disabled.padding};
        `
          : ''
      }
  `;
  }}

  > .input-icon {
    padding-top: 4px;
  }
`;

const StyledIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const StyledCategory = styled.button`
  padding: 0;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  gap: 1rem;
  cursor: pointer;
`;

const StyledCategoryLabel = styled(Typography)`
  width: 7.125rem;
  flex-shrink: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
`;

const Separator = styled.div`
  height: 2rem;
  width: 0.063rem;
  background: ${colors.neutral[200]};
  flex-shrink: 0;
  margin: 0 calc(0.25rem - (0.063rem / 2));
`;

const StyledSearchInput = styled.input`
  border: 0rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ disabled }: InterfaceInput) => (disabled ? variants.disabled.color : variants.default.color)};
  background-color: ${({ disabled }: InterfaceInput) => (disabled ? colors.neutral[100] : colors.neutral[0])};
  width: 100%;
  cursor: pointer;

  &::placeholder {
    color: ${({ disabled }: InterfaceInput) => (disabled ? variants.disabled.color : colors.neutral[200])};
  }

  &:focus-visible,
  &:focus {
    outline: none;
  }
`;

const ButtonClear = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: none;
  cursor: pointer;
`;

const StyledDropdown = styled.div`
  position: absolute;
  margin-top: 0.5rem;
  box-shadow: ${elevations.l};
  z-index: ${zIndex.dropdown};
  background-color: ${colors.neutral[0]};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  overflow: hidden;
  width: 100%;
`;

const StyledResultsRow = styled.div`
  padding: 0.594rem 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HorizontalSeparator = styled.div`
  width: 100%;
  background-color: ${colors.neutral[100]};
  height: 0.063rem;
  margin: 0.25rem 0;
`;

const StyledDeleteAll = styled.button`
  border: 0;
  background: none;
`;

const InputsStates = {
  default: {
    colorFeedbackText: colors.neutral[400],
    'focus-border-color': `2px solid ${colors.primary[500]}`,
    'border-color': `1px solid ${colors.neutral[200]}`,
    'hover-border': `1px solid ${colors.neutral[400]}`,
    background: colors.neutral[0],
    padding: '11px 15px',
    paddingFocus: '10px 14px',
  },
  success: {
    colorFeedbackText: colors.neutral[400],
    'focus-border-color': `2px solid ${colors.feedback.success.medium}`,
    'border-color': `2px solid ${colors.feedback.success.medium}`,
    'hover-border': `2px solid ${colors.feedback.success.medium}`,
    background: colors.neutral[0],
    padding: '10px 14px',
    paddingFocus: '10px 14px',
  },
  error: {
    colorFeedbackText: colors.feedback.error.medium,
    'focus-border-color': `2px solid ${colors.feedback.error.medium}`,
    'border-color': `2px solid ${colors.feedback.error.medium}`,
    'hover-border': `2px solid ${colors.feedback.error.medium}`,
    background: colors.neutral[0],
    padding: '10px 14px',
    paddingFocus: '10px 14px',
  },
  warning: {
    colorFeedbackText: colors.neutral[400],
    'focus-border-color': `2px solid ${colors.feedback.warning.medium}`,
    'border-color': `2px solid ${colors.feedback.warning.medium}`,
    'hover-border': `2px solid ${colors.feedback.warning.medium}`,
    background: colors.neutral[0],
    padding: '10px 14px',
    paddingFocus: '10px 14px',
  },
  disable: {
    colorFeedbackText: colors.neutral[400],
    'focus-border-color': `2px solid ${colors.neutral[200]}`,
    'border-color': `2px solid ${colors.neutral[200]}`,
    'hover-border': `2px solid ${colors.neutral[200]}`,
    background: colors.neutral[100],
    padding: '10px 14px',
    paddingFocus: '10px 14px',
  },
  activated: {
    border: `1px solid ${colors.neutral[400]}`,
    padding: '11px 15px',
  },
};

let propertiesInput: LabelInterface;

const LabelContainer = styled.label`
  height: fit-content;
  ${(props: LabelInterface) => {
    propertiesInput = { ...props };
    if (props.disabled) {
      propertiesInput['input-state'] = 'disable';
      return `
      .input-icon > svg > path {
        fill: ${colors.neutral[200]}
      };
      }`;
    }
    return ``;
  }};

  position: relative;
  display: flex;
  border-radius: 8px;
  width: ${() => propertiesInput.width || '22em'};

  /*INPUT */
  input {
    border: 0px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    color: ${colors.neutral[400]};

    ${() => `
      padding: ${InputsStates[propertiesInput['input-state']].padding};
      border: ${InputsStates[propertiesInput['input-state']]['border-color']};
      background: ${InputsStates[propertiesInput['input-state']].background};
      &:hover{
        border: ${InputsStates[propertiesInput['input-state']]['hover-border']};
      }
    `}

    ${() =>
      propertiesInput['input-state'] === 'default' && propertiesInput['input-value'] !== ''
        ? `
          border: ${InputsStates.activated.border};
          padding: ${InputsStates.activated.padding};
          `
        : ''};

    &::placeholder {
      opacity: ${() => (propertiesInput['input-focused'] ? '70%' : '0%')};
      transition: opacity 200ms ease-in;
      color: ${colors.neutral[400]};
    }

    &:focus-visible,
    &:focus {
      outline: none;
      ${() => `
        border:${InputsStates[propertiesInput['input-state']]['focus-border-color']};
        padding: ${InputsStates[propertiesInput['input-state']].paddingFocus};
      `}
    }
  }

  /*GENERAL */
  .input-p-label,
  .input-icon {
    position: absolute;
    z-index: 5;
  }

  /*P-LABEL */
  .input-p-label {
    ${() => `
      background: ${InputsStates[propertiesInput['input-state']].background};
    `};
    left: ${() => (propertiesInput['input-focused'] ? '12px' : propertiesInput['input-value'] ? '12px' : '15px')};
    top: ${() => (propertiesInput['input-focused'] ? '-12px' : propertiesInput['input-value'] ? '-12px' : '7.5px')};
    padding: 4px;
    border-radius: 4px;
    transition: top 100ms ease-in, left 100ms ease-in, font-size 100ms ease-in;
  }

  /*ICON */
  .input-icon {
    right: 16px;
    top: 12px;
  }

  /* SPAN-AYUDA */
  .input-text-feedback {
    bottom: -20px;
    color: ${() => InputsStates[propertiesInput['input-state']].colorFeedbackText};
  }
`;

export {
  StyledGlobalSearch,
  StyledCategory,
  StyledCategoryLabel,
  StyledGlobalSearchInput,
  StyledIcon,
  Separator,
  StyledSearchInput,
  ButtonClear,
  StyledDropdown,
  StyledResultsRow,
  HorizontalSeparator,
  StyledDeleteAll,
  LabelContainer,
};
