import React, { useEffect, useState } from 'react';
import { Dropdown, Input, Typography, Toggle, colors } from '@cencosud-ds/bigbang';
import { BottomBar, FormSection, ToolTip } from '../../components';
import { IWizardFormProps } from '../../interfaces/wizard.interface';
import { useWizardFormControl } from '../../context';
import t from '../../../../../utils/translate';
import './index.css';
import { getCommunesByRegion, getRegions } from 'utils/signup';
import { useFormik } from 'formik';
import validationSchema from './logistic.schema';
import { allowAlphanumeric, allowAplphabetic, allownumber, allowEmail } from 'utils/patterns';
import { trackGTMPageViewCuston } from 'utils/analytics';

interface IDataDrop {
  value: string;
  label: string;
  children: string;
}

const LogisticStep: React.FC<IWizardFormProps> = props => {
  const { position } = props;

  const formWizard = useWizardFormControl(position);

  const formik = useFormik({
    initialValues: {
      addressWarehouse: formWizard.data.addressWarehouse ? formWizard.data.addressWarehouse : '',
      regionWarehouse: formWizard.data.regionWarehouse ? formWizard.data.regionWarehouse : '',
      communeWarehouse: formWizard.data.communeWarehouse ? formWizard.data.communeWarehouse : '',
      nameContact: formWizard.data.nameContact ? formWizard.data.nameContact : '',
      lastnameContact: formWizard.data.lastnameContact ? formWizard.data.lastnameContact : '',
      emailWarehouse: formWizard.data.emailWarehouse ? formWizard.data.emailWarehouse : '',
      emailConfirmWarehouse: formWizard.data.emailConfirmWarehouse ? formWizard.data.emailConfirmWarehouse : '',
      rolWarehouse: formWizard.data.rolWarehouse ? formWizard.data.rolWarehouse : '',
      phoneWarehouse: formWizard.data.phoneWarehouse ? formWizard.data.phoneWarehouse : '',
      technicalService: formWizard.data.technicalService ? formWizard.data.technicalService : false,
    },
    onSubmit: values => {
      console.log(values);
    },
    validationSchema,
  });

  const [optionsCommune, setOptionsCommune] = useState<IDataDrop[]>([]);

  const getMessageTooltip = (message: string) => {
    return (
      <>
        <span className="font-message">{t(message)}</span>
      </>
    );
  };

  useEffect(() => {
    setOptionsCommune(getCommunesByRegion(formik.values.regionWarehouse));
  }, [formik.values.regionWarehouse]);

  const setRegion = (region: string) => {
    if (region !== formik.values.regionWarehouse) {
      setOptionsCommune([]);
      formik.setFieldValue('regionWarehouse', region);
    }
  };

  const handleOnBack = () => {
    formWizard.data = formik.values;
  };

  const handleOnNext = async (): Promise<boolean> => {
    await formik.submitForm();
    const isFormValid = formik.isValid && Object.keys(formik.touched).length > 0;

    if (isFormValid) {
      formWizard.data = formik.values;
      return true;
    }
    return false;
  };

  useEffect(() => {
    trackGTMPageViewCuston({
      page: '/registrate-ahora',
      title: 'Información logística',
    });
  }, []);

  return (
    <>
      <div className="wizard-form-section pb-3">
        <div className="mb-5">
          <Typography
            typeElement="subtitle1"
            weight="semiBold"
            color={colors.primary[500]}
            children={t('sign_up_logistic_title')}
          />
        </div>
        <div className="mb-4">
          <FormSection
            label={t('sign_up_logistic_title1')}
            description={
              <div className="content-description">
                <div className="text-description">
                  <span>{t('sign_up_logistic_text1')}</span>
                </div>
              </div>
            }
          >
            <div className="row mb-8">
              <div className="col-6">
                <Input
                  name="addressWarehouse"
                  value={formik.values.addressWarehouse ? formik.values.addressWarehouse : ''}
                  input-state={formik.touched.addressWarehouse && formik.errors.addressWarehouse ? 'error' : 'default'}
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_logistic_label_address')}
                  feedback-text={
                    formik.touched.addressWarehouse && formik.errors.addressWarehouse
                      ? formik.errors.addressWarehouse
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={255}
                  onKeyPress={event => {
                    if (!allowAlphanumeric.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Dropdown
                  dropdown-options={getRegions()}
                  disabled={false}
                  width="100%"
                  placeholder={t('sign_up_logistic_label_region')}
                  default-value={formik.values.regionWarehouse ? formik.values.regionWarehouse : ''}
                  onChange={value => setRegion(value)}
                  variant={formik.touched.regionWarehouse && formik.errors.regionWarehouse ? 'error' : 'default'}
                  text-feedback={
                    formik.touched.regionWarehouse && formik.errors.regionWarehouse ? formik.errors.regionWarehouse : ''
                  }
                />
              </div>
            </div>
            <div className="row  mb-8 rows-margin-top">
              <div className="col-6">
                {optionsCommune && optionsCommune.length > 0 ? (
                  <Dropdown
                    dropdown-options={optionsCommune}
                    disabled={false}
                    width="100%"
                    placeholder={t('sign_up_logistic_label_commune')}
                    default-value={formik.values.communeWarehouse ? formik.values.communeWarehouse : ''}
                    onChange={value => formik.setFieldValue('communeWarehouse', value)}
                    variant={formik.touched.communeWarehouse && formik.errors.communeWarehouse ? 'error' : 'default'}
                    text-feedback={
                      formik.touched.communeWarehouse && formik.errors.communeWarehouse
                        ? formik.errors.communeWarehouse
                        : ''
                    }
                  />
                ) : null}
              </div>
              <div className="col-6" />
            </div>
          </FormSection>
        </div>
        <div>
          <FormSection
            label={t('sign_up_logistic_title2')}
            description={
              <div className="content-description">
                <div className="text-description">
                  <span>{t('sign_up_logistic_text2')}</span>
                </div>
              </div>
            }
          >
            <div className="row  mb-8">
              <div className="col-6">
                <div className="label-rut">
                  <Input
                    name="nameContact"
                    value={formik.values.nameContact ? formik.values.nameContact : ''}
                    input-state={formik.touched.nameContact && formik.errors.nameContact ? 'error' : 'default'}
                    disabled={false}
                    onChange={formik.handleChange}
                    label-text={t('sign_up_logistic_label_name')}
                    feedback-text={
                      formik.touched.nameContact && formik.errors.nameContact ? formik.errors.nameContact : ''
                    }
                    onBlur={formik.handleBlur}
                    width="100%"
                    placeholder=""
                    maxLength={50}
                    onKeyPress={event => {
                      if (!allowAplphabetic.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={event => {
                      if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-6">
                <Input
                  name="lastnameContact"
                  value={formik.values.lastnameContact ? formik.values.lastnameContact : ''}
                  input-state={formik.touched.lastnameContact && formik.errors.lastnameContact ? 'error' : 'default'}
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_logistic_label_lastname')}
                  feedback-text={
                    formik.touched.lastnameContact && formik.errors.lastnameContact ? formik.errors.lastnameContact : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={50}
                  onKeyPress={event => {
                    if (!allowAplphabetic.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="row  mb-8 rows-margin-top">
              <div className="col-6">
                <Input
                  name="emailWarehouse"
                  value={formik.values.emailWarehouse ? formik.values.emailWarehouse : ''}
                  input-state={formik.touched.emailWarehouse && formik.errors.emailWarehouse ? 'error' : 'default'}
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_logistic_label_email')}
                  feedback-text={
                    formik.touched.emailWarehouse && formik.errors.emailWarehouse ? formik.errors.emailWarehouse : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={100}
                  onKeyPress={event => {
                    if (!allowEmail.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  name="emailConfirmWarehouse"
                  value={formik.values.emailConfirmWarehouse ? formik.values.emailConfirmWarehouse : ''}
                  input-state={
                    formik.touched.emailConfirmWarehouse && formik.errors.emailConfirmWarehouse ? 'error' : 'default'
                  }
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_logistic_label_confirm_email')}
                  feedback-text={
                    formik.touched.emailConfirmWarehouse && formik.errors.emailConfirmWarehouse
                      ? formik.errors.emailConfirmWarehouse
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={100}
                  onKeyPress={event => {
                    if (!allowEmail.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="row  mb-8 rows-margin-top">
              <div className="col-6">
                <Input
                  name="rolWarehouse"
                  value={formik.values.rolWarehouse ? formik.values.rolWarehouse : ''}
                  input-state={formik.touched.rolWarehouse && formik.errors.rolWarehouse ? 'error' : 'default'}
                  disabled={false}
                  onChange={formik.handleChange}
                  label-text={t('sign_up_logistic_label_rol')}
                  feedback-text={
                    formik.touched.rolWarehouse && formik.errors.rolWarehouse ? formik.errors.rolWarehouse : ''
                  }
                  onBlur={formik.handleBlur}
                  width="100%"
                  placeholder=""
                  maxLength={50}
                  onKeyPress={event => {
                    if (!allowAlphanumeric.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={event => {
                    if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <div className="label-rut">
                  <div className="phone-area">
                    <Typography typeElement="body1" weight="regular" color="#212C39" children={t('phone_area')} />
                  </div>
                  <Input
                    name="phoneWarehouse"
                    maxLength={9}
                    value={formik.values.phoneWarehouse ? formik.values.phoneWarehouse : ''}
                    input-state={formik.touched.phoneWarehouse && formik.errors.phoneWarehouse ? 'error' : 'default'}
                    disabled={false}
                    onChange={formik.handleChange}
                    label-text={t('sign_up_logistic_label_phone')}
                    feedback-text={
                      formik.touched.phoneWarehouse && formik.errors.phoneWarehouse ? formik.errors.phoneWarehouse : ''
                    }
                    onBlur={formik.handleBlur}
                    width="323px"
                    placeholder=""
                    onKeyPress={event => {
                      if (!allownumber.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onPaste={event => {
                      if (!allownumber.test(event.clipboardData.getData('Text'))) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <div className="tooltip-space">
                    <ToolTip msg={getMessageTooltip('phone_information')} position="top-left" />
                  </div>
                </div>
              </div>
            </div>
          </FormSection>
        </div>
        <div>
          <FormSection label={t('sign_up_logistic_title3')} description={<div />}>
            <div className="row">
              <div className="col" />
              <div className="col-auto container-toggle">
                <Toggle
                  onChange={value => formik.setFieldValue('technicalService', value.state)}
                  active={formik.values.technicalService ? formik.values.technicalService : false}
                  size="s"
                  text={t('sign_up_logistic_label_toggle')}
                  disabled={false}
                />
              </div>
            </div>
          </FormSection>
        </div>
      </div>
      <BottomBar onBack={() => handleOnBack()} onNext={() => handleOnNext()} />
    </>
  );
};

export default LogisticStep;
