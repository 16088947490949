import React, { useState } from 'react';
import { TextTooltip, TooltipPopover } from './style';
import { Icon, ToggleButton, Tooltip } from '@cencosud-ds/bigbang';
import TriangleSvg from './triangle';

const CardTooltip: React.FC<{ text: string }> = props => {
  const { text } = props;
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      TooltipComponent={
        <div style={{ marginTop: '-65px', marginLeft: '-125px' }}>
          <TooltipPopover
            style={{
              position: 'fixed',
            }}
          >
            <TextTooltip>{text}</TextTooltip>
          </TooltipPopover>
          <TriangleSvg
            className="triangle-position"
            {...{
              border: '#166dc2',
              background: '#166dc2',
            }}
          />
        </div>
      }
      isOpen={open}
      position="top"
      version="v2"
    >
      <ToggleButton onClick={() => setOpen(!open)} icon={<Icon name="AlertCircle" isFill={false} />} size="s" />
    </Tooltip>
  );
};

export default CardTooltip;
