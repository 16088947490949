/* eslint-disable react/require-default-props */
import React from 'react';
import { IWithChildren } from './interfaces/index';
import { RightSideBarStyles, Backdrop } from './styles';
import SlideTransitionGroup from './SlideTransitionGroup';

const SideBar = ({
  open,
  width,
  className,
  onOutSideClick,
  children,
}: { open: boolean; width: number; onOutSideClick: () => void; className?: string } & IWithChildren): JSX.Element => (
  <div>
    <Backdrop onClick={open ? onOutSideClick : undefined} className={`${open ? 'open' : ''}`} data-testid="backdrop" />
    <SlideTransitionGroup direction="rtl" duration={250} trigger={open} startOffset={`-${width}px`} endOffset="0px">
      <RightSideBarStyles
        $width={width}
        className={`${open ? 'right-sidebar-open' : ''} ${className}`}
        data-testid="right-sidebar"
      >
        {children}
      </RightSideBarStyles>
    </SlideTransitionGroup>
  </div>
);

export default SideBar;
