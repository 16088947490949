import styled from '@emotion/styled';

export const CardContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  background: #fff;
  width: 412px;
  height: 150px;
  :hover {
    box-shadow: 0px 8px 24px 0px rgba(184, 220, 255, 0.6);
    cursor: pointer;
    border: 1px solid rgba(184, 220, 255, 0.6);
  }
`;

export const CardContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #f4f4f5;
  background: #fff;
`;

export const CardIconAndTitleContainer = styled.div`
  display: flex;
  width: 347px;
  align-items: center;
  gap: 16px;
`;

export const CardIconContainer = styled.div`
  display: flex;
  padding: 4px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 50px;
  border: 8px solid #fff7f0;
  background: #ffdcb2;
`;

export const IconInnerContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  color: #e17c02;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  width: 306px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`;

export const CardContainerBody = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
`;

export const CardContainerBodyData = styled.div`
  display: flex;
  width: 299px;
`;

export const CardContainerBodyDateRange = styled.div`
  display: flex;
  align-items: center;
`;

export const CardContainerBodyEmpty = styled.div`
  display: flex;
  height: 86px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #fff;
`;
