const zIndex = {
  base: 1000,
  dropdown: 1010,
  sticky: 1020,
  fixed: 1030,
  modalBackdrop: 1040,
  offCanvas: 1050,
  modal: 1060,
  popover: 1070,
  tooltip: 1180,
};

export default zIndex;
