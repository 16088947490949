import * as singleSpa from 'single-spa';
import loadDependencies, { ModuleFederationData } from './loadDependencies';
import { getManifest } from './utils/manifestFunctions';
import { getMicrofrontends, getMicrofrontendByPrefix, getRemoteMicrofrontends } from './utils/microfrontends';
import { MicrofrontendInterface } from './interfaces/microfrontend.interface';
import { handleStyles, hideLoading, showLoading } from './styles';
import getDataSession from './getSession';
import { SINGLE_SPA_BEFORE_ROUTE_CHANGE_EVENT } from '../config';

//  declare global {
//    interface Window {
//      System: any;
//    }
//  }

const sharedScope: any[] = [];

export default async function bootstrap() {
  const jwt = localStorage.getItem('_ud') ? JSON.parse(localStorage.getItem('_ud') || '') : '';
  const { permissions, routes } = await loadDependencies();
  const Session = await getDataSession(jwt);

  const microfrontends = getMicrofrontends();
  const loadFunction = async (microfrontendToLoad: MicrofrontendInterface) => {
    try {
      showLoading();
      const promises: any[] = [];
      // MKP: se agrega manifest
      const manifest = getManifest(microfrontendToLoad);
      if (manifest['main.css']) {
        promises.push(handleStyles(microfrontends, microfrontendToLoad));
      }
      promises.push(window.System.import(microfrontendToLoad.name));
      const result = await Promise.all(promises);
      for (const data of result) {
        if (!(data instanceof Event)) {
          hideLoading();
          //  MKP: se agrega condicional para la carga de remotos en mkp
          if (data.init && data.get) {
            //  MKP: se modifica la implementacion de carga para EPV
            await data.init(sharedScope);
            let moduleFederationRemotes: ModuleFederationData[] = [];
            try {
              moduleFederationRemotes = getRemoteMicrofrontends();
            } catch (error) {
              console.error('REACT_APP_MODULE_FEDERATED_REMOTES env var not setted');
            }
            const moduleFederation = moduleFederationRemotes.find(m => m.name === microfrontendToLoad.name);
            if (moduleFederation) {
              const factory = await data.get(moduleFederation.appModule);
              const module = factory();
              const { default: m } = await module;
              const mod = await m;
              return mod;
            }
            throw new Error('ModuleFederation Module cant be loaded');
          }
          console.log(data);
          return data;
        }
      }
    } catch (error) {
      console.log(`Failed loading ${microfrontendToLoad.name}`);
      console.log(error);
    }
  };

  const registeredApps = singleSpa.getAppNames();

  const getSession = () => Session;

  for (const microfrontend of microfrontends) {
    try {
      const manifest = getManifest(microfrontend);
      if (manifest) {
        await window.System.import(`${microfrontend.name}-variables`);

        if (registeredApps.indexOf(microfrontend.name) > -1) {
          await singleSpa.unloadApplication(microfrontend.name, { waitForUnmount: true });
        } else {
          singleSpa.registerApplication(
            microfrontend.name,
            //  @ts-ignore
            () => loadFunction(microfrontend),
            (location: Location) => {
              const basePath = location.pathname.split('/')[1];
              return basePath === microfrontend.prefix;
            },
            {
              getSession,
              permissions: permissions[microfrontend.name],
              routes: routes[microfrontend.name],
            },
          );
        }
      }
    } catch (error) {
      console.log('Failed bootstrap', microfrontend);
      console.log(error);
    }
  }

  singleSpa.start();

  //  Listen route changes from microfrontends
  window.addEventListener(
    SINGLE_SPA_BEFORE_ROUTE_CHANGE_EVENT,
    async (event: any) => {
      try {
        const oldPath = event.detail.oldUrl.replace(window.location.origin, '');
        const newPath = event.detail.newUrl.replace(window.location.origin, '');
        let activePrefix = oldPath.split('/')[1];
        if (activePrefix.includes('?')) {
          activePrefix = activePrefix.split('?')[0];
        }
        let nextPrefix = newPath.split('/')[1];
        if (nextPrefix.includes('?')) {
          nextPrefix = nextPrefix.split('?')[0];
        }
        const nextMicrofrontend = getMicrofrontendByPrefix(nextPrefix);
        const activeMicrofrontend = getMicrofrontendByPrefix(activePrefix);

        if (nextPrefix !== activePrefix) {
          if (activeMicrofrontend && nextMicrofrontend) {
            showLoading();
            const manifest = getManifest(nextMicrofrontend);
            if (manifest['main.css']) {
              await handleStyles(getMicrofrontends(), nextMicrofrontend);
            }
            hideLoading();
          }
        }
      } catch (error) {
        console.log('Failed changing route', event);
        console.log(error);
      }
    },
    false,
  );
}
