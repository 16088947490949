import React from 'react';
import { Container, Description, Title, Content, ItemTable, TitleTable, ContentRow, ItemTableLink } from './styles';
import t from 'utils/translate';

const TablePopover: React.FC = () => {
  return (
    <div style={{ marginLeft: '100%', marginTop: '100px' }}>
      <Container>
        <Title>{t('reputation_components_popover_title')}</Title>
        <Description>{t('reputation_components_popover_subtitle')}</Description>
        <Description>
          {t('reputation_components_popover_subtitle2')}
          <ItemTableLink
            href="https://ayuda.marketplace.paris.cl/category/reputacion-de-paris-marketplace/"
            target="_blank"
            rel="noreferrer"
          >
            {t('reputation_components_popover_subtitle3')}
          </ItemTableLink>
        </Description>
        <Content>
          <ContentRow>
            <div style={{ textAlign: 'left' }}>
              <TitleTable>{t('reputation_components_title1')}</TitleTable>
            </div>
            <div style={{ textAlign: 'right' }}>
              <TitleTable>{t('reputation_components_title2')}</TitleTable>
            </div>
          </ContentRow>
          <ContentRow>
            <div style={{ textAlign: 'left' }}>
              <ItemTable>{t('reputation_components_item1')}</ItemTable>
            </div>
            <div style={{ textAlign: 'right' }}>
              <ItemTable>{t('reputation_components_detail1')}</ItemTable>
            </div>
          </ContentRow>
          <ContentRow>
            <div style={{ textAlign: 'left' }}>
              <ItemTable>{t('reputation_components_item2')}</ItemTable>
            </div>
            <div style={{ textAlign: 'right' }}>
              <ItemTable>{t('reputation_components_detail2')}</ItemTable>
            </div>
          </ContentRow>
          <ContentRow>
            <div style={{ textAlign: 'left' }}>
              <ItemTable>{t('reputation_components_item3')}</ItemTable>
            </div>
            <div style={{ textAlign: 'right' }}>
              <ItemTable>{t('reputation_components_detail3')}</ItemTable>
            </div>
          </ContentRow>
          <ContentRow>
            <div style={{ textAlign: 'left' }}>
              <ItemTable>{t('reputation_components_item4')}</ItemTable>
            </div>
            <div style={{ textAlign: 'right' }}>
              <ItemTable>{t('reputation_components_detail4')}</ItemTable>
            </div>
          </ContentRow>
        </Content>
      </Container>
    </div>
  );
};

export default TablePopover;
