import styled from '@emotion/styled';

export const EiffelStyled = styled.div`
  position: absolute;
  overflow: auto;
  inset: 42px 0px 0px 100px;
  width: auto;
  max-width: 1440px;
  ${({ display }) => `display: ${display ? `block` : `none`} ;`}
  @media (min-width: 1440px) {
    margin: 32px auto;
  }
  background: #e1f0ff;
`;

export const Container = styled.div`
  margin: 40px;
`;
