import React from 'react';

export const IconBook = () => {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 18.7308V1.24336C0 -0.0284572 1.32481 -0.0814475 1.98721 0.0510345L11.9233 1.6408L21.4619 0.0510345C23.3696 -0.266919 23.8465 0.978398 23.8465 1.6408V17.9359C23.8465 20.1616 22.2567 20.453 21.4619 20.3206L11.9233 18.7308L2.38465 20.3206C0.47693 20.6385 0 19.3932 0 18.7308Z"
        fill="#166DC2"
      />
      <path d="M2.80112 2.96387L10.4402 4.17338V16.2049L2.80112 17.4781V2.96387Z" fill="#F5F5F5" />
      <path d="M13.2409 4.30076L21.0072 3.02759V17.5418L13.2409 16.2686V4.30076Z" fill="#F5F5F5" />
      <path
        d="M7.51169 11.1121L5.53827 11.1757C4.83803 11.2394 4.07412 11.7614 4.32876 12.8308C4.58339 13.9003 5.49582 13.9343 5.92022 13.8494L7.82999 13.5948C8.53024 13.4038 8.93765 12.8308 8.78487 12.0669C8.63209 11.303 7.82999 11.1121 7.51169 11.1121Z"
        fill="#FF8A00"
      />
      <path
        d="M6.11142 5.95571L7.76656 6.14669C8.59413 6.27401 9.03974 7.10157 8.59413 7.86548C8.23764 8.4766 7.70291 8.52552 7.25729 8.50207C6.85412 8.48085 5.91603 8.4589 5.41119 8.37475C5.02924 8.31108 4.60485 7.97158 4.51998 7.80182C4.26532 7.41986 4.18895 6.78328 4.6473 6.27401C5.10564 5.76474 5.66579 5.89204 6.11142 5.95571Z"
        fill="#FF8A00"
      />
      <path
        d="M17.9519 5.95571L15.7874 6.274C15.4055 6.33766 14.718 6.56683 14.7689 7.48352C14.8198 8.40021 15.4691 8.62938 16.1694 8.50206L18.2702 8.24742C18.9704 8.1201 19.2887 7.54718 19.0977 6.71961C18.9233 5.96377 18.2277 5.93449 17.9519 5.95571Z"
        fill="#FF8A00"
      />
      <path
        d="M18.0155 11.303L15.9784 11.3667C15.4055 11.3667 14.7689 11.685 14.7689 12.5126C14.7689 13.1746 15.3418 13.5948 15.9784 13.6584L17.8245 13.7857C18.3338 13.7857 19.225 13.6022 19.225 12.5126C19.225 11.5577 18.5248 11.303 18.0155 11.303Z"
        fill="#FF8A00"
      />
    </svg>
  );
};
