const kitUIColors = {
  'primary-0': '#F5FAFF',
  'primary-10': '#E1F0FF',
  'primary-20': '#B8DCFF',
  'primary-30': '#80C0FF',
  'primary-40': '#1C8AF5',
  'primary-60': '#166DC2',
  'primary-80': '#0D4275',
  'primary-100': '#072542',

  'secondary-0': '#FFF7F0',
  'secondary-10': '#FFF1E0',
  'secondary-20': '#FFDCB2',
  'secondary-30': '#FFC075',
  'secondary-40': '#FFA133',
  'secondary-60': '#FF8A00',
  'secondary-80': '#E57C00',
  'secondary-100': '#CC6E00',

  'neutral-0': '#FFFFFF',
  'neutral-10': '#F9FAFB',
  'neutral-20': '#F2F5FA',
  'neutral-30': '#E6ECF5',
  'neutral-40': '#BDC7D6',
  'neutral-50': '#9FAABD',
  'neutral-60': '#7989A3',
  'neutral-70': '#6C7C94',
  'neutral-80': '#4D5F7A',
  'neutral-90': '#334661',
  'neutral-100': '#161D29',

  'success-0': '#E6F5F1',
  'success-10': '#CAEBE3',
  'success-20': '#A7DBCF',
  'success-30': '#74C2B0',
  'success-40': '#22A889',
  'success-60': '#008F6E',
  'success-80': '#00765B',
  'success-100': '#005C47',

  'info-0': '#EBFCFF',
  'info-10': '#D6F9FF',
  'info-20': '#B2F4FF',
  'info-30': '#87EDFF',
  'info-40': '#4DD8F0',
  'info-60': '#2ECBE5',
  'info-80': '#14B1CC',
  'info-100': '#0F8599',

  'error-0': '#FDF1EE',
  'error-10': '#FADFD7',
  'error-20': '#F7BEAE',
  'error-30': '#F09B81',
  'error-40': '#E5633C',
  'error-60': '#E54717',
  'error-80': '#BD2C00',
  'error-100': '#751D00',

  'warning-0': '#FFFEF0',
  'warning-10': '#FFFBD6',
  'warning-20': '#FFF8B2',
  'warning-30': '#FFF380',
  'warning-40': '#FFEE59',
  'warning-60': '#FFE819',
  'warning-80': '#DBC716',
  'warning-100': '#998900',

  default: 'black',
};

const Colors = {
  light_lightblue: '#E5F3F9',
  lightblue: '#00B0EB',
  blue: '#007196',
  red: '#DE3233',
  darkBlue: '#003F6D',
  berry: '#CA1862',
  orange: '#ED7E05',
  turquoise: '#00B5AD',
  white: '#ffffff',
  grey1: '#f4f4f4',
  grey2: '#e6e6e6',
  grey3: '#d9d9d9',
  grey4: '#d6d6d6',
  grey5: '#c2c2c2',
  grey6: '#b8b8b6',
  grey7: '#747470',
  grey8: '#707070',
  grey9: '#4d4d4d',
  black: '#000000',
  shadow: '#00000029',
  darkBlue_LST: '#032146',
  'color-black': '#000000',
  'color-white': '#ffffff',

  'color-G1': '#0a2143',
  'color-G2': '#314561',
  'color-G3': '#58687f',
  'color-G4': '#a7afbb',
  'color-G5': '#ced3d9',
  'color-G6': '#dfe1e6',

  'color-A1': '#032146',
  'color-A2': '#07457e',
  'color-A3': '#095aa5',
  'color-A4': '#6b9cc9',
  'color-A5': '#9dbddb',
  'color-A6': '#cedeed',

  'color-C1': '#1543ba',
  'color-C2': '#174cd1',
  'color-C3': '#1a54e8',
  'color-C4': '#a3bbf6',
  'color-C5': '#dae2ff',
  'color-C6': '#f1f4fe',

  'color-N1': '#e65f09',
  'color-N2': '#ff690a',
  'color-N3': '#ff873b',
  'color-N4': '#ffa56c',
  'color-N5': '#ffc39d',
  'color-N6': '#ffe1ce',

  'color-V1': '#005844',
  'color-V2': '#00765b',
  'color-V3': '#009372',
  'color-V4': '#44b098',
  'color-V5': '#88cdbd',
  'color-V6': '#cce9e3',

  'color-R1': '#800000',
  'color-R2': '#ab0000',
  'color-R3': '#d60000',
  'color-R4': '#e14444',
  'color-R5': '#ec8888',
  'color-R6': '#f7cccc',

  'color-W1': '#e8d31d',
  'color-W2': '#ffe600',
  'color-W3': '#ffed48',
  'color-W4': '#fff064',
  'color-W5': '#fff599',
  'color-W6': '#fffacc',
};

const inheritedVariants = {
  'color-text-principal': Colors['color-G1'],
  'color-text-subtitle': Colors['color-G2'],
  'color-text-paragraph': Colors['color-G3'],
  'color-callToAction-principal': Colors['color-C3'],
  'color-callToAction-secondary': Colors['color-A2'],
  'color-callToAction-disabled': Colors['color-G6'],
  'color-callToAction-principal-hover': Colors['color-C1'],
  'color-callToAction-inactive': Colors['color-C5'],
  'color-feedback-success': Colors['color-V2'],
  'color-feedback-error': Colors['color-R3'],
  'color-feedback-warning': Colors['color-W2'],
  'color-background-website': Colors['color-C6'],
  'color-background-principal-modal': Colors['color-white'],
  'color-background-secondary-message': Colors['color-N6'],
  'color-background-success-message': Colors['color-V6'],
  'color-background-error-message': Colors['color-R6'],
  'color-background-warning-message': Colors['color-W6'],
  'color-background-secondary-modal': Colors['color-A2'],
};

const All = { ...kitUIColors, ...Colors, ...inheritedVariants };
export default All;
