import React from 'react';
import './style.css';

interface IButtonLearning {
  title: string;
  onClick: () => void;
  icon: React.ReactNode;
}

export const ButtonLearning: React.FC<IButtonLearning> = props => {
  return (
    <div id="bg-button-learning-guide" className="bg-button-learning-guide" onClick={props.onClick}>
      <p>{props.title}</p>
      <span>{props.icon}</span>
    </div>
  );
};
