import React from 'react';
import { Typography, colors } from '@cencosud-ds/bigbang';
import './style.css';

interface ServiceLevelTable {
  children?: React.ReactNode;
  title: string;
  separador?: boolean;
}

export default function Panel({ children, separador, title }: ServiceLevelTable): JSX.Element {
  return (
    <>
      <div className="level__service-level">
        <div className="level__service-level--header">
          <Typography typeElement="body3" color={colors.primary[500]} weight="bold">
            {title}
          </Typography>
        </div>
        <div className="level__service-level--body">{children}</div>
      </div>
      {separador && <div className="level__separador" />}
    </>
  );
}
