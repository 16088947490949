import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import { Modal, Button, Icon, Typography } from '@cencosud-ds/bigbang';
import t from 'utils/translate';
import SessionProvider from '../../api/session/context';
import './style.css';

export default function ModalConference(): JSX.Element {
  const {
    isModalInfo,
    setIsModalInfo,
    auth: { sellerName },
  } = useContext(SessionProvider);

  const onClick = () => {
    setIsModalInfo(false);
    localStorage.setItem('_is_modal_conference', '0');
    window.location.reload();
  };

  const markdown = `
  Te invitamos a nuestra [capacitación SEO](https://teams.microsoft.com/l/meetup-join/19%3ameeting_NGQ5OTVmYTgtMjc4MC00NmZmLWJkMmQtMDI0YzNkNDM2MjZi%40thread.v2/0?context=%7b%22Tid%22%3a%22a50762c4-c5ad-413a-a05e-9ffe15752882%22%2c%22Oid%22%3a%227bd46f9f-6901-46ba-9221-fbb29f21c3ff%22%7d) en la que conocerás todo lo necesario para optimizar tus productos y aparecer en los resultados de Google.
  Para asistir conéctate el próximo **jueves 19 de octubre** a las **11:00** horas.
  \n
  ¡Te esperamos!
  `;

  return (
    <Modal open={isModalInfo} persistent={true} with-blur={false} id="modal-example-conference" onClose={onClick}>
      <div className="modal__conference">
        <div className="modal__conference--close">
          <Button className="button-exitbutton" darkMode={true} onClick={onClick}>
            <Icon name="Close" />
          </Button>
        </div>
        <div className="modal-conference__containers">
          <Typography color="#166DC2" propsElement={{}} typeElement="body1" weight="bold">
            {`¡Hola ${sellerName}!`}
          </Typography>
          <ReactMarkdown className="modal-conference-markdown" linkTarget="_blank" remarkPlugins={[rehypeHighlight]}>
            {markdown}
          </ReactMarkdown>
        </div>
        <Button className="modal-conference__button" onClick={onClick}>
          {t('Conectarme a capacitación')}
        </Button>
      </div>
    </Modal>
  );
}
