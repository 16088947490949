import { REPORTS_URL, SELLERS_URL } from '../../config';
import getAxios from '../../clients';
import { ReportViews, SalesInfo, SellerConvertionResponse } from './interface';
import moment from 'moment';

export const getSellerReportConversion = async ({
  jwt,
  sellerId,
}: {
  jwt: string;
  sellerId: string;
}): Promise<SellerConvertionResponse> => {
  try {
    const response = await getAxios.instance(jwt).get(`${REPORTS_URL}/reports/convertion-seller?sellerId=${sellerId}`);
    return response.data;
  } catch (ex) {
    return {
      convertion: {
        value: [{ result: '0' }],
        prefix: '',
      },
      avarage: {
        value: [{ result: '0' }],
        prefix: '',
      },
    };
  }
};

export const getSellerReportViews = async ({
  jwt,
  sellerId,
}: {
  jwt: string;
  sellerId: string;
}): Promise<ReportViews[]> => {
  try {
    const response = await getAxios
      .instance(jwt)
      .get(`${REPORTS_URL}/reports/category-report?sellerId=${sellerId}&categories=[]&dateFilter=7`);
    return response.data.data;
  } catch (ex) {
    return [];
  }
};

export const getSellerReportSales = async ({ jwt }: { jwt: string }): Promise<SalesInfo> => {
  try {
    const startDate = moment()
      .subtract(1, 'week')
      .format('YYYY-MM-DD');
    const endDate = moment().format('YYYY-MM-DD');

    const response = await getAxios
      .instance(jwt)
      .get(`${SELLERS_URL}/v1/sellers/sale-info?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (ex) {
    return {
      totalSales: 0,
      totalRefunds: 0,
      netSales: 0,
      orderQuantity: 0,
      itemssold: 0,
      averageTicket: 0,
    };
  }
};
