import * as React from 'react';

function SvgNotFlatNivelThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.621 2.08501L14.1689 5.02793L13.8516 5.6677L12.0425 1.46123C12.1536 1.2853 12.3519 1.17334 12.5662 1.17334H15.0655C15.5258 1.18134 15.8273 1.66916 15.621 2.08501Z"
        fill="#0041D6"
      />
      <path
        d="M15.6765 7.90691V6.5634C15.6765 5.33185 14.6767 4.32422 13.4548 4.32422H10.7094C9.48748 4.32422 8.48773 5.33185 8.48773 6.5634V7.90691C5.94073 9.22643 4.19513 11.9054 4.19513 14.9923C4.19513 19.3827 7.72601 22.9414 12.0821 22.9414C16.4382 22.9414 19.969 19.3827 19.969 14.9923C19.969 11.8975 18.2234 9.21843 15.6765 7.90691ZM14.2482 7.33912C13.5579 7.13919 12.8359 7.03523 12.0821 7.03523C11.3283 7.03523 10.6063 7.13919 9.91595 7.33912V6.5634C9.91595 6.12356 10.273 5.76369 10.7094 5.76369H13.4548C13.7563 5.76369 14.0181 5.93163 14.153 6.17954L14.1609 6.18754C14.2165 6.2995 14.2482 6.42745 14.2482 6.5554V7.33912Z"
        fill="url(#paint0_linear_500_9546)"
      />
      <path
        d="M12.0822 23.0617C7.66262 23.0617 4.0762 19.439 4.0762 14.9926C4.0762 11.9698 5.71866 9.23475 8.3688 7.83526V6.56373C8.3688 5.2602 9.41616 4.20459 10.7095 4.20459H13.4549C14.7482 4.20459 15.7956 5.2602 15.7956 6.56373V7.83526C18.4457 9.23475 20.0882 11.9698 20.0882 14.9926C20.0961 19.439 16.5017 23.0617 12.0822 23.0617ZM12.0822 6.9156C12.7725 6.9156 13.4628 7.00357 14.1293 7.1795V6.56373C14.1293 6.18787 13.8278 5.88398 13.4549 5.88398H10.7095C10.3366 5.88398 10.0351 6.18787 10.0351 6.56373V7.1795C10.7016 7.00357 11.3919 6.9156 12.0822 6.9156Z"
        fill="url(#paint1_radial_500_9546)"
      />
      <path
        d="M13.4548 4.32439C14.6768 4.32439 15.6765 5.33202 15.6765 6.56357V7.90708C18.2235 9.22659 19.9691 11.8976 19.9691 14.9845C19.9691 19.3749 16.4382 22.9336 12.0822 22.9336C7.72607 22.9336 4.19519 19.3749 4.19519 14.9845C4.19519 11.8976 5.9408 9.2186 8.48779 7.90708V6.56357C8.48779 5.33202 9.48755 4.32439 10.7095 4.32439H13.4548ZM9.91602 7.33928C10.6063 7.13936 11.3284 7.03539 12.0822 7.03539C12.8359 7.03539 13.558 7.13936 14.2483 7.33928V6.56357C14.2483 6.12373 13.8912 5.76386 13.4548 5.76386H10.7095C10.2731 5.76386 9.91602 6.12373 9.91602 6.56357V7.33928ZM13.4548 4.08447H10.7095C9.35266 4.08447 8.24976 5.19607 8.24976 6.56357V7.75513C6.99609 8.43488 5.93286 9.42652 5.17908 10.6501C4.37769 11.9456 3.94922 13.4411 3.94922 14.9845C3.94922 19.5028 7.59912 23.1735 12.0742 23.1735C16.5572 23.1735 20.1992 19.4948 20.1992 14.9845C20.1992 13.4491 19.7708 11.9536 18.9694 10.6501C18.2156 9.42652 17.1523 8.43488 15.8987 7.75513V6.56357C15.9145 5.19607 14.8116 4.08447 13.4548 4.08447ZM10.1541 7.0274V6.56357C10.1541 6.25168 10.4 6.00377 10.7095 6.00377H13.4548C13.7643 6.00377 14.0103 6.25168 14.0103 6.56357V7.0274C13.3834 6.87545 12.7328 6.79548 12.0822 6.79548C11.4315 6.79548 10.7809 6.86746 10.1541 7.0274Z"
        fill="#1AC5EB"
      />
      <path
        d="M11.9949 22.1256C15.8161 22.1256 18.9138 19.0035 18.9138 15.1522C18.9138 11.3008 15.8161 8.17871 11.9949 8.17871C8.17368 8.17871 5.07596 11.3008 5.07596 15.1522C5.07596 19.0035 8.17368 22.1256 11.9949 22.1256Z"
        fill="url(#paint2_radial_500_9546)"
      />
      <path
        d="M12.0013 17.3832L9.90632 15.2717C9.80544 15.17 9.80544 15.0052 9.90632 14.9044L11.1238 13.1812C11.1725 13.1322 11.2378 13.105 11.3065 13.105H13.0606C13.1293 13.105 13.1945 13.1322 13.2432 13.1812L14.4607 14.9044C14.5616 15.0061 14.5616 15.1709 14.4607 15.2717L12.3657 17.3832C12.2657 17.4849 12.1022 17.4849 12.0013 17.3832Z"
        fill="#006277"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.724 12.8404L10.7548 12.8094C10.9015 12.6616 11.0994 12.5791 11.3064 12.5791H13.0605C13.2675 12.5791 13.4654 12.6616 13.6121 12.8094L13.6429 12.8404L14.8671 14.5731C15.1327 14.8809 15.1225 15.35 14.829 15.6442C14.8286 15.6446 14.8283 15.6449 14.828 15.6452L12.7362 17.7535C12.7359 17.7538 12.7355 17.7542 12.7352 17.7545C12.4301 18.0636 11.9357 18.0609 11.6323 17.7551L9.5373 15.6436C9.24681 15.3508 9.232 14.8817 9.50033 14.5724L10.724 12.8404ZM9.90626 14.9045C9.80538 15.0053 9.80538 15.17 9.90626 15.2717L12.0013 17.3832C12.1021 17.4849 12.2656 17.4849 12.3656 17.3832L14.4606 15.2717C14.5615 15.1709 14.5615 15.0061 14.4606 14.9045L13.2431 13.1813C13.1944 13.1322 13.1292 13.105 13.0605 13.105H11.3064C11.2377 13.105 11.1725 13.1322 11.1238 13.1813L9.90626 14.9045Z"
        fill="url(#paint3_linear_500_9546)"
      />
      <path
        d="M12.0504 22.0699C8.17041 22.0699 5.01245 18.895 4.94897 14.9604C4.94897 15.0004 4.94897 15.0484 4.94897 15.0884C4.94897 19.0869 8.13074 22.3338 12.0504 22.3338C15.9701 22.3338 19.1518 19.0949 19.1518 15.0884C19.1518 15.0484 19.1518 15.0004 19.1518 14.9604C19.0804 18.903 15.9304 22.0699 12.0504 22.0699Z"
        fill="white"
      />
      <path
        d="M16.2795 9.28254C13.1692 6.94739 8.75754 7.59515 6.36923 10.714C6.39304 10.682 6.41684 10.642 6.44858 10.6101C8.82101 7.40322 13.2882 6.71547 16.4382 9.07461C19.5882 11.4338 20.2151 15.9521 17.8426 19.1589C17.8188 19.1909 17.7871 19.2309 17.7633 19.2629C20.0405 16.0641 19.3899 11.6177 16.2795 9.28254Z"
        fill="#03ACD1"
      />
      <path
        d="M12.0822 22.1655C8.15457 22.1655 4.95694 18.9427 4.95694 14.9841C4.95694 11.0256 8.15457 7.80273 12.0822 7.80273C16.0098 7.80273 19.2074 11.0256 19.2074 14.9841C19.2074 18.9427 16.0098 22.1655 12.0822 22.1655ZM12.0822 8.36253C8.46402 8.36253 5.51236 11.3294 5.51236 14.9841C5.51236 18.6388 8.45608 21.6057 12.0822 21.6057C15.7003 21.6057 18.652 18.6388 18.652 14.9841C18.652 11.3294 15.7083 8.36253 12.0822 8.36253Z"
        fill="url(#paint4_linear_500_9546)"
      />
      <path
        d="M14.1531 6.17954C14.0182 5.93163 13.7563 5.76369 13.4548 5.76369H12.5979L11.8759 4.32422H13.3199L14.1531 6.17954Z"
        fill="#501717"
      />
      <path
        d="M12.9946 6.54712H10.6539C10.4159 6.54712 10.2017 6.41117 10.0985 6.20324L9.89222 5.7794L8.10694 2.17271C7.90064 1.75686 8.20216 1.26904 8.66236 1.26904H11.1618C11.4077 1.26904 11.6299 1.41299 11.7251 1.63691L13.558 5.67543C13.5739 5.71542 13.5897 5.7554 13.5977 5.79539C13.6929 6.16326 13.3993 6.54712 12.9946 6.54712Z"
        fill="#1C8AF5"
      />
      <path
        opacity="0.25"
        d="M5.72653 15.0325C5.72653 11.5058 8.55917 8.65088 12.0583 8.65088C15.5575 8.65088 18.3901 11.5058 18.3901 15.0325C18.3901 18.5593 15.7717 14.0729 12.2805 14.0729C8.78927 14.0729 5.72653 18.5593 5.72653 15.0325Z"
        fill="white"
      />
      <path
        opacity="0.25"
        d="M15.0339 5.25189C15.3195 5.63575 15.383 6.07558 15.1767 6.23553C14.9704 6.38747 14.9466 5.92364 14.6609 5.53178C14.3753 5.13993 13.9389 4.98798 14.1452 4.82804C14.3515 4.6761 14.7482 4.86003 15.0339 5.25189Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M15.9644 11.1976C16.1842 10.7752 15.6862 10.0752 14.8519 9.63398C14.0176 9.1928 13.163 9.17757 12.9431 9.59995C12.7233 10.0223 13.2213 10.7224 14.0556 11.1636C14.8899 11.6047 15.7445 11.62 15.9644 11.1976Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9546"
          x1="4.1926"
          y1="13.6308"
          x2="19.9729"
          y2="13.6308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#558895" />
          <stop offset="0.2368" stop-color="#588A97" />
          <stop offset="0.392" stop-color="#60909C" />
          <stop offset="0.5238" stop-color="#6F9BA5" />
          <stop offset="0.6425" stop-color="#85A9B3" />
          <stop offset="0.7524" stop-color="#A0BCC4" />
          <stop offset="0.8559" stop-color="#C2D4D9" />
          <stop offset="0.9525" stop-color="#E9EFF1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_500_9546"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5154 9.14989) rotate(95.9624) scale(13.3953 13.2929)"
        >
          <stop stop-color="#E2F1FF" />
          <stop offset="1" stop-color="#02ABD0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_500_9546"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5416 10.8277) scale(6.91689 6.97138)"
        >
          <stop stop-color="#E2F1FF" />
          <stop offset="0.520833" stop-color="#02ABD0" />
          <stop offset="1" stop-color="#058CB7" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_500_9546"
          x1="12.1834"
          y1="12.5791"
          x2="12.1834"
          y2="17.9854"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.015625" stop-color="#0689A6" />
          <stop offset="0.197917" stop-color="#7DD3E8" />
          <stop offset="0.359375" stop-color="white" />
          <stop offset="0.671875" stop-color="#B2E5F1" />
          <stop offset="0.838542" stop-color="#C6E9FA" />
          <stop offset="1" stop-color="#2EA2BC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_500_9546"
          x1="12.0822"
          y1="7.80273"
          x2="12.0822"
          y2="22.1655"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.015625" stop-color="#0689A6" />
          <stop offset="0.197917" stop-color="#7DD3E8" />
          <stop offset="0.359375" stop-color="white" />
          <stop offset="0.671875" stop-color="#B2E5F1" />
          <stop offset="0.838542" stop-color="#C6E9FA" />
          <stop offset="1" stop-color="#2EA2BC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNotFlatNivelThree;
