import * as React from 'react';

function SvgNivelWell(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_607_8761)">
        <circle cx="36" cy="36" r="35.5" stroke="#B8DCFF" />
        <circle cx="36" cy="36" r="24" fill="white" />
        <path
          d="M41.6734 19.0194L39.2174 23.9494L38.6806 25.0211L35.6206 17.9745C35.8085 17.6797 36.144 17.4922 36.5064 17.4922H40.734C41.5124 17.5056 42.0224 18.3228 41.6734 19.0194Z"
          fill="#0041D6"
        />
        <path
          d="M41.7672 28.7722V26.5216C41.7672 24.4585 40.0761 22.7705 38.0093 22.7705H33.3657C31.2989 22.7705 29.6079 24.4585 29.6079 26.5216V28.7722C25.2998 30.9827 22.3472 35.4705 22.3472 40.6416C22.3472 47.9964 28.3194 53.9579 35.6875 53.9579C43.0556 53.9579 49.0279 47.9964 49.0279 40.6416C49.0279 35.4571 46.0753 30.9693 41.7672 28.7722ZM39.3514 27.821C38.1838 27.4861 36.9625 27.312 35.6875 27.312C34.4125 27.312 33.1912 27.4861 32.0236 27.821V26.5216C32.0236 25.7848 32.6275 25.1819 33.3657 25.1819H38.0093C38.5193 25.1819 38.9622 25.4632 39.1903 25.8785L39.2038 25.8919C39.2977 26.0795 39.3514 26.2938 39.3514 26.5082V27.821Z"
          fill="url(#paint0_linear_607_8761)"
        />
        <path
          d="M35.6876 54.1587C28.2122 54.1587 22.146 48.09 22.146 40.6414C22.146 35.5775 24.9241 30.9958 29.4067 28.6514V26.5214C29.4067 24.3377 31.1782 22.5693 33.3658 22.5693H38.0095C40.1971 22.5693 41.9686 24.3377 41.9686 26.5214V28.6514C46.4512 30.9958 49.2293 35.5775 49.2293 40.6414C49.2427 48.09 43.1631 54.1587 35.6876 54.1587ZM35.6876 27.1108C36.8553 27.1108 38.0229 27.2582 39.1502 27.5529V26.5214C39.1502 25.8917 38.6402 25.3826 38.0095 25.3826H33.3658C32.7351 25.3826 32.2251 25.8917 32.2251 26.5214V27.5529C33.3524 27.2582 34.52 27.1108 35.6876 27.1108Z"
          fill="#FF8A00"
        />
        <path
          d="M35.5399 52.5913C42.0033 52.5913 47.2429 47.3612 47.2429 40.9094C47.2429 34.4577 42.0033 29.2275 35.5399 29.2275C29.0765 29.2275 23.8369 34.4577 23.8369 40.9094C23.8369 47.3612 29.0765 52.5913 35.5399 52.5913Z"
          fill="url(#paint1_linear_607_8761)"
        />
        <path
          d="M36.2638 35.3672L37.58 38.0359C37.6482 38.1746 37.7806 38.269 37.9311 38.2911L40.8724 38.7192C41.2556 38.7754 41.4081 39.2477 41.1312 39.517L39.0025 41.5949C38.8921 41.7034 38.842 41.8581 38.868 42.0088L39.3696 44.9428C39.4358 45.3246 39.0346 45.616 38.6915 45.4371L36.0612 44.0526C35.9247 43.9802 35.7622 43.9802 35.6258 44.0526L32.9955 45.4371C32.6524 45.618 32.2511 45.3266 32.3173 44.9428L32.8189 42.0088C32.845 41.8561 32.7948 41.7014 32.6845 41.5949L30.5558 39.517C30.2789 39.2457 30.4314 38.7734 30.8146 38.7192L33.7559 38.2911C33.9084 38.269 34.0388 38.1726 34.107 38.0359L35.4231 35.3672C35.5977 35.0176 36.0913 35.0176 36.2638 35.3672Z"
          fill="url(#paint2_linear_607_8761)"
        />
        <path
          d="M36.1811 36.7536L37.1704 38.7527C37.2216 38.8566 37.3212 38.9274 37.4343 38.9439L39.6452 39.2646C39.9332 39.3067 40.0478 39.6605 39.8397 39.8622L38.2396 41.4188C38.1567 41.5001 38.119 41.616 38.1386 41.7289L38.5156 43.9267C38.5654 44.2128 38.2637 44.431 38.0059 44.2971L36.0287 43.2598C35.9262 43.2057 35.804 43.2057 35.7015 43.2598L33.7244 44.2971C33.4665 44.4325 33.1648 44.2143 33.2146 43.9267L33.5916 41.7289C33.6112 41.6145 33.5735 41.4985 33.4906 41.4188L31.8905 39.8622C31.6824 39.659 31.797 39.3052 32.085 39.2646L34.2959 38.9439C34.4105 38.9274 34.5086 38.8551 34.5598 38.7527L35.5492 36.7536C35.6804 36.4916 36.0514 36.4916 36.1811 36.7536Z"
          fill="#A65A00"
        />
        <path
          d="M35.6877 52.6585C29.0444 52.6585 23.6357 47.2597 23.6357 40.6283C23.6357 33.997 29.0444 28.5981 35.6877 28.5981C42.331 28.5981 47.7396 33.997 47.7396 40.6283C47.7396 47.2597 42.331 52.6585 35.6877 52.6585ZM35.6877 29.5359C29.5678 29.5359 24.5752 34.5061 24.5752 40.6283C24.5752 46.7506 29.5543 51.7208 35.6877 51.7208C41.8076 51.7208 46.8002 46.7506 46.8002 40.6283C46.8002 34.5061 41.821 29.5359 35.6877 29.5359Z"
          fill="white"
        />
        <path
          d="M37.2309 26.4946H33.2718C32.8691 26.4946 32.5068 26.2669 32.3323 25.9186L31.9834 25.2085L28.9637 19.1667C28.6147 18.47 29.1247 17.6528 29.9031 17.6528H34.1307C34.5468 17.6528 34.9225 17.894 35.0836 18.2691L38.1838 25.0344C38.2107 25.1014 38.2375 25.1684 38.2509 25.2353C38.412 25.8516 37.9154 26.4946 37.2309 26.4946Z"
          fill="#1C8AF5"
        />
        <mask id="path-11-inside-1_607_8761" fill="white">
          <path d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z" />
        </mask>
        <path
          d="M6.88913 33.2131C5.37394 33.068 4.25118 31.7186 4.52601 30.2215C5.74076 23.6043 9.01675 17.5122 13.9202 12.8379C18.8236 8.16359 25.0654 5.18265 31.7331 4.2857C33.2416 4.08277 34.5358 5.26878 34.6082 6.78917C34.6807 8.30956 33.5028 9.58613 31.9981 9.81607C26.6559 10.6325 21.6669 13.0685 17.7235 16.8276C13.7801 20.5868 11.1083 25.4536 10.0374 30.7507C9.73575 32.2426 8.40432 33.3581 6.88913 33.2131Z"
          stroke="#E1F0FF"
          stroke-width="6"
          mask="url(#path-11-inside-1_607_8761)"
        />
        <mask id="path-12-inside-2_607_8761" fill="white">
          <path d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z" />
        </mask>
        <path
          d="M34.6147 65.2111C34.5426 66.7315 33.2486 67.9178 31.74 67.7153C24.5569 66.7506 17.8828 63.3694 12.8432 58.0853C7.80358 52.8012 4.7421 45.9745 4.11863 38.7537C3.9877 37.2373 5.23392 36.0008 6.75603 36.0008C8.27815 36.0008 9.49724 37.2381 9.65534 38.752C10.2612 44.5538 12.7706 50.0226 16.832 54.2811C20.8934 58.5395 26.2373 61.3049 32.0039 62.1848C33.5086 62.4144 34.6868 63.6907 34.6147 65.2111Z"
          stroke="#166DC2"
          stroke-width="6"
          mask="url(#path-12-inside-2_607_8761)"
        />
        <mask id="path-13-inside-3_607_8761" fill="white">
          <path d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z" />
        </mask>
        <path
          d="M65.244 36C66.7661 36 68.0123 37.2364 67.8814 38.7529C67.259 45.9641 64.2049 52.7827 59.1768 58.0644C54.1487 63.346 47.4884 66.7315 40.3165 67.7076C38.8082 67.9129 37.5121 66.7289 37.4373 65.2086C37.3625 63.6883 38.5384 62.41 40.0427 62.1777C45.8 61.2887 51.1325 58.52 55.1845 54.2637C59.2366 50.0074 61.7398 44.5452 62.3447 38.7513C62.5028 37.2374 63.7219 36 65.244 36Z"
          stroke="#166DC2"
          stroke-width="6"
          mask="url(#path-13-inside-3_607_8761)"
        />
        <mask id="path-14-inside-4_607_8761" fill="white">
          <path d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z" />
        </mask>
        <path
          d="M37.3786 6.78855C37.4503 5.26812 38.744 4.08153 40.2526 4.28378C46.9207 5.17771 53.1637 8.15578 58.0692 12.8278C62.9747 17.4998 66.2535 23.5903 67.4713 30.2069C67.7468 31.7039 66.6247 33.0538 65.1096 33.1996C63.5945 33.3453 62.2625 32.2304 61.9602 30.7386C60.8868 25.4422 58.2128 20.5766 54.2677 16.8193C50.3227 13.062 45.3325 10.6282 39.9901 9.81426C38.4853 9.585 37.3068 8.30897 37.3786 6.78855Z"
          stroke="#166DC2"
          stroke-width="8"
          mask="url(#path-14-inside-4_607_8761)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_607_8761"
          x1="22.3429"
          y1="38.3609"
          x2="49.0343"
          y2="38.3609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#558895" />
          <stop offset="0.2368" stop-color="#588A97" />
          <stop offset="0.392" stop-color="#60909C" />
          <stop offset="0.5238" stop-color="#6F9BA5" />
          <stop offset="0.6425" stop-color="#85A9B3" />
          <stop offset="0.7524" stop-color="#A0BCC4" />
          <stop offset="0.8559" stop-color="#C2D4D9" />
          <stop offset="0.9525" stop-color="#E9EFF1" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_607_8761"
          x1="35.5399"
          y1="29.2275"
          x2="35.5399"
          y2="52.5913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FDC431" />
          <stop offset="1" stop-color="#FDC431" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_607_8761"
          x1="32.0025"
          y1="46.2144"
          x2="41.753"
          y2="33.4688"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFC700" />
          <stop offset="1" stop-color="#E54717" />
        </linearGradient>
        <clipPath id="clip0_607_8761">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgNivelWell;
