import styled from '@emotion/styled';

export const MetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  gap: 24px;
`;

export const MetricsContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 697px;
`;

export const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  padding-top: 25px;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 1339px;
  align-items: flex-start;
  gap: 20px;
`;
