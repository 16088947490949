import React from 'react';
import { Input, Typography } from '@cencosud-ds/bigbang';
import { FormSection, ToolTip } from '../../../../components';
import t from '../../../../../../../utils/translate';
import { allowAlphanumeric, allowAplphabetic, allownumber, allowEmail } from 'utils/patterns';

export const FinanceArea: React.FC<{ formik: any }> = props => {
  const { formik } = props;
  const getMessageTooltip = (message: string) => {
    return (
      <>
        <span className="font-message">{t(message)}</span>
      </>
    );
  };
  return (
    <>
      <FormSection
        label={t('sign_up_contact_finance_area_label')}
        description={<span>{t('sign_up_contact_finance_area_description')}</span>}
      >
        <div className="row mb-8">
          <div className="col-6">
            <Input
              name="financeAreaName"
              input-state={formik.touched.financeAreaName && formik.errors.financeAreaName ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              maxLength={50}
              label-text={t('sign_up_contact_finance_area_name')}
              feedback-text={
                formik.touched.financeAreaName && formik.errors.financeAreaName ? formik.errors.financeAreaName : ''
              }
              value={formik.values.financeAreaName ? formik.values.financeAreaName : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <Input
              name="financeAreaSecondName"
              input-state={
                formik.touched.financeAreaSecondName && formik.errors.financeAreaSecondName ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              maxLength={50}
              label-text={t('sign_up_contact_finance_area_second_name')}
              feedback-text={
                formik.touched.financeAreaSecondName && formik.errors.financeAreaSecondName
                  ? formik.errors.financeAreaSecondName
                  : ''
              }
              value={formik.values.financeAreaSecondName ? formik.values.financeAreaSecondName : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              onKeyPress={event => {
                if (!allowAplphabetic.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAplphabetic.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-8  rows-margin-top">
          <div className="col-6">
            <Input
              name="financeAreaEmail"
              input-state={formik.touched.financeAreaEmail && formik.errors.financeAreaEmail ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              maxLength={100}
              label-text={t('sign_up_contact_finance_area_email')}
              feedback-text={
                formik.touched.financeAreaEmail && formik.errors.financeAreaEmail ? formik.errors.financeAreaEmail : ''
              }
              value={formik.values.financeAreaEmail ? formik.values.financeAreaEmail : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6 ">
            <Input
              name="financeAreaConfirmEmail"
              input-state={
                formik.touched.financeAreaConfirmEmail && formik.errors.financeAreaConfirmEmail ? 'error' : 'default'
              }
              disabled={false}
              onChange={formik.handleChange}
              maxLength={100}
              label-text={t('sign_up_contact_finance_area_confirm_email')}
              feedback-text={
                formik.touched.financeAreaConfirmEmail && formik.errors.financeAreaConfirmEmail
                  ? formik.errors.financeAreaConfirmEmail
                  : ''
              }
              value={formik.values.financeAreaConfirmEmail ? formik.values.financeAreaConfirmEmail : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              onKeyPress={event => {
                if (!allowEmail.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowEmail.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        </div>
        <div className="row mb-8 rows-margin-top">
          <div className="col-6">
            <Input
              name="financeAreaCharge"
              input-state={formik.touched.financeAreaCharge && formik.errors.financeAreaCharge ? 'error' : 'default'}
              disabled={false}
              onChange={formik.handleChange}
              label-text={t('sign_up_contact_finance_area_charge')}
              feedback-text={
                formik.touched.financeAreaCharge && formik.errors.financeAreaCharge
                  ? formik.errors.financeAreaCharge
                  : ''
              }
              value={formik.values.financeAreaCharge ? formik.values.financeAreaCharge : ''}
              onBlur={formik.handleBlur}
              width="100%"
              placeholder=""
              maxLength={50}
              onKeyPress={event => {
                if (!allowAlphanumeric.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onPaste={event => {
                if (!allowAlphanumeric.test(event.clipboardData.getData('Text'))) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          <div className="col-6">
            <div className="label-rut">
              <div className="phone-area">
                <Typography typeElement="body1" weight="regular" color="#212C39" children={t('phone_area')} />
              </div>
              <Input
                name="financeAreaPhone"
                input-state={formik.touched.financeAreaPhone && formik.errors.financeAreaPhone ? 'error' : 'default'}
                disabled={false}
                onChange={formik.handleChange}
                label-text={t('sign_up_contact_finance_area_phone')}
                feedback-text={
                  formik.touched.financeAreaPhone && formik.errors.financeAreaPhone
                    ? formik.errors.financeAreaPhone
                    : ''
                }
                value={formik.values.financeAreaPhone ? formik.values.financeAreaPhone : ''}
                onBlur={formik.handleBlur}
                width="323px"
                placeholder=""
                onKeyPress={event => {
                  if (!allownumber.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength={9}
                onPaste={event => {
                  if (!allownumber.test(event.clipboardData.getData('Text'))) {
                    event.preventDefault();
                  }
                }}
              />
              <div className="tooltip-space">
                <ToolTip msg={getMessageTooltip('phone_information')} position="top-left" />
              </div>
            </div>
          </div>
        </div>
      </FormSection>
    </>
  );
};
