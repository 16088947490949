import { Icons } from '@cencosud-ds/bigbang/antiguo';
import GestionReclamosIcon from 'components/icons/gestion-reclamos';
import PostVentaIcon from 'components/icons/postventa-icon';
interface IHarcodedIcons {
  [key: string]: () => JSX.Element;
}
const harcodedIcons: IHarcodedIcons = {
  'gestion-reclamos': GestionReclamosIcon,
  postventa: PostVentaIcon,
};

const getIcon = (iconId: string): (() => JSX.Element) => {
  let icon: () => JSX.Element = Icons[`${iconId}`];
  if (!icon) {
    icon = harcodedIcons[iconId];
  }
  return icon;
};
export default getIcon;
