import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { BACKEND_URL } from '../../config';
import getAxios from '../../clients';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';
import CustomForm from '../../components/customFormForgotPassword/Form';
import ChangePasswordSchema from '../../schemas/changePassword.js';
import t from '../../utils/translate';
import HeaderMarquetPlace from '../../components/HeaderMarquetPlace/HeaderMarquetPlace';
import Footer from '../../components/Footer/Footer';

const ChangePassword = props => {
  const { hash } = props.match.params;

  const [changePassword, setChangePassword] = useState({
    hash: '',
    hashError: false,
    changePasswordSucess: false,
    changePasswordError: false,
  });

  const handleValidateHash = async hash => {
    try {
      const response = await getAxios.instance().get(`${BACKEND_URL}/auth/validate/hash/${hash}`, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = response;
      setChangePassword({ ...changePassword, hash: data.hash, hashError: false });
    } catch (error) {
      console.log('handleValidateHash', error);
      setChangePassword({ ...changePassword, hash: '', hashError: true });
    }
  };

  const handleChangePassword = async body => {
    try {
      const response = await getAxios.instance().post(`${BACKEND_URL}/auth/change/password`, body, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { data } = response;
      setChangePassword({ ...changePassword, changePasswordSucess: data.result });
    } catch (error) {
      console.log('handleChangePassword', error);
      setChangePassword({ ...changePassword, changePasswordError: true });
    }
  };

  useEffect(() => {
    handleValidateHash(hash);
  }, [hash]);

  const onSubmit = async values => {
    handleChangePassword({ password: values.pass, hash: changePassword.hash });
  };

  const fields = [
    {
      name: 'pass',
      id: 'pass',
      label: 'Contraseña',
      type: 'password',
      initialValue: '',
      width: 12,
    },
    {
      name: 'password',
      id: 'password',
      label: 'Confirmar Contraseña',
      type: 'password',
      initialValue: '',
      width: 12,
    },
  ];

  if (changePassword.hashError === true) {
    return <Redirect to={`/login?type=error&message=Your password change attempt has expired. Please try again.`} />;
  }

  if (changePassword.changePasswordError === true) {
    return <Redirect to={`/login?type=error&message=Your password change attempt has expired. Please try again.`} />;
  }

  if (changePassword.changePasswordSucess === true) {
    return <Redirect to={`/login?type=success&message=The password has been modified successfully.`} />;
  }

  return (
    <div className="App">
      <HeaderMarquetPlace />

      <div className="App-login">
        <GridContainer>
          <GridItem xs={1} sm={2} md={4} />
          <GridItem xs={10} sm={8} md={4}>
            <CustomForm
              fields={fields}
              onSubmitFunction={onSubmit}
              validationSchema={ChangePasswordSchema}
              includeCardHeader={true}
              title={t('Change password')}
              headerColor="primary"
              submitColor="primary"
              submitText={t('Change password')}
            />
          </GridItem>
        </GridContainer>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
