import React, { useEffect, useState, useRef } from 'react';
import { Typography, Icon, colors, Loader } from '@cencosud-ds/bigbang';
import { InterfaceGlobalSearch } from './interfaces';
import {
  StyledGlobalSearch,
  StyledCategory,
  StyledCategoryLabel,
  StyledGlobalSearchInput,
  StyledIcon,
  Separator,
  StyledSearchInput,
  ButtonClear,
  StyledDropdown,
  StyledResultsRow,
  HorizontalSeparator,
  StyledDeleteAll,
} from './styles';

const switchIcon: {
  success: JSX.Element;
  error: JSX.Element;
  warning: JSX.Element;
} = {
  success: <Icon isFill={true} name="CheckmarkCircle" iconColor={colors.feedback.success.dark} />,
  error: <Icon isFill={true} name="AlertCircle" iconColor={colors.feedback.error.dark} />,
  warning: <Icon isFill={true} name="AlertTriangle" iconColor={colors.feedback.warning.dark} />,
};

const GlobalSearch = (props: InterfaceGlobalSearch) => {
  const {
    disabled,
    onChangeInput,
    'input-value': value,
    width,
    'icon-end': iconEnd,
    handleDeleteAll,
    'slot-results': slotResults,
    isLoading,
    'without-results-texts': withoutResultsTexts,
    showResults,
    setShowResults,
    placeholder,
    'label-category': labelCategory,
    showCategories,
    setShowCategories,
    'slot-categories': slotCategories,
    maxLength,
    'input-state': inputState,
    'feedback-text': feedbackText,
    onKeyPress,
    onPaste,
  } = props;

  const [focused, setFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value || '');

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowCategories(false);
        setShowResults(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (showCategories) {
      setShowResults(false);
    }
  }, [setShowResults, showCategories]);

  useEffect(() => {
    if (showResults) {
      setShowCategories(false);
    }
  }, [setShowCategories, showResults]);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = String(e.target.value);
    setInputValue(text);
    onChangeInput(text);
  };

  const handleInputClear = () => {
    setInputValue('');
    onChangeInput('');
  };

  useEffect(() => {
    if (disabled) {
      setShowCategories(false);
      setShowResults(false);
      handleInputClear();
    }
  }, [disabled, setShowCategories, setShowResults]);

  const inputFocused = props['input-focused'] ? true : focused;

  const IconEndComponent =
    iconEnd && React.cloneElement(iconEnd, { iconColor: disabled ? colors.neutral[200] : colors.primary[500] });

  const handleOnClickCategory = () => setShowCategories(!showCategories);

  const WithoutResults = () => (
    <>
      <StyledResultsRow>
        <Typography
          color={colors.neutral[400]}
          typeElement="body2"
          weight="regular"
          children={`${withoutResultsTexts.title} "${inputValue}"`}
        />
      </StyledResultsRow>
      <HorizontalSeparator />
      <StyledResultsRow>
        <Typography
          color={colors.neutral[400]}
          typeElement="body2"
          weight="regular"
          children={withoutResultsTexts.description}
        />
      </StyledResultsRow>
    </>
  );

  const WithResults = () => (
    <>
      <StyledResultsRow>
        <Typography color={colors.neutral[400]} typeElement="body2" weight="regular" children={inputValue} />
      </StyledResultsRow>
      <HorizontalSeparator />
      <StyledResultsRow>
        <Typography color={colors.neutral[400]} typeElement="body2" weight="semiBold" children="Busqueda Sugerida" />
        {handleDeleteAll && (
          <StyledDeleteAll type="button" onClick={handleDeleteAll}>
            <Typography color={colors.primary[500]} typeElement="links-m" weight="regular" children="Borrar todas" />
          </StyledDeleteAll>
        )}
      </StyledResultsRow>
      {slotResults}
    </>
  );

  return (
    <StyledGlobalSearch feedback-text={''} maxLength={0} input-state={''} ref={wrapperRef} {...{ width }}>
      <StyledGlobalSearchInput
        input-state={inputState}
        input-value={inputValue}
        input-focused={inputFocused}
        {...{ disabled }}
        feedback-text={feedbackText}
        maxLength={maxLength}
      >
        <StyledCategory type="button" onClick={handleOnClickCategory}>
          <StyledCategoryLabel
            color={disabled ? colors.neutral[200] : colors.neutral[400]}
            typeElement="body1"
            weight="regular"
            children={labelCategory}
          />
          <Icon
            isFill={true}
            name={showCategories ? 'ArrowUp' : 'ArrowDown'}
            iconColor={disabled ? colors.neutral[200] : colors.primary[500]}
            size="s"
          />
          <Separator />
        </StyledCategory>
        <StyledSearchInput
          type="text"
          value={inputValue}
          onChange={handleOnChange}
          autoFocus={inputFocused}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          {...{ disabled, placeholder, width }}
          onKeyPress={onKeyPress}
          onPaste={onPaste}
        />
        <StyledIcon>
          {isLoading ? (
            <Loader size="large" />
          ) : inputValue !== '' ? (
            <ButtonClear
              type="button"
              role="button"
              onMouseUp={handleInputClear}
              onTouchStart={handleInputClear}
              onClick={handleInputClear}
            >
              <Icon name="CloseCircle" iconColor={colors.neutral[200]} />
            </ButtonClear>
          ) : (
            iconEnd && IconEndComponent
          )}
        </StyledIcon>
        <StyledIcon>
          <Icon name="Search" iconColor={disabled ? colors.neutral[200] : colors.primary[500]} />
        </StyledIcon>
        <div className="input-icon">
          {switchIcon[props['input-state'] as 'success' | 'error' | 'warning'] || props['icon-end']}
        </div>
      </StyledGlobalSearchInput>
      {showCategories && slotCategories && <StyledDropdown>{slotCategories}</StyledDropdown>}
      {showResults && inputValue !== '' && (
        <StyledDropdown {...{ width }}>{slotResults ? <WithResults /> : <WithoutResults />}</StyledDropdown>
      )}
      {props['feedback-text'] && (
        <Typography
          propsElement={{
            className: 'input-text-feedback-global-search',
          }}
          color={colors.feedback.error.medium}
          typeElement="body3"
          weight="regular"
        >
          {props['feedback-text']}
        </Typography>
      )}
    </StyledGlobalSearch>
  );
};

GlobalSearch.defaultProps = {
  disabled: false,
  width: '47rem',
  placeholder: '',
  onChangeInput: console.log,
  'input-value': '',
  'icon-end': '',
  handleDeleteAll: '',
  isLoading: false,
  showResults: false,
  'slot-results': undefined,
  'without-results-texts': {
    title: 'No hay sugerencias para',
    description: 'Si tu búsqueda no arrojó resultados, revisa tu ortografía navega para encontrar un producto similar.',
  },
  'label-category': '',
  showCategories: false,
  'slot-categories': undefined,
};

export default GlobalSearch;
