import React, { ReactNode } from 'react';
import { Icon, Tooltip } from '@cencosud-ds/bigbang';
import './index.css';
import { options } from '@cencosud-ds/bigbang/Tooltip/interfaces';

interface FormToolTipProps {
  msg: ReactNode;
  position: options;
}

const FormSection: React.FC<FormToolTipProps> = props => {
  const { msg, position } = props;

  return (
    <div className="icon-alert">
      {
        <Tooltip title={msg} text="" position={position} darkMode={true} with-triangle={true} active={false}>
          <Icon name="AlertCircle" isFill={false} />
        </Tooltip>
      }
    </div>
  );
};

export default FormSection;
