import * as React from 'react';

function SvgNivelThree(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.5074 1.91363L14.0116 4.88901L13.6846 5.53583L11.8127 1.29107C11.9272 1.11319 12.1315 1 12.3522 1H14.9271C15.4093 1 15.7199 1.4932 15.5074 1.91363Z"
        fill="#0041D6"
      />
      <path
        d="M15.5566 7.79993V6.44161C15.5566 5.19648 14.5267 4.17773 13.2679 4.17773H10.4397C9.1809 4.17773 8.15097 5.19648 8.15097 6.44161V7.79993C5.52711 9.134 3.72882 11.8426 3.72882 14.9635C3.72882 19.4023 7.36626 23.0002 11.8538 23.0002C16.3414 23.0002 19.9788 19.4023 19.9788 14.9635C19.987 11.8345 18.1887 9.12591 15.5566 7.79993ZM14.0853 7.22588C13.3742 7.02375 12.6303 6.91864 11.8538 6.91864C11.0773 6.91864 10.3334 7.02375 9.6223 7.22588V6.44161C9.6223 5.99692 9.99013 5.63308 10.4397 5.63308H13.2679C13.5785 5.63308 13.8483 5.80287 13.9872 6.05351L13.9954 6.0616C14.0526 6.17479 14.0853 6.30416 14.0853 6.43352V7.22588Z"
        fill="#1AC5EB"
      />
      <path
        d="M11.7641 22.1321C15.6637 22.1321 18.8249 18.9508 18.8249 15.0265C18.8249 11.1022 15.6637 7.9209 11.7641 7.9209C7.86454 7.9209 4.70331 11.1022 4.70331 15.0265C4.70331 18.9508 7.86454 22.1321 11.7641 22.1321Z"
        fill="url(#paint0_linear_500_9596)"
      />
      <path
        d="M11.8619 22.2159C7.8158 22.2159 4.52167 18.9576 4.52167 14.9554C4.52167 10.9532 7.80763 7.69482 11.8619 7.69482C15.9163 7.69482 19.2022 10.9532 19.2022 14.9554C19.1941 18.9576 15.9081 22.2159 11.8619 22.2159ZM11.8619 8.26079C8.13459 8.26079 5.09385 11.2604 5.09385 14.9554C5.09385 18.6503 8.12642 21.65 11.8619 21.65C15.5893 21.65 18.63 18.6503 18.63 14.9554C18.63 11.2604 15.5893 8.26079 11.8619 8.26079Z"
        fill="white"
      />
      <path
        d="M11.6074 17.2279L9.42159 15.0658C9.31634 14.9617 9.31634 14.7929 9.42159 14.6897L10.6919 12.9253C10.7427 12.875 10.8108 12.8472 10.8824 12.8472H12.7126C12.7842 12.8472 12.8523 12.875 12.9031 12.9253L14.1734 14.6897C14.2787 14.7938 14.2787 14.9626 14.1734 15.0658L11.9876 17.2279C11.8832 17.332 11.7127 17.332 11.6074 17.2279Z"
        fill="#04758E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2748 12.5762L10.307 12.5444C10.46 12.3931 10.6665 12.3086 10.8825 12.3086H12.7126C12.9286 12.3086 13.1351 12.3931 13.2881 12.5444L13.3202 12.5762L14.5975 14.3504C14.8746 14.6656 14.864 15.1458 14.5577 15.4472C14.5574 15.4475 14.5571 15.4478 14.5567 15.4481L12.3742 17.6069C12.3739 17.6073 12.3735 17.6076 12.3732 17.608C12.0549 17.9244 11.5391 17.9217 11.2225 17.6086L9.03667 15.4465C8.73359 15.1467 8.71814 14.6663 8.9981 14.3496L10.2748 12.5762ZM9.42163 14.6897C9.31638 14.7929 9.31638 14.9616 9.42163 15.0657L11.6074 17.2278C11.7127 17.3319 11.8833 17.3319 11.9876 17.2278L14.1734 15.0657C14.2787 14.9625 14.2787 14.7938 14.1734 14.6897L12.9032 12.9252C12.8523 12.8749 12.7843 12.8471 12.7126 12.8471H10.8825C10.8108 12.8471 10.7427 12.8749 10.6919 12.9252L9.42163 14.6897Z"
        fill="url(#paint1_linear_500_9596)"
      />
      <path
        d="M12.7937 6.42541H10.3824C10.1372 6.42541 9.91648 6.28796 9.81022 6.07774L9.59769 5.64923L7.75854 1.99469C7.54601 1.57426 7.85662 1.08105 8.33072 1.08105H10.9055C11.1589 1.08105 11.3878 1.22659 11.4859 1.45298L13.3741 5.53603C13.3904 5.57646 13.4068 5.61688 13.415 5.65731C13.5131 6.03732 13.2188 6.42541 12.7937 6.42541Z"
        fill="#166DC2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9596"
          x1="11.7354"
          y1="10.2444"
          x2="11.7354"
          y2="23.3479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E1F0FF" />
          <stop offset="1" stop-color="#068DB7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_9596"
          x1="13.8223"
          y1="12.3871"
          x2="11.8586"
          y2="15.8162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1AC5EB" />
          <stop offset="1" stop-color="#E1F0FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNivelThree;
