import { BACKEND_URL } from '../config';
import getAxios from 'clients';
import { headers } from './patterns';

const getCheckExpiration = async (jwt: string) => {
  try {
    if (jwt) {
      const result = await getAxios.instance(jwt).get(`${BACKEND_URL}/auth/check`, { headers });
      if (result.data === true) {
        return true;
      }
    } else {
      throw new Error();
    }
  } catch (err) {
    return false;
  }
};

export default getCheckExpiration;
