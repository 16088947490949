import React, { useEffect } from 'react';
import { colors, Typography } from '@cencosud-ds/bigbang';
import { BottomBar } from '../../components';
import { IWizardFormProps } from '../../interfaces/wizard.interface';
import { useWizardFormControl } from '../../context';
import t from '../../../../../utils/translate';
import { PartherOne } from './components';
import { useFormik } from 'formik';
import validationSchema from './legal.schema';
import { IWizardForm } from '../../interfaces/wizard-form.interface';
import { SocietyType } from 'utils/signup/constants/society';
import { PERSON_NATURAL } from 'variables/society';
import { trackGTMPageViewCuston } from 'utils/analytics';

const LegalStep: React.FC<IWizardFormProps> = props => {
  const { position } = props;

  useEffect(() => {
    trackGTMPageViewCuston({
      page: '/registrate-ahora',
      title: 'Información legal',
    });
  }, []);

  const formWizard = useWizardFormControl(position);

  const stepOne: IWizardForm = useWizardFormControl(0);
  const stepOneRut: string = stepOne.data.rutNumber;

  const formik = useFormik({
    initialValues: {
      societyTypePartherOne: formWizard ? formWizard.data.societyTypePartherOne : '',
      rutNumberPartherOne: formWizard ? formWizard.data.rutNumberPartherOne : '',
      namePartherOne: formWizard ? formWizard.data.namePartherOne : '',
      firstPartherOne: formWizard ? formWizard.data.firstPartherOne : '',
      secondNamePartherOne: formWizard ? formWizard.data.secondNamePartherOne : '',
      nationalityPartherOne: formWizard ? formWizard.data.nationalityPartherOne : '',
      addressPartherOne: formWizard ? formWizard.data.addressPartherOne : '',
      regionPartherOne: formWizard ? formWizard.data.regionPartherOne : '',
      communePartherOne: formWizard ? formWizard.data.communePartherOne : '',
      societyTypePartherTwo: formWizard ? formWizard.data.societyTypePartherTwo : '',
      rutNumberPartherTwo: formWizard ? formWizard.data.rutNumberPartherTwo : '',
      firstPartherTwo: formWizard ? formWizard.data.firstPartherTwo : '',
      secondNamePartherTwo: formWizard ? formWizard.data.secondNamePartherTwo : '',
      namePartherTwo: formWizard ? formWizard.data.namePartherTwo : '',
      nationalityPartherTwo: formWizard ? formWizard.data.nationalityPartherTwo : '',
      addressPartherTwo: formWizard ? formWizard.data.addressPartherTwo : '',
      regionPartherTwo: formWizard ? formWizard.data.regionPartherTwo : '',
      communePartherTwo: formWizard ? formWizard.data.communePartherTwo : '',
      societyTypeRepresentative: formWizard ? formWizard.data.societyTypeRepresentative : '',
      rutNumberRepresentative: formWizard ? formWizard.data.rutNumberRepresentative : '',
      nameRepresentative: formWizard ? formWizard.data.nameRepresentative : '',
      firstPartherRepresentative: formWizard ? formWizard.data.firstPartherRepresentative : '',
      secondNamePartherRepresentative: formWizard ? formWizard.data.secondNamePartherRepresentative : '',
      nationalityRepresentative: formWizard ? formWizard.data.nationalityRepresentative : '',
      addressRepresentative: formWizard ? formWizard.data.addressRepresentative : '',
      regionRepresentative: formWizard ? formWizard.data.regionRepresentative : '',
      communeRepresentative: formWizard ? formWizard.data.communeRepresentative : '',
      showPartherTwo: stepOne
        ? stepOne.data.societyType === SocietyType.SA || stepOne.data.societyType === SocietyType.LTDA
          ? true
          : false
        : false,
    },
    onSubmit: values => {
      console.log(values);
    },
    validationSchema: validationSchema(stepOneRut),
  });

  const handleOnBack = () => {
    formWizard.data = formik.values;
  };

  const handleOnNext = async (): Promise<boolean> => {
    if ([PERSON_NATURAL].includes(formik.values.societyTypePartherOne)) {
      formik.values.namePartherOne = formik.values.firstPartherOne;
    } else {
      formik.values.firstPartherOne = '';
      formik.values.secondNamePartherOne = '';
    }

    if ([PERSON_NATURAL].includes(formik.values.societyTypePartherTwo)) {
      formik.values.namePartherTwo = formik.values.firstPartherTwo;
    } else {
      formik.values.firstPartherTwo = '';
      formik.values.secondNamePartherTwo = '';
    }

    if ([PERSON_NATURAL].includes(formik.values.societyTypeRepresentative)) {
      formik.values.nameRepresentative = formik.values.firstPartherRepresentative;
    } else {
      formik.values.firstPartherRepresentative = '';
      formik.values.secondNamePartherRepresentative = '';
    }

    await formik.submitForm();
    const isFormValid = formik.isValid && Object.keys(formik.touched).length > 0;

    if (isFormValid) {
      formWizard.data = formik.values;

      return true;
    }
    return false;
  };

  return (
    <>
      <div className="wizard-form-section pb-3">
        <div className="mb-5">
          <Typography
            typeElement="subtitle1"
            weight="semiBold"
            color={colors.primary[500]}
            children={t('sign_up_legal_title')}
          />
        </div>
        <div className="mb-4">
          <PartherOne formik={formik} stepOne={stepOne} />
        </div>
      </div>
      <BottomBar onBack={() => handleOnBack()} onNext={() => handleOnNext()} />
    </>
  );
};

export default LegalStep;
