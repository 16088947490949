const colors = {
  primary: {
    900: '#020B13',
    800: '#07233F',
    700: '#0C3C6B',
    600: '#115596',
    500: '#166DC2',
    400: '#1C8AF5',
    300: '#80C0FF',
    200: '#B8DCFF',
    100: '#E1F0FF',
  },
  secondary: {
    900: '#4D2900',
    800: '#794200',
    700: '#A65A00',
    600: '#E17C02',
    500: '#FF8A00',
    400: '#FFA133',
    300: '#FFC075',
    200: '#FFDCB2',
    100: '#FFF7F0',
  },
  neutral: {
    500: '#212C39',
    400: '#323A44',
    300: '#5F666D',
    200: '#A4A7AC',
    100: '#F4F4F5',
    0: '#FFFFFF',
  },
  feedback: {
    success: {
      dark: '#22A889',
      medium: '#B6DFD6',
      light: '#E6F5F1',
    },
    warning: {
      dark: '#DBC716',
      medium: '#ECE285',
      light: '#FFFCE5',
    },
    error: {
      dark: '#BD2C00',
      medium: '#E54717',
      light: '#F7E6E1',
    },
    information: {
      dark: '#166DC2',
      medium: '#80C0FF',
      light: '#FFFFFF',
    },
  },
};

export default colors;
