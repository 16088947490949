import React from 'react';

interface IProperties {
  className?: string;
}

const IconRendimiento = ({ className }: IProperties) => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.5056 16.0028H25.5045C25.5045 12.3421 23.3141 9.19153 20.1735 7.79127L22.6239 2.31024C27.8549 4.65068 31.5056 9.90167 31.5056 16.0028Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M40.9074 40.4174C40.1273 41.1976 38.867 41.1976 38.0869 40.4174L33.0959 35.4265C31.9057 36.1266 30.5155 36.5267 29.0252 36.5267C24.6044 36.5267 21.0237 32.946 21.0237 28.5252C21.0237 24.1044 24.6044 20.5237 29.0252 20.5237C33.446 20.5237 37.0267 24.1044 37.0267 28.5252C37.0267 30.0155 36.6266 31.4057 35.9265 32.5959L40.9074 37.5869C41.6976 38.367 41.6976 39.6373 40.9074 40.4174Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M29.0252 32.5359C31.2347 32.5359 33.0259 30.7447 33.0259 28.5352C33.0259 26.3256 31.2347 24.5344 29.0252 24.5344C26.8156 24.5344 25.0244 26.3256 25.0244 28.5352C25.0244 30.7447 26.8156 32.5359 29.0252 32.5359Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.624 2.30024V2.31025L20.1735 7.79127C19.0533 7.28118 17.8131 7.00113 16.5028 7.00113C11.5319 7.00113 7.50113 11.0319 7.50113 16.0028C7.50113 20.9737 11.5319 25.0045 16.5028 25.0045V31.0056C15.7227 31.0056 14.9625 30.9456 14.2124 30.8356C13.7223 30.7556 13.2422 30.6556 12.7721 30.5355C12.7621 30.5355 12.7521 30.5355 12.7521 30.5355C7.51113 29.1553 3.35035 24.9945 1.97009 19.7535C1.97009 19.7435 1.97009 19.7335 1.97009 19.7335C1.85007 19.2534 1.75005 18.7733 1.67003 18.2832C1.56001 17.5431 1.5 16.783 1.5 16.0028C1.5 15.2227 1.56001 14.4625 1.67003 13.7224C1.75005 13.2323 1.85007 12.7522 1.97009 12.2721C1.97009 12.2721 1.97009 12.2621 1.97009 12.2521C3.35035 7.01113 7.51113 2.85035 12.7521 1.47009C12.7521 1.47009 12.7621 1.47009 12.7721 1.47009C13.2422 1.35007 13.7223 1.25005 14.2124 1.17003C14.9625 1.06001 15.7227 1 16.5028 1C17.353 1 18.1831 1.07001 18.9933 1.21004C19.2133 1.24005 19.4234 1.28005 19.6334 1.33006C19.8834 1.38007 20.1435 1.44008 20.3935 1.5101C21.1037 1.70013 21.7938 1.94018 22.4539 2.23023C22.5139 2.25023 22.5639 2.27024 22.624 2.30024Z"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default IconRendimiento;
