import styled from '@emotion/styled';

export const Container = styled.div`
  padding-bottom: 28px;
  margin: 0 0 28px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #b8dcff;
  width: 100%;
  .container-subtitle {
    width: 29em;
    margin-right: 3em;
    margin-bottom: 8px;
  }
  .container-textbody {
    width: 404px;
  }
  .container-forms {
    width: 100%;
  }

  .container-forms input {
    width: 360px;
  }

  .container-forms div.Dropdown {
    width: 360px;
  }
`;

export const ContainerLastItem = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  .container-subtitle {
    width: 29em;
    margin-right: 3em;
    margin-bottom: 8px;
  }
  .container-textbody {
    width: 404px;
  }
  .container-forms {
    width: 100%;
  }

  .container-forms input {
    width: 360px;
  }

  .container-forms div.Dropdown {
    width: 360px;
  }
`;
