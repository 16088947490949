import React from 'react';
import './style.scss';
import { Typography } from '@cencosud-ds/bigbang';
import Icons from './icons';
import t from '../../../../../../../utils/translate';

interface CardProps {
  icon: number;
  step: string;
  title: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ icon, step, title, description }) => {
  const getDescription = () => {
    let response;

    response = <span className="mr-5">{description}</span>;

    if (step === 'Paso 4') {
      const texts = description.split('24 horas');
      response = (
        <span className="mr-5">
          {texts[0]}
          <span className="mr-5 text-step">
            {t('extra_text_card')} <span className="mr-5 text-step-clear">{texts[1]}</span>
          </span>
        </span>
      );
    }

    return response;
  };

  return (
    <div className="card">
      <div className="header-card">
        {<Icons icon={icon} />}
        <div className="filler" />
        <div className="chip-step">
          <span className="chip-text">{step}</span>
        </div>
      </div>
      <div className="content-card">
        <Typography color="#166DC2" propsElement={{}} typeElement="body2" weight="semiBold">
          <span className="mr-5">{title}</span>
        </Typography>
        <Typography color="#323A44" propsElement={{}} typeElement="caption">
          {getDescription()}
        </Typography>
      </div>
    </div>
  );
};

export default Card;
