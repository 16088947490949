import { isAfter, isEqual } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import t from '../../utils/translate';
import { TermsModalInterface } from './interfaces';
import { Container } from './styles';
import { approveTerms } from '../../api/terms';
import SessionContext from '../../api/session/context';
import Html from '../Html';
import { CONTENT_URL_2, SPA_FLASH_MESSAGE_EVENT } from '../../config';
import { fixTimeZone, forceMidnight } from '../../utils/formatDates';
import { Button, Modal, Typography } from '@cencosud-ds/bigbang';

const TermsModal = ({ terms, closeModal, seller }: TermsModalInterface) => {
  const {
    auth: { jwt },
  } = useContext(SessionContext);
  const [position, setPosition] = useState(0);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [buttonExitShow, setButtonExitShow] = useState(false);
  const [finishValidationsSeller, setFinishValidationsSeller] = useState(false);

  /* Es necesario formatear el día de hoy porque si no da un día menos, la fecha limite de aprobación
      tiene hora 0:00:00 y la de hoy depende del momento del día en que se ejecute por eso da un dia menos)*/
  const today = forceMidnight(new Date());

  useEffect(() => {
    if (
      seller &&
      seller.id !== '' &&
      (seller.vendor_code_801_client || seller.vendor_code_801_client !== '0') &&
      (seller.vendor_code_801_provider || seller.vendor_code_801_provider !== '0')
    ) {
      if (seller.publish === true && seller.terms_conditions_signed === 0) {
        if (terms[position].exception === 0) {
          setButtonExitShow(true);
        } else {
          setButtonExitShow(false);
        }
      } else {
        setButtonExitShow(true);
      }
    } else {
      setButtonExitShow(true);
    }
  }, [seller]);

  useEffect(() => {
    setTimeout(() => {
      setFinishValidationsSeller(true);
    }, 1000);
  }, [buttonExitShow]);

  useEffect(() => {
    terms.forEach(({ startDate }) => {
      const start = fixTimeZone(startDate);
      if (isAfter(today, start) || isEqual(start, today)) {
        setShouldShowModal(true);
      }
    });
    return () => {
      setPosition(0);
    };
  }, []);

  const logOut = () => {
    setOpenModal(false);
    closeModal();
  };

  const sendApproval = async () => {
    const response = await approveTerms(jwt, terms[position].id);

    if (!response) {
      const event = new Event(SPA_FLASH_MESSAGE_EVENT);
      // @ts-ignore
      event.data = {
        message: t('approveTermsError'),
        flashMessageType: 'danger',
      };

      window.dispatchEvent(event);
    } else {
      setOpenModal(false);
    }
    window.location.reload();
  };

  if (terms.length && shouldShowModal && finishValidationsSeller) {
    return (
      <Modal persistent={true} open={openModal} id={'modal-terms'} onClose={logOut}>
        <Container className="container-modal">
          <div className="description-modal">
            <div>
              <Typography color="#166DC2" propsElement={{}} typeElement="h4" weight="semiBold">
                {t('titleModal')}
              </Typography>
            </div>
            <div className="description-complement">
              <Typography color="#020B13" propsElement={{}} typeElement="body2" weight="regular">
                {t('tcWarning')}
              </Typography>
            </div>
          </div>
          {terms[position].content.includes('pdf') ? (
            <iframe
              title="termsfile"
              src={`${CONTENT_URL_2}/document/termsfile/${terms[position].content}`}
              width={'100%'}
              height={'100%'}
              frameBorder={0}
              className="content-pdf"
            />
          ) : (
            <div className="content">{<Html>{terms[position].content.replace(/\\r|\\n/g, '')}</Html>}</div>
          )}
          {/* <div>
            <Notification className="notification" extended={true} variant="warning">
              <p
                style={{
                  fontFamily: 'Open Sans',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  letterSpacing: '0.25px',
                  lineHeight: '16px',
                }}
              >
                {t('warningModalOne')}
                <strong>
                  {`${moment(terms[position].endDate, 'YYYY-MM-DD').format('DD')} de`}&nbsp;
                  {`${moment(terms[position].endDate, 'YYYY-MM-DD').format('MMMM')}.`}&nbsp;
                </strong>
                {t('warningModalTwo')}
              </p>
            </Notification>
          </div> */}
          <div className="buttons">
            {buttonExitShow ? (
              <Button className="button-exit" onClick={logOut} type-button="secondary" variant-button="s">
                {t('getOut')}
              </Button>
            ) : (
              <>
                {terms[position].exception === 0 ? (
                  <Button className="button-exit" onClick={logOut} type-button="secondary" variant-button="s">
                    {t('getOut')}
                  </Button>
                ) : (
                  <Button
                    className="button-exit"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                    type-button="secondary"
                    variant-button="s"
                  >
                    {t('tGetOut')}
                  </Button>
                )}
              </>
            )}
            <Button className="button-accept" onClick={sendApproval} type-button="primary" variant-button="s">
              {t('taccept')}
            </Button>
          </div>
        </Container>
      </Modal>
    );
  } else {
    return null;
  }
};

export default TermsModal;
