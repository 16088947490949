import React from 'react';
import { Container, Column, Row, Icons, SimpleButton } from '@cencosud-ds/bigbang/antiguo';
import { useHistory } from 'react-router-dom';
import { WrapperButtons } from './styles';
import t from '../../../../../utils/translate';
import { InterfaceResponseBulkLoad } from './interfaces';

function AlertErrorBulkLoad(props: { info: InterfaceResponseBulkLoad; toggleModalState: () => void }) {
  const { info: responseBulkUpload, toggleModalState } = props;
  const history = useHistory();
  const [withoutErrors] = React.useState(() =>
    Boolean(responseBulkUpload.errors.length || responseBulkUpload.warnings.length),
  );
  const PushToReportError = () => {
    history.push('invoice/reportError', { ...responseBulkUpload });
    toggleModalState();
  };

  const message = withoutErrors
    ? t(`An Excel file was uploaded without the required fields`)
    : t('invoices have been loaded successfully');
  return (
    <Container style={{ marginTop: '1em' }}>
      <Row>
        <Column justifyContent="flex-start" alignItems="flex-end">
          {withoutErrors && (
            <>
              {' '}
              <Icons.AlertIcon scale={0.7} />
              <strong
                style={{
                  marginRight: '3px',
                  fontSize: '1.3em',
                }}
              >
                {'alert'}
              </strong>{' '}
            </>
          )}
          <span>{message}</span>
        </Column>
      </Row>
      {withoutErrors && (
        <Row>
          <WrapperButtons justifyContent="space-between">
            <SimpleButton onClick={toggleModalState} uppercase={false} roundBorder={true} outline={true}>
              <strong>{t('close')}</strong>
            </SimpleButton>
            <SimpleButton onClick={PushToReportError} uppercase={false} roundBorder={true}>
              {t('view bug report')}
            </SimpleButton>
          </WrapperButtons>
        </Row>
      )}
    </Container>
  );
}
export default AlertErrorBulkLoad;
