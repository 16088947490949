import React from 'react';
import { Typography, colors } from '@cencosud-ds/bigbang';
import t from '../../../../../utils/translate';
import SpinnerIcon from '../vectors/spinner-icon';
import SignupFormIcon from '../vectors/signup-form-icon';
import '../../index.css';

const Sending: React.FC<{}> = () => {
  return (
    <>
      <div className="sending-screen">
        <div className="container-horizontal-sending">
          <SpinnerIcon className="mb-4 spinner" />
          <Typography
            typeElement="h3"
            weight="bold"
            color={colors.feedback.information.dark}
            children={t('sign_up_main_waiting_title_1')}
          />
          <Typography
            typeElement="caption"
            weight="regular"
            color={'#334661'}
            children={t('sign_up_main_waiting_title_2')}
          />
          <SignupFormIcon className="mt-4" />
        </div>
      </div>
    </>
  );
};

export default Sending;
