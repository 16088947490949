/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

interface IProperties {
  className?: string;
}

const SpinnerIcon = ({ className }: IProperties) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ className }}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M43.082 11.625C43.082 9.48213 44.8367 7.75 46.9987 7.75C49.1607 7.75 50.9154 9.48213 50.9154 11.625V19.375C50.9154 21.5179 49.1607 23.25 46.9987 23.25C44.8367 23.25 43.082 21.5179 43.082 19.375V11.625ZM74.4154 42.625H82.2487C84.4107 42.625 86.1654 44.3571 86.1654 46.5C86.1654 48.6429 84.4107 50.375 82.2487 50.375H74.4154C72.2534 50.375 70.4987 48.6429 70.4987 46.5C70.4987 44.3571 72.2534 42.625 74.4154 42.625ZM23.4987 46.5C23.4987 44.3571 21.744 42.625 19.582 42.625H11.7487C9.5867 42.625 7.83203 44.3571 7.83203 46.5C7.83203 48.6429 9.5867 50.375 11.7487 50.375H19.582C21.744 50.375 23.4987 48.6429 23.4987 46.5ZM18.8249 19.5815C20.3329 18.047 22.8082 17.9967 24.3631 19.4847L29.9992 24.8709C31.5541 26.355 31.5972 28.8118 30.0932 30.3502C29.3255 31.1368 28.3033 31.532 27.2771 31.532C26.2979 31.532 25.3188 31.1755 24.555 30.447L18.9189 25.0608C17.364 23.5767 17.3209 21.1199 18.8249 19.5815ZM66.7199 31.5336C67.6991 31.5336 68.6782 31.1732 69.442 30.4486L75.0781 25.0623C76.633 23.5743 76.6761 21.1176 75.1721 19.5831C73.672 18.0486 71.1967 17.9982 69.6339 19.4862L63.9978 24.8686C62.4429 26.3566 62.3998 28.8133 63.9038 30.3478C64.6715 31.1345 65.6937 31.5336 66.7199 31.5336ZM43.082 73.625C43.082 71.4821 44.8367 69.75 46.9987 69.75C49.1607 69.75 50.9154 71.4821 50.9154 73.625V81.375C50.9154 83.5179 49.1607 85.25 46.9987 85.25C44.8367 85.25 43.082 83.5179 43.082 81.375V73.625ZM69.4412 62.5526C67.8863 61.0684 65.407 61.1149 63.903 62.6494C62.399 64.1878 62.4421 66.6446 63.997 68.1287L69.6331 73.515C70.3969 74.2435 71.376 74.6 72.3552 74.6C73.3814 74.6 74.4036 74.2047 75.1713 73.4181C76.6753 71.8797 76.6322 69.423 75.0773 67.9388L69.4412 62.5526ZM18.9201 67.9388L24.5562 62.5526C26.115 61.0684 28.5943 61.1149 30.0944 62.6494C31.5984 64.1878 31.5553 66.6446 30.0004 68.1287L24.3643 73.515C23.6005 74.2435 22.6214 74.6 21.6422 74.6C20.616 74.6 19.5938 74.2047 18.8261 73.4181C17.3221 71.8797 17.3652 69.423 18.9201 67.9388Z"
        fill="#166DC2"
      />
    </svg>
  );
};

export default SpinnerIcon;
