import * as Yup from 'yup';
import { validate } from 'rut.js';
import t from '../../../../../utils/translate';
import {
  checkCompanyIsFundation,
  extractCompanyExtension,
  normalizeBusinessName,
  normalizeCompanyName,
  optionFundation,
} from 'utils/signup';

export default () =>
  Yup.object({
    businessName: Yup.string()
      .required(t('sign_up_company_required_field'))
      .test('businessNameFormat', t('sign_up_company_invalid_business_name'), function test(value) {
        const businessName = normalizeBusinessName(value);
        return businessName !== false;
      }),
    companyHeadAddress: Yup.string().required(t('sign_up_company_required_field')),
    companyHeadAddressRegion: Yup.string().required(t('sign_up_company_required_field')),
    companyHeadAddressCommunes: Yup.string().required(t('sign_up_company_required_field')),
    companyHeadAddressRegionsCodePostal: Yup.number().required(t('sign_up_company_required_field')),
    companyName: Yup.string()
      .required(t('sign_up_company_required_field'))
      .test('companyNameFormat', t('sign_up_company_invalid_company_name'), function test(value) {
        const companyName = normalizeCompanyName(value);
        return companyName !== false;
      }),
    rutNumber: Yup.string()
      .test('rutFormat', 'sign_up_company_invalid_rut_format', function test(value) {
        const { path, createError } = this;
        if (value) {
          return validate(value) || createError({ path, message: t('sign_up_company_rut_is_invalid') });
        }
        return true;
      })
      .required(t('sign_up_company_required_field')),
    codeUploadFile: Yup.number().required(t('sign_up_company_required_field')),
    societyType: Yup.string()
      .test('societyTypeValid', t('sign_up_company_invalid_society_type'), function test(value) {
        const companyName = normalizeCompanyName(this.parent.companyName);
        if (companyName && value) {
          const societyType: string = value.replaceAll('.', '');
          const isFundation = checkCompanyIsFundation(companyName);

          if (isFundation && societyType === optionFundation.value) {
            return true;
          }
          const companyNameExtension = extractCompanyExtension(companyName);
          return companyNameExtension === societyType;
        }
        return true;
      })
      .required(t('sign_up_company_required_field')),
    productMode: Yup.string().required(t('sign_up_company_required_field')),
    nProducts: Yup.string().required(t('sign_up_company_required_field')),
    categoriesSellers: Yup.string().required(t('sign_up_company_required_field')),
    sellerWorkUs: Yup.string().required(t('sign_up_company_required_field')),
  });
