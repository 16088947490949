import styled from '@emotion/styled';
import App from '../views/layouts/app';

export const AppStyled = styled(App)`
  height: 100vh;
  position: relative;
  /* margin: 0px; */
  background: white;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;
