import * as React from 'react';

function SvgFlatNivelTwo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.5205 1.92172L14.0247 4.8971L13.6977 5.54392L11.834 1.29107C11.9485 1.11319 12.1528 1 12.3735 1H14.9483C15.4224 1 15.733 1.50129 15.5205 1.92172Z"
        fill="#0041D6"
      />
      <path
        d="M15.5778 7.79993V6.44161C15.5778 5.19648 14.5479 4.17773 13.2891 4.17773H10.4609C9.20209 4.17773 8.17216 5.19648 8.17216 6.44161V7.79993C5.54829 9.134 3.75 11.8426 3.75 14.9635C3.75 19.4023 7.38745 23.0002 11.875 23.0002C16.3625 23.0002 20 19.4023 20 14.9635C20 11.8345 18.2017 9.134 15.5778 7.79993ZM14.1065 7.22588C13.3954 7.02375 12.6515 6.91864 11.875 6.91864C11.0985 6.91864 10.3546 7.02375 9.64348 7.22588V6.44161C9.64348 5.99692 10.0113 5.63308 10.4609 5.63308H13.2891C13.5997 5.63308 13.8695 5.80287 14.0084 6.05352L14.0166 6.0616C14.0738 6.17479 14.1065 6.30416 14.1065 6.43352V7.22588V7.22588Z"
        fill="#BABABA"
      />
      <path
        d="M12.0925 22.013C16.0261 22.013 19.2149 18.8317 19.2149 14.9074C19.2149 10.9831 16.0261 7.80176 12.0925 7.80176C8.15894 7.80176 4.97012 10.9831 4.97012 14.9074C4.97012 18.8317 8.15894 22.013 12.0925 22.013Z"
        fill="url(#paint0_linear_500_9574)"
      />
      <path
        d="M9.74074 16.5947L9.76664 13.2396C9.76906 12.9259 10.0274 12.6704 10.3437 12.6688L13.7358 12.6432C14.0529 12.6408 14.3073 12.8925 14.304 13.2053L14.2781 16.5605C14.2757 16.8741 14.0174 17.1297 13.7011 17.1312L10.309 17.1568C9.99192 17.1592 9.73748 16.9076 9.74074 16.5947Z"
        fill="url(#paint1_linear_500_9574)"
      />
      <path
        d="M10.2877 16.1725L10.2877 13.62C10.2877 13.3814 10.4827 13.1885 10.7234 13.1891L13.3039 13.1891C13.5452 13.1891 13.7402 13.382 13.7396 13.62L13.7396 16.1725C13.7396 16.4111 13.5445 16.6041 13.3039 16.6034H10.7234C10.4821 16.6034 10.2871 16.4105 10.2877 16.1725Z"
        fill="#515151"
      />
      <path
        d="M11.8751 22.2155C7.82893 22.2155 4.53479 18.9571 4.53479 14.9549C4.53479 10.9527 7.82893 7.69434 11.8751 7.69434C15.9212 7.69434 19.2154 10.9527 19.2154 14.9549C19.2154 18.9652 15.9212 22.2155 11.8751 22.2155ZM11.8751 8.26839C8.14772 8.26839 5.10697 11.268 5.10697 14.963C5.10697 18.658 8.13954 21.6576 11.8751 21.6576C15.6024 21.6576 18.6432 18.658 18.6432 14.963C18.6432 11.268 15.6024 8.26839 11.8751 8.26839Z"
        fill="white"
      />
      <path
        d="M12.8069 6.43344H10.3955C10.1503 6.43344 9.92961 6.29599 9.82335 6.08578L9.61082 5.65726L7.77166 2.0108C7.55913 1.59037 7.86975 1.09717 8.34384 1.09717H10.9187C11.1721 1.09717 11.4009 1.2427 11.499 1.46909L13.3872 5.55215C13.4036 5.59257 13.4199 5.633 13.4281 5.67343C13.5262 6.03726 13.2319 6.43344 12.8069 6.43344Z"
        fill="#166DC2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_500_9574"
          x1="4.96978"
          y1="14.9049"
          x2="19.2192"
          y2="14.9049"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#696969" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_500_9574"
          x1="9.93778"
          y1="12.8366"
          x2="14.064"
          y2="17.0082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#696969" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgFlatNivelTwo;
