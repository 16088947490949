import React from 'react';
import { Typography, Notification, colors } from '@cencosud-ds/bigbang';
import { BottomBar, FormSectionItem } from '../../components';
import { IWizardFormProps } from '../../interfaces/wizard.interface';
import { useWizardFormControl } from '../../context';
import t from '../../../../../utils/translate';
import './index.css';
import { IWizardForm } from '../../interfaces/wizard-form.interface';
import { format } from 'rut.js';

const ConfirmationStep: React.FC<IWizardFormProps> = props => {
  const stepOne: IWizardForm = useWizardFormControl(0);

  const stepTwo: IWizardForm = useWizardFormControl(1);

  const stepFour: IWizardForm = useWizardFormControl(3);

  const stepFive: IWizardForm = useWizardFormControl(4);

  const handleOnBack = () => {
    return true;
  };

  const handleOnNext = async (): Promise<boolean> => {
    return true;
  };

  const getFormattedText = (text: string, isRut?: boolean) => {
    if (isRut) {
      return text ? format(text).toUpperCase() : '';
    }
    return text ? text.toUpperCase() : '';
  };

  return (
    <>
      <div className="wizard-form-section pb-3">
        <div className="mb-5">
          <Typography
            typeElement="subtitle1"
            weight="semiBold"
            color={colors.primary[500]}
            children={t('sign_up_confirmation_title')}
          />
        </div>
        <div className="mb-5">
          <Notification className="notification" extended={true} variant="warning">
            <p className="notification-message">{t('sign_up_confirmation_warning_message')}</p>
          </Notification>
        </div>
        <div className="mb-4 content-resume">
          <FormSectionItem label={t('sign_up_confirmation_title_company')} description={<div />} lastItem={false}>
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_company_name')}
                  />
                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepOne.data.companyName)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_rut')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepOne.data.rutNumber, true)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_activity_code')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepOne.data.codeUploadFile)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_business_name')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepOne.data.businessName)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_society')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepOne.data.societyType)}
                  />
                </div>
              </div>
            </div>
          </FormSectionItem>

          <FormSectionItem label={t('sign_up_confirmation_title_sales')} description={<div />} lastItem={false}>
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_email')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.notificationInfoEmail)}
                  />
                </div>
              </div>
              <div className="col-6" />
            </div>
          </FormSectionItem>

          <FormSectionItem label={t('sign_up_confirmation_title_legal')} description={<div />} lastItem={false}>
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_name_comertial_area')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.comertialAreaName)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_email')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.comertialAreaEmail)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_phone')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.comertialAreaPhone)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_lastname_comertial_area')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.comertialAreaSecondName)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_rol_comertial_area')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.comertialAreaCharge)}
                  />
                </div>
              </div>
            </div>
          </FormSectionItem>

          <FormSectionItem label={t('sign_up_confirmation_title_finance')} description={<div />} lastItem={false}>
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_email')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.financeAreaEmail)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_phone')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepTwo.data.financeAreaPhone)}
                  />
                </div>
              </div>
            </div>
          </FormSectionItem>

          <FormSectionItem label={t('sign_up_confirmation_title_bank')} description={<div />} lastItem={false}>
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_bank')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFour.data.bankChooseBank)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_account_number')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFour.data.accountNumber)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_account_type')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFour.data.bankType)}
                  />
                </div>
              </div>
            </div>
          </FormSectionItem>

          <FormSectionItem label={t('sign_up_confirmation_title_warehouse')} description={<div />} lastItem={false}>
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_logistic_address')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFive.data.addressWarehouse)}
                  />
                </div>
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_logistic_region')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFive.data.regionWarehouse)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_logistic_commune')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFive.data.communeWarehouse)}
                  />
                </div>
              </div>
            </div>
          </FormSectionItem>

          <FormSectionItem
            label={t('sign_up_confirmation_title_representative_warehouse')}
            description={<div />}
            lastItem={true}
          >
            <div className="row mb-8">
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_email')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFive.data.emailWarehouse)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="row-item">
                  <Typography
                    typeElement="body3"
                    weight="semiBold"
                    color="#334661"
                    children={t('sign_up_confirmation_phone')}
                  />

                  <div className="space-text" />
                  <Typography
                    typeElement="body3"
                    weight="regular"
                    color="#334661"
                    children={getFormattedText(stepFive.data.phoneWarehouse)}
                  />
                </div>
              </div>
            </div>
          </FormSectionItem>
        </div>
      </div>
      <BottomBar onBack={() => handleOnBack()} onNext={() => handleOnNext()} />
    </>
  );
};

export default ConfirmationStep;
