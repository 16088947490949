import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SessionContext from '../api/session/context';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <SessionContext.Consumer>
      {({ auth }) => (
        <Route
          {...rest}
          render={props => {
            if (auth.isAuthenticated) {
              return <Component {...props} />;
            } else {
              if (auth.isChecking) {
                return null;
              } else {
                return <Redirect to={{ pathname: '/login', from: props.location }} />;
              }
            }
          }}
        />
      )}
    </SessionContext.Consumer>
  );
};

export default PrivateRoute;
