import React from 'react';

export const IconThumbsDown = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 15L22 15L22 18L28 18L28 4L25 4L25 15Z" fill="#B8DCFF" stroke="#B8DCFF" />
      <path
        d="M27.9204 3H21.4429C21.3997 3 21.3709 3 21.3278 3.01444C21.2846 3 21.2414 3 21.1982 3H8.68943C6.80376 3 5.17718 4.34333 4.80293 6.19222L3.07559 14.8589C2.84528 16.0289 3.14756 17.2278 3.89608 18.1522C4.65898 19.0767 5.76735 19.6111 6.96209 19.6111H12.2305L11.0789 23.9156C10.7478 25.1433 11.0069 26.4144 11.7698 27.4256C12.5327 28.4222 13.6987 29 14.9654 29C15.3253 29 15.6563 28.8267 15.8579 28.5378L22.0187 19.6111H27.9204C28.5106 19.6111 29 19.12 29 18.5278V4.08333C29 3.49111 28.5106 3 27.9204 3ZM14.4472 26.7611C14.073 26.66 13.7275 26.4289 13.4828 26.0967C13.1229 25.6344 13.0078 25.0567 13.1661 24.4933L14.6919 18.8167C14.7783 18.4844 14.7063 18.1378 14.5048 17.8633C14.3033 17.6033 13.9866 17.4444 13.6411 17.4444H6.96209C6.4151 17.4444 5.9113 17.1989 5.56583 16.78C5.23476 16.3611 5.09082 15.8122 5.19158 15.2778L6.91891 6.61111C7.09164 5.77333 7.84016 5.16667 8.68943 5.16667H20.3633V18.1811L14.4472 26.7611ZM26.8408 17.4444H22.5225V5.16667H26.8408V17.4444Z"
        fill="#166DC2"
      />
      <path d="M20 7H22.6667" stroke="#FF8A00" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
